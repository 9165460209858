import { NumericFormat } from "react-number-format";
import {
  ICON_DELETE,
  ICON_INFO,
  ICON_RIGHT,
  ICON_RLOCNG_RIGHT,
} from "../../utils/useIcons";
import {
  PARENT_INPUT_BALANCE_CARD,
  STYLE_CARD_BG,
  STYLE_CARD_STRAGADY,
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../utils/useStyle";
import { HeadtitleCard } from "../header/Header";
import { DeleteButtonCard, MTButton } from "../button/MTButton";
import { useNavigate } from "react-router-dom";
import { InputMain } from "../input/InputMain";
import { ImageMain } from "../img/Img.Main";

export const CardMain = ({
  title,
  onClick,
  panel,
  icon,
  border,
  margin = "mt-6",
  mb,
  check,
  openCheckbox,
  onChangeCheckbox,
  marginyellow,
  classNameLine,
  stylehead
}) => {
  return (
    <div className={STYLE_CARD_BG}>
      <HeadtitleCard
        title={title}
        onClick={onClick}
        stylehead={stylehead}
        icon={icon}
        border={border}
        mb={mb}
        check={check}
        onChangeCheckbox={onChangeCheckbox}
        openCheckbox={openCheckbox}
        marginyellow={marginyellow}
        classNameLine={classNameLine}
      />
      <div className={margin}>{panel}</div>
    </div>
  );
};

export const CardDetailsTax = ({ title, value, path }) => {
  const navigate = useNavigate();

  return (
    <div className="  flex justify-between px-5 md:text-lg  text-md">
      <div className=" text-maingray text-center ">{title}</div>
      <div className=" flex items-center ">
        <div className="text-maincyan text-center font-medium">{value} บาท</div>
        <ICON_RIGHT
          className={` w-6 h-6 text-maingray hover:text-maincyan cursor-pointer `}
          // onClick={() => {
          //   navigate(path);
          // }}
          onClick={path}
        />
      </div>
    </div>
  );
};

export const CardStrategy = ({
  title,
  value1,
  value2,
  value3,
  value4,
  unit,
  twoline,
  icon,
  icon_width = "w-7 h-7",
  padding = "p-2.5",
  space = "space-y-2",
  etc,
}) => {
  return (
    <div className={`${STYLE_CARD_STRAGADY} ${padding}  ${space}`}>
      <div className=" flex items-center gap-2">
        {icon && <img src={icon} className={icon_width} alt="" />}
        <div className="text-md md:text-lg font-medium text-black ">
          {" "}
          {title}
        </div>
      </div>
      <div className="flex items-center text-md md:text-lg  ">
        <div className="font-medium text-maingray">
          {value1} {unit}
        </div>
        <ICON_RLOCNG_RIGHT className="w-9 xxs:mx-2 xs:mx-5 mx-5" />
        <div className="font-semibold text-maincyan">
          {value2} {unit}
        </div>
      </div>
      {twoline && (
        <div className="flex items-center gap-5">
          <div className="font-medium text-maingray">
            {value3} {unit}
          </div>
          <ICON_RLOCNG_RIGHT className="w-9" />
          <div className="font-medium text-maincyan">
            {value4} {unit}
          </div>
        </div>
      )}
      {etc}
    </div>
  );
};

export const CardStrategy2 = ({
  title,
  value1,
  value2,
  value3,
  value4,
  unit,
  twoline,
  icon,
  icon_width = "w-7 h-7",

  etc,
}) => {
  return (
    <div
      className={` bg-blueopacity rounded-[10px] font-IBMPlexSansThai space-y-1 p-2`}
    >
      <div className=" flex items-center gap-2">
        {/* {icon && <img src={icon} className={icon_width} alt="" />} */}
        {/* <div className="text-md md:text-lg font-medium text-black ">
          {" "}
          {title}
        </div> */}
      </div>
      <div className="flex items-center text-md md:text-lg  ">
        <div className="font-medium text-maingray">
          {value1} {unit}
        </div>
        <ICON_RLOCNG_RIGHT className="w-9 xxs:mx-2 xs:mx-5 mx-5" />
        <div className="font-semibold text-maincyan">
          {value2} {unit}
        </div>
      </div>
      {twoline && (
        <div className="flex items-center gap-5">
          <div className="font-medium text-maingray">
            {value3} {unit}
          </div>
          <ICON_RLOCNG_RIGHT className="w-9" />
          <div className="font-medium text-maincyan">
            {value4} {unit}
          </div>
        </div>
      )}
      {etc}
    </div>
  );
};

export const CardDeduction = ({
  title,
  amount,
  onClick,
  minus,
  colorbg = "bg-white",
  onClickDelete,
}) => {
  return (
    <div
      className={`${colorbg} h-10 rounded-full p-2 grid grid-cols-2 justify-between items-center relative`}
    >
      <div className=" text-black text-sm font-medium flex gap-2 items-center ">
        {minus ? (
          <ICON_DELETE onClick={onClickDelete} />
        ) : (
          <div className=" w-[14px]"></div>
        )}
        <div className="  line-clamp-1">{title}</div>
      </div>
      <div className=" flex items-center gap-2 font-medium text-maincyan h-fit ml-auto">
        <div>{amount} บาท</div>
      </div>
    </div>
  );
};

export const CardDeductionChild = ({ title, amount, onClickDelete, minus }) => {
  return (
    <div
      className={` bg-gray50 h-[54px] rounded-[20px] p-4 py-[14px] grid grid-cols-2 justify-between items-center relative`}
    >
      <div className=" text-black text-sm font-medium flex gap-2 items-center">
        {minus ? (
          <svg
            onClick={onClickDelete}
            xmlns="http://www.w3.org/2000/svg"
            className=" md:w-4 md:h-4  w-4 h-4 flex-none cursor-pointer"
            viewBox="0 0 16 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.8662 6.15407C2.29365 6.16442 2.63173 6.5171 2.62131 6.94181L1.84735 6.92308L1.07338 6.90434C1.0838 6.47963 1.43876 6.14373 1.8662 6.15407ZM1.84735 6.92308C1.07338 6.90434 1.07338 6.90434 1.07338 6.90434L1.07235 6.94807L1.06956 7.07238C1.06721 7.18044 1.06396 7.33725 1.06034 7.53284C1.05312 7.92395 1.04446 8.47047 1.03868 9.09247C1.02716 10.333 1.02698 11.8865 1.07371 13.1062C1.12484 14.4402 1.26247 16.1669 1.37603 17.4527C1.50429 18.9048 2.73177 20 4.18698 20H11.813C13.2682 20 14.4957 18.9048 14.624 17.4527C14.7375 16.1669 14.8752 14.4402 14.9263 13.1062C14.973 11.8865 14.9728 10.333 14.9613 9.09247C14.9555 8.47047 14.9469 7.92395 14.9397 7.53284C14.936 7.33725 14.9328 7.18044 14.9304 7.07238L14.9277 6.94807L14.9266 6.90515C14.9162 6.48044 14.5612 6.14373 14.1338 6.15407C13.7063 6.16442 13.3683 6.5171 13.3787 6.94181L13.8719 6.92987C13.3787 6.94181 13.3787 6.94181 13.3787 6.94181L13.3797 6.98354L13.3824 7.10565C13.3847 7.2122 13.388 7.36732 13.3915 7.56106C13.3987 7.94862 13.4073 8.49035 13.413 9.10666C13.4245 10.3427 13.4243 11.8661 13.379 13.0477C13.3295 14.3412 13.1948 16.0358 13.0815 17.3182C13.0245 17.9641 12.4774 18.4615 11.813 18.4615H4.18698C3.52258 18.4615 2.97554 17.9641 2.91849 17.3182C2.80522 16.0358 2.67055 14.3412 2.62098 13.0477C2.5757 11.8661 2.57552 10.3427 2.587 9.10666C2.59273 8.49035 2.60132 7.94862 2.60847 7.56106C2.61205 7.36732 2.61527 7.2122 2.61759 7.10565L2.62032 6.98354L2.62131 6.94181L1.84735 6.92308Z"
              fill="#AA1616"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 3.84615C0 3.42132 0.346618 3.07692 0.774194 3.07692H15.2258C15.6534 3.07692 16 3.42132 16 3.84615C16 4.27099 15.6534 4.61539 15.2258 4.61539H0.774194C0.346618 4.61539 0 4.27099 0 3.84615Z"
              fill="#AA1616"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.25655 1.49321C4.7499 0.574111 5.71292 0 6.76129 0H9.23871C10.2871 0 11.2501 0.57411 11.7435 1.49321L12.8121 3.48416C13.0134 3.85902 12.8706 4.32497 12.4934 4.52489C12.1161 4.72481 11.6471 4.583 11.4459 4.20815L10.3772 2.21719C10.153 1.79942 9.71524 1.53846 9.23871 1.53846H6.76129C6.28476 1.53846 5.84702 1.79942 5.62277 2.21719L4.55408 4.20815C4.35287 4.583 3.88391 4.72481 3.50664 4.52489C3.12937 4.32497 2.98664 3.85902 3.18786 3.48416L4.25655 1.49321Z"
              fill="#AA1616"
            />
          </svg>
        ) : (
          <div className=" w-[14px]"></div>
        )}
        <div className="  line-clamp-1 font-medium">{title}</div>
      </div>
      <div className=" flex items-center gap-2 font-medium text-maincyan h-fit ml-auto">
        <div>{amount} บาท</div>
      </div>
    </div>
  );
};

export const CardFinancialResult = ({
  title,
  value,
  status,
  amount,
  openPopup,
  openInfo,
}) => {
  return (
    <div className=" bg-white px-2.5 py-2.5 rounded-[10px] font-IBMPlexSansThai h-fit ">
      {value ? (
        <div className=" flex justify-between items-start">
          <div className=" ">
            <div className=" mb-2 text-mainblue font-medium flex  gap-1 ">
              {title}
              <div>
                <ICON_INFO
                  onClick={openInfo}
                  className={` w-4 h-4 text-maingray mt-1`}
                />
              </div>
            </div>
            {status === "good" && (
              <div className=" bg-[#16AA37]/30 px-[14px] py-[2px] text-maingreen font-medium text-sm rounded-[5px] w-fit h-fit ">
                ยอดเยี่ยม
              </div>
            )}
            {status === "normal" && (
              <div className=" bg-[#FFA800]/30 px-[14px] py-[2px] text-[#FFA800] font-medium text-sm rounded-[5px] w-fit h-fit ">
                พอใช้
              </div>
            )}
            {status === "chance" && (
              <div className=" bg-[#FFA800]/30 px-[14px] py-[2px] text-[#FFA800] font-medium text-sm rounded-[5px] w-fit h-fit ">
                เพิ่มโอกาส
              </div>
            )}
            {status === "bad" && (
              <div className=" bg-[#FF0000]/30 px-[14px] py-[2px] text-[#FF0000] font-medium text-sm rounded-[5px] w-fit h-fit ">
                ปรับปรุง
              </div>
            )}
          </div>
          <div onClick={openPopup} className=" flex gap-1 items-center">
            <div
              className={` 
            ${status === "good" && "text-maingreen"} 
            ${status === "chance" && "text-[#FFA800]"} 
          
            ${status === "bad" && "text-[#FF0000]"} 

            text-xl font-semibold text-right text-nowrap
            `}
            >
              {amount}
            </div>
            {status === "good" && (
              <img
                src="/images/happy_finance.svg"
                className=" w-11 cursor-pointer"
                alt=""
              />
            )}
            {status === "normal" && (
              <img
                src="/images/medium_finance.svg"
                className=" w-11 cursor-pointer"
                alt=""
              />
            )}
            {status === "chance" && (
              <div className="">
                <img
                  src="/images/chance_finance.svg"
                  className=" h-12 w-12 cursor-pointer"
                  alt=""
                />
              </div>
            )}

            {status === "bad" && (
              <img
                src="/images/bad_finance.svg"
                className=" w-11 cursor-pointer"
                alt=""
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className=" flex justify-between items-start">
            <div>
              <div className=" mb-2 text-mainblue font-medium flex items-center gap-1 ">
                {title}
                <div>
                  <ICON_INFO
                    onClick={openInfo}
                    className={` w-4 h-4 text-maingray cursor-pointer`}
                  />
                </div>
              </div>
              <div className=" bg-[#b9b9b9]/30 px-[14px] py-[2px] text-gray80 font-medium text-sm rounded-[5px] w-fit ">
                ไม่มีข้อมูล
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const CardYearInput = ({ onChange, onClickDelete, value }) => {
  return (
    <div className={PARENT_INPUT_BALANCE_CARD}>
      <span className="text-black font-medium"> ปีละ </span>
      <NumericFormat
        maxLength={11}
        value={value}
        placeholder="1,000,000"
        onChange={onChange}
        thousandSeparator=","
        inputMode="decimal"
        pattern="[0-9],*"
        className={STYLE_INPUT}
      />

      <ICON_DELETE
        className=" w-5 text-maincyan mr-1 cursor-pointer"
        onClick={onClickDelete}
      />
    </div>
  );
};

export const CardTable = ({
  head = "ผู้ค้ำประกัน",
  title_1 = "ชื่อ",
  title_2 = "ความสัมพันธ์",
  name1,
  name2,
  relationship1,
  relationship2,
  onChangeName1,
  onChangeName2,
  onChangeRelationship1,
  onChangeRelationship2,
}) => {
  return (
    <div className=" mt-2.5">
      <div className={STYLE_TITLE_CARD_DRAWER_BL}>{head}</div>
      <div className=" bg-maincyan/10 grid grid-cols-2 rounded-[5px] p-[5px] mt-[5px] h-[30px] items-center">
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} `}>{title_1}</div>
        <div className={`${STYLE_TITLE_CARD_DRAWER_BL} ml-3`}>{title_2}</div>
      </div>
      <div className=" mt-2.5 bg-[#f9f9f9] p-2 rounded-[5px]">
        <div className=" flex items-center ">
          <div className={STYLE_TITLE_CARD_DRAWER_BL}>1.</div>
          <div className=" grid grid-cols-2 space-x-1 items-center ">
            <InputMain
              placeholder={` ระบุ${title_1}`}
              value={name1}
              onChange={onChangeName1}
              className={`${STYLE_INPUT_CARD_BALANCE} `}
            />
            <InputMain
              placeholder={`ระบุ${title_2}`}
              value={relationship1}
              onChange={onChangeRelationship1}
              className={`${STYLE_INPUT_CARD_BALANCE} `}
            />
          </div>
        </div>
        <div className=" flex items-center mt-2.5 ">
          <div className={STYLE_TITLE_CARD_DRAWER_BL}>2.</div>
          <div className=" grid grid-cols-2 space-x-1 items-center ">
            <InputMain
              placeholder=" ระบุชื่อ - นามสกุล"
              value={name2}
              onChange={onChangeName2}
              className={`${STYLE_INPUT_CARD_BALANCE} `}
            />
            <InputMain
              placeholder="ระบุความสัมพันธ์"
              value={relationship2}
              onChange={onChangeRelationship2}
              className={`${STYLE_INPUT_CARD_BALANCE} `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardTips = ({ title = "คุณสมบัติผู้สมัคร", panel }) => {
  return (
    <div className="">
      <div className=" flex items-center gap-2 px-3 py-2 bg-[#f6f6f6] text-black rounded-t-[20px]">
        <ImageMain src="../images/lamp.png" className=" w-5 h-5 " />
        <div className=" font-medium">{title}</div>
      </div>
      <div className=" bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 text-black text-sm w-full h-full space-y-1">
        {panel}
      </div>
    </div>
  );
};

export const CardGrayMenu = ({ img, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`bg-gray50 group hover:bg-blueopacity duration-200 px-[18px] py-2.5 rounded-[20px]   cursor-pointer`}
    >
      <div className="flex items-center  ">
        <div className={`flex items-center space-x-[17px] w-full  `}>
          <ImageMain src={img} className=" w-[50px] h-[50px] " />
          <div className=" text-sm text-black font-semibold ">{title}</div>
        </div>
        <div className={`flex items-center justify-end `}>
          <ICON_RIGHT
            className={` w-6 h-6 flex-none ml-auto text-[#14181F] `}
          />
        </div>
      </div>
    </div>
  );
};

export const CardProduct = ({ title, panel, onClick }) => {
  return (
    <div className=" border border-maincyan rounded-[10px] p-4">
      <div className=" flex justify-between items-center">
        <div className=" font-semibold text-black text-sm">
          ผลิตภัณฑ์ที่เหมาะกับคุณ
        </div>
        <MTButton
          onClick={() => {
            window?.open(
              "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
            );
          }}
          width="w-fit px-2"
          text="ปรึกษาผู้เชี่ยวชาญ"
          color="bg-mainblue"
          colortext="text-white group-hover:text-white font-semibold text-xs"
        />
      </div>

      <div className=" grid grid-cols-2 gap-2 my-3 rouded-[10px]">
        <ImageMain
          src="../images/product.png"
          className=" w-full h-full rouded-[10px] object-cover "
        />
        <div>
          <div className=" text-mainblue font-semibold">
            วางแผนความมั่นคง เพื่อครอบครัว
          </div>
          <div className=" text-xs text-maingray leading-4">
            Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do
            eiusmod tempor inc ididunt ut
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardHeadgray = ({ title, panel, text_color = "text-black" }) => {
  return (
    <div className="">
      <div className=" flex items-center gap-2 px-3 py-2 bg-[#f6f6f6] text-black font-semibold rounded-t-[20px]">
        <div className=" font-medium">{title}</div>
      </div>
      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-1`}
      >
        {panel}
      </div>
    </div>
  );
};
