import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PDF_ITEM_TOTAL } from "../styles/pdf.style";
import {
  options_expenses_fixed,
  options_expenses_invest,
  options_expenses_variable,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calExpenseFixedOption,
  calExpenseVariableMonthOption,
  calExpenseVariableOption,
  calPropertyLocalOption,
  getAllBalanceTotal,
  getAllPropertyTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageBalananceSeven({page}) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const mergeOptions = useMemo(() => {
    return [
      {
        label: "ค่าใช้จ่ายคงที่",
        key: "expenses_fixed_list",
        key_option: "options_expenses_fixed",
        options: options_expenses_fixed,
        icon: "accounting.svg",
        calLocalOption: calExpenseFixedOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
      {
        label: "ค่าใช้จ่ายเพื่อออม/ลงทุน",
        key: "expenses_invest_saving_list",
        key_option: "options_expenses_invest",
        options: options_expenses_invest,
        icon: "piggy-bank.svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: calExpenseVariableMonthOption,
      },
      {
        label: "ค่าใช้จ่ายแปรผัน",
        key: "expenses_variable_list",
        key_option: "options_expenses_variable",
        options: options_expenses_variable,
        icon: "cost (1).svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
    ];
  }, [balanceData]);

  const propertyItems = useMemo(() => {
    const newPropertyItems = getBalanceItems(
      balanceData,
      mergeOptions
      // calExpenseVariableOption,
      // calExpenseVariableMonthOption
    );
    return newPropertyItems;
  }, [balanceData]);

  // console.log("propertyItems", propertyItems);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="งบดุล" icon="/images/balance.svg" />
      <PdfCard title={"กระแสเงินสด"}>
        {/* รายจ่าย */}
        <PdfCardWhite className={"flex flex-col"}>
          
          <PdfBalanceHeaderItemCard title={"แหล่งที่มาของรายจ่าย"} />
          <PdfLine className={"my-2"} />

          <div className="mx-2 font-[500] text-[11px] my-1">รายจ่าย</div>

          {propertyItems
            .filter((propertyItem) => propertyItem.label === "ค่าใช้จ่ายแปรผัน")
            .map((propertyItem, index) => {
              return (
                <div key={index} className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={`/images/${propertyItem?.icon}`}
                        className="bg-cover"
                      />
                    </div>
                    <div className="text-[11px] font-[500]">
                      {propertyItem?.label}
                    </div>
                  </div>

                  <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                    {propertyItem.items.map((item, index) => {
                      return <PdfBalanceItemCard key={index} item={item} />;
                    })}
                  </PdfCardGray>
                </div>
              );
            })}
        </PdfCardWhite>

        {/* รวม */}
        {/* <PdfCardWhite className={"flex flex-col mt-2 "}>
          <div className="flex flex-col mx-2">
            <div className="flex justify-between">
              <div className="text-[11px] font-[500]">รายรับทั้งหมด</div>
              <div className="text-[12px] font-[600]">0,000 บาท</div>
            </div>
            <div className="flex justify-between">
              <div className="text-[11px] font-[500]">รายรับทั้งหมด</div>
              <div className="text-[12px] font-[600]">0,000 บาท</div>
            </div>
            <div className="flex justify-between">
              <div className="text-[11px] font-[500]">รายรับทั้งหมด</div>
              <div className="text-[12px] font-[600]">0,000 บาท</div>
            </div>
          </div>
        </PdfCardWhite> */}
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageBalananceSeven;
