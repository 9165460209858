import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";

function PdfPageFamilyThree({page}) {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  const status = useMemo(() => {
    if (insuranceData.sum_disabled_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_disabled_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  }, [insuranceData]);

  const familyItems = useMemo(() => {
    const newFamilyItems = [
      //   {
      //     label: "ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว 5 ปี",
      //     value: numeral(insuranceData?.sum_family_five_year)?.format("0,0"),
      //   },
      {
        label: "ทุนประกันสุทธิ",
        value: numeral(insuranceData?.sum_disabled_insurance_net)?.format(
          "0,0"
        ),
      },
      {
        label: "ทุนประกันที่คุณมี ณ ปัจจุบัน",
        value: numeral(insuranceData?.sum_disabled_insurance_current)?.format(
          "0,0"
        ),
      },
      {
        label: "มูลค่าทรัพย์สิน",
        value: numeral(insuranceData?.sum_disabled_asset_current)?.format(
          "0,0"
        ),
      },
      {
        label: "ทุนประกันแนะนำ",
        value: numeral(insuranceData?.sum_disabled_insurance_more)?.format(
          "0,0"
        ),
      },
    ];
    return newFamilyItems;
  }, [insuranceData]);

  const checkstatus = (status) => {
    if (status === "more") {
      return "ต้องซื้อเพิ่มอีก";
    } else if (status === "normal") {
      return "ยอดเยี่ยม";
    } else if (status === "toomuch") {
      return "ทุนประกันเกินจากที่แนะนำ";
    }
  };

  const checkicon = () => {
    if (status === "more") {
      return "../images/bad_face.png";
    } else if (status === "normal") {
      return "../images/verygoodface.png";
    } else if (status === "toomuch") {
      return "../images/taxicon3.png";
    }
  };

  const checkText = (status) => {
    if (status === "more") {
      return (
        <div className=" text-maincyan font-semibold text-[15px] text-center">
          {numeral(insuranceData.sum_disabled_insurance_more)?.format("0,0")}{" "}
          บาท
        </div>
      );
    } else if (status === "normal") {
      return (
        <div className=" text-mainblue font-semibold text-[15px] text-center">
          คุณมีทุนประกันที่วางแผนมาแล้วอย่างพอดี
        </div>
      );
    } else if (status === "toomuch") {
      return (
        <div className=" text-mainblue font-semibold text-[15px] text-center">
          {/* คุณรักครอบครัวมาก มีการคุ้มครองมาดีแล้ว */}
        </div>
      );
    }
  };

  const recomenedItems = useMemo(() => {
    const newRecomenedItems = [
      {
        label: "ประกันอุบัติเหตุวงเงินสูง",
      },
      {
        label: "ประกันที่มีสัญญาเพิ่มเติมคุ้มครองการทุพพลภาพ (WP)",
      },
    ];
    return newRecomenedItems;
  }, []);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนทุนประกันทุพพลภาพ" icon="/images/personal.svg" />
      {/* ทุนประกัน */}
      <PdfCard className={"flex flex-col space-y-3"} title="ทุนประกัน">
        <PdfCardWhite>
          <FamilyBarChart
            yourData={[insuranceData?.sum_disabled_insurance_current, 0]}
            recomenedData={[0, insuranceData.sum_disabled_insurance_more]}
          />
        </PdfCardWhite>
      </PdfCard>

      {/* ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย */}
      <PdfCard
        className={"flex flex-col space-y-3"}
        title="ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย"
      >
        <PdfCardWhite>
          <PdfFamilyHeaderItemCard title={"รายการ"} />
          <PdfLine />

          {familyItems.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-between items-center mx-4"
              >
                <div className="flex items-center space-x-2">
                  <div>
                    <img src="/images/money_bag.svg" />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {item.label}
                  </div>
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                  {item.value} บาท
                </div>
              </div>
            );
          })}

          <div className=" mx-auto flex justify-center items-center space-x-2">
            <div className="flex justify-center items-center gap-2 font-medium">
              <ImageMain
                src={checkicon(status)}
                alt="insu_plan_3"
                className=" w-6 h-6"
              />
              <div className="text-[15px]">{checkstatus(status)}</div>
            </div>
            {checkText(status)}
          </div>
          {/* <div className={cn(STYLE_PDF_TEXT_NORMAL_BLUE_12, "text-center")}>
            คุณรักครอบครัวมาก มีการคุ้มครองมาดีแล้ว
          </div> */}
        </PdfCardWhite>
      </PdfCard>
      <PdfCard>
        <PdfCardWhite>
          <div
            className={cn(
              " px-6 flex justify-center text-center",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            ติดต่อนักวางแผนการเงิน CFP® / ตัวแทนประกันชีวิต
            ที่ท่านไว้วางใจเป็นเพื่อนคู่คิดตลอดเส้นทางชีวิต
          </div>
        </PdfCardWhite>

        <div className="flex items-center space-x-2 my-3">
          <div>
            <img src="/images/lamp_yellow.svg" />
          </div>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>
            เครื่องมือช่วยให้บรรลุเป้าหมาย
          </div>
        </div>

        <PdfCardWhite>
          <div className="flex flex-col mx-3 space-y-1 py-1">
            {recomenedItems.map((item, index) => {
              return (
                <div key={index} className={cn(STYLE_PDF_TEXT_NORMAL_11)}>
                  {index + 1}. {item.label}
                </div>
              );
            })}
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageFamilyThree;
