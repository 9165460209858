import { useNavigate } from "react-router-dom";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { ICON_INFO, ICON_NAVIGATE } from "../../utils/useIcons";
import { STYLE_TITLE_DRAWER } from "../../utils/useStyle";
import CheckBoxMain from "../checkbox/CheckboxMain";
import { Checkbox } from "@material-tailwind/react";
import { ImageMain } from "../img/Img.Main";
import { cn } from "../../utils/useFunctions";

export const HeaderTitle = ({
  title = "",
  title_2 = "",
  colortext = "text-mainblue",
  color = "bg-secoundblue",
  isGoback,
  onClickGoBack,
  rounded = "rounded-r-full",
}) => {
  // const navigate = useNavigate();

  // const onGoBack = () => {
  //   NavigateGoBack(navigate);
  // };
  return (
    <div className={`${color} w-11/12 py-[7px] ${rounded} `}>
      {isGoback ? (
        <div className=" pl-[18px]  flex space-x-[10px] items-center ">
          <svg
            className="cursor-pointer"
            onClick={onClickGoBack}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M15 19.5L8 12.5L15 5.5"
              stroke="#18488A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className={`text-xl font-semibold ${colortext}`}>
            {title}
            <div className={`text-xl font-semibold ${colortext}`}>
              {title_2}
            </div>
          </div>
        </div>
      ) : (
        <div className={` pl-8  text-xl font-semibold ${colortext}`}>
          {title}
        </div>
      )}
    </div>
  );
};

export const HeadtitleCard = ({
  title = "",
  bordercolor = "",
  onClick,
  icon,
  width = ` md:w-[200px]  w-[119px]`,
  border = true,
  mb = "mb-4",
  check = false,
  onChangeCheckbox,
  openCheckbox,
  stylehead = "text-black font-medium",
  marginyellow,
  classNameLine
}) => {
  return (
    <div className={mb}>
      <div className=" flex gap-[6px] items-center font-IBMPlexSansThai h-6">
        <div className={` md:text-lg text-sm  pl-[14px] ${stylehead}`}>
          {title}
        </div>
        {icon && (
          <ICON_INFO
            className=" w-[15px] h-[15px] cursor-pointer hover:text-maincyan duration-100 flex-none "
            onClick={onClick}
          />
        )}
        {check && (
          <div className=" ml-auto">
            <Checkbox
              onChange={onChangeCheckbox}
              // onChange={handleChange}
              // onClick={() => {
              //   setOpen(value);
              // }}
              size={"sm"}
              className="p-0 bg-white"
              color="cyan"
              checked={openCheckbox}
              ripple={false}
            />
          </div>
        )}
      </div>
      {border && (
        <div
          className={cn(`border-b border-mainyellow ${width} ${marginyellow}`, classNameLine)}
        ></div>
      )}
    </div>
  );
};

export const HeaderDrawer = ({ title, onClickGoBack }) => {
  return (
    <div className=" grid grid-cols-7  mt-10 ml-6 pb-1">
      <ICON_NAVIGATE
        onClick={onClickGoBack}
        className=" w-6 text-gray90 col-span-2 "
      />
      <div
        className={` text-2xl sm:text-3xl font-IBMPlexSansThai font-semibold text-mainblue col-span-5 `}
      >
        {title}
      </div>
    </div>
  );
};

export const HeadWithIcon = ({ img, title }) => {
  return (
    <div className=" flex items-center ">
      <ImageMain src={img} className=" w-8 h-8" />
      <div className=" text-mainblue text-xl font-semibold">{title} </div>
    </div>
  );
};
