import { CardTest } from "../styles/Card";

const ViewNumberNine = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        nine_question_choice_one: true,
        nine_question_choice_two: false,
        nine_question_choice_three: false,
        nine_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        nine_question_choice_one: false,
        nine_question_choice_two: true,
        nine_question_choice_three: false,
        nine_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        nine_question_choice_one: false,
        nine_question_choice_two: false,
        nine_question_choice_three: true,
        nine_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        nine_question_choice_one: false,
        nine_question_choice_two: false,
        nine_question_choice_three: false,
        nine_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 9 จาก 10</div>
      <CardTest
        choice1={<div>5% หรือน้อยกว่า</div>}
        choice2={<div>มากกว่า 5% - 10%</div>}
        choice3={<div>มากกว่า 10% - 20%</div>}
        choice4={<div>มากกว่า 20% ขึ้นไป</div>}
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest.nine_question_choice_one}
        checked2={formInvest.nine_question_choice_two}
        checked3={formInvest.nine_question_choice_three}
        checked4={formInvest.nine_question_choice_four}
        question="9. ท่านจะรู้สึกกังวล/รับไม่ได้เมื่อมูลค่าเงิน
        ลงทุนของท่านมีการปรับตัวลดลงในสัดส่วน
        เท่าใด"
      />
    </div>
  );
};

export default ViewNumberNine;
