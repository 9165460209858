import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Checkbox,
} from "@material-tailwind/react";
import { ICON_INFO } from "../../utils/useIcons";
import { useState } from "react";
import { useStrategyStore } from "../../_store/strategyRetireStore";

const CheckBoxMain = ({
  label,
  label2,
  value,
  onChange,
  info,
  onClickInfo,
  panel,

  index,
  disabled = false,
  setChecked,
  handleOpen,
  setOpen,
  retireData,
  setRetireData,
  field,
  field2,
  open,
  icon,
  icon_width = "w-7 h-7",
  ishasRightLabel = true,
}) => {
  const clearvalue = () => {
    // setRetireData((retireData) => ({
    //   ...retireData,
    // [field]: "",
    // [field2]: "",
    // }));
    setRetireData({
      ...retireData,
      [field]: "",
      [field2]: "",
    });
  };

  // const handleChange = (e) => {
  //   // if (!setOpenpasssive) return;
  //   const value = e.target.checked;
  //   setOpen(value);
  //   if (!open) {
  //     setOpen((value) => {
  //       value : "",
  //     })
  //   }

  // };
  const handleChange = (e) => {
    const value = e.target.checked;
    setOpen(value);
    if (!value) {
      clearvalue();
    }
    // clearvalue(value);
  };

  return (
    <div key={index} className=" ">
      <div className=" -ml-3 ">
        <Checkbox
          onChange={handleChange}
          // onClick={() => {
          //   setOpen(value);
          // }}
          size={"sm"}
          className="p-0"
          color="cyan"
          checked={open}
          label={
            ishasRightLabel ? (
              <div className=" flex gap-2 items-center ">
                {icon && <img src={icon} className={icon_width} alt="" />}
                <div className=" text-md md:text-lg text-black  font-medium  hover:none ">
                  {label}
                  {label2}
                </div>
              </div>
            ) : (
              ""
            )
          }
          ripple={false}
        />
        {open && <div>{panel}</div>}
      </div>

      {/* <Accordion
        icon={
          <ICON_INFO
            className={`w-[15px] text-maingray cursor-pointer font-IBMPlexSansThai p-0`}
          />
        }
        open={open}
        className="group"
      >
        <AccordionHeader
          onClick={onClickInfo}
          className={` border-b-0 transition-colors text-md text-gray-800 font-IBMPlexSansThai flex justify-start p-0 w-full `}
        >
          <Checkbox
            // onChange={disabled ? null : handleChange}
            // onClick={onChange}
            size={"sm"}
            className="p-0"
            color="cyan"
            checked={handleChange(value)}
            id="ripple-on"
            label={
              <div className=" text-xs text-black font-medium flex-none ">
                {label}
              </div>
            }
            ripple={true}
          />
        </AccordionHeader>
        <AccordionBody index={index} className="p-0 my-1.5">
          {panel}
        </AccordionBody>
      </Accordion> */}

      {/* {open && <div>{panel}</div>} */}
    </div>
  );
};
export default CheckBoxMain;
