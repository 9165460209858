import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";

function PdfPageFamilyOne({ page }) {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  const status = useMemo(() => {
    if (insuranceData.sum_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  }, [insuranceData]);

  const familyItems = useMemo(() => {
    const newFamilyItems = [
      {
        label: "ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว 5 ปี",
        value: numeral(insuranceData?.sum_family_five_year)?.format("0,0"),
      },
      {
        label: `ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว ${insuranceData?.family_year_survive}`,
        value: numeral(insuranceData?.sum_family_remain)?.format("0,0"),
      },
      (insuranceData?.asset_is_debt === "Y" && {
        label: "หนี้สินทั้งหมดของคุณ",
        value: numeral(insuranceData?.sum_family_debt)?.format("0,0"),
      }),
      {
        label: "ทุนประกันสุทธิ",
        value: numeral(insuranceData?.sum_insurance_net)?.format("0,0"),
      },
      {
        label: "ทุนประกันที่คุณมี ณ ปัจจุบัน",
        value: numeral(insuranceData?.sum_insurance_current)?.format("0,0"),
      },
      {
        label: "มูลค่าทรัพย์สิน",
        value: numeral(insuranceData?.sum_asset_current)?.format("0,0"),
      },
    ];
    return newFamilyItems;
  }, []);

  const checkstatus = (status) => {
    if (status === "more") {
      return "ต้องซื้อเพิ่มอีก";
    } else if (status === "normal") {
      return "ยอดเยี่ยม";
    } else if (status === "toomuch") {
      return "ทุนประกันเกินจากที่แนะนำ";
    }
  };

  const checkicon = () => {
    if (status === "more") {
      return "../images/bad_face.png";
    } else if (status === "normal") {
      return "../images/verygoodface.png";
    } else if (status === "toomuch") {
      return "../images/taxicon3.png";
    }
  };

  const checkText = (status) => {
    if (status === "more") {
      return (
        <div className=" text-maincyan font-semibold text-[17px] text-center">
          {numeral(insuranceData.sum_insurance_more)?.format("0,0")} บาท
        </div>
      );
    } else if (status === "normal") {
      return (
        <div className=" text-mainblue font-semibold text-[17px] text-center">
          คุณมีทุนประกันที่วางแผนมาแล้วอย่างพอดี
        </div>
      );
    } else if (status === "toomuch") {
      return (
        <div className=" text-mainblue font-semibold text-[17px] text-center">
          คุณรักครอบครัวมาก มีการคุ้มครองมาดีแล้ว
        </div>
      );
    }
  };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="แผนทุนประกันคุ้มครองครอบครัว"
        icon="/images/family.svg"
      />
      {/* ทุนประกัน */}
      <PdfCard className={"flex flex-col space-y-3"} title="ทุนประกัน">
        <PdfCardWhite>
          <FamilyBarChart
            yourData={[insuranceData?.sum_insurance_current, 0]}
            recomenedData={[0, insuranceData.sum_insurance_more]}
          />
        </PdfCardWhite>
      </PdfCard>

      {/* ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย */}
      <PdfCard
        className={"flex flex-col space-y-3"}
        title="ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย"
      >
        <PdfCardWhite>
          <PdfFamilyHeaderItemCard title={"รายการ"} />
          <PdfLine />

          {familyItems.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-between items-center mx-4"
              >
                <div className="flex items-center space-x-2">
                  <div>
                    <img src="/images/money_bag.svg" />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {item.label}
                  </div>
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                  {item.value} บาท
                </div>
              </div>
            );
          })}

          {insuranceData?.asset_is_debt === "Y" && (
            <div className="flex flex-col mx-4">
              <div className="flex items-center space-x-2 mb-1">
                <div>
                  <img src="/images/money_bag.svg" />
                </div>
                <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                  ทุนประกันเพื่อการศึกษาบุตร
                </div>
              </div>
              <ViewTableFamily
                className={cn(STYLE_PDF_TEXT_NORMAL_BLUE_10, "p-1")}
              />
            </div>
          )}

          <div className=" mx-auto flex justify-center items-center space-x-2">
            <div className="flex justify-center items-center gap-2 font-medium">
              <ImageMain
                src={checkicon(status)}
                alt="insu_plan_3"
                className=" w-6 h-6"
              />
              <div className="text-[15px]">{checkstatus(status)}</div>
            </div>
            {checkText(status)}
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageFamilyOne;
