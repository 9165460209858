import _ from "lodash";
import numeral from "numeral";
import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  calDebtCreditCard,
  cn,
  mapDebtConditionColor,
} from "../../../utils/useFunctions";
import {
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE
} from "../../balance/functions";
import { options_debt } from "../../balance/styles/Card";
import {
  formatPeriod,
  formatPeriodTime
} from "../../debt/component/VIDebtTable";
import {
  PdfCard,
  PdfCardGray,
  PdfCardWhite
} from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderText
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_11
} from "../styles/pdf.style";

function PdfPageDebtTwo({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const debtShortItems = useMemo(() => {
    const newDebtShortItems = [];

    if (balanceData?.debt_short_list?.length > 0) {
      for (const debt_short_item of balanceData?.debt_short_list) {
        const keys = Object.keys(debt_short_item);
        for (const key of keys) {
          if (_.isArray(debt_short_item[key])) {
            for (const item of debt_short_item[key]) {
              let createItem = {
                title: "",
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: "",
                type_payment: "",
                type_payment_ori: item?.type_payment,
                label: item?.creditor_name,
                status: options_debt.find(
                  (option) => option?.value === item?.status
                ),
                unit_interest: "ต่อปี",
                sort: 0,
              };

              // เครดิตการ์ด
              if (debt_short_item?.options_debt_short === "debt_creditcard") {
                const creditor = calDebtCreditCard(item);
                const period = creditor.creditor_items.length;
                const typePayment = !item?.type_payment
                  ? ""
                  : item?.type_payment === "pay_minimum"
                  ? "จ่ายขั้นต่ำ(รายเดือน)"
                  : item?.type_payment === "pay_full"
                  ? "จ่ายเต็มจำนวน"
                  : "จ่ายแบบระบุจำนวน(รายเดือน)";
                createItem.period = !item?.type_payment ? "" : period;
                createItem.period_time = !item?.type_payment
                  ? ""
                  : formatPeriodTime(period);
                createItem.type_payment = typePayment;
                createItem.title = "บัตรเครดิต";
                createItem.sort = 1;
              }

              if (debt_short_item?.options_debt_short === "debt_shortterm") {
                createItem.sort = 2;
                createItem.type_payment = "รายเดือน";
              } else if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.sort = 3;
                createItem.type_payment = "รายวัน";
                createItem.period = `${item?.number_of_days} วัน`;
              } else if (
                debt_short_item?.options_debt_short === "debt_shortterm_other"
              ) {
                createItem.sort = 4;
                createItem.type_payment = "รายเดือน";
                createItem.unit_interest = "ต่อเดือน";
              }

              // เงินกู้ระยะสั้น, หนี้นอกระบบ, เงินกู้ระยะสั้นอื่นๆ
              if (
                [
                  "debt_shortterm",
                  "debt_informal_shortterm",
                  "debt_shortterm_other",
                ].includes(debt_short_item?.options_debt_short)
              ) {
                createItem.period_time = formatPeriodTime(item?.period);
              }

              if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.unpaid_amount = item?.start_amount;
              }
              newDebtShortItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtShortItems.sort((a, b) => a.sort - b.sort);
  }, [balanceData]);

  const debtLongItems = useMemo(() => {
    const newDebtLongItems = [];

    if (balanceData?.debt_long_list?.length > 0) {
      for (const debt_long_item of balanceData.debt_long_list) {
        const keys = Object.keys(debt_long_item);
        for (const key of keys) {
          if (_.isArray(debt_long_item[key])) {
            for (const item of debt_long_item[key]) {
              let createItem = {
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: formatPeriodTime(item?.period),
                type_payment: "",
                label: item.title,
              };

              // รถยนต์
              if (debt_long_item?.options_debt_long === "debt_car") {
                const findTypeCar = OPTIONS_TYPE_CAR?.filter((thisType) => {
                  if (thisType.value === item?.type_car) return thisType;
                });
                if (findTypeCar) {
                  createItem.label = `${findTypeCar[0]?.label} ${item?.title_car}`;
                }
              }

              // สินเชื่อที่อยู่อาศัย
              if (debt_long_item?.options_debt_long === "debt_place") {
                const findTypePlace = OPTIONS_TYPE_PLACE?.filter((thisType) => {
                  if (thisType.value === item?.type_place) return thisType;
                });
                const typePlace = item?.type_place_other
                  ? item?.type_place_other
                  : findTypePlace?.[0]?.label;
                if (typePlace) {
                  createItem.label = `${typePlace} (${item?.address_place})`;
                }
              }

              newDebtLongItems.push(createItem);
            }
          }
        }
      }
    }
    return newDebtLongItems;
  }, [balanceData]);

  const mergeDebtItems = useMemo(() => {
    const newMergeDebtItems = [
      {
        label: "หนี้ระยะสั้น",
        items: debtShortItems,
      },
      // {
      //   label: "หนี้ระยะยาว",
      //   items: debtLongItems,
      // },
    ];
    return newMergeDebtItems;
  }, [balanceData]);

  const totalItem = useMemo(() => {
    let total = 0;
    for (const item of [...debtShortItems, ...debtLongItems]) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const totalShortItem = useMemo(() => {
    let total = 0;
    for (const item of debtShortItems) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนจัดการหนี้สิน" icon="/images/wealth_1.svg" />

      {/* แผนจัดการหนี้สิน SECOND */}
      <PdfCard>
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
            1. รวมหนี้ระยะสั้นเป็นก้อนเดียวกัน
          </div>
          <div
            className={cn(
              "flex justify-between mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>จำนวนเงินค้างชำระ</div>
            <div>{numeral(totalShortItem).format("0,0")} บาท</div>
          </div>
          <PdfLine />
          <div className="flex space-x-2 items-center">
            <div>
              <img src="/images/emoji_bar_chart.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
              สินทรัพย์ไม่มีหลักประกัน
            </div>
          </div>

          <PdfCardGray className={cn("mt-2")}>
            {debtShortItems.map((item, index) => {
              return (
                <div key={index} className={cn("flex justify-between")}>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                    {item.label}
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                    {numeral(item.unpaid_amount).format("0,0")}
                  </div>
                </div>
              );
            })}
          </PdfCardGray>
          <div className="flex justify-between mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ยอดรวม</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13, "mr-2")}>
              {numeral(totalShortItem).format("0,0")}
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageDebtTwo;
