import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { NavigatePath } from "../../../functions/Navigate.Goback";
import {
  STYLE_CARD_BG,
  STYLE_HEADER_LEGACY,
  STYLE_HEAD_TITLE,
  STYLE_INPUT,
  STYLE_INPUT_SLIDER,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { CardMain, CardTips } from "../../../components/card/CardMain";
import { NumericFormat } from "react-number-format";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import ViewFooterDebt from "./VIFooterDebt";
import { BarChartGroupDebt } from "../styles/ChartDebt";
import { useEffect, useState } from "react";
import { ImageMain } from "../../../components/img/Img.Main";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { useDebtStore } from "../../../_store/debtplanStore";
import { ICON_ABOUTUS } from "../../../utils/useIcons";
import _ from "lodash";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import {
  calculateLoanDetails,
  calculateLoanPayments,
  calculateMonthlyPayment,
  calPMT,
  calTotalCreditorFloatInterest,
} from "../../../utils/useFunctions";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const Row = ({
  title,
  creditor_name,
  amount,
  interest,
  index,
  customAmountOfInterest = 0,
}) => {
  const amountOfInterest = customAmountOfInterest
    ? customAmountOfInterest
    : amount * (interest / 100);

  return (
    <div key={index} className={`flex justify-between items-center mb-2`}>
      <div className=" text-maingray shrink-0 w-[40vw]">
        {title} {creditor_name}
      </div>
      <div className="flex space-x-3 shrink-0 w-[40vw] px-3 justify-center">
        <div className={`${STYLE_TEXT_BLUE} text-center `}>
          <div>{numeral(amount)?.format("number")} บาท</div>
        </div>
      </div>
      <div className="flex space-x-3 shrink-0 w-[40vw] justify-center px-3">
        <div className={`${STYLE_TEXT_BLUE} text-center`}>
          <div>{numeral(interest).format("number")}%</div>
        </div>
      </div>
      <div className="flex space-x-3 shrink-0 w-[40vw] justify-center px-3">
        <div className={`${STYLE_TEXT_BLUE} text-center`}>
          <div>{numeral(amountOfInterest).format("number")}</div>
        </div>
      </div>
    </div>
  );
};

const RowTotal = ({ total, interest, totalInterest = 0 }) => {
  return (
    <div className="flex justify-between items-center text-md">
      <div className=" text-maingray shrink-0 w-[40vw]">ยอดรวม</div>
      <div className="flex space-x-3 shrink-0 w-[40vw] px-3 justify-center">
        <div className={`${STYLE_TEXT_BLUE} text-center text-maingray`}>
          <div>{numeral(total)?.format("0,0")} บาท</div>
        </div>
      </div>
      <div className="flex space-x-3 shrink-0 w-[40vw] px-3 justify-center">
        <div className={`${STYLE_TEXT_BLUE} text-center text-maingray`}>
          <div>{numeral(interest).format("0.00")}%</div>
        </div>
      </div>
      <div className="flex space-x-3 shrink-0 w-[40vw] justify-center px-3">
        <div className={`${STYLE_TEXT_BLUE} text-center text-maingray`}>
          <div>{numeral(totalInterest).format("number")}</div>
        </div>
      </div>
    </div>
  );
};

export const options_car_period = Array.from({ length: 73 })
  .fill(11)
  .map((value, i) => {
    return {
      label: `${value + i + 1} เดือน`,
      value: value + i + 1,
    };
  });

const ViewDebtPlanForm = () => {
  // ข้อมูลงบดุล
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  // console.log("balanceData : ", balanceData);
  // console.log("Short", balanceData?.debt_short_list);
  // console.log("Long", balanceData?.debt_long_list);

  const navigate = useNavigate();

  const setFormDebtPlan = useDebtStore((state) => state.setDebtData);
  const formDebtPlan = useDebtStore((state) => state.debtData);

  const [isHasCollateral, setIsHasCollateral] = useState(false);

  // console.log("formDebtPlan", formDebtPlan);

  // Form
  // const [formDebtPlan, setFormDebtPlan] = useState({
  //   sum_start_unpaid_amount_short: "",
  //   sum_unpaid_amount: "",
  //   loan_interest_rate: "",
  //   // รถ
  //   total_current_price_car: "",
  //   total_unpaid_amount_car: "",
  //   difference_total_car: "",
  //   car_interest_rate: 13,
  //   refinance_amount: 100000,
  //   total_car_interest_rate: 0,
  //   // หลังวางแผน
  //   pay_permonth_af: "",
  //   sum_unpaid_amount_af: "",
  //   total_creditor_receive_af: "",
  //   total_loan_interest_rate_af: "",
  //   total_save_amount_af: "",
  // });
  const [dataMostCar, setDataMostCar] = useState(null);
  // const [firstRender, setFirstRender] = useState(true);

  // useEffect(() => {
  //   setBalanceData({
  //     ...balanceData,
  //     ...formDebtPlan
  //   })
  // }, [formDebtPlan])

  // useEffect(() => {
  //   if (firstRender) {
  //     setFirstRender(false);
  //     setFormDebtPlan({
  //       ...debtData
  //     })
  //     return;
  //   }
  // }, [])

  // MAX loan interest rate
  const MAX_LOAN_INTEREST_RATE = 100;
  const MAX_CAR_INTEREST_RATE = 20;

  // ระยะเวลากู้
  const onChangePeriod = (e) => {
    setFormDebtPlan({
      ...formDebtPlan,
      car_period: e,
    });
  };

  // อัตราดอกเบี้ยเงินกู้ต่อปี (%)
  const onChangeLoanInterestRate = (e) => {
    try {
      let value = numberWithoutComma(e.target.value);
      let finalValue =
        value > MAX_LOAN_INTEREST_RATE ? MAX_LOAN_INTEREST_RATE : value;
      if (isNaN(value)) {
        setFormDebtPlan({
          ...formDebtPlan,
          temp_loan_interest_rate: "",
          loan_interest_rate: "",
          payPerMonth: 0,
        });
        setBalanceData({
          ...balanceData,
          is_change_debt: false,
        });
      } else {
        const totalCreditorReceive =
          formDebtPlan?.sum_start_unpaid_amount_short +
          formDebtPlan?.sum_start_unpaid_amount_short * (finalValue / 100);
        // formDebtPlan?.sum_start_unpaid_amount_short +
        // formDebtPlan?.sum_start_unpaid_amount_short * (finalValue / 100);

        const sumUnpaidAmountAf =
          formDebtPlan?.sum_start_unpaid_amount_short +
          formDebtPlan?.sum_start_unpaid_amount_short * (finalValue / 100);

        const newDebtPlan = {
          loan_interest_rate: finalValue,
          sum_unpaid_amount_af: sumUnpaidAmountAf,
          total_creditor_receive_af: totalCreditorReceive,
          total_loan_interest_rate_af:
            formDebtPlan?.sum_unpaid_amount - sumUnpaidAmountAf,
          total_save_amount_af:
            formDebtPlan?.sum_unpaid_amount - sumUnpaidAmountAf,
          temp_loan_interest_rate: finalValue,
        };
        const pmt = calPMT(
          value / 100 / 12,
          formDebtPlan?.year * 12,
          -formDebtPlan?.sum_start_unpaid_amount_short
        );

        setFormDebtPlan({
          ...formDebtPlan,
          ...newDebtPlan,
          payPerMonth: pmt,
        });
        setBalanceData({
          ...balanceData,
          is_change_debt: false,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // ดอกเบี้ย
  const onChangeCarInterestRate = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value)) {
      setFormDebtPlan({
        ...formDebtPlan,
        car_interest_rate: "",
      });
    } else {
      setFormDebtPlan({
        ...formDebtPlan,
        car_interest_rate: value,
      });
    }
    setBalanceData({
      ...balanceData,
      is_change_debt: false,
    });
  };

  // จำนวนเงินที่จะรีไฟแนนซ์
  const onChangeRefinanceAmount = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value)) {
      setFormDebtPlan({
        ...formDebtPlan,
        refinance_amount: "",
      });
    } else {
      setFormDebtPlan({
        ...formDebtPlan,
        refinance_amount: value,
      });
    }
    setBalanceData({
      ...balanceData,
      is_change_debt: false,
    });
  };

  // ระยะเวลาปี
  const onChangeYear = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value)) {
      setFormDebtPlan({
        ...formDebtPlan,
        year: "",
        payPerMonth: 0,
      });
    } else {
      const pmt = calPMT(
        formDebtPlan?.loan_interest_rate / 100 / 12,
        value * 12,
        -formDebtPlan?.sum_start_unpaid_amount_short
      );
      setFormDebtPlan({
        ...formDebtPlan,
        year: value,
        payPerMonth: pmt,
      });
    }
    setBalanceData({
      ...balanceData,
      is_change_debt: false,
    });
  };

  // เดือนละ
  const onChangePayPerMonth = (e) => {
    let value = numberWithoutComma(e.target.value);
    if (isNaN(value)) {
      setFormDebtPlan({
        ...formDebtPlan,
        pay_permonth_af: "",
      });
    } else {
      setFormDebtPlan({
        ...formDebtPlan,
        pay_permonth_af: value,
      });
    }
  };

  // รวมยอดเงินต้นค้างชำระ
  const sumStartUnpaidAmount = () => {
    // บัตรเครดิต
    const sumCreditCard = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.credit_cards?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้น
    const sumShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_terms?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้นอกระบบ
    const sumInformalShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.informal_short_terms?.map((row) => {
          result += row?.start_amount ? row?.start_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้นอื่นๆ
    const sumShortTermOther = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_term_others?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };

    const finalResult =
      sumCreditCard() +
      sumShortTerm() +
      sumInformalShortTerm() +
      sumShortTermOther();

    return finalResult;
  };

  // หาอัตราดอกเบี้ยต่อปี
  const loanInterestRate = () => {
    // บัตรเครดิต
    const sumInterestCreditCard = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.credit_cards?.map((row) => {
          const startUnpaidAmount = row?.unpaid_amount ? row?.unpaid_amount : 0;
          const interest = row?.interest ? row?.interest : 0;
          result += interest * startUnpaidAmount;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้น
    const sumInterestShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_terms?.map((row) => {
          const startUnpaidAmount = row?.unpaid_amount ? row?.unpaid_amount : 0;
          const interest = row?.interest ? row?.interest : 0;
          result += interest * startUnpaidAmount;
        });
      });
      return result;
    };
    // เงินกู้นอกระบบ
    const sumInterestInformalShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.informal_short_terms?.map((row) => {
          const startUnpaidAmount = row?.start_amount ? row?.start_amount : 0;
          const interest = row?.interest ? row?.interest : 0;
          result += interest * startUnpaidAmount;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้นอื่นๆ
    const sumInterestShortTermOther = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_term_others?.map((row) => {
          const startUnpaidAmount = row?.unpaid_amount ? row?.unpaid_amount : 0;
          const interest = row?.interest ? row?.interest : 0;
          result += interest * startUnpaidAmount;
        });
      });
      return result;
    };

    const sumInterest =
      sumInterestCreditCard() +
      sumInterestShortTerm() +
      sumInterestInformalShortTerm() +
      sumInterestShortTermOther();

    const finalResult = sumInterest / sumStartUnpaidAmount();

    return Math.ceil(finalResult);
  };

  // เงินที่ได้จากการรีไฟแนนซ์รถยนต์
  const calTotalInterestRate = () => {
    const result =
      formDebtPlan?.refinance_amount * (formDebtPlan?.car_interest_rate / 100);
    return result;
  };

  //---------- Set Has Collateral And Set Default Form ----------//
  useEffect(() => {
    // if (firstRender) return;

    // ดูว่ามีรถคันไหนที่ผ่านเงื่อนไข
    // 1.มูลค่ารถยนต์ปัจจุบัน > หนี้รถยนต์ โดยที่ ต้องมากกว่า 100,000 บาทขึ้นไป
    // 2.ต้องมีหนี้ระยะสั้น
    // 3.ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ต้องมากกว่าจำนวนหนี้ระยะสั้น
    if (balanceData) {
      //---- เช็คว่ามีหนี้รพยะสั้นไหม ----//
      if (balanceData?.sum_debt_short > 0) {
        let carItems = [];

        for (const debt_long_item of balanceData?.debt_long_list) {
          if (debt_long_item?.options_debt_long === "debt_car") {
            carItems = [...carItems, ...debt_long_item?.debt_long_cars];
          }
        }

        //---- หารถที่มูลค่าปัจจุบันเยอะที่สุด ----//
        const mostCar = () => {
          let latestItemMax = { current_price: 0, unpaid_amount: 0 };
          for (const carItem of carItems) {
            if (carItem.current_price > latestItemMax.current_price) {
              latestItemMax = carItem;
            }
          }
          return latestItemMax;
        };

        //---- ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ----//
        const differenceCurrentPriceAndDebtCar =
          mostCar().current_price - mostCar().unpaid_amount;

        //========== เช็คว่ามีรถที่สามารถขายเพื่อล้างหนี้ได้ไหม ==========//
        if (
          differenceCurrentPriceAndDebtCar > 100000 &&
          differenceCurrentPriceAndDebtCar > sumStartUnpaidAmount()
        ) {
          setIsHasCollateral(true);
          setDataMostCar(mostCar());
        } else {
          setIsHasCollateral(false);
        }

        //----------------------------------------------------//

        const monthlyPay = calculateLoanPayments(
          sumStartUnpaidAmount(),
          formDebtPlan.loan_interest_rate || loanInterestRate(),
          formDebtPlan?.year
        );

        const totalCreditorReceive = monthlyPay.totalPayment;
        // sumStartUnpaidAmount() +
        // sumStartUnpaidAmount() * (loanInterestRate() / 100);

        const sumUnpaidAmount =
          sumStartUnpaidAmount() +
          sumStartUnpaidAmount() * (loanInterestRate() / 100);

        const sumUnpaidAmountAf = monthlyPay.totalPayment;
        // sumStartUnpaidAmount() +
        // sumStartUnpaidAmount() * (loanInterestRate() / 100);

        const total_car_interest_rate = calTotalInterestRate();
        const carLoanDetail = calculateLoanPayments(
          formDebtPlan.refinance_amount,
          formDebtPlan.car_interest_rate,
          formDebtPlan.car_period?.value / 12
        );

        const newFormDebtPlan = {
          total_car_interest_rate,
          sum_start_unpaid_amount_short: sumStartUnpaidAmount(),
          loan_interest_rate: loanInterestRate(),
          sum_unpaid_amount: sumUnpaidAmount,
          sum_unpaid_amount_af: sumUnpaidAmountAf,
          total_creditor_receive_af: totalCreditorReceive,
          total_loan_interest_rate_af: monthlyPay.totalInterest,
          total_save_amount_af: sumUnpaidAmount - sumUnpaidAmountAf,
          // sumStartUnpaidAmount() +
          // sumStartUnpaidAmount() * (loanInterestRate() / 100) -
          // sumUnpaidAmountAf,
          total_per_month:
            total_car_interest_rate / 12 +
            formDebtPlan?.refinance_amount / formDebtPlan?.car_period?.value,
        };

        console.log("sumUnpaidAmount", sumUnpaidAmount);
        console.log("sumUnpaidAmountAf", sumUnpaidAmountAf);

        // Set Form
        setFormDebtPlan(
          balanceData.is_change_debt
            ? {
                ...formDebtPlan,
                ...newFormDebtPlan,
                carLoanDetail,
                year: balanceData?.year ?? formDebtPlan?.year,
                original_loan_interest_rate: loanInterestRate(),
              }
            : {
                ...formDebtPlan,
                ...newFormDebtPlan,
                carLoanDetail,
                year: balanceData?.year ?? formDebtPlan?.year,
                total_car_interest_rate:
                  // formDebtPlan?.total_car_interest_rate ??
                  total_car_interest_rate,
                sum_start_unpaid_amount_short:
                  // formDebtPlan?.sum_start_unpaid_amount_short ??
                  sumStartUnpaidAmount(),
                loan_interest_rate:
                  formDebtPlan?.loan_interest_rate ?? loanInterestRate(),
                sum_unpaid_amount:
                  // formDebtPlan?.sum_unpaid_amount ?? sumUnpaidAmount,
                  sumUnpaidAmount,
                sum_unpaid_amount_af:
                  // formDebtPlan?.sum_unpaid_amount_af ?? sumUnpaidAmountAf,
                  sumUnpaidAmountAf,
                total_creditor_receive_af:
                  // formDebtPlan?.total_creditor_receive_af ??
                  totalCreditorReceive,
                total_loan_interest_rate_af:
                  // formDebtPlan?.total_loan_interest_rate_af ??
                  monthlyPay.totalInterest,
                total_save_amount_af: newFormDebtPlan.total_save_amount_af,
                // formDebtPlan?.total_save_amount_af ??
                // sumStartUnpaidAmount() +
                //   sumStartUnpaidAmount() * (loanInterestRate() / 100) -
                //   sumUnpaidAmountAf,
                original_loan_interest_rate: loanInterestRate(),
                // รถ
                // total_current_price_car: totalCurrentPriceCar(),
                // total_unpaid_amount_car: totalStartUnpaidAmountCar(),
                // difference_total_car: differenceTotalCar,
              }
        );
        // setBalanceData({
        //   ...balanceData,
        //   ...newFormDebtPlan,
        // });
      }
    }
  }, [
    balanceData,
    formDebtPlan?.car_interest_rate,
    formDebtPlan?.refinance_amount,
    formDebtPlan?.car_period,
  ]);

  const getResult = () => {
    if (
      formDebtPlan.refinance_amount > formDebtPlan.total_creditor_receive_af
    ) {
      return "คุณสามารถนำเงินกู้ส่วนนี้ไปชำระหนี้ระยะสั้นได้";
    }
    return "คุณไม่สามารถนำเงินกู้ส่วนนี้ไปชำระหนี้ระยะสั้นได้";
  };

  const sumTotalWeightInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;
    let total_final = 0;

    if (!balanceData?.debt_short_list) return total_final;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * item.interest;
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest * 12;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest;
            }
          }
        }
      }
    }
    total_final = total_left / total_unpaid;
    return total_final;
  };

  const sumTotalInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;

    if (!balanceData?.debt_short_list) return total_left;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * (item.interest / 100);
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              const creditor = calTotalCreditorFloatInterest(item);
              const totalCreditorInterest = creditor.reduce(
                (acc, item, index) => {
                  if (index > 11) {
                    return acc;
                  }
                  return acc + item.interest;
                },
                0
              );
              total_unpaid += item.unpaid_amount;
              total_left += totalCreditorInterest;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * (item.interest / 100);
            }
          }
        }
      }
    }
    return total_left;
  };

  return (
    <Container>
      <div className={`${STYLE_MARGIN_TOP_PARENT}`}>
        <HeaderTitle
          title="วางแผนหนี้สิน"
          isGoback
          onClickGoBack={() => {
            navigate("/debtplan?tab=5");
          }}
        />
        <div
          className={`${STYLE_PADDING_MAIN} space-y-5 mb-10 overflow-hidden`}
        >
          <div className={`${STYLE_TEXT_CYAN}  text-center text-2xl`}>
            มาวางแผนหนี้สินกันเถอะ
          </div>
          {/*======================================== สินทรัพย์ไม่มีหลักประกัน ========================================*/}
          <div className="flex flex-col px-3 ">
            <div className="text-sm text-black mb-3">
              สินทรัพย์ไม่มีหลักประกัน
            </div>
            <div className=" space-y-2.5  w-[80vw] overflow-x-scroll overflow-y-hidden relative">
              <div className="flex items-center flex-nowrap ">
                <div
                  className={`${STYLE_TEXT_BLUE} text-sm flex w-[40vw] shrink-0 items-center px-3 bg-[#F4F2F3] rounded-l-lg h-[46px]`}
                >
                  รายการ
                </div>
                <div
                  className={`${STYLE_TEXT_BLUE} text-sm flex w-[40vw] shrink-0 items-center px-3 bg-[#F4F2F3] h-[46px]`}
                >
                  จำนวนเงินค้างชำระ
                </div>
                <div
                  className={`${STYLE_TEXT_BLUE} text-sm flex w-[40vw] shrink-0 items-center justify-center px-3 bg-[#F4F2F3] h-[46px]`}
                >
                  ดอกเบี้ย ต่อปี(%)
                </div>
                <div
                  className={`${STYLE_TEXT_BLUE} text-sm flex w-[40vw] shrink-0 items-center justify-center px-3 bg-[#F4F2F3] rounded-r-lg h-[46px]`}
                >
                  ดอกเบี้ย(บาท)
                </div>
              </div>

              {/*----------- รายการหนี้ระยะสั้น -----------*/}
              {balanceData?.debt_short_list?.length > 0 ? (
                <div className=" text-sm">
                  {/*---- บัตรเครดิต ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_creditcard")
                      return (
                        <div key={index} className="">
                          {item?.credit_cards?.map((item2, index) => {
                            return (
                              <Row
                                key={index}
                                index={index}
                                title="บัตรเครดิต"
                                creditor_name={item2?.creditor_name}
                                amount={item2.unpaid_amount}
                                interest={item2.interest}
                              />
                            );
                          })}
                        </div>
                      );
                  })}
                  {/*---- เงินกู้ระยะสั้น ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_shortterm")
                      return (
                        <div key={index} className="">
                          {item?.short_terms?.map((item2, index) => {
                            return (
                              <Row
                                key={index}
                                index={index}
                                title="หนี้กู้ระยะสั้น"
                                creditor_name={item2?.creditor_name}
                                amount={item2.unpaid_amount}
                                interest={item2.interest}
                              />
                            );
                          })}
                        </div>
                      );
                  })}
                  {/*---- หนี้นอกระบบ ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_informal_shortterm")
                      return (
                        <div key={index} className="">
                          {item?.informal_short_terms?.map((item2, index) => {
                            return (
                              <Row
                                key={index}
                                index={index}
                                title="หนี้นอกระบบ (รายวัน)"
                                creditor_name={item2?.creditor_name}
                                amount={item2.start_amount}
                                interest={item2.interest}
                              />
                            );
                          })}
                        </div>
                      );
                  })}
                  {/*---- เงินกู้ระยะสั้นอื่น ๆ ----*/}
                  {balanceData?.debt_short_list?.map((item, index) => {
                    if (item?.options_debt_short === "debt_shortterm_other")
                      return (
                        <div key={index} className="">
                          {item?.short_term_others?.map((item2, index) => {
                            const creditor =
                              calTotalCreditorFloatInterest(item2);
                            const totalCreditorInterest = creditor.reduce(
                              (acc, item, index) => {
                                if (index > 11) {
                                  return acc;
                                }
                                return acc + item.interest;
                              },
                              0
                            );

                            return (
                              <Row
                                key={index}
                                index={index}
                                title={"หนี้ระยะสั้น (ดอกลอย)"}
                                creditor_name={item2?.creditor_name}
                                amount={item2.unpaid_amount}
                                interest={item2?.interest * 12}
                                customAmountOfInterest={totalCreditorInterest}
                              />
                            );
                          })}
                        </div>
                      );
                  })}
                </div>
              ) : (
                <div className=" text-maingray text-center">ไม่มีรายการ</div>
              )}

              {/*----------- ยอดรวมหนี้ระยะสั้น -----------*/}
              <RowTotal
                total={sumStartUnpaidAmount()}
                interest={sumTotalWeightInterest()}
                totalInterest={sumTotalInterest()}
              />
            </div>
          </div>

          <div className=" text-mainblue font-bold text-center text-sm ">
            <div className="">
              วางแผนขอสินเชื่อส่วนบุคคล จากสถาบันการเงินที่คิด
            </div>
            <div className=" text-base flex justify-center items-center my-1">
              {" "}
              <ImageMain
                src={"../images/stg_6.png"}
                className=" w-9 h-9 "
              />{" "}
              อัตราดอกเบี้ยต่อปีถูกกว่าเดิม
            </div>
            <div>แล้วมาชำระหนี้สินเดิม</div>
          </div>
          {/*----------------------------------- อัตราดอกเบี้ยเงินกู้ต่อปี (%) -----------------------------------*/}
          <CardMain
            title={" อัตราดอกเบี้ยสถาบันการเงินที่ใหม่ (%)"}
            panel={
              <div className=" px-3 -mt-3 space-y-2.5">
                {/* <div className=" flex space-x-2 my2.5 text-sm ">
                  <div className=" text-maingray ">จำนวนเงิน</div>
                  <div className={`${STYLE_TEXT_BLUE} `}>
                    {numeral(formDebtPlan?.sum_unpaid_amount_af)?.format("0,0")}{" "}
                    บาท
                  </div>
                </div> */}
                <div className=" flex justify-center">
                  <NumericFormat
                    // type="number"
                    maxLength={2}
                    placeholder="0"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT_SLIDER}
                    value={formDebtPlan?.loan_interest_rate}
                    onChange={onChangeLoanInterestRate}
                  />
                </div>
                <SliderSingle
                  valuemax={MAX_LOAN_INTEREST_RATE}
                  value={formDebtPlan?.loan_interest_rate}
                  onChange={onChangeLoanInterestRate}
                  // onChnageMax={onChangeOutComePerYearMax}
                />
              </div>
            }
          />

          {/* ระยะเวลา (ปี) */}
          <CardMain
            title={"ระยะเวลา (ปี)"}
            panel={
              <div className=" px-3 -mt-3 space-y-2.5">
                <div className=" flex justify-center">
                  <NumericFormat
                    maxLength={2}
                    placeholder="0"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT_SLIDER}
                    value={formDebtPlan?.year}
                    onChange={onChangeYear}
                  />
                </div>
                <SliderSingle
                  valuemax={MAX_LOAN_INTEREST_RATE}
                  value={formDebtPlan?.year}
                  onChange={onChangeYear}
                  year
                  // onChnageMax={onChangeOutComePerYearMax}
                />
              </div>
            }
          />

          {/*---------------------------------------- เดือนละ ----------------------------------------*/}
          {/* <CardMain
            title={"เดือนละ (บาท)"}
            panel={
              <div className=" px-3 -mt-3 space-y-2.5">
                <NumericFormat
                  // type="number"
                  maxLength={11}
                  placeholder="0"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={STYLE_INPUT}
                  value={formDebtPlan?.pay_permonth_af}
                  onChange={onChangePayPerMonth}
                />
              </div>
            }
          /> */}

          {/* ผ่อนชำระต่อเดือน */}
          <div className="flex flex-col items-center">
            <div className="text-sm font-semibold">ผ่อนชำระต่อเดือน</div>
            <div className="text-lg font-semibold text-maingray">
              {numeral(formDebtPlan?.payPerMonth).format("number")} บาท
            </div>
          </div>

          {/*--------------------- ผลลัพธ์หากวางแผนปรับปรุงโครงสร้างหนี้ ----------------------------------*/}
          <CardMain
            title={"ผลลัพธ์หากวางแผนปรับปรุงโครงสร้างหนี้"}
            panel={
              <div className=" px-3 -mt-3 space-y-2.5">
                <div className=" flex justify-between my2.5 text-sm ">
                  <div className=" text-maingray ">
                    เจ้าหนี้จะได้เงินไปทั้งสิ้น
                  </div>
                  <div className={`${STYLE_TEXT_CYAN} `}>
                    {numeral(formDebtPlan?.total_creditor_receive_af)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div className=" flex justify-between my2.5 text-sm ">
                  <div className=" text-maingray ">เป็นดอกเบี้ยเงินกู้</div>
                  <div className={`${STYLE_TEXT_CYAN} `}>
                    {numeral(formDebtPlan?.total_loan_interest_rate_af)?.format(
                      "0,0"
                    )}{" "}
                    บาท
                  </div>
                </div>
                <div className=" flex justify-between my2.5 text-sm ">
                  <div className=" text-maingray ">จากเงินกู้</div>
                  <div className={`${STYLE_TEXT_CYAN} `}>
                    {numeral(
                      formDebtPlan?.sum_start_unpaid_amount_short
                    )?.format("0,0")}{" "}
                    บาท
                  </div>
                </div>
              </div>
            }
          />

          {/*---------- กราฟ ----------*/}
          <BarChartGroupDebt
            data1={formDebtPlan?.sum_unpaid_amount}
            data2={formDebtPlan?.sum_unpaid_amount_af}
            data3={formDebtPlan?.total_save_amount_af}
            // width={70}
          />

          {/*---------------------------------------- จะประหยัดเงินได้ ----------------------------------------*/}
          <div className={` font-medium text-center text-sm`}>
            จะประหยัดเงินได้
            <div className={`${STYLE_TEXT_CYAN}  text-center text-xl`}>
              {numeral(formDebtPlan?.total_save_amount_af)?.format("0,0")} บาท
            </div>
            <div className=" text-gray90">
              ({numeral(formDebtPlan?.sum_unpaid_amount)?.format("0,0")} -{" "}
              {numeral(formDebtPlan?.sum_unpaid_amount_af)?.format("0,0")} ={" "}
              {numeral(formDebtPlan?.total_save_amount_af)?.format("0,0")} บาท)
            </div>
          </div>

          {/* mark */}
          {/*======================================== สินทรัพย์มีหลักประกัน ========================================*/}
          {/* isHasCollateral */}
          {isHasCollateral ? (
            <div className="space-y-5 ">
              <div className=" text-mainblue  text-center font-medium">
                คุณมีสินเชื่อรถยนต์ที่จะช่วยให้คุณชำระหนี้ได้ โดยที่รถยนต์
                <span className=" font-semibold px-1 text-maincyan">
                  {dataMostCar?.title_car}
                </span>
                ของคุณตรงกับคุณสมบัติ 3 ข้อนี้
              </div>
              <CardTips
                title="คุณสมบัติ 3 ข้อ"
                panel={
                  <div className=" space-y-1  text-maingray">
                    <li className="list-decimal">
                      มูลค่ารถยนต์ปัจจุบันมากกว่าหนี้รถยนต์ โดยที่ ต้องมากกว่า
                      100,000 บาทขึ้นไป
                    </li>
                    <li className="list-decimal">ต้องมีหนี้ระยะสั้น</li>
                    <li className="list-decimal">
                      ส่วนต่างของมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์
                      ต้องมากกว่าจำนวนหนี้ระยะสั้น
                    </li>
                  </div>
                }
              />
              <CardMain
                title={"สินทรัพย์มีหลักประกัน"}
                panel={
                  <div className=" px-3 -mt-3 space-y-2.5">
                    <div className=" flex justify-between my2.5 text-sm ">
                      <div className=" text-maingray ">
                        วางแผนขอสินเชื่อส่วนบุคคลจากสถาบันการเงิน
                        ที่คิดอัตราดอกเบี้ยต่อปีถูกกว่าเดิมแล้วมาชำระหนี้สินเดิม
                        เช่น รีไฟแนนซ์{" "}
                        <span className=" font-semibold text-mainblue">
                          {"รถยนต์"}
                        </span>{" "}
                        นำเงินที่ได้ไปชำระหนี้ โดยจะเป็นการรีไฟแนนซ์{"รถยนต์"}
                        ทั้งคัน
                        หากเป็นรถมือหนึ่งที่มีอัตราดอกเบี้ยถูกอยู่แล้วต้องพิจารณาให้ถี่ถ้วน
                      </div>
                    </div>
                  </div>
                }
              />
              <CardMain
                title={"สมมติรถยนต์รีไฟแนนซ์ได้"}
                panel={
                  <div className=" px-3 -mt-3 space-y-2.5">
                    <div className=" font-semibold text-mainblue text-center">
                      <NumericFormat
                        // type="number"
                        maxLength={11}
                        placeholder="0"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={formDebtPlan?.refinance_amount}
                        // value={formRetire?.out_come_peryear}
                        onChange={onChangeRefinanceAmount}
                      />
                    </div>
                  </div>
                }
              />
              <CardMain
                title={"ดอกเบี้ย"}
                panel={
                  <div className=" px-3 -mt-3 space-y-2.5">
                    {/* <div className=" flex space-x-2 my2.5 text-sm ">
                      <div className=" text-maingray ">เป็นดอกเบี้ย</div>
                      <div className={`${STYLE_TEXT_BLUE} `}>
                        {numeral(
                          formDebtPlan.total_car_interest_rate || 0
                        ).format("0,000")}
                      </div>

                      <div className=" text-maingray ">
                        จำนวนเงินรวมดอกเบี้ย
                      </div>
                      <div className={`${STYLE_TEXT_BLUE} `}>
                        {numeral(
                          formDebtPlan.total_car_interest_rate +
                            formDebtPlan?.refinance_amount || 0
                        ).format("0,000")}
                      </div>
                    </div> */}
                    <div className=" flex justify-center">
                      <NumericFormat
                        // type="number"
                        maxLength={11}
                        placeholder="0"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={STYLE_INPUT_SLIDER}
                        value={formDebtPlan?.car_interest_rate}
                        // value={formRetire?.out_come_peryear}
                        onChange={onChangeCarInterestRate}
                      />
                    </div>
                    <SliderSingle
                      valuemax={MAX_CAR_INTEREST_RATE}
                      value={formDebtPlan?.car_interest_rate}
                      onChange={onChangeCarInterestRate}
                      // onChnageMax={onChangeOutComePerYearMax}
                    />
                  </div>
                }
              />
              <CardMain
                title={"ระยะเวลากู้"}
                panel={
                  <div className=" px-3 -mt-3 space-y-2.5">
                    <div className=" flex justify-center">
                      <DropDownMain
                        value={formDebtPlan?.car_period}
                        options={options_car_period}
                        zIndex={1}
                        className="w-full"
                        onChange={onChangePeriod}
                      />
                    </div>
                  </div>
                }
              />
              {/* ------------ ผลลัพธ์หลังรีไฟแนนซ์ ------------ */}
              <CardMain
                title={`ผลลัพธ์`}
                panel={
                  <div className=" px-3 -mt-3 space-y-2.5">
                    <div className=" flex justify-between my2.5 text-sm ">
                      <div className=" text-maingray ">ต้องผ่อนเดือนละ</div>
                      <div className={`${STYLE_TEXT_CYAN} `}>
                        {numeral(
                          formDebtPlan?.carLoanDetail?.monthlyPayment
                        )?.format("number")}{" "}
                        บาท
                      </div>
                    </div>
                    <div className=" flex justify-between my2.5 text-sm ">
                      <div className=" text-maingray ">
                        เจ้าหนี้จะได้เงินไปทั้งสิ้น
                      </div>
                      <div className={`${STYLE_TEXT_CYAN} `}>
                        {numeral(
                          formDebtPlan?.carLoanDetail?.totalInterest
                        )?.format("number")}{" "}
                        บาท
                      </div>
                    </div>
                    <div className=" flex justify-between my2.5 text-sm ">
                      <div className=" text-maingray ">เป็นดอกเบี้ยเงินกู้</div>
                      <div className={`${STYLE_TEXT_CYAN} `}>
                        {numeral(
                          formDebtPlan?.carLoanDetail?.totalPayment
                        )?.format("number")}{" "}
                        บาท
                      </div>
                    </div>
                    <div className=" flex justify-between my2.5 text-sm ">
                      <div className=" text-maingray ">จากเงินกู้</div>
                      <div className={`${STYLE_TEXT_CYAN} `}>
                        {numeral(formDebtPlan?.refinance_amount)?.format("0,0")}{" "}
                        บาท
                      </div>
                    </div>
                  </div>
                }
              />

              {/* -----------ข้อความ ว่าเอาไปชำระได้หรือไม่ได้ ------------ */}
              <div className=" text-center text-mainblue font-medium">
                {getResult()}
              </div>

              {/* <div className={` font-medium text-center text-sm`}>
                เงินที่ได้จากการรีไฟแนนซ์รถยนต์
                <div className={`${STYLE_TEXT_CYAN}  text-center text-xl`}>
                  {numeral(
                    (formDebtPlan.total_car_interest_rate || 0) +
                      (formDebtPlan?.refinance_amount || 0)
                  ).format("0,000")}{" "}
                  บาท
                </div>
              </div>
              <div className={` font-medium text-center text-sm`}>
                หากใช้วิธีการนี้คุณจะประหยัดเงินได้
                <div className={`${STYLE_TEXT_CYAN}  text-center text-xl`}>
                  {numeral(formDebtPlan.total_loan_interest_rate_af).format(
                    "0,000"
                  )}{" "}
                  บาท
                </div>
          
              </div> */}
              <div className={`${STYLE_CARD_BG} space-y-2`}>
                <ImageMain
                  src="../images/warning.png"
                  className=" w-8 h-8 mx-auto"
                />
                <div className=" text-mainblue text-center text-sm">
                  หากคุณนำรถไปรีไฟแนนซ์เพื่อนำเงินไปชำระหนี้ระยะสั้น
                  คุณมีความเสี่ยงที่จะโดนยึดรถยนต์
                  <div>
                    ดังนั้น{" "}
                    <span className=" font-semibold">
                      ควรวางแผนด้วยความระมัดระวัง
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <ViewFooterDebt />
        </div>
      </div>
    </Container>
  );
};

export default ViewDebtPlanForm;
