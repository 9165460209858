import { useEffect, useState } from "react";
import {
  STYLE_BODY_TAX,
  STYLE_BORDER_BODY_TAX,
  STYLE_TABLE_TAX,
} from "../../../utils/useStyle";
import numeral from "numeral";

export const VITaxTable = ({ netIncome }) => {
  const taxDataList = [
    {
      row: "1",
      tax_progressive_rate: "0 - 150,000",
      income_cal_tax: "",
      tax_rate: "ยกเว้น",
      tax_amount: "",
    },
    {
      row: "2",
      tax_progressive_rate: "เกิน 150,000 - 300,000",
      income_cal_tax: "",
      tax_rate: "5%",
      tax_amount: "",
    },
    {
      row: "3",
      tax_progressive_rate: "เกิน 300,000 - 500,000",
      income_cal_tax: "",
      tax_rate: "10%",
      tax_amount: "",
    },
    {
      row: "4",
      tax_progressive_rate: "เกิน 500,000 - 750,000",
      income_cal_tax: "",
      tax_rate: "15%",
      tax_amount: "",
    },
    {
      row: "5",
      tax_progressive_rate: "เกิน 750,000 - 1,000,000",
      income_cal_tax: "",
      tax_rate: "20%",
      tax_amount: "",
    },
    {
      row: "6",
      tax_progressive_rate: "เกิน 1,000,000 - 2,000,000",
      income_cal_tax: "",
      tax_rate: "25%",
      tax_amount: "",
    },
    {
      row: "7",
      tax_progressive_rate: "เกิน 2,000,000 - 5,000,000",
      income_cal_tax: "",
      tax_rate: "30%",
      tax_amount: "",
    },
    {
      row: "8",
      tax_progressive_rate: "เกิน 5,000,000",
      income_cal_tax: "",
      tax_rate: "35%",
      tax_amount: "",
    },
  ];

  const [tax, setTax] = useState(0);
  const [dataStepCalTaxList, setDataStepCalTaxList] = useState([]);

  const calculateTax = (income) => {
    const incomeRanges = [
      { min: 0, max: 150000, rate: 0.0 },
      { min: 150000, max: 300000, rate: 0.05 },
      { min: 300000, max: 500000, rate: 0.1 },
      { min: 500000, max: 750000, rate: 0.15 },
      { min: 750000, max: 1000000, rate: 0.2 },
      { min: 1000000, max: 2000000, rate: 0.25 },
      { min: 2000000, max: 5000000, rate: 0.3 },
      { min: 5000000, max: "Infinity", rate: 0.35 },
    ];

    let tax = 0;
    let previousMax = 0;
    let dataList = [];

    for (const range of incomeRanges) {
      if (income <= range.min) {
        tax += (income - previousMax) * range.rate;
        dataList.push({
          income_cal_tax: income - previousMax,
          tax_amount: (income - previousMax) * range.rate,
        });
        break;
      } else if (income <= range.max) {
        tax += (income - range.min) * range.rate;
        dataList.push({
          income_cal_tax: income - range.min,
          tax_amount: (income - range.min) * range.rate,
        });
        break;
      } else {
        tax += (range.max - range.min) * range.rate;
        previousMax = range.max;
        dataList.push({
          income_cal_tax: range.max - range.min,
          tax_amount: (range.max - range.min) * range.rate,
        });
      }
    }

    return { step_cal_tax: dataList, tax: tax };
  };

  const formatStepTaxData = () => {
    const dataStepCalTax = calculateTax(netIncome)?.step_cal_tax;
    const result = taxDataList.map((item, index) => {
      // Check if there's a corresponding element in dataStepCalTax
      const correspondingItem = dataStepCalTax[index];
      // Create a new object based on item from taxDataList
      const newItem = { ...item };
      // If corresponding item exists, update income_cal_tax and tax_amount
      if (correspondingItem) {
        newItem.income_cal_tax = correspondingItem.income_cal_tax;
        newItem.tax_amount = correspondingItem.tax_amount;
      }
      return newItem;
    });
    return result;
  };

  useEffect(() => {
    setTax(calculateTax(netIncome)?.tax);
    setDataStepCalTaxList(formatStepTaxData());
  }, [netIncome]);

  // console.log("netIncome : ", netIncome);
  // console.log("dataStepCalTaxList : ", dataStepCalTaxList);

  return (
    <div className=" w-full overflow-hidden overflow-x-scroll rounded-t-[20px]  ">
      {/*-------------------- Header --------------------*/}
      <div className=" bg-[#F9F9F9] h-[100px] flex  items-center w-full">
        <div className={STYLE_TABLE_TAX}>คิดภาษีแบบอัตราก้าวหน้า</div>
        <div className={STYLE_TABLE_TAX}>เงินได้ที่ใช้คิดภาษี</div>
        <div className={STYLE_TABLE_TAX}>อัตราภาษี</div>
        <div className={STYLE_TABLE_TAX}>ค่าภาษี</div>
      </div>

      {/*-------------------- Body --------------------*/}
      {dataStepCalTaxList?.map((row, index) => {
        return (
          <div key={index} className={STYLE_BORDER_BODY_TAX}>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.tax_progressive_rate}
            </div>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.income_cal_tax
                ? numeral(row.income_cal_tax)?.format("0,0")
                : ""}
            </div>
            <div className={`${STYLE_BODY_TAX} text-mainblue`}>
              {row.tax_rate}
            </div>
            <div className={`${STYLE_BODY_TAX} text-maincyan`}>
              {row.tax_amount ? numeral(row.tax_amount)?.format("0,0") : ""}
            </div>
          </div>
        );
      })}

      {/* ------- รวมภาษีที่ต้องชำระ ------ */}
      <div className={STYLE_BORDER_BODY_TAX}>
        <div
          className={` whitespace-nowrap  flex-none w-[180px] text-sm text-center text-maincyan`}
        ></div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px] text-sm text-center`}
        ></div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px] text-sm text-center h-[60px] flex justify-center items-center px-3  text-mainblue font-semibold`}
        >
          รวมภาษีที่ต้องชำระ
        </div>
        <div
          className={`whitespace-nowrap  flex-none w-[180px]  text-center h-[60px] flex justify-center items-center px-3  text-maincyan font-semibold text-xl`}
        >
          {numeral(tax)?.format("0,0")}
        </div>
      </div>
    </div>
  );
};
