import { Drawer } from "@material-tailwind/react";

import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_BALANCE,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_IMG_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_PROPOTION,
  STYLE_VALUE_BALANCE,
} from "../../../utils/useStyle";
import { useBalanceContext } from "../Balance.Main";
import { useEffect, useMemo } from "react";
import numeral from "numeral";
import { ICON_NAVIGATE_BALANCE } from "../../../utils/useIcons";
import {
  DonutChartDebt,
  DonutChartEmpty,
  DonutChartMain,
} from "../../../components/chart/DonutChartMain";
import { BoxWhite } from "../styles/Card";
import { useBalanceStore } from "../../../_store/balanceStore";

const ViewDebtProportion = ({ onClick }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  return (
    <div>
      <div className={STYLE_CARD_PROPOTION}>
        <div className={STYLE_HEADER_SECTION_PROPOTION}>
          <div>
            <div className={STYLE_TITLE_PROPOTION}>สัดส่วนหนี้สิน</div>
            <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
          </div>
          <ICON_NAVIGATE_BALANCE onClick={onClick} />
        </div>

        {balanceData?.debt_propotion <= 0 ||
        balanceData?.debt_propotion === undefined ? (
          <DonutChartEmpty text={`หนี้สิน`} />
        ) : (
          <DonutChartDebt
            text={`หนี้สิน`}
            value={numeral(balanceData?.debt_propotion).format("0,0")}
            // defalut={getDebtBalance > 0 ? 0 : 100}
            data1={balanceData?.sum_debt_short}
            data2={balanceData?.sum_debt_long}
          />
        )}

        <BoxWhite
          title="หนี้สินระยะสั้น"
          icon="/images/debt_short2.png"
          colortext="text-red500"
          value={balanceData?.sum_debt_short}
          info
        />
        <BoxWhite
          title="หนี้สินระยะยาว"
          icon="/images/debt_long2.png"
          colortext="text-mainorange"
          value={balanceData?.sum_debt_long}
          info
        />
      </div>
    </div>
  );
};

export default ViewDebtProportion;
