import { NumericFormat } from "react-number-format";
import { CardMain, CardProduct } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState, useEffect } from "react";
import numeral from "numeral";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import { RadioMain } from "../../../components/radio/Radio.Main";
import ViewTableFamily from "./VITableFamily.Main";
import { ImageMain } from "../../../components/img/Img.Main";
import { MTButton } from "../../../components/button/MTButton";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { StatusCard } from "../styles/Card";
import { first_reload } from "../functions/firstLoad";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import { useNavigate } from "react-router-dom";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { useEducationStore } from "../../../_store/educationStore";
import { defaultValue } from "../../../functions/main";
import CustomHorizontalBarChart from "../../pdf/component/pdf.c.familyChart";

const ViewFamily_Final = ({ setFinal, setTab }) => {
  const navigate = useNavigate();
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  // console.log("insuranceData", insuranceData);
  // console.log("educationData", educationData);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  const getStatus = () => {
    if (insuranceData.sum_insurance_more === 0) {
      return "normal";
    } else if (insuranceData.sum_insurance_more < 0) {
      return "toomuch";
    }
    return "more";
  };

  return (
    <div id="first_reload">
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={
            <div className=" text-mainblue font-semibold ">
              ผลลัพธ์วางแผนทุนประกันคุ้มครองค่าใช้จ่าย
            </div>
          }
          panel={
            <div className=" px-3  space-y-5 font-medium">
              <div>
                <div>ทุนประกันขั้นต่ำที่ต้องการให้ครอบครัวอยู่รอด 5 ปี</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(
                    (defaultValue(insuranceData.sum_income) -
                      defaultValue(insuranceData.sum_personal_expenses)) *
                      5
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>

              <div>
                <div>
                  ทุนประกันที่ควรมีเพื่อคุ้มครองครอบครัว{" "}
                  {insuranceData?.family_year_survive} ปี
                </div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_family_remain)?.format("0,0")} บาท
                </div>
              </div>
              {/* <div>
                <div>ทุนประกันสำหรับหนี้สิน</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(0)?.format("0,0")} บาท
                </div>
              </div> */}

              {insuranceData?.asset_is_debt === "Y" && (
                <div className="flex flex-col">
                  <div className="mb-4">
                    <div>หนี้สินทั้งหมดของคุณ</div>
                    <div className=" text-maincyan font-semibold text-2xl">
                      {numeral(insuranceData.sum_family_debt).format("0,0")}
                    </div>
                  </div>

                  <div>
                    <div className=" mb-2">ทุนประกันเพื่อการศึกษาบุตร</div>
                    <ViewTableFamily />
                  </div>
                </div>
              )}

              <div>
                <div>ทุนประกันสุทธิ</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData.sum_insurance_net)?.format("0,0")} บาท
                </div>
              </div>
              <div>
                <div>ทุนประกันที่คุณมี ณ ปัจจุบัน</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_insurance_current)?.format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div>
                <div>มูลค่าสินทรัพย์</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_asset_current)?.format("0,0")} บาท
                </div>
              </div>
              <div>
                <div>ทุนประกันแนะนำ</div>
                <div className=" text-maincyan font-semibold text-2xl">
                  {numeral(insuranceData?.sum_insurance_more)?.format("0,0")} บาท
                </div>
              </div>
              <div className="w-[300px] flex justify-center mx-auto">
                <CustomHorizontalBarChart
                  width={120}
                  yourData={[insuranceData?.sum_insurance_current, 0]}
                  recomenedData={[0, insuranceData.sum_insurance_more]}
                  barThickness={40}
                />
              </div>
              <StatusCard
                status={getStatus()}
                value={insuranceData.sum_insurance_more}
              />
            </div>
          }
        />
        <MTButton
          onClick={() => {
            setFinal(false);
            setTab(2);
          }}
          text="ย้อนกลับ"
          color="bg-white"
          border="border border-maincyan"
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          width="w-full"
        />
        <CardProduct />
        <FooterAfterSum />
      </div>
    </div>
  );
};

export default ViewFamily_Final;
