import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";

function PdfPageFamilyTwo({page}) {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  const items = useMemo(() => {
    const newItems = [
      {
        label:
          "ประกันชั่วระยะเวลา เบี้ยประกันสูง ทุนประกันสูงไม่เน้นเงินคืนใช้คุ้มครองชีวิตวิตามระยะเวลาเป้าหมาย ที่เรากำหนด เช่น 5 ปี, 10 ปี, 20 ปี ",
      },
      {
        label:
          "ประกันแบบตลอดชีพชำระเบี้ยเพื่อให้มีความคุ้มครองตลอดตลอดระยะเวลาที่เรามีชีวิต",
      },
      {
        label: "ประกันแบบสะสมทรัพย์ จุดประสงค์เพื่อให้มีเงินก้อน",
      },
      {
        label:
          "ตามเป้าหมายระยะเวลาที่เรากำหนด โดยมีความคุ้มครองชีวิตประกันแบบ UNIT LINK เป็นประกันที่ให้ความคุ้มครองชีวิตวิพร้อมกับโอกาสในการรับผลตอบแทนจากกองทุนรวม",
      },
    ];
    return newItems;
  }, []);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="แผนทุนประกันคุ้มครองครอบครัว"
        icon="/images/family.svg"
      />
      {/* ทุนประกัน */}
      <PdfCard className={"flex flex-col space-y-3"}>
        <div className="flex items-center space-x-2">
          <div>
            <img src="/images/lamp_yellow.svg" />
          </div>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>
            แนะนำแบบประกันชีวิต
          </div>
        </div>
        <PdfCardWhite>
          <div className="flex flex-col mx-3 space-y-1 py-1">
            {items.map((item, index) => {
              return (
                <div key={index} className={cn(STYLE_PDF_TEXT_NORMAL_11)}>
                  {index + 1}. {item.label}
                </div>
              );
            })}
          </div>
        </PdfCardWhite>
      </PdfCard>
      <div
        className={cn(
          "mt-10 px-10 flex justify-center text-center",
          STYLE_PDF_TEXT_NORMAL_11
        )}
      >
        ติดต่อนักวางแผนการเงิน CFP® / ตัวแทนประกันชีวิต
        ที่ท่านไว้วางใจเป็นเพื่อนคู่คิดตลอดเส้นทางชีวิต
      </div>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageFamilyTwo;
