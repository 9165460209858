import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useDebtStore } from "../../../_store/debtplanStore";
import { useEducationStore } from "../../../_store/educationStore";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { ImageMain } from "../../../components/img/Img.Main";
import { cn } from "../../../utils/useFunctions";
import {
  key_under_education,
  options_education_rank,
} from "../../education/functions/options";
import { ChartCashFlow } from "../../future_plan/components/VIChartCashFlow.Main";
import { PdfCard, PdfCardWhite } from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { getNotPlan } from "../lib/pdf.lib";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
} from "../styles/pdf.style";

function PdfPageOverviewThree({ page }) {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const debtData = useDebtStore((state) => state.debtData);
  const [isHasCollateral, setIsHasCollateral] = useState(false);

  // รวมยอดเงินต้นค้างชำระ
  const sumStartUnpaidAmount = () => {
    // บัตรเครดิต
    const sumCreditCard = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.credit_cards?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้น
    const sumShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_terms?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้นอกระบบ
    const sumInformalShortTerm = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.informal_short_terms?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };
    // เงินกู้ระยะสั้นอื่นๆ
    const sumShortTermOther = () => {
      let result = 0;
      balanceData?.debt_short_list?.map((item) => {
        item?.short_term_others?.map((row) => {
          result += row?.unpaid_amount ? row?.unpaid_amount : 0;
        });
      });
      return result;
    };

    const finalResult =
      sumCreditCard() +
      sumShortTerm() +
      sumInformalShortTerm() +
      sumShortTermOther();

    return finalResult;
  };

  useEffect(() => {
    // if (firstRender) return;

    // ดูว่ามีรถคันไหนที่ผ่านเงื่อนไข
    // 1.มูลค่ารถยนต์ปัจจุบัน > หนี้รถยนต์ โดยที่ ต้องมากกว่า 100,000 บาทขึ้นไป
    // 2.ต้องมีหนี้ระยะสั้น
    // 3.ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ต้องมากกว่าจำนวนหนี้ระยะสั้น
    if (balanceData) {
      //---- เช็คว่ามีหนี้รพยะสั้นไหม ----//
      if (balanceData?.sum_debt_short > 0) {
        let carItems = [];

        for (const debt_long_item of balanceData?.debt_long_list) {
          if (debt_long_item?.options_debt_long === "debt_car") {
            carItems = [...carItems, ...debt_long_item?.debt_long_cars];
          }
        }

        //---- หารถที่มูลค่าปัจจุบันเยอะที่สุด ----//
        const mostCar = () => {
          let latestItemMax = { current_price: 0, unpaid_amount: 0 };
          for (const carItem of carItems) {
            if (carItem.current_price > latestItemMax.current_price) {
              latestItemMax = carItem;
            }
          }
          return latestItemMax;
        };

        //---- ส่วนต่างข้องมูลค่ารถยนต์ปัจจุบัน - หนี้รถยนต์ ----//
        const differenceCurrentPriceAndDebtCar =
          mostCar().current_price - mostCar().unpaid_amount;

        //========== เช็คว่ามีรถที่สามารถขายเพื่อล้างหนี้ได้ไหม ==========//
        if (
          differenceCurrentPriceAndDebtCar > 100000 &&
          differenceCurrentPriceAndDebtCar > sumStartUnpaidAmount()
        ) {
          setIsHasCollateral(true);
        } else {
          setIsHasCollateral(false);
        }
      }
    }
  }, [balanceData]);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
    ];
    let merge_expenese_keys = [...expenese_keys, "outcome_peryear"];
    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }
      dataDebt.push(dataDebtItems);
    }

    return dataDebt;
  }, [futurePlanData]);

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }
    return labels;
  }, [futurePlanData]);

  return (
    <div
      style={{
        backgroundImage: `url(/images/bg_pdf.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
      }}
      className={cn(STYLE_PAGE)}
    >
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText />
      <PdfCard className="space-y-2.5 ">
        <div className=" grid grid-cols-2 gap-2.5 ">
          {/* แผนอนาคต */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title={`แผนอนาคต ${getNotPlan(
                !futurePlanData?.cash_flow_items?.length
              )}`}
              icon="/images/personal.svg"
              classNameIcon="w-[24px] h-[24px]"
            />
            <ChartCashFlow
              labels={labels}
              dataDebt={dataDebt}
              merge_keys={merge_keys}
            />
            <div className="w-full">
              <div className="  w-full rounded-lg h-full p-3 space-y-[6px]">
                <ImageMain
                  src="../images/cashflow.png"
                  className=" w-6 h-6 mx-auto"
                />
                <div className=" text-center text-mainblue text-[10px] ">
                  เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
                  <div>
                    คุณต้องหารายรับเพิ่มอีก
                    <span className=" font-semibold">
                      {" "}
                      {numeral(futurePlanData.sum_diff_total)?.format(
                        "0,0"
                      )}{" "}
                      บาท
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </PdfCardWhite>

          {/* แผนศึกษาบุตร */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title={`แผนศึกษาบุตร ${getNotPlan(!educationData.length)}`}
              icon="/images/personal.svg"
              classNameIcon="w-[24px] h-[24px]"
            />
            <div className="flex flex-col">
              <div className={cn(STYLE_PDF_TEXT_NORMAL_11, "mx-auto")}>
                บุตรทั้งหมด {educationData?.length} คน
              </div>
              <div
                className={cn(
                  STYLE_PDF_TEXT_NORMAL_11,
                  "flex justify-between "
                )}
              >
                <div> รวมค่าใช้จ่ายทั้งหมด </div>
                <div className="text-maingreen">
                  {numeral(
                    educationData.reduce((prev, educationItem) => {
                      return prev + educationItem?.sum_total_education;
                    }, 0)
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>
              <div
                className={cn(
                  STYLE_PDF_TEXT_NORMAL_11,
                  "flex justify-between "
                )}
              >
                <div> รวมยอดทุนประกันของคุณ </div>
                <div className="text-mainyellow">
                  {numeral(
                    educationData.reduce((prev, educationItem) => {
                      return prev + educationItem?.sum_total_invest;
                    }, 0)
                  ).format("0,0")}{" "}
                  บาท
                </div>
              </div>
            </div>

            {educationData?.slice(0, 3).map((educationItem, index) => {
              return (
                <div key={index} className="flex flex-col mt-2">
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    บุตรคนที่ {index + 1}: {educationItem?.name} อายุ{" "}
                    {educationItem?.age} สังกัด{" "}
                    {
                      key_under_education?.[educationItem?.under_education]
                        ?.label || ""
                    }
                  </div>
                  <PdfLine className={"mt-1 mb-1"} />
                  <div
                    className={cn(
                      "flex justify-between",
                      STYLE_PDF_TEXT_MEDIUM_11
                    )}
                  >
                    <div>รวมค่าใช้จ่ายเพื่อการศึกษา</div>
                    <div className={cn("text-maingreen")}>
                      {numeral(educationItem?.sum_total_education).format(
                        "0,0"
                      )}{" "}
                      บาท
                    </div>
                  </div>
                  <div
                    className={cn(
                      "flex justify-between",
                      STYLE_PDF_TEXT_MEDIUM_11
                    )}
                  >
                    <div>ยอดทุนประกันของคุณ</div>
                    <div className={cn("text-mainyellow")}>
                      {numeral(educationItem?.sum_total_invest).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                  <div
                    className={cn(
                      "flex justify-between",
                      STYLE_PDF_TEXT_MEDIUM_11
                    )}
                  >
                    <div>เป้าหมายการศึกษา</div>
                    <div className={cn()}>
                      {
                        options_education_rank?.find(
                          (thisRank) =>
                            thisRank?.value === educationItem?.target_education
                        )?.label
                      }
                    </div>
                  </div>
                </div>
              );
            })}

            {educationData?.length > 3 && (
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_11, "mt-3 text-center")}>
                กรณีมีบุตรมากกว่า 3 คน ดูละเอียดบุตรคนอื่นๆ
                ที่หน้าแผนการศึกษาบุตร”
              </div>
            )}
          </PdfCardWhite>

          {/* แผนจัดการหนี้ */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title={`แผนจัดการหนี้ ${getNotPlan(
                !balanceData?.debt_propotion
              )}`}
              icon="/images/wealth_1.svg"
              classNameIcon="w-[24px] h-[24px]"
            />
            <div className={cn("flex flex-col")}>
              <div className="flex justify-center w-full">
                <img src="/images/good_pdf.svg" />
              </div>
              <div
                className={cn("flex flex-col mt-2", STYLE_PDF_TEXT_NORMAL_10)}
              >
                <div className={cn("flex justify-center")}>ยอดเยี่ยม</div>
                <div className="text-center">
                  คุณสามารถจัดการหนี้สินได้ดีเยี่ยม
                </div>
                {isHasCollateral ? (
                  <div className="flex">
                    <div className="text-center">
                      ผ่านการรวมหนี้สินเป็นก้อนเดียว
                    </div>
                    <div className="text-center">/</div>
                    <div className="text-center">ผ่านการรีไฟแนนซ์รถยนต์</div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="text-center">
                      ผ่านการรวมหนี้สินเป็นก้อนเดียว
                    </div>
                  </div>
                )}
              </div>

              <div
                className={cn(STYLE_PDF_TEXT_MEDIUM_11, "flex justify-between")}
              >
                <div className={cn("")}>หนี้สินทั้งหมด</div>
                <div className={cn("text-maingreen")}>
                  {numeral(balanceData?.debt_propotion).format("0,0")} บาท
                </div>
              </div>
            </div>
          </PdfCardWhite>

          {/* แผนมรดก */}
          <PdfCardWhite className={"flex flex-col  "}>
            <PdfHeaderCardText
              title="แผนมรดก"
              icon="/images/revenue_1.svg"
              classNameIcon="w-[24px] h-[24px]"
            />
            <div className={cn("flex flex-col")}>
              <div className="flex justify-center w-full">
                <img src="/images/good_pdf_2.svg" />
              </div>
              <div
                className={cn("flex flex-col mt-2", STYLE_PDF_TEXT_NORMAL_10)}
              >
                <div className="text-center">
                  ความมั่งคั่งจะส่งมอบสู่คนที่คุณรักและห่วงใหญ่
                  แต่หากมีการเขียนพินัยกรรมสินทรัพย์ หนี้สิน สิทธิ หน้าที่
                  จะถูกส่งมอบให้กับคนที่คุณไว้วางใจได้อย่างเป็นระบบ
                </div>
              </div>
            </div>
          </PdfCardWhite>
        </div>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageOverviewThree;
