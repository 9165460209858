import { useNavigate } from "react-router-dom";
import Container from "../../../../components/layout/Container";
import {
  STYLE_BG_WHITE_CARD_TAXINCOME,
  STYLE_CARD_BG,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_PARENT_PADDING,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../utils/useStyle";
import { useState } from "react";
import { HeaderTitle } from "../../../../components/header/Header";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../../components/card/CardMain";
import { CheckBoxSecond } from "../../../../components/checkbox/CheckboxSecond";
import { ButtonCounter } from "../../../../components/button/MTButton";
import { CardTaxPremium } from "../../styles/Card/CardMenuPremium";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";

const Income408 = ({ openIncome408, setOpenIncome408 }) => {
  return (
    <InfoDrawer
      head={"เงินได้จากการให้หรือการรับ (มาตรา 40(8)) "}
      text={
        <div className=" mb-4">
          <li>
            เงินได้จากการโอนกรรมสิทธิ์หรือสิทธิครอบครองในอสังหาริมทรัพย์โดยไม่มี
            ค่าตอบแทน ให้แก่บุตรชอบด้วยกฎหมาย ซึ่งไม่รวมถึงบุตรบุญธรรม
            เฉพาะเงินได้ในส่วนที่เกิน 20 ล้านบาท
          </li>
          <li>
            เงินได้ที่ได้รับจากการอุปการะหรือจากการให้โดยเสน่หาจากบุพการี
            ผู้สืบสันดาน หรือคู่สมรสเฉพาะเงินได้ในส่วนที่เกิน 20 ล้านบาท
          </li>
          <li>
            เงินได้ที่ได้รับจากการอุปการะโดยหน้าที่ธรรมจรรยาหรือการให้โดยเสน่หาเนื่องในพิธีหรือตามโอกาส
            แห่งขนบธรรมเนียมประเพณี ทั้งนี้ จากบุคคลซึ่งมิใช่บุพการี
            ผู้สืบสันดาน หรือคู่สมรส เฉพาะเงินได้ในส่วนที่เกิน 10 ล้านบาท
          </li>
          <li>
            โดยสามารถเลือกนำมารวมคำนวณภาษีกับเงินได้อื่นๆ หรือ
            เลือกเสียภาษีในอัตราร้อยละ 5 ได้
          </li>
        </div>
      }
      open={openIncome408}
      onClose={() => {
        setOpenIncome408(false);
      }}
    />
  );
};

const ViewIncomeHeritage = () => {
  const navigate = useNavigate();
  const [isCheckedSave, setIsCheckedSave] = useState(false);
  const [openIncome408, setOpenIncome408] = useState(false);

  return (
    <div>
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            title="รายได้จากมรดกหรือได้รับมา"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            {/* ------ เงินได้จากการให้หรือการรับ (มาตรา 40(8)) ------ */}
            <CardMain
              icon={true}
              onClick={() => {
                setOpenIncome408(true);
              }}
              title={
                <div className=" pr-3 flex">
                  เงินได้จากการให้หรือการรับ (มาตรา 40(8))
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" text-maingray text-xs space-y-1">
                    <div> * กรณีมีเงินได้จากหลายที่</div>
                    <div>
                      1. ให้รวมเงินได้ / ภาษีหัก ณ ที่จ่าย เป็นอันเดียว ก่อนกรอก
                    </div>
                  </div>
                  <div className=" text-center font-medium text-black text-sm ">
                    ส่วนของผู้มีเงินได้
                  </div>
                  {/* เงินได้จากการโอนกรรมสิทธิ์ / สิทธิครอบครอง ในอสังหาริมทรัพย์ตามมาตรา 42 (26) */}
                  <div className={STYLE_BG_WHITE_CARD_TAXINCOME}>
                    <div className=" flex justify-between">
                      <div className=" text-sm text-black font-semibold">
                        เงินได้จากการโอนกรรมสิทธิ์ / สิทธิครอบครอง
                        ในอสังหาริมทรัพย์ตามมาตรา 42 (26)
                      </div>
                    </div>
                    {/* เงินได้พึงประเมิน */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        เงินได้พึงประเมิน
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        // value={currentPrice}
                        // onChange={onChangeCurrentPrice}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                    {/* ภาษีหัก ณ ที่จ่าย */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        ภาษีหัก ณ ที่จ่าย
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        // value={currentPrice}
                        // onChange={onChangeCurrentPrice}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                  </div>
                  {/* เงินได้ที่ได้รับจากการอุปการะ/จากการให้ โดยเสน่หาจากบุพการี ตามมาตรา 42 (27) */}
                  <div className={STYLE_BG_WHITE_CARD_TAXINCOME}>
                    <div className=" flex justify-between">
                      <div className=" text-sm text-black font-semibold">
                        เงินได้ที่ได้รับจากการอุปการะ/จากการให้
                        โดยเสน่หาจากบุพการี ตามมาตรา 42 (27)
                      </div>
                    </div>
                    {/* เงินได้พึงประเมิน */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        เงินได้พึงประเมิน
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        // value={currentPrice}
                        // onChange={onChangeCurrentPrice}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                  </div>
                  {/* เงินได้ที่ได้รับจากการอุปการะโดยหน้าที่ธรรมจรรยา/จากการให้โดยเสน่หาฯ ตามมาตรา 42 (28) */}
                  <div className={STYLE_BG_WHITE_CARD_TAXINCOME}>
                    <div className=" flex justify-between">
                      <div className=" text-sm text-black font-semibold">
                        เงินได้ที่ได้รับจากการอุปการะโดยหน้าที่ธรรมจรรยา
                        /จากการให้โดยเสน่หาฯ ตามมาตรา 42 (28)
                      </div>
                    </div>
                    {/* เงินได้พึงประเมิน */}
                    <div className={STYLE_PARENT_CARD_BALANCE}>
                      <div
                        className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}
                      >
                        เงินได้พึงประเมิน
                      </div>
                      <NumericFormat
                        maxLength={11}
                        placeholder="0"
                        // value={currentPrice}
                        // onChange={onChangeCurrentPrice}
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                      />
                    </div>
                  </div>
                </div>
              }
            />

            <div className={`${STYLE_CARD_BG} px-3`}>
              <div className="font-medium text-black text-sm ">
                การคำนวณภาษีและโอนกรรมสิทธิ์
                คุณต้องการเลือกเสียภาษีโดยไม่นำไปรวมกับเงินได้อื่นๆ
                ในอัตราร้อยละ 5 หรือไม่ ?
              </div>
              <div className="flex items-center mb-3 space-x-6">
                <CheckBoxSecond
                  isLeft={true}
                  name={`type`}
                  label_title={`ไม่ต้องการ`}
                  value={`personal`}
                  //   checked={personalOrInvestment?.personal}
                />
                <CheckBoxSecond
                  isLeft={true}
                  name={`type`}
                  label_title={`ต้องการ`}
                  value={`investment`}
                  //   checked={personalOrInvestment?.investment}
                />
              </div>
              <div className="font-medium text-black text-sm ">
                คุณต้องการบันทึกรายละเอียดการโอนกรรมสิทธิ์
                หรือสิทธิครอบครองในอสังหาริมทรัพย์ หรือไม่ ?
              </div>
              <div className="flex items-center mb-3 space-x-6">
                <CheckBoxSecond
                  isLeft={true}
                  name={`type_save`}
                  label_title={`ไม่ต้องการ`}
                  value={`personal`}
                  checked={!isCheckedSave}
                  onChange={() => {
                    setIsCheckedSave(!isCheckedSave);
                  }}
                />
                <CheckBoxSecond
                  onChange={() => {
                    setIsCheckedSave(!isCheckedSave);
                  }}
                  isLeft={true}
                  name={`type_save`}
                  label_title={`ต้องการ`}
                  value={`investment`}
                  checked={isCheckedSave}
                />
              </div>
              {isCheckedSave && (
                <div>
                  <div className="space-y-[15px]">
                    <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                      <div className=" text-sm text-black font-medium">
                        เพิ่มรายการ
                      </div>
                      <ButtonCounter
                      // value={row?.credit_cards?.length}
                      // handleNext={handleCreditCardNext?.bind(
                      //   this,
                      //   index
                      // )}
                      />
                    </div>
                    <CardTaxPremium
                      isHasDropDown1={false}
                      ishasCal={false}
                      isHasDate1={true}
                      isHasDate2={true}
                      isHasPay={true}
                      isHasCompany={false}
                      isHasLand={true}
                      isHasDividend={false}
                      isHastitle1={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      {openIncome408 && (
        <Income408
          openIncome408={openIncome408}
          setOpenIncome408={setOpenIncome408}
        />
      )}
    </div>
  );
};

export default ViewIncomeHeritage;
