import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PDF_ITEM_TOTAL } from "../styles/pdf.style";
import {
  options_other_income,
  options_other_income_interest,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calDebtLocalOption,
  calIncomeInterestLocalOption,
  calIncomeInterestOtherOption,
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageBalananceFour({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const mergeOptions = useMemo(() => {
    return [
      {
        label: "ดอกเบี้ย",
        key: "interest_peryear_list",
        key_option: "option_interest",
        options: options_other_income_interest,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestLocalOption,
      },
      {
        label: "โบนัส",
        key: "other_income_list",
        key_option: "option_income_other",
        options: options_other_income,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestOtherOption,
      },
    ];
  }, []);

  const propertyIncomeItems = useMemo(() => {
    const tempPropertyItems = getBalanceItems(balanceData, []);
    const newPropertyItems = [];

    let lastItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "รวม",
      returns: 100,
    };

    let salaryItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "เงินเดือนรวมทั้งปี",
      returns: 0,
    };

    if (balanceData?.salary_peryear_list?.length > 0) {
      for (const salary_peryear_item of balanceData.salary_peryear_list) {
        salaryItem.amount_per_year += +salary_peryear_item.amount;
        salaryItem.amount_per_month += +salary_peryear_item.salary_permonth;
        lastItem.amount_per_year += +salary_peryear_item.amount;
        lastItem.amount_per_month += +salary_peryear_item.salary_permonth;
      }
    }

    newPropertyItems.push(salaryItem);

    for (const tempPropertyItem of tempPropertyItems) {
      const items = tempPropertyItem.items;
      for (const item of items) {
        if (item.label === "รวม") {
          continue;
        }
        lastItem.amount_per_year += item.amount_per_year;
        lastItem.amount_per_month += item.amount_per_month;
        newPropertyItems.push(item);
      }
    }

    for (const newPropertyItem of newPropertyItems) {
      newPropertyItem.returns =
        (newPropertyItem.amount_per_year / lastItem.amount_per_year) * 100;
    }

    newPropertyItems.push(lastItem);
    return [
      {
        label: "เงินเดือน",
        icon: "salary_pdf.svg",
        items: newPropertyItems,
      },
    ];
  }, [balanceData]);

  const propertyIncomeInterestItems = useMemo(() => {
    const tempPropertyItems = getBalanceItems(balanceData, [
      {
        label: "ดอกเบี้ย",
        key: "interest_peryear_list",
        key_option: "option_interest",
        options: options_other_income_interest,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestLocalOption,
      },
    ]);
    const newPropertyItems = [];

    let lastItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "รวม",
      returns: 100,
    };

    if (balanceData?.interest_peryear_list?.length > 0) {
      for (const salary_peryear_item of balanceData.interest_peryear_list) {
        lastItem.amount_per_year += +salary_peryear_item.interest_peryear;
      }
    }

    for (const tempPropertyItem of tempPropertyItems) {
      const items = tempPropertyItem.items;
      for (const item of items) {
        if (item.label === "รวม") {
          continue;
        }
        newPropertyItems.push(item);
      }
    }

    for (const newPropertyItem of newPropertyItems) {
      newPropertyItem.returns =
        (newPropertyItem.amount_per_year / lastItem.amount_per_year) * 100;
    }

    newPropertyItems.push(lastItem);
    return [
      {
        label: "ดอกเบี้ย",
        icon: "interest.svg",
        items: newPropertyItems,
      },
    ];
  }, [balanceData]);

  const propertyIncomeBonusItems = useMemo(() => {
    const tempPropertyItems = getBalanceItems(balanceData, [
      {
        label: "โบนัส",
        key: "other_income_list",
        key_option: "option_income_other",
        options: options_other_income,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestOtherOption,
      },
    ]);
    const newPropertyItems = [];

    let lastItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "รวม",
      returns: 100,
    };

    if (balanceData?.other_income_list?.length > 0) {
      for (const salary_peryear_item of balanceData.other_income_list) {
        lastItem.amount_per_year += +salary_peryear_item.other_income_peryear;
      }
    }

    for (const tempPropertyItem of tempPropertyItems) {
      const items = tempPropertyItem.items;
      for (const item of items) {
        if (item.label === "รวม") {
          continue;
        }
        newPropertyItems.push(item);
      }
    }

    for (const newPropertyItem of newPropertyItems) {
      newPropertyItem.returns =
        (newPropertyItem.amount_per_year / lastItem.amount_per_year) * 100;
    }

    newPropertyItems.push(lastItem);
    return [
      {
        label: "โบนัส",
        icon: "baht.svg",
        items: newPropertyItems,
      },
    ];
  }, [balanceData]);

  const total = useMemo(() => {
    let total = 0;
    for (const propertyItem of propertyIncomeItems) {
      for (const item of propertyItem.items) {
        if (item?.label === "รวม") continue;
        total += item.amount_per_year;
      }
    }
    return total;
  }, [propertyIncomeItems]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="งบดุล" icon="/images/balance.svg" />
      <PdfCard title={"กระแสเงินสด"}>
        {/* กระแสเงินสด */}
        <PdfCardWhite className={"flex flex-col"}>
          <PdfHeaderCardText
            title="รวมรายได้ทั้งหมด"
            titleEnd={`${numeral(total).format("0,0")} บาท`}
            icon=""
            classNameRoot="mt-2"
          />

          <PdfBalanceHeaderItemCard title={"แหล่งที่มาของรายได้"} />
          <PdfLine className={"my-2"} />

          {/* SALARY ITEMS */}
          <div className="mb-2">
            <div className="mx-2 font-[500] text-[11px] my-1">รายได้</div>
            {propertyIncomeItems.map((propertyItem, index) => {
              return (
                <div key={index} className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={`/images/${propertyItem?.icon}`}
                        className="bg-cover"
                      />
                    </div>
                    <div className="text-[11px] font-[500]">
                      {propertyItem?.label}
                    </div>
                  </div>

                  <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                    {propertyItem.items.map((item, index) => {
                      return <PdfBalanceItemCard key={index} item={item} />;
                    })}
                  </PdfCardGray>
                </div>
              );
            })}
          </div>

          {/* INTEREST ITEMS */}
          <div className="mb-2">
            {propertyIncomeInterestItems.map((propertyItem, index) => {
              return (
                <div key={index} className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={`/images/${propertyItem?.icon}`}
                        className="bg-cover"
                      />
                    </div>
                    <div className="text-[11px] font-[500]">
                      {propertyItem?.label}
                    </div>
                  </div>

                  <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                    {propertyItem.items.map((item, index) => {
                      return <PdfBalanceItemCard key={index} item={item} />;
                    })}
                  </PdfCardGray>
                </div>
              );
            })}
          </div>

           {/* BONUS ITEMS */}
           <div className="mb-2">
            {propertyIncomeBonusItems.map((propertyItem, index) => {
              return (
                <div key={index} className="flex flex-col mx-2">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={`/images/${propertyItem?.icon}`}
                        className="bg-cover"
                      />
                    </div>
                    <div className="text-[11px] font-[500]">
                      {propertyItem?.label}
                    </div>
                  </div>

                  <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                    {propertyItem.items.map((item, index) => {
                      return <PdfBalanceItemCard key={index} item={item} />;
                    })}
                  </PdfCardGray>
                </div>
              );
            })}
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageBalananceFour;
