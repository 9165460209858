import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useRiskStore } from "../../../_store/riskStore";

function PdfPageRiskOne({page}) {
  const riskData = useRiskStore((state) => state.riskData);

  // dead
  const getRiskText = () => {
    if (riskData?.dead_sum_score === 1) {
      return `ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้`;
    } else if (riskData?.dead_sum_score === 1.5) {
      return `ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้`;
    } else if (riskData?.dead_sum_score === 2) {
      return `แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันชีวิตและประกันอุบัติเหตุ`;
    } else if (riskData?.dead_sum_score === 2.5) {
      return `ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้`;
    }
  };

  // sick
  const getSickText = () => {
    if (!riskData?.sick_sum_score) return "-";
    return `แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันชีวิตและประกันอุบัติเหตุ`;
  };
  const getSickDesc = () => {
    if (riskData?.sick_sum_score === 1) {
      return `แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ถ้าท่านสุขภาพไม่ดีเกิดการเจ็บป่วยก่อนการทำประกัน บริษัทประกันจะปฏิเสธการรับประกันท่านในโรคที่เป็นมาก่อนและภาวะสืบเนื่อง`;
    } else if (riskData?.sick_sum_score === 1.5) {
      return `แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ถ้าท่านสุขภาพไม่ดีเกิดการเจ็บป่วยก่อนการทำประกัน บริษัทประกันจะปฏิเสธการรับประกันท่านในโรคที่เป็นมาก่อนและภาวะสืบเนื่อง`;
    } else if (riskData?.sick_sum_score === 2) {
      return `แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ดูแลสุขภาพตนเอง ทานอาหารให้ครบ 5 หมู่ ออกกำลังกายอย่างสม่ำเสมอ พักผ่อนให้เพียงพอ อยู่ในสภาพแวดล้อมที่ดี ฝึกทำสมาธิ ดูแลสติให้อยู่ในปัจจุบัน ทำจิตใจให้เบิกบาน`;
    } else if (riskData?.sick_sum_score === 2.5) {
      return `โอนความเสี่ยง ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยงด้วยการทำประกันสุขภาพ ดูแลสุขภาพตนเอง ทานอาหารให้ครบ 5 หมู่ ออกกำลังกายอย่างสม่ำเสมอ พักผ่อนให้เพียงพอ อยู่ในสภาพแวดล้อมที่ดี ฝึกทำสมาธิ ดูแลสติให้อยู่ในปัจจุบัน ทำจิตใจให้เบิกบาน`;
    } else {
      return "";
    }
  };

  // disabled
  const getDisabledText = () => {
    if (!riskData?.accident_sum_score) return "-";
    return `ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้`;
  };

  // accident
  const getAccidentText = () => {
    if (!riskData?.accident_sum_score) return "-";
    return `ท่านมีความสามารถในการรับความเสี่ยงไว้เองได้`;
  };

  // risk_car_accident
  const getCarAccidentText = () => {
    if (!riskData?.car_accident_sum_score) return "-";
    return `ท่านเลือกวิธีจัดการความเสี่ยงด้วยการ โอนความเสี่ยง`;
  };
  const getCarAccidentDesc = () => {
    if (!riskData?.car_accident_sum_score) return "";
    return "'โอนความเสี่ยง' ท่านเลือกวิธีจัดการความเสี่ยงได้เหมาะสม แนะนำให้ท่านโอนความเสี่ยงด้วย การทำประกันภัยภาคบังคับ พ.ร.บ.";
  };

  const riskItems = useMemo(() => {
    const newRiskItems = [
      {
        label: "กรณีเสียชีวิต",
        icon: "risk_dead.svg",
        title: getRiskText(),
        desc: "",
      },
      {
        label: "กรณีเจ็บป่วย",
        icon: "risk_sick.svg",
        title: getSickText(),
        desc: getSickDesc(),
      },
      {
        label: "กรณีทุพพลภาพ",
        icon: "risk_diabled.svg",
        title: getDisabledText(),
        desc: "",
      },
      {
        label: "กรณีอุบัติเหตุ",
        icon: "risk_accident.svg",
        title: getAccidentText(),
        desc: "",
      },
      {
        label: "กรณีอุบัติเหตุทางรถยนต์",
        icon: "risk_car_accident.svg",
        title: getCarAccidentText(),
        desc: getCarAccidentDesc(),
      },
    ];
    return newRiskItems;
  }, [riskData]);

  const insuranceItems = useMemo(() => {
    const newInsuranceItems = [
      {
        label: "ประกันชีวิต",
        icon: "insurance_life.svg",
        desc: `คุณมีรายได้มากกว่า 1 ช่องทาง รายได้เหล่านี้สามารถช่วยให้ คุณบรรลุเป้าหมายทางการเงินได้แน่นอน`,
      },
      {
        label: "ประกันทุพพลภาพ",
        icon: "risk_diabled.svg",
        desc: `คุณต้องเพิ่มการออมและการลงทุนคุณน่าจะลดค่าใช้จ่าย แล้วนำเงินมาเพิ่มการออมและลงทุนเพื่อเป้าหมายทางการเงิน`,
      },
      {
        label: "ประกันสุขภาพ",
        icon: "risk_sick.svg",
        desc: `คุณต้องเพิ่มการออมและการลงทุนคุณน่าจะลดค่าใช้จ่าย แล้วนำเงินมาเพิ่มการออมและลงทุนเพื่อเป้าหมายทางการเงิน`,
      },
    ];
    return newInsuranceItems;
  }, [riskData]);

  const insurance2Items = useMemo(() => {
    const newInsuranceItems = [
      {
        label: "ประกันบ้าน",
        icon: "insurance_house.svg",
        desc: `คุณมีรายได้มากกว่า 1 ช่องทาง รายได้เหล่านี้สามารถช่วยให้ คุณบรรลุเป้าหมายทางการเงินได้แน่นอน`,
      },
      {
        label: "ประกันรถยนต์",
        icon: "car_insuarance_all.png",
        desc: `คุณต้องเพิ่มการออมและการลงทุนคุณน่าจะลดค่าใช้จ่าย แล้วนำเงินมาเพิ่มการออมและลงทุนเพื่อเป้าหมายทางการเงิน`,
      },
    ];
    return newInsuranceItems;
  }, [riskData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="บริหารความเสี่ยง" icon="/images/payment_pdf.svg" />
      <PdfCard className={"flex flex-col space-y-3"}>
        {/* บริหารความเสี่ยง */}
        {riskItems.map((item, index) => {
          return (
            <PdfCardWhite key={index} className={"flex flex-col"}>
              <div className="flex items-center justify-between mx-1">
                <div className="flex">
                  <div>
                    <img
                      src={`/images/${item.icon}`}
                      className="w-[18px] h-[18px]"
                    />
                  </div>
                  <div className="ml-1 font-[500] text-[11px] my-1">
                    {item.label}
                  </div>
                </div>
                <div className={cn(STYLE_PDF_TEXT_SEMIBOLD_BLUE_12)}>
                  {item.title}
                </div>
              </div>
              {item.desc && (
                <div
                  className={cn(
                    "flex justify-center items-center text-center px-20 mt-1",
                    STYLE_PDF_TEXT_NORMAL_10
                  )}
                >
                  {item.desc}
                </div>
              )}
            </PdfCardWhite>
          );
        })}
      </PdfCard>

      {/* ประกันที่ควรมี */}
      <PdfCard className={"flex flex-col space-y-3"} title={"ประกันที่ควรมี"}>
        {/* ประกันที่ควรมี 1 */}
        <div className="grid grid-cols-3 gap-3">
          {insuranceItems.map((item, index) => {
            return (
              <PdfCardWhite key={index} className={"flex flex-col"}>
                <div className="flex items-center  mx-1">
                  <div>
                    <img
                      src={`/images/${item.icon}`}
                      className="w-[18px] h-[18px]"
                    />
                  </div>
                  <div className="ml-1 font-[500] text-[11px] my-1">
                    {item.label}
                  </div>
                </div>
                <div className={cn(STYLE_PDF_TEXT_NORMAL_10, "text-center")}>
                  {item.desc}
                </div>
              </PdfCardWhite>
            );
          })}
        </div>

        {/* ประกันที่ควรมี 2 */}
        <div className="grid grid-cols-2 gap-3">
          {insurance2Items.map((item, index) => {
            return (
              <PdfCardWhite key={index} className={"flex flex-col"}>
                <div className="flex items-center  mx-1">
                  <div>
                    <img
                      src={`/images/${item.icon}`}
                      className="w-[18px] h-[18px]"
                    />
                  </div>
                  <div className="ml-1 font-[500] text-[11px] my-1">
                    {item.label}
                  </div>
                </div>
                <div className={cn(STYLE_PDF_TEXT_NORMAL_10, "text-center")}>
                  {item.desc}
                </div>
              </PdfCardWhite>
            );
          })}
        </div>
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageRiskOne;
