import { Checkbox } from "@material-tailwind/react";
import CheckBoxMain from "../../../components/checkbox/CheckboxMain";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  ButtonCounter,
  DeleteButtonCard,
} from "../../../components/button/MTButton";
import { ICON_DELETE } from "../../../utils/useIcons";
import { NumericFormat } from "react-number-format";
import {
  STYLE_INPUT,
  STYLE_INPUT_BORDER,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_INPUT_SLIDER,
  STYLE_LABEL_FUTURE,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TEXT_CYAN,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../utils/useStyle";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { InputMain } from "../../../components/input/InputMain";
import numeral from "numeral";

export const CardFutureCheckBox = ({
  checked,
  icon,
  icon_width = " w-6 h-6",
  label,
  onChange,
}) => {
  return (
    <div className=" bg-white rounded-[10px] px-5 py-2.5 flex  items-center ">
      <Checkbox
        onChange={onChange}
        // onClick={() => {
        //   setOpen(value);
        // }}
        containerProps={{
          className: "p-0",
        }}
        size={"sm"}
        className="p-0"
        color="cyan"
        checked={checked}
        label={
          <div className="  flex items-center gap-2.5 ml-2 ">
            {icon && <img src={icon} className={icon_width} alt="" />}
            <div className=" text-md md:text-lg text-mainblue  font-medium  hover:none ">
              {label}
            </div>
          </div>
        }
        ripple={false}
      />
    </div>
  );
};

export const CardFuture = ({
  title,
  text_color = "text-black",
  icon,
  onChangeForm,
  item,
  primary_key,
  index,
}) => {
  return (
    <div className="">
      <div className=" flex items-center gap-2 px-3 py-2 bg-[#f6f6f6] text-mainblue font-semibold rounded-t-[20px]">
        <ImageMain src={icon} className=" w-6 h-6 " />
        <div> ซื้อ{title}</div>
        <ICON_DELETE className=" w-5 h-5 text-mainred ml-auto" />
      </div>
      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-4 `}
      >
        {/* ------ คาดการณ์ไว้อีกกี่ปีข้างหน้า ------ */}

        <div className="">
          <div>
            <div className={STYLE_LABEL_FUTURE}>
              คาดการณ์ไว้อีกกี่ปีข้างหน้า
            </div>
            <div className=" flex justify-center">
              <NumericFormat
                // type="number"
                maxLength={11}
                placeholder="0"
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={STYLE_INPUT_SLIDER}
                onChange={onChangeForm(primary_key, "year", index)}
                value={item?.year}
                // value={formRetire?.out_come_peryear}
                // onChange={onChangeOutComePerYear}
              />
            </div>
            <SliderSingle
              year={true}
              onChange={onChangeForm(primary_key, "year", index)}
              value={item?.year}
              valuemax={25}
            />
          </div>
        </div>

        {/* ------ เงินดาวน์ ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>เงินดาวน์</div>
          <NumericFormat
            // type="number"
            maxLength={11}
            placeholder="0"
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={STYLE_INPUT_BORDER}
            onChange={onChangeForm(primary_key, "down", index)}
            value={item?.down}
            // value={formRetire?.out_come_peryear}
            // onChange={onChangeOutComePerYear}
          />
        </div>
        {/* ------ ออมเงิน/ลงทุน เพื่อเงินดาวน์ได้ดอกเบี้ย (ต่อปี) ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>
            ออมเงิน/ลงทุน เพื่อเงินดาวน์{title}
            <div>ผลตอบแทน (ต่อปี)</div>
          </div>
          <div className=" flex justify-center">
            <NumericFormat
              // type="number"
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(
                primary_key,
                "invest_down_interest",
                index
              )}
              value={item?.invest_down_interest}
            />
          </div>
          <SliderSingle
            year={false}
            onChange={onChangeForm(primary_key, "invest_down_interest", index)}
            value={item?.invest_down_interest}
            valuemax={25}
            // valuemax={formRetire?.out_come_peryear_max}
            // value={formRetire?.out_come_peryear}
            // onChange={onChangeOutComePerYear}
            // onChnageMax={onChangeOutComePerYearMax}
          />
          <div className=" mt-2">
            <div className={`${STYLE_LABEL_FUTURE} text-center`}>
              มีเวลาออม {item?.year} ปี
            </div>
            <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
              ต้องออมปีละ {numeral(item?.sum_invest_five).format("0,0")} บาท
            </div>
          </div>
        </div>

        {/* ------ มูลค่า ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>มูลค่า{title} (บาท)</div>
          <NumericFormat
            // type="number"
            maxLength={11}
            placeholder="0"
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={STYLE_INPUT_BORDER}
            onChange={onChangeForm(primary_key, "amount", index)}
            value={item?.amount}
          />
        </div>
        {/* ------ ดอกเบี้ย (ต่อปี) ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>ดอกเบี้ย{title} (ต่อปี)</div>
          <div className=" flex justify-center">
            <NumericFormat
              // type="number"
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(primary_key, "interest", index)}
              value={item?.interest}
            />
          </div>
          <SliderSingle
            year={false}
            onChange={onChangeForm(primary_key, "interest", index)}
            value={item?.interest}
            valuemax={25}
          />
        </div>
        {/* ------ ระยะเวลาที่ต้องการผ่อน ------ */}
        <div>
          <div className={STYLE_LABEL_FUTURE}>ระยะเวลาที่ต้องการผ่อน</div>
          <div className=" flex justify-center">
            <NumericFormat
              // type="number"
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              onChange={onChangeForm(primary_key, "period", index)}
              value={item?.period}
            />
          </div>
          <SliderSingle
            year={true}
            onChange={onChangeForm(primary_key, "period", index)}
            value={item?.period}
            valuemax={50}
          />
          <div className=" mt-2">
            <div className={`${STYLE_LABEL_FUTURE} text-center`}>
              ต้องชำระงวดละ (บาท)
            </div>
            <div className={`${STYLE_TEXT_CYAN} text-center text-xl`}>
              {numeral(item?.sum_amount_per_period).format("0,0")} บาท
            </div>
          </div>
        </div>

        {/* เจ้าหนี้ */}
        <div className="bg-gray-200 w-ful px-3 py-2 rounded mx-2 flex-col">
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">
              เจ้าหนี้จะได้เงินไปทั้งสิ้น
            </div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.amount_all).format("0,0")} บาท
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">เป็นดอกเบี้ยเงินกู้</div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.interest_amount).format("0,0")} บาท
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-maingray text-[12px]">จากเงินกู้</div>
            <div className="text-maingray text-[12px]">
              {numeral(item?.amount).format("0,0")} บาท
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardFutureTwo = ({
  title,
  text_color = "text-black",
  icon,
  onChangeForm,
  item,
  primary_key,
  index,
}) => {
  return (
    <div className="">
      <div className=" flex items-center gap-2 p-3 bg-[#f6f6f6] text-mainblue font-semibold rounded-t-[20px]">
        <ImageMain src={icon} className=" w-6 h-6" />
        <div> {title}</div>
        <ICON_DELETE className=" w-5 h-5 text-mainred ml-auto" />
      </div>
      <div
        className={`bg-[#fcfcfc] rounded-b-[20px] px-3 py-2 ${text_color} text-sm w-full h-full space-y-4 `}
      >
        <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
          <div className=" text-sm text-black font-medium">{title}</div>
          {/* <ButtonCounter
          value={row?.debt_long_places?.length}
          handleNext={handleNextLongPlace.bind(
            this,
            index
          )}
          /> */}
        </div>

        {/* ------ Card ตอนกดปุ่มบวกเพิ่ม ------ */}
        <div className=" bg-white rounded-lg px-4 py-[6px] ">
          <div className={` text-mainblue text-sm font-semibold `}>{title}</div>
          <div className=" flex items-center">
            <InputMain
              placeholder={`ระบุรายรายการ${title}`}
              value={item?.title}
              onChange={onChangeForm(primary_key, "title", index, "string")}
            />
            <ICON_DELETE className=" w-5 h-5 text-mainred ml-auto" />
          </div>
          <div>
            <div className={`text-mainblue font-semibold my-2 `}>
              คาดการณ์ไว้อีกกี่ปีข้างหน้า
            </div>
            <div className=" flex justify-center">
              <NumericFormat
                maxLength={11}
                placeholder="0"
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className={STYLE_INPUT_SLIDER}
                value={item?.year}
                onChange={onChangeForm(primary_key, "year", index)}
              />
            </div>
            <SliderSingle
              showInputMax={false}
              year={true}
              value={item?.year}
              onChange={onChangeForm(primary_key, "year", index)}
              valuemax={25}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงิน
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
              value={item?.amount}
              onChange={onChangeForm(primary_key, "amount", index)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
