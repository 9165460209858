import { useSettingStore } from "../../../_store/settingStore";

const settingData = useSettingStore.getState().settingData;

export const options_education_rank = [
  {
    value: "education_nursery_1",
    label: "เนอสเซอรี่ 1",
    year: 1,
    key: "education_nursery",
  },
  {
    value: "education_nursery_2",
    label: "เนอสเซอรี่ 2",
    year: 2,
    key: "education_nursery",
  },
  {
    value: "education_nursery_3",
    label: "เนอสเซอรี่ 3",
    year: 3,
    key: "education_nursery",
  },
  {
    value: "education_curator_1",
    label: "อนุบาล 1 ",
    year: 4,
    key: "education_curator",
  },
  {
    value: "education_curator_2",
    label: "อนุบาล 2",
    year: 5,
    key: "education_curator",
  },
  {
    value: "education_curator_3",
    label: "อนุบาล 3",
    year: 6,
    key: "education_curator",
  },
  {
    value: "education_primary_1",
    label: "ประถมศึกษา 1 ",
    year: 7,
    key: "education_primary",
  },
  {
    value: "education_primary_2",
    label: "ประถมศึกษา 2",
    year: 8,
    key: "education_primary",
  },
  {
    value: "education_primary_3",
    label: "ประถมศึกษา 3",
    year: 9,
    key: "education_primary",
  },
  {
    value: "education_primary_4",
    label: "ประถมศึกษา 4",
    year: 10,
    key: "education_primary",
  },
  {
    value: "education_primary_5",
    label: "ประถมศึกษา 5",
    year: 11,
    key: "education_primary",
  },
  {
    value: "education_primary_6",
    label: "ประถมศึกษา 6",
    year: 12,
    key: "education_primary",
  },
  {
    value: "education_secondary_1",
    label: "มัธยมศึกษา 1",
    year: 13,
    key: "education_secondary_middle",
  },
  {
    value: "education_secondary_2",
    label: "มัธยมศึกษา 2",
    year: 14,
    key: "education_secondary_middle",
  },
  {
    value: "education_secondary_3",
    label: "มัธยมศึกษา 3",
    year: 15,
    key: "education_secondary_middle",
  },
  {
    value: "education_secondary_4",
    label: "มัธยมศึกษา 4",
    year: 16,
    key: "education_secondary_high",
  },
  {
    value: "education_secondary_5",
    label: "มัธยมศึกษา 5",
    year: 17,
    key: "education_secondary_high",
  },
  {
    value: "education_secondary_6",
    label: "มัธยมศึกษา 6",
    year: 18,
    key: "education_secondary_high",
  },
  {
    value: "education_bachelor_1",
    label: "ปริญญาตรี 1",
    year: 19,
    key: "education_bachelor",
  },
  {
    value: "education_bachelor_2",
    label: "ปริญญาตรี 2",
    year: 20,
    key: "education_bachelor",
  },
  {
    value: "education_bachelor_3",
    label: "ปริญญาตรี 3",
    year: 21,
    key: "education_bachelor",
  },
  {
    value: "education_bachelor_4",
    label: "ปริญญาตรี 4",
    year: 22,
    key: "education_bachelor",
  },
  {
    value: "education_master_1",
    label: "ปริญญาโท 1",
    year: 23,
    key: "education_master",
  },
  {
    value: "education_master_2",
    label: "ปริญญาโท 2",
    year: 24,
    key: "education_master",
  },
  {
    value: "education_doctoral_1",
    label: "ปริญญาเอก 1",
    year: 25,
    key: "education_doctoral",
  },
  {
    value: "education_doctoral_2",
    label: "ปริญญาเอก 2",
    year: 26,
    key: "education_doctoral",
  },
  {
    value: "education_doctoral_3",
    label: "ปริญญาเอก 3",
    year: 27,
    key: "education_doctoral",
  },
  {
    value: "education_doctoral_4",
    label: "ปริญญาเอก 4",
    year: 28,
    key: "education_doctoral",
  },
  {
    value: "education_doctoral_5",
    label: "ปริญญาเอก 5",
    year: 29,
    key: "education_doctoral",
  },
];

export const options_educations_type = [
  {
    label: "รัฐบาล",
    value: "education_type_government",
  },
  {
    label: "เอกชน",
    value: "education_type_private",
  },
  {
    label: "นานาชาติ",
    value: "education_type_foreign",
  },
];

export const key_education_rank = {
  education_nursery: "เนอสเซอรี่",
  education_curator: "อนุบาล",
  education_primary: "ประถมศึกษา",
  education_secondary_middle: "มัธยมต้น",
  education_secondary_high: "มัธยมปลาย",
  education_bachelor: "ปริญญาตรี",
  education_master: "ปริญญาโท",
  education_doctoral: "ปริญญาเอก",
};

export const key_education_intial = {
  education_nursery: "นร.",
  education_curator: "อน.",
  education_primary: "ปศ.",
  education_secondary_middle: "ม.ต้น",
  education_secondary_high: "ม.ปลาย",
  education_bachelor: "ป.ตรี",
  education_master: "ป.โท",
  education_doctoral: "ป.เอก",
};

export const options_under_education = [
  {
    label: "รัฐบาล",
    value: "government",
  },
  {
    label: "เอกชน",
    value: "private",
  },
  {
    label: "นานาชาติ",
    value: "foreign",
  },
  {
    label: "ต่างประเทศ",
    value: "abroad",
  },
  {
    label: "กำหนดเอง",
    value: "custom",
  },
];

export const key_under_education = {
  government: {
    label: "รัฐบาล",
    default_value: settingData?.goverment ?? 60000,
    // เนอสเชอรี่ รัฐบาล
    education_nursery: {
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // อนุบาล รัฐบาล
    education_curator: {
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // ประถม รัฐบาล
    education_primary: {
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // มัธยมต้น รัฐบาล
    education_secondary_middle: {
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // มัธยมปลาย รัฐบาล
    education_secondary_high: {
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // ปริญญาตรี รัฐบาล
    education_bachelor: {
      // expenses_per_year: 30000,
      // expenses_living: 180000,
      // inflation_rate: 5,
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // ปริญญาโท รัฐบาล
    education_master: {
      // expenses_per_year: 60000,
      // expenses_living: 180000,
      // inflation_rate: 5,
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
    // ปริญญาเอก รัฐบาล
    education_doctoral: {
      // expenses_per_year: 200000,
      // expenses_living: 240000,
      // inflation_rate: 5,
      expenses_per_year: 20000,
      expenses_living: 40000,
      inflation_rate: 5,
    },
  },
  private: {
    label: "เอกชน",
    default_value: settingData?.education?.private ?? 120000,
  },
  foreign: {
    label: "นานาชาติ",
    default_value: settingData?.education?.foreign ?? 800000,
  },
  abroad: {
    label: "ต่างประเทศ",
    default_value: settingData?.education?.abroad ?? 5000000,
  },
};
