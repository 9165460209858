import { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { Checkbox, Drawer } from "@material-tailwind/react";
import {
  STYLE_MAX_W_DRAWER,
  STYLE_PADDING_BALANCE_DRAWER,
} from "../../../utils/useStyle";
import { v4 as uuidv4 } from "uuid";

import numeral from "numeral";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import {
  OPTIONS_COMPOUNDING,
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
  calPeryear,
  options_debt_long,
  options_debt_short,
} from "../functions";
import {
  ButtonCounter,
  DeleteButtonCard,
  MTButton,
} from "../../../components/button/MTButton";
import { CardAccordian, CardDebt, CardDebtLong } from "../styles/Card";
import { HeaderDrawer } from "../../../components/header/Header";
import { PopupMain } from "../../../components/popup/PopupMain";
import _, { set } from "lodash";
import {
  calculateMonthlyPayment,
  calPMT,
  calTotalCreditorFloatInterest,
  IRR,
  toFloat2,
} from "../../../utils/useFunctions";

export const numberWithoutComma = (value) => {
  let result = toFloat2(value?.replace(/,/g, ""), 10);
  return result;
};

export const calPeriodShortTerm = (short_term) => {
  let unpaid_amount = short_term?.unpaid_amount;
  let total_creditor = unpaid_amount;
  let total_creditor_interest = 0;
  let remain = unpaid_amount;
  let creditor_items = [];
  let row = 0;

  do {
    row++;
    const total_min = toFloat2(unpaid_amount * 0.1);
    const interest = toFloat2((remain * short_term.interest) / 100);
    const total_interest = toFloat2(interest + remain);
    const total_after_pay = toFloat2(total_interest - short_term?.pay_permonth);
    remain = toFloat2(total_after_pay);
    creditor_items.push({
      row,
      total_min,
      interest,
      total_interest,
      total_after_pay,
      remain,
    });
  } while (remain > 1);
  return creditor_items;
};

export const DrawerBalanceDebt = ({
  open,
  onclose,
  formBL,
  setFormBL,
  setOpenDrawer,
  openAccordion,
  setOpenAccordion,
  addShortDebt,
  setAddShortDebt,
  addLongDebt,
  setAddLongDebt,
}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const handleOpen = (value) => setOpenAccordion(open === value ? 0 : value);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert2, setOpenAlert2] = useState(false);

  const [dataIndexDeleteOne, setDataIndexDeleteOne] = useState(null);
  const [dataIndexDeleteAll, setDataIndexDeleteAll] = useState(null);

  // mark
  //====================================================== Short ======================================================//
  //---------------------------------------- Select Short Dropdown ----------------------------------------//
  const onChangeSelectedShort = (created, index, e) => {
    setAddShortDebt((prev) => {
      let value = e.value;
      const result = [...prev];

      // หนี้บัตรเครดิต
      if (value === "debt_creditcard") {
        result[index].options_debt_short = value;
        result[index].credit_cards = [
          {
            created: created,
            title: "",
            creditor_name: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 16,
            type_payment: "",
            pay_permonth: "",
            pay_peryear: "",
          },
        ];
        delete result[index].short_terms;
        delete result[index].informal_short_terms;
        delete result[index].short_term_others;
      }
      // หนี้เงินกู้ระยะสั้น
      if (value === "debt_shortterm") {
        result[index].options_debt_short = value;
        result[index].short_terms = [
          {
            created: created,
            creditor_name: "",
            title: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 24,
            compounding_type: "",
            period: "",
            pay_permonth: "",
            pay_peryear: "",
            normal_payment: false,
          },
        ];
        delete result[index].credit_cards;
        delete result[index].informal_short_terms;
        delete result[index].short_term_others;
      }
      // หนี้เงินกู้นอกระบบ
      if (value === "debt_informal_shortterm") {
        result[index].options_debt_short = value;
        result[index].informal_short_terms = [
          {
            created: created,
            creditor_name: "",
            title: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 100,
            compounding_type: "",
            period: "",
            pay_permonth: "",
            pay_peryear: "",
            normal_payment: false,
          },
        ];
        delete result[index].credit_cards;
        delete result[index].short_terms;
        delete result[index].short_term_others;
      }
      // หนี้เงินกู้ระยะสั้นอื่น ๆ
      if (value === "debt_shortterm_other") {
        result[index].options_debt_short = value;
        result[index].short_term_others = [
          {
            created: created,
            creditor_name: "",
            title: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 24,
            compounding_type: "",
            period: "",
            pay_permonth: "",
            pay_peryear: "",
            normal_payment: false,
          },
        ];
        delete result[index].credit_cards;
        delete result[index].short_terms;
        delete result[index].informal_short_terms;
      }

      return result;
    });
  };

  //------------------------------------------------ CreditCard ------------------------------------------------ //
  const handleCreditCardNext = (index, e) => {
    setAddShortDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        creditor_name: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 16,
        type_payment: "",
        pay_permonth: "",
        pay_peryear: "",
      };
      result[index] = {
        ...result[index],
        credit_cards: [...result[index].credit_cards, { ...newList }],
      };
      return result;
    });
  };
  const onChangeTitleCreditCard = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].credit_cards[indexCard].title = value;
      return result;
    });
  };
  const onChangeTitleCreditorCreditCard = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].credit_cards[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountCreditCard = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].credit_cards[indexCard].start_amount = "";
      } else {
        result[index].credit_cards[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountCreditCard = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].credit_cards[indexCard].start_unpaid_amount = "";
      } else {
        result[index].credit_cards[indexCard].start_unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountCreditCard = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].credit_cards[indexCard].pay_permonth = "";
      result[index].credit_cards[indexCard].pay_peryear = "";
      result[index].credit_cards[indexCard].type_payment = "";
      if (isNaN(value)) {
        result[index].credit_cards[indexCard].unpaid_amount = "";
      } else {
        result[index].credit_cards[indexCard].unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeInterestCreditCard = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].credit_cards[indexCard].interest = "";
      } else {
        result[index].credit_cards[indexCard].interest = value;
      }
      return result;
    });
  };
  const onChangeStatusCreditCard = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].credit_cards[indexCard].status = value;
      return result;
    });
  };
  // ประเภทชำระหนี้
  const handleCheckboxChangeTypePaymentCreditCard = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (value === "pay_full") {
        result[index].credit_cards[indexCard].type_payment = value;
        result[index].credit_cards[indexCard].pay_permonth = result[index]
          .credit_cards[indexCard].unpaid_amount
          ? result[index].credit_cards[indexCard].unpaid_amount
          : 0;
        // ต่อปี
        let payPeryear =
          result[index].credit_cards[indexCard].pay_permonth * 12;
        result[index].credit_cards[indexCard].pay_peryear =
          payPeryear > result[index].credit_cards[indexCard].unpaid_amount
            ? result[index].credit_cards[indexCard].unpaid_amount
            : payPeryear;
      }
      if (value === "pay_minimum") {
        result[index].credit_cards[indexCard].type_payment = value;
        result[index].credit_cards[indexCard].pay_permonth = result[index]
          .credit_cards[indexCard].unpaid_amount
          ? (result[index].credit_cards[indexCard].unpaid_amount * 8) / 100
          : 0;
        // ต่อปี
        let payPeryear =
          result[index].credit_cards[indexCard].pay_permonth * 12;
        result[index].credit_cards[indexCard].pay_peryear =
          payPeryear > result[index].credit_cards[indexCard].unpaid_amount
            ? result[index].credit_cards[indexCard].unpaid_amount
            : payPeryear;
      }
      if (value === "pay_other") {
        result[index].credit_cards[indexCard].type_payment = value;
        result[index].credit_cards[indexCard].pay_permonth = "";
        result[index].credit_cards[indexCard].pay_peryear = "";
      }
      return result;
    });
  };
  const onChangePayPerMonthCreditCard = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].credit_cards[indexCard].pay_permonth = "";
        result[index].credit_cards[indexCard].pay_peryear = "";
      } else {
        result[index].credit_cards[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].credit_cards[indexCard].pay_peryear =
          payPeryear > result[index].credit_cards[indexCard].unpaid_amount
            ? result[index].credit_cards[indexCard].unpaid_amount
            : payPeryear;
      }
      return result;
    });
  };

  const onClickDeleteCreditCard = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onClickConfirmDeleteCreditCard = () => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedCreditCards = [...result[index].credit_cards];
      updatedCreditCards.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        credit_cards: updatedCreditCards,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  //------------------------------------------------ ShortTerm ------------------------------------------------ //
  const handleShortTermNext = (index, e) => {
    setAddShortDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        creditor_name: "",
        title: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 24,
        compounding_type: "",
        period: "",
        pay_permonth: "",
        pay_peryear: "",
        normal_payment: false,
        status: "normal",
      };
      result[index] = {
        ...result[index],
        short_terms: [...result[index].short_terms, { ...newList }],
      };
      return result;
    });
  };
  const onChangeTitleShortTerm = (index, indexCard, e) => {
    const value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_terms[indexCard].title = value;
      return result;
    });
  };
  const onChangeTitleCreditorShortTerm = (index, indexCard, e) => {
    const value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_terms[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountShortTerm = (index, indexCard, e) => {
    const value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_terms[indexCard].start_amount = "";
      } else {
        result[index].short_terms[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].short_terms[indexCard].start_unpaid_amount = "";
      } else {
        result[index].short_terms[indexCard].start_unpaid_amount = value;

        const interest_min = toFloat2(
          (result[index].short_terms[indexCard].unpaid_amount *
            result[index].short_terms[indexCard].interest) /
            100
        );

        if (interest_min >= value) {
          result[index].short_terms[indexCard].period = "Infinity";
        } else {
          const periodShortTerm = calPeriodShortTerm(
            result[index].short_terms[indexCard]
          );
          result[index].short_terms[indexCard].period = periodShortTerm.length;
        }
      }
      return result;
    });
  };
  const onChangeUnpaidAmountShortTerm = (index, indexCard, e) => {
    const value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_terms[indexCard].pay_permonth = "";
      result[index].short_terms[indexCard].pay_peryear = "";
      result[index].short_terms[indexCard].period = "";
      if (isNaN(value)) {
        result[index].short_terms[indexCard].unpaid_amount = "";
      } else {
        result[index].short_terms[indexCard].unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeInterestShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_terms[indexCard].interest = "";
      } else {
        result[index].short_terms[indexCard].interest = value;
        const interest_min = toFloat2(
          (result[index].short_terms[indexCard].unpaid_amount *
            result[index].short_terms[indexCard].interest) /
            100
        );

        if (interest_min >= value) {
          result[index].short_terms[indexCard].period = "Infinity";
        } else {
          const periodShortTerm = calPeriodShortTerm(
            result[index].short_terms[indexCard]
          );
          result[index].short_terms[indexCard].period = periodShortTerm.length;
        }
      }
      return result;
    });
  };
  const onChangeCompoundingTypeShortTerm = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });

    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_terms[indexCard].compounding_type = value;
      return result;
    });
  };
  const onChangeStatusShortTerm = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_terms[indexCard].status = value;
      return result;
    });
  };
  const onChangePeriodShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_terms[indexCard].period = "";
      } else {
        result[index].short_terms[indexCard].period = value;
      }
      return result;
    });
  };

  const onChangePayPerMonthShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_terms[indexCard].pay_permonth = "";
        result[index].short_terms[indexCard].pay_peryear = "";
        result[index].short_terms[indexCard].period = "";
      } else {
        // // ต่อเดือน
        const interest_min = toFloat2(
          (result[index].short_terms[indexCard].unpaid_amount *
            result[index].short_terms[indexCard].interest) /
            100
        );
        result[index].short_terms[indexCard].pay_permonth = value;

        // if (interest_min >= value) {
        //   result[index].short_terms[indexCard].period = "Infinity";
        // } else {
        //   const periodShortTerm = calPeriodShortTerm(
        //     result[index].short_terms[indexCard]
        //   );
        //   result[index].short_terms[indexCard].period = periodShortTerm.length;
        // }

        // // ต่อปี
        // let payPeryear = value * 12;
        // result[index].short_terms[indexCard].pay_peryear =
        //   payPeryear > result[index].short_terms[indexCard].unpaid_amount
        //     ? result[index].short_terms[indexCard].unpaid_amount
        //     : payPeryear;
        // // งวด
        if (value !== 0) {
          result[index].short_terms[indexCard].period = result[index]
            .short_terms[indexCard].unpaid_amount
            ? Math.ceil(
                result[index].short_terms[indexCard].unpaid_amount / value
              )
            : 0;
        }
      }
      return result;
    });
  };
  const [isCheckedNomalPaymentShortTerm, setisCheckedNomalPaymentShortTerm] =
    useState(false);
  const handleCheckboxNormalPaymentChangeShortTerm = (index, indexCard, e) => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].short_terms[indexCard].normal_payment =
        !isCheckedNomalPaymentShortTerm;
      return result;
    });
    setisCheckedNomalPaymentShortTerm(!isCheckedNomalPaymentShortTerm);
  };

  const onClickDeleteShortTerm = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onClickConfirmDeleteShortTerm = () => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedShortTerms = [...result[index].short_terms];
      updatedShortTerms.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        short_terms: updatedShortTerms,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  //----------------------------------------------- Informal ShortTerm ----------------------------------------------//
  const handleInformalShortTermNext = (index, e) => {
    setAddShortDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        creditor_name: "",
        title: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 0,
        compounding_type: "",
        period: "",
        pay_permonth: "",
        pay_peryear: "",
        normal_payment: false,
        status: "normal",
        number_of_days: 0,
      };
      result[index] = {
        ...result[index],
        informal_short_terms: [
          ...result[index].informal_short_terms,
          { ...newList },
        ],
      };
      return result;
    });
  };
  const onChangeTitleInformalShortTerm = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].informal_short_terms[indexCard].title = value;
      return result;
    });
  };
  const onChangeTitleCreditorInformalShortTerm = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].informal_short_terms[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeYearShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].year = "";
      } else {
        result[index].informal_short_terms[indexCard].year = value;
      }
      return result;
    });
  };
  const onChangeNumberOfDaysInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].number_of_days = "";
      } else {
        // B6 = start_amount, B7 pay_permonth, B8 = number_of_days
        // ((B7-(B6/B8))/B7)*365
        result[index].informal_short_terms[indexCard].number_of_days = value;
        // result[index].informal_short_terms[indexCard].interest =
        //   ((result[index].informal_short_terms[indexCard].pay_permonth -
        //     result[index].informal_short_terms[indexCard].start_amount /
        //       result[index].informal_short_terms[indexCard].number_of_days) /
        //     result[index].informal_short_terms[indexCard].pay_permonth) *
        //   365;
        if (result[index].informal_short_terms[indexCard].number_of_days) {
          const cashFlows = Array.from({
            length:
              result[index].informal_short_terms[indexCard].number_of_days,
          }).fill(result[index].informal_short_terms[indexCard].pay_permonth);
          result[index].informal_short_terms[indexCard].interest =
            IRR([
              -result[index].informal_short_terms[indexCard].start_amount,
              ...cashFlows,
            ]) *
            100 *
            365;
        }
      }
      return result;
    });
  };
  const onChangeStartAmountInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].start_amount = "";
      } else {
        // B6 = start_amount, B7 pay_permonth, B8 = number_of_days
        // ((B7-(B6/B8))/B7)*365
        result[index].informal_short_terms[indexCard].start_amount = value;
        // result[index].informal_short_terms[indexCard].interest =
        //   ((result[index].informal_short_terms[indexCard].pay_permonth -
        //     result[index].informal_short_terms[indexCard].start_amount /
        //       result[index].informal_short_terms[indexCard].number_of_days) /
        //     result[index].informal_short_terms[indexCard].pay_permonth) *
        //   365;

        if (result[index].informal_short_terms[indexCard].number_of_days) {
          const cashFlows = Array.from({
            length:
              result[index].informal_short_terms[indexCard].number_of_days,
          }).fill(result[index].informal_short_terms[indexCard].pay_permonth);
          result[index].informal_short_terms[indexCard].interest =
            IRR([
              -result[index].informal_short_terms[indexCard].start_amount,
              ...cashFlows,
            ]) *
            100 *
            365;
        }
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountInformalShortTerms = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].start_unpaid_amount = "";
      } else {
        result[index].informal_short_terms[indexCard].start_unpaid_amount =
          value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].informal_short_terms[indexCard].pay_permonth = "";
      result[index].informal_short_terms[indexCard].pay_peryear = "";
      result[index].informal_short_terms[indexCard].period = "";
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].unpaid_amount = "";
      } else {
        result[index].informal_short_terms[indexCard].unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeInterestInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].interest = "";
      } else {
        result[index].informal_short_terms[indexCard].interest = value;
      }
      return result;
    });
  };
  const onChangeCompoundingTypeInformalShortTerm = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });

    let interest = 0;

    if (value === "yearly") {
      interest = 100;
    } else if (value === "daily") {
      interest = 3;
    } else if (value === "weekly") {
      interest = 20;
    } else if (value === "monthly") {
      interest = 80;
    }
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].informal_short_terms[indexCard].compounding_type = value;
      result[index].informal_short_terms[indexCard].interest = interest;
      return result;
    });
  };
  const onChangeStatusInformalShortTerm = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].informal_short_terms[indexCard].status = value;
      return result;
    });
  };
  const onChangePeriodInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].period = "";
      } else {
        result[index].informal_short_terms[indexCard].period = value;
      }
      return result;
    });
  };
  const onChangePayPerMonthInformalShortTerm = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].informal_short_terms[indexCard].pay_permonth = "";
        result[index].informal_short_terms[indexCard].pay_peryear = "";
        result[index].informal_short_terms[indexCard].period = "";
      } else {
        // ต่อเดือน
        result[index].informal_short_terms[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].informal_short_terms[indexCard].pay_peryear =
          payPeryear >
          result[index].informal_short_terms[indexCard].unpaid_amount
            ? result[index].informal_short_terms[indexCard].unpaid_amount
            : payPeryear;
        // งวด
        if (value !== 0) {
          result[index].informal_short_terms[indexCard].period = result[index]
            .informal_short_terms[indexCard].unpaid_amount
            ? result[index].informal_short_terms[indexCard].unpaid_amount /
              value
            : 0;
        }

        if (result[index].informal_short_terms[indexCard].number_of_days) {
          const cashFlows = Array.from({
            length:
              result[index].informal_short_terms[indexCard].number_of_days,
          }).fill(result[index].informal_short_terms[indexCard].pay_permonth);
          result[index].informal_short_terms[indexCard].interest =
            IRR([
              -result[index].informal_short_terms[indexCard].start_amount,
              ...cashFlows,
            ]) *
            100 *
            365;
        }

        // result[index].informal_short_terms[indexCard].interest =
        //   ((result[index].informal_short_terms[indexCard].pay_permonth -
        //     result[index].informal_short_terms[indexCard].start_amount /
        //       result[index].informal_short_terms[indexCard].number_of_days) /
        //     result[index].informal_short_terms[indexCard].pay_permonth) *
        //   365;
      }
      return result;
    });
  };
  const [
    isCheckedNomalPaymentInformalShortTerm,
    setisCheckedNomalPaymentInformalShortTerm,
  ] = useState(false);
  const handleCheckboxNormalPaymentChangeInformalShortTerm = (
    index,
    indexCard,
    e
  ) => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].informal_short_terms[indexCard].normal_payment =
        !isCheckedNomalPaymentInformalShortTerm;
      return result;
    });
    setisCheckedNomalPaymentInformalShortTerm(
      !isCheckedNomalPaymentInformalShortTerm
    );
  };

  const onClickDeleteInformalShortTerm = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onClickConfirmDeleteInformalShortTerm = () => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedInformalShortTerms = [...result[index].informal_short_terms];
      updatedInformalShortTerms.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        informal_short_terms: updatedInformalShortTerms,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  //------------------------------------------------ ShortTermOther ------------------------------------------------//
  const handleShortTermOtherNext = (index, e) => {
    setAddShortDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        creditor_name: "",
        title: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 24,
        compounding_type: "",
        period: "",
        pay_permonth: "",
        pay_peryear: "",
        normal_payment: false,
        status: "normal",
      };
      result[index] = {
        ...result[index],
        short_term_others: [...result[index].short_term_others, { ...newList }],
      };
      return result;
    });
  };
  const onChangeTitleShortTermOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_term_others[indexCard].title = value;
      return result;
    });
  };
  const onChangeTitleCreditorShortTermOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_term_others[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].start_amount = "";
      } else {
        result[index].short_term_others[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      const result = [...prev];
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].start_unpaid_amount = "";
      } else {
        result[index].short_term_others[indexCard].start_unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_term_others[indexCard].pay_permonth = "";
      result[index].short_term_others[indexCard].pay_peryear = "";
      result[index].short_term_others[indexCard].period = "";
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].unpaid_amount = "";
      } else {
        result[index].short_term_others[indexCard].unpaid_amount = value;

        const amountInterest = toFloat2(
          (result[index].short_term_others[indexCard].unpaid_amount *
            result[index].short_term_others[indexCard].interest) /
            100
        );

        if (
          amountInterest >=
          result[index].short_term_others[indexCard].pay_permonth
        ) {
          result[index].short_term_others[indexCard].period = "Infinity";
        } else {
          result[index].short_term_others[indexCard].period =
            calTotalCreditorFloatInterest(
              result[index].short_term_others[indexCard]
            ).length;
        }
      }
      return result;
    });
  };
  const onChangeInterestShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].interest = "";
      } else {
        result[index].short_term_others[indexCard].interest = value;

        const amountInterest = toFloat2(
          (result[index].short_term_others[indexCard].unpaid_amount *
            result[index].short_term_others[indexCard].interest) /
            100
        );

        if (
          amountInterest >=
          result[index].short_term_others[indexCard].pay_permonth
        ) {
          result[index].short_term_others[indexCard].period = "Infinity";
        } else {
          result[index].short_term_others[indexCard].period =
            calTotalCreditorFloatInterest(
              result[index].short_term_others[indexCard]
            ).length;
        }
      }
      return result;
    });
  };
  const onChangeCompoundingTypeShortTermOther = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_term_others[indexCard].compounding_type = value;
      return result;
    });
  };
  const onChangeStatusShortTermOther = (index, indexCard, e) => {
    const value = e.value;
    // const resultCompound = OPTIONS_COMPOUNDING?.filter((item) => {
    //   if (item?.value === value) return item;
    // });
    setAddShortDebt((prev) => {
      let result = [...prev];
      result[index].short_term_others[indexCard].status = value;
      return result;
    });
  };
  const onChangePeriodShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].period = "";
      } else {
        result[index].short_term_others[indexCard].period = value;
      }
      return result;
    });
  };
  const onChangePayPerMonthShortTermOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddShortDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].short_term_others[indexCard].pay_permonth = "";
        result[index].short_term_others[indexCard].pay_peryear = "";
        result[index].short_term_others[indexCard].period = "";
      } else {
        // ต่อเดือน
        result[index].short_term_others[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].short_term_others[indexCard].pay_peryear =
          payPeryear > result[index].short_term_others[indexCard].unpaid_amount
            ? result[index].short_term_others[indexCard].unpaid_amount
            : payPeryear;
        // งวด
        if (value !== 0) {
          // result[index].short_term_others[indexCard].period = result[index]
          //   .short_term_others[indexCard].unpaid_amount
          //   ? result[index].short_term_others[indexCard].unpaid_amount / value
          //   : 0;
          result[index].short_term_others[indexCard].period =
            calTotalCreditorFloatInterest(
              result[index].short_term_others[indexCard]
            ).length;
        }

        const amountInterest = toFloat2(
          (result[index].short_term_others[indexCard].unpaid_amount *
            result[index].short_term_others[indexCard].interest) /
            100
        );

        if (
          amountInterest >=
          result[index].short_term_others[indexCard].pay_permonth
        ) {
          result[index].short_term_others[indexCard].period = "Infinity";
        }
      }
      return result;
    });
  };
  const [
    isCheckedNomalPaymentShortTermOther,
    setisCheckedNomalPaymentShortTermOther,
  ] = useState(false);
  const handleCheckboxNormalPaymentChangeShortTermOther = (
    index,
    indexCard,
    e
  ) => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      result[index].short_term_others[indexCard].normal_payment =
        !isCheckedNomalPaymentShortTermOther;
      return result;
    });
    setisCheckedNomalPaymentShortTermOther(
      !isCheckedNomalPaymentShortTermOther
    );
  };

  const onClickDeleteShortTermOther = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onClickConfirmDeleteShortTermOther = () => {
    setAddShortDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedShortTermOther = [...result[index].short_term_others];
      updatedShortTermOther.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        short_term_others: updatedShortTermOther,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  // mark
  //====================================================== Long ======================================================//
  //---------------------------------------- Select Long Dropdown ----------------------------------------//
  const onChangeSelectedLong = (created, index, e) => {
    setAddLongDebt((prev) => {
      let value = e.value;
      const result = [...prev];

      //---- หนี้สินเชื่อรถยนต์ / จักรยานยนต์ ----//
      if (value === "debt_car") {
        result[index].options_debt_long = value;
        result[index].debt_long_cars = [
          {
            created: created,
            title_car: "",
            type_car: "",
            registration_car: "",
            current_price: "",
            // personal_or_investment: {
            //   personal: true,
            //   investment: false,
            // },
            personal_or_investment: "personal",
            creditor_name: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 5,
            pay_permonth: "",
            pay_peryear: "",
            period: "",
            guarantor_name_one: "",
            guarantor_relationship_one: "",
            guarantor_name_two: "",
            guarantor_relationship_two: "",
          },
        ];
        delete result[index].debt_long_places;
        delete result[index].debt_long_others;
      }
      //---- หนี้ระยะยาวที่อยู่ ----//
      if (value === "debt_place") {
        result[index].options_debt_long = value;
        result[index].debt_long_places = [
          {
            created: created,
            address_place: "",
            type_place: "",
            type_place_other: "",
            current_price: "",
            use_collateral_assets: true,
            collateral_asset_data: "",
            collateral_asset_value: "",
            personal_or_investment: "personal",
            creditor_name: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 13,
            pay_permonth: "",
            pay_peryear: "",
            period: "",
            guarantor_name_one: "",
            guarantor_relationship_one: "",
            guarantor_name_two: "",
            guarantor_relationship_two: "",
          },
        ];
        delete result[index].debt_long_cars;
        delete result[index].debt_long_others;
      }
      //---- หนี้ระยะยาวอื่น ๆ ----//
      if (value === "debt_long_other") {
        result[index].options_debt_long = value;
        result[index].debt_long_others = [
          {
            created: created,
            title: "",
            current_price: "",
            use_collateral_assets: false,
            collateral_asset_data: "",
            collateral_asset_value: "",
            personal_or_investment: "personal",
            creditor_name: "",
            start_amount: "",
            start_unpaid_amount: "",
            unpaid_amount: "",
            interest: 12,
            pay_permonth: "",
            pay_peryear: "",
            period: "",
          },
        ];
        delete result[index].debt_long_cars;
        delete result[index].debt_long_places;
      }

      return result;
    });
  };

  //-------------------------------------------------- Car --------------------------------------------------//
  const handleNextLongCar = (index, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title_car: "",
        type_car: "",
        registration_car: "",
        current_price: "",
        personal_or_investment: "personal",
        creditor_name: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 5,
        pay_permonth: "",
        pay_peryear: "",
        period: "",
        guarantor_name_one: "",
        guarantor_relationship_one: "",
        guarantor_name_two: "",
        guarantor_relationship_two: "",
        stauts: "normal",
      };
      result[index] = {
        ...result[index],
        debt_long_cars: [...result[index].debt_long_cars, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteCars = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onClickConfirmDeleteCars = () => {
    setAddLongDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedCars = [...result[index].debt_long_cars];
      updatedCars.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        debt_long_cars: updatedCars,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].title_car = value;
      return result;
    });
  };
  const onChangeStatusCar = (index, indexCard, e) => {
    const value = e.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].status = value;
      return result;
    });
  };
  const onChangeSelectedTypeCar = (index, indexCard, e) => {
    let value = e.value;
    setAddLongDebt((prev) => {
      const result = [...prev];
      result[index].debt_long_cars[indexCard].type_car = value;
      return result;
    });
  };
  const onChangeRegistrationCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].registration_car = value;
      return result;
    });
  };
  const onChangeCurrentPriceCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].current_price = "";
      } else {
        result[index].debt_long_cars[indexCard].current_price = value;
      }
      return result;
    });
  };
  const onChangePersonalOrInvestmentCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].personal_or_investment = value;
      return result;
    });
  };
  const onChangeCreditorNameCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].start_amount = "";
      } else {
        result[index].debt_long_cars[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].start_unpaid_amount = "";
      } else {
        result[index].debt_long_cars[indexCard].start_unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].pay_permonth = "";
      result[index].debt_long_cars[indexCard].pay_peryear = "";
      result[index].debt_long_cars[indexCard].period = "";
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].unpaid_amount = "";
      } else {
        result[index].debt_long_cars[indexCard].unpaid_amount = value;
        result[index].debt_long_cars[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_cars[indexCard].interest / 100 / 12,
            result[index].debt_long_cars[indexCard].period,
            -result[index].debt_long_cars[indexCard].unpaid_amount
          )
        );
        result[index].debt_long_cars[indexCard].pay_peryear = toFloat2(
          result[index].debt_long_cars[indexCard].pay_permonth * 12
        );
      }
      return result;
    });
  };
  const onChangeInterestCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].interest = "";
      } else {
        result[index].debt_long_cars[indexCard].interest = value;
        result[index].debt_long_cars[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_cars[indexCard].interest / 100 / 12,
            result[index].debt_long_cars[indexCard].period,
            -result[index].debt_long_cars[indexCard].unpaid_amount
          )
        );
      }
      return result;
    });
  };
  const onChangePeriodCar = (index, indexCard, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].period = e?.value;
      result[index].debt_long_cars[indexCard].pay_permonth = toFloat2(
        calPMT(
          result[index].debt_long_cars[indexCard].interest / 100 / 12,
          result[index].debt_long_cars[indexCard].period,
          -result[index].debt_long_cars[indexCard].unpaid_amount
        )
      );
      result[index].debt_long_cars[indexCard].pay_peryear = toFloat2(
        result[index].debt_long_cars[indexCard].pay_permonth * 12
      );
      return result;
    });
  };
  const onChangePayPerMonthCar = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_cars[indexCard].pay_permonth = "";
        result[index].debt_long_cars[indexCard].pay_peryear = "";
        result[index].debt_long_cars[indexCard].period = "";
      } else {
        // ต่อเดือน
        result[index].debt_long_cars[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].debt_long_cars[indexCard].pay_peryear =
          payPeryear > result[index].debt_long_cars[indexCard].unpaid_amount
            ? result[index].debt_long_cars[indexCard].unpaid_amount
            : payPeryear;
        // งวด
        // if (value !== 0) {
        //   result[index].debt_long_cars[indexCard].period = result[index]
        //     .debt_long_cars[indexCard].unpaid_amount
        //     ? result[index].debt_long_cars[indexCard].unpaid_amount / value
        //     : 0;
        // }
      }
      return result;
    });
  };
  // ผู้คำ้ประกัน คนที่ 1
  const onChangeGuarantorNameOneCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].guarantor_name_one = value;
      return result;
    });
  };
  const onChangeGuarantorRelationshipOneCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].guarantor_relationship_one =
        value;
      return result;
    });
  };
  // ผู้คำ้ประกัน คนที่ 2
  const onChangeGuarantorNameTwoCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].guarantor_name_two = value;
      return result;
    });
  };
  const onChangeGuarantorRelationshipTwoCar = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_cars[indexCard].guarantor_relationship_two =
        value;
      return result;
    });
  };

  //-------------------------------------------------- Place --------------------------------------------------//
  const handleNextLongPlace = (index, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        address_place: "",
        type_place: "",
        type_place_other: "",
        current_price: "",
        use_collateral_assets: false,
        collateral_asset_data: "",
        collateral_asset_value: "",
        personal_or_investment: "personal",
        creditor_name: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 13,
        pay_permonth: "",
        pay_peryear: "",
        period: "",
        guarantor_name_one: "",
        guarantor_relationship_one: "",
        guarantor_name_two: "",
        guarantor_relationship_two: "",
        status: "normal",
      };
      result[index] = {
        ...result[index],
        debt_long_places: [...result[index].debt_long_places, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeletePlace = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };
  const onChangeStatusPlace = (index, indexCard, e) => {
    const value = e.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].status = value;
      return result;
    });
  };
  const onClickConfirmDeletePlace = () => {
    setAddLongDebt((prev) => {
      const result = [...prev];
      const index = dataIndexDeleteOne?.index;
      const indexCard = dataIndexDeleteOne?.indexCard;
      const updatedPlaces = [...result[index].debt_long_places];
      updatedPlaces.splice(indexCard, 1);
      result[index] = {
        ...result[index],
        debt_long_places: updatedPlaces,
      };
      return result;
    });
    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };
  const onChangeAddressPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].address_place = value;
      return result;
    });
  };
  const onChangeSelectedTypePlace = (index, indexCard, e) => {
    const value = e.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].type_place = value;
      result[index].debt_long_places[indexCard].type_place_other = "";
      return result;
    });
  };
  const onChangeTypeOtherPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].type_place_other = value;
      return result;
    });
  };
  const onChangeCurrentPricePlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].current_price = "";
      } else {
        result[index].debt_long_places[indexCard].current_price = value;
      }
      return result;
    });
  };
  // ใช้สินทรัพย์คำ้ประกัน
  const [collateralAssetsPlace, setCollateralAssetsPlace] = useState(false);
  const onChangeCollateralAssetsPlace = (index, indexCard, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].use_collateral_assets =
        !collateralAssetsPlace;
      result[index].debt_long_places[indexCard].collateral_asset_data = "";
      result[index].debt_long_places[indexCard].collateral_asset_value = "";
      return result;
    });
    setCollateralAssetsPlace(!collateralAssetsPlace);
  };
  const onChangeCollateralAssetDataPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].collateral_asset_data = value;
      return result;
    });
  };
  const onChangeCollateralAssetValuePlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].collateral_asset_value = "";
      } else {
        result[index].debt_long_places[indexCard].collateral_asset_value =
          value;
      }
      return result;
    });
  };
  const onChangePersonalOrInvestmentPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].personal_or_investment = value;
      return result;
    });
  };
  const onChangeCreditorNamePlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].start_amount = "";
      } else {
        result[index].debt_long_places[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].start_unpaid_amount = "";
      } else {
        result[index].debt_long_places[indexCard].start_unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].pay_permonth = "";
      result[index].debt_long_places[indexCard].pay_peryear = "";
      result[index].debt_long_places[indexCard].period = "";
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].unpaid_amount = "";
      } else {
        result[index].debt_long_places[indexCard].unpaid_amount = value;
        result[index].debt_long_places[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_places[indexCard].interest / 100 / 12,
            result[index].debt_long_places[indexCard].period * 12,
            -result[index].debt_long_places[indexCard].unpaid_amount
          )
        );
        result[index].debt_long_places[indexCard].pay_peryear = toFloat2(
          result[index].debt_long_places[indexCard].pay_permonth * 12
        );
      }
      return result;
    });
  };
  const onChangeInterestPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].interest = "";
      } else {
        result[index].debt_long_places[indexCard].interest = value;
        result[index].debt_long_places[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_places[indexCard].interest / 100 / 12,
            result[index].debt_long_places[indexCard].period * 12,
            -result[index].debt_long_places[indexCard].unpaid_amount
          )
        );
      }
      return result;
    });
  };
  const onChangePayPerMonthPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].pay_permonth = "";
        result[index].debt_long_places[indexCard].pay_peryear = "";
        result[index].debt_long_places[indexCard].period = "";
      } else {
        // ต่อเดือน
        result[index].debt_long_places[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].debt_long_places[indexCard].pay_peryear =
          payPeryear > result[index].debt_long_places[indexCard].unpaid_amount
            ? result[index].debt_long_places[indexCard].unpaid_amount
            : payPeryear;
        // งวด
        if (value !== 0) {
          result[index].debt_long_places[indexCard].period = result[index]
            .debt_long_places[indexCard].unpaid_amount
            ? result[index].debt_long_places[indexCard].unpaid_amount / value
            : 0;
        }
      }
      return result;
    });
  };
  const onChangePeriodPlace = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_places[indexCard].period = "";
      } else {
        result[index].debt_long_places[indexCard].period = value;
        result[index].debt_long_places[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_places[indexCard].interest / 100 / 12,
            result[index].debt_long_places[indexCard].period * 12,
            -result[index].debt_long_places[indexCard].unpaid_amount
          )
        );
        result[index].debt_long_places[indexCard].pay_peryear = toFloat2(
          result[index].debt_long_places[indexCard].pay_permonth * 12
        );
      }
      return result;
    });
  };
  // ผู้คำ้ประกัน คนที่ 1
  const onChangeGuarantorNameOnePlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].guarantor_name_one = value;
      return result;
    });
  };
  const onChangeGuarantorRelationshipOnePlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].guarantor_relationship_one =
        value;
      return result;
    });
  };
  // ผู้คำ้ประกัน คนที่ 2
  const onChangeGuarantorNameTwoPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].guarantor_name_two = value;
      return result;
    });
  };
  const onChangeGuarantorRelationshipTwoPlace = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_places[indexCard].guarantor_relationship_two =
        value;
      return result;
    });
  };

  //-------------------------------------------------- Long Other --------------------------------------------------//
  const handleNextLongOther = (index, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      const date = new Date();
      const created = date.toISOString();
      const newList = {
        created: created,
        title: "",
        current_price: "",
        use_collateral_assets: false,
        collateral_asset_data: "",
        collateral_asset_value: "",
        personal_or_investment: "personal",
        creditor_name: "",
        start_amount: "",
        start_unpaid_amount: "",
        unpaid_amount: "",
        interest: 12,
        pay_permonth: "",
        pay_peryear: "",
        period: "",
        status: "normal",
      };
      result[index] = {
        ...result[index],
        debt_long_others: [...result[index].debt_long_others, { ...newList }],
      };
      return result;
    });
  };

  const onClickDeleteLongOther = (index, indexCard, type, e) => {
    setDataIndexDeleteOne({
      index,
      indexCard,
    });
    setOpenAlert(type);
  };

  const onChangeStatusLongOther = (index, indexCard, e) => {
    const value = e.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].status = value;
      return result;
    });
  };

  const onClickConfirmDeleteLongOther = () => {
    const index = dataIndexDeleteOne?.index;
    const indexCard = dataIndexDeleteOne?.indexCard;

    if (indexCard?.length === 0) {
      const result = [...addLongDebt];
      result.splice(index, 1);
      setAddLongDebt(result);
      setDataIndexDeleteAll(null);
    } else {
      setAddLongDebt((prev) => {
        const result = [...prev];
        const updatedLongOther = [...result[index].debt_long_others];

        updatedLongOther.splice(indexCard, 1);
        result[index] = {
          ...result[index],
          debt_long_others: updatedLongOther,
        };
        return result;
      });
    }

    setDataIndexDeleteOne(null);
    setOpenAlert(false);
  };

  const onChangeTitleLongOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].title = value;
      return result;
    });
  };
  const onChangeStatusOther = (index, indexCard, e) => {
    const value = e.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].status = value;
      return result;
    });
  };
  const onChangeCurrentPriceLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].current_price = "";
      } else {
        result[index].debt_long_others[indexCard].current_price = value;
      }
      return result;
    });
  };
  // ใช้สินทรัพย์คำ้ประกัน
  const [collateralAssetsLongOther, setCollateralAssetsLongOther] =
    useState(false);
  const onChangeCollateralAssetsLongOther = (index, indexCard, e) => {
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].use_collateral_assets =
        !collateralAssetsLongOther;
      result[index].debt_long_others[indexCard].collateral_asset_data = "";
      result[index].debt_long_others[indexCard].collateral_asset_value = "";
      return result;
    });
    setCollateralAssetsLongOther(!collateralAssetsLongOther);
  };
  const onChangeCollateralAssetDataLongOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].collateral_asset_data = value;
      return result;
    });
  };
  const onChangeCollateralAssetValueLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].collateral_asset_value = "";
      } else {
        result[index].debt_long_others[indexCard].collateral_asset_value =
          value;
      }
      return result;
    });
  };
  const onChangePersonalOrInvestmentLongOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].personal_or_investment = value;
      return result;
    });
  };
  const onChangeCreditNameLongOther = (index, indexCard, e) => {
    let value = e.target.value;
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].creditor_name = value;
      return result;
    });
  };
  const onChangeStartAmountLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].start_amount = "";
      } else {
        result[index].debt_long_others[indexCard].start_amount = value;
      }
      return result;
    });
  };
  const onChangeStartUnpaidAmountLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].start_unpaid_amount = "";
      } else {
        result[index].debt_long_others[indexCard].start_unpaid_amount = value;
      }
      return result;
    });
  };
  const onChangeUnpaidAmountLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      result[index].debt_long_others[indexCard].pay_permonth = "";
      result[index].debt_long_others[indexCard].pay_peryear = "";
      result[index].debt_long_others[indexCard].period = "";
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].unpaid_amount = "";
      } else {
        result[index].debt_long_others[indexCard].unpaid_amount = value;
        result[index].debt_long_others[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_others[indexCard].interest / 100 / 12,
            result[index].debt_long_others[indexCard].period * 12,
            -result[index].debt_long_others[indexCard].unpaid_amount
          )
        );
      }
      return result;
    });
  };
  const onChangeInterestLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].interest = "";
      } else {
        result[index].debt_long_others[indexCard].interest = value;
        result[index].debt_long_others[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_others[indexCard].interest / 100 / 12,
            result[index].debt_long_others[indexCard].period * 12,
            -result[index].debt_long_others[indexCard].unpaid_amount
          )
        );
      }
      return result;
    });
  };
  const onChangePeriodLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].period = "";
      } else {
        result[index].debt_long_others[indexCard].period = value;
        result[index].debt_long_others[indexCard].pay_permonth = toFloat2(
          calPMT(
            result[index].debt_long_others[indexCard].interest / 100 / 12,
            result[index].debt_long_others[indexCard].period * 12,
            -result[index].debt_long_others[indexCard].unpaid_amount
          )
        );
        result[index].debt_long_others[indexCard].pay_peryear = toFloat2(
          result[index].debt_long_others[indexCard].pay_permonth * 12
        );
      }
      return result;
    });
  };
  const onChangePayPerMonthLongOther = (index, indexCard, e) => {
    let value = numberWithoutComma(e.target.value);
    setAddLongDebt((prev) => {
      let result = [...prev];
      if (isNaN(value)) {
        result[index].debt_long_others[indexCard].pay_permonth = "";
        result[index].debt_long_others[indexCard].pay_peryear = "";
        result[index].debt_long_others[indexCard].period = "";
      } else {
        // ต่อเดือน
        result[index].debt_long_others[indexCard].pay_permonth = value;
        // ต่อปี
        let payPeryear = value * 12;
        result[index].debt_long_others[indexCard].pay_peryear =
          payPeryear > result[index].debt_long_others[indexCard].unpaid_amount
            ? result[index].debt_long_others[indexCard].unpaid_amount
            : payPeryear;
        // งวด
        if (value !== 0) {
          result[index].debt_long_others[indexCard].period = result[index]
            .debt_long_others[indexCard].unpaid_amount
            ? result[index].debt_long_others[indexCard].unpaid_amount / value
            : 0;
        }
      }
      return result;
    });
  };

  // mark
  //======================================== Sum Short ========================================//
  // บัตรเครดิต
  const sumCreditCard = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.credit_cards?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumCreditCardPerMonth = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.credit_cards?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  // เงินกู้ระยะสั้น
  const sumShortTerm = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.short_terms?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumShortTermPerMonth = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.short_terms?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  // เงินกู้นอกระบบ
  const sumInformalShortTerm = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.informal_short_terms?.map((row) => {
        result += row?.start_amount ? row?.start_amount : 0;
      });
    });
    return result;
  };
  const sumInformalShortTermPerMonth = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.informal_short_terms?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  // เงินกู้ระยะสั้นอื่น ๆ
  const sumShortTermOther = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.short_term_others?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumShortTermOtherPerMonth = () => {
    let result = 0;
    addShortDebt?.map((item) => {
      item?.short_term_others?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  const sumAllDebtShort = () => {
    let result = 0;
    result += sumCreditCard();
    result += sumShortTerm();
    result += sumInformalShortTerm();
    result += sumShortTermOther();
    return result;
  };
  const sumDebtShortPerMonth = () => {
    let result = 0;
    result += sumCreditCardPerMonth();
    result += sumShortTermPerMonth();
    result += sumInformalShortTermPerMonth();
    result += sumShortTermOtherPerMonth();
    return result;
  };

  //======================================== Sum Long ========================================//
  // สินเชื่อรถยนต์
  const sumCar = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_cars?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumCarPerMonth = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_cars?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  // สินเชื่อที่อยู่
  const sumPlace = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_places?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumPlacePerMonth = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_places?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  // เงินกู้ระยะยาวอื่น ๆ
  const sumLongOther = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_others?.map((row) => {
        result += row?.unpaid_amount ? row?.unpaid_amount : 0;
      });
    });
    return result;
  };
  const sumLongOtherPerMonth = () => {
    let result = 0;
    addLongDebt?.map((item) => {
      item?.debt_long_others?.map((row) => {
        result += row?.pay_permonth ? row?.pay_permonth : 0;
      });
    });
    return result;
  };
  const sumAllDebtLong = () => {
    let result = 0;
    result += sumCar();
    result += sumPlace();
    result += sumLongOther();
    return result;
  };
  const sumDebtLongPerMonth = () => {
    let result = 0;
    result += sumCarPerMonth();
    result += sumPlacePerMonth();
    result += sumLongOtherPerMonth();
    return result;
  };

  //-------------------- Submit --------------------//
  const onClickSubmit = () => {
    // คำนวณสินทรัพย์ส่วนตัว
    let property_private_list = [];
    let new_sum_property_private = 0;
    let property_propotion = 0;
    let global_car_list = [];
    let global_place_list = [];
    let new_sum_expenses_fixed =
      (sumDebtShortPerMonth() + sumDebtLongPerMonth()) * 12;
    let old_sum_expenses_fixed = 0;
    let old_sum_expenses_invest_saving = 0;
    let old_sum_expenses_variable = 0;
    let new_expenses_propotion = 0;
    let new_temp_cash_flow = formBL?.temp_net_cash_flow || 0;
    let new_sum_expenses_invest_saving = 0;

    if (formBL?.debt_long_list?.length > 0) {
      for (const debt_long_item of formBL?.debt_long_list) {
        if (debt_long_item.options_debt_long === "debt_car") {
          let car_lists = [];
          for (const car_item of debt_long_item.debt_long_cars) {
            if (car_item.personal_or_investment === "personal") {
              car_lists.push({
                created: car_item.created,
                title: car_item.title_car,
                registration_car: car_item.registration_car,
                amount: car_item.current_price,
                debt_free: false,
              });
              global_car_list.push({
                created: car_item.created,
                title: car_item.title_car,
                registration_car: car_item.registration_car,
                amount: car_item.current_price,
                debt_free: false,
              });
              new_sum_property_private += +car_item.current_price;
            }
          }
          if (car_lists.length > 0) {
            property_private_list.push({
              created: debt_long_item.created,
              options_property_private: "property_car",
              options_property_private_title: "รถยนต์",
              car_lists: car_lists,
              is_debt: true,
            });
          }
        } else if (debt_long_item.options_debt_long === "debt_place") {
          let home_lists = [];
          for (const place_item of debt_long_item.debt_long_places) {
            if (place_item.personal_or_investment === "personal") {
              home_lists.push({
                created: place_item.created,
                title: "",
                address: place_item.address_place,
                type_place: String(place_item.type_place).split("_")[1],
                address_other: "",
                amount: place_item.current_price,
                debt_free: false,
              });
              global_place_list.push({
                created: place_item.created,
                title: "",
                address: place_item.address_place,
                type_place: String(place_item.type_place).split("_")[1],
                address_other: "",
                amount: place_item.current_price,
                debt_free: false,
              });
              new_sum_property_private += +place_item.current_price;
            }
          }
          if (home_lists.length > 0) {
            property_private_list.push({
              home_lists,
              created: debt_long_item.created,
              options_property_private: "property_home",
              options_property_private_title: "บ้านที่อยู่อาศัย",
              is_debt: true,
            });
          }
        }
      }
    }

    if (formBL?.property_private_list?.length > 0) {
      for (const property_private_item of formBL?.property_private_list) {
        const keys = Object.keys(property_private_item);
        for (const key of keys) {
          if (_.isArray(property_private_item[key])) {
            for (const item of property_private_item[key]) {
              new_sum_property_private += +item.amount;
            }
          }
        }
      }
    }

    // คำนวนค่า old_sum_expenses_fixed ของรายจ่ายคงที่
    if (formBL?.expenses_fixed_list?.length > 0) {
      for (const expenses_fixed_item of formBL?.expenses_fixed_list) {
        const keys = Object.keys(expenses_fixed_item);
        for (const key of keys) {
          if (_.isArray(expenses_fixed_item[key])) {
            for (const item of expenses_fixed_item[key]) {
              if (
                [
                  "expenses_insuarant_villages",
                  "expenses_insuarant_socials",
                  "expenses_provide_funds",
                  "expenses_fixed_others",
                ].includes(key)
              ) {
                old_sum_expenses_fixed += item.pay_peryear;
              } else {
                old_sum_expenses_fixed += item.allowance_peryear;
              }
            }
          }
        }
      }
    }

    // คำนวนค่าใช้จ่ายเพื่อลงทุน old_sum_expenses_invest_saving
    if (formBL?.expenses_invest_saving_list?.length > 0) {
      for (const expenses_invest_saving_list_item of formBL?.expenses_invest_saving_list) {
        old_sum_expenses_invest_saving +=
          expenses_invest_saving_list_item.pay_peryear;
      }
    }

    // คำนวนค่าใช้จ่ายการผันแปร old_sum_expenses_variable
    if (formBL?.expenses_variable_list?.length > 0) {
      for (const expenses_variable_item of formBL?.expenses_variable_list) {
        old_sum_expenses_variable += expenses_variable_item.pay_peryear;
      }
    }

    // คำนวนหาค่า temp_net_cash_flow คงเหลือ
    if (new_sum_expenses_fixed > 0) {
      new_temp_cash_flow = new_temp_cash_flow - new_sum_expenses_fixed;
      if (new_temp_cash_flow < 0) {
        new_temp_cash_flow = 0;
      }
    }

    property_propotion +=
      +new_sum_property_private +
      +Number(formBL.sum_property_asset) +
      +Number(formBL.sum_property_intangible) +
      +Number(formBL.sum_property_invest);

    new_sum_expenses_fixed += +old_sum_expenses_fixed;
    new_expenses_propotion +=
      +new_sum_expenses_fixed +
      +old_sum_expenses_invest_saving +
      +old_sum_expenses_variable +
      +new_temp_cash_flow;

    new_sum_expenses_invest_saving =
      +old_sum_expenses_invest_saving + +new_temp_cash_flow;

    // console.log("formBL", formBL);
    // console.log("property_propotion", property_propotion);
    // console.log("new_sum_property_private", new_sum_property_private);
    // console.log("old_sum_expenses_fixed", old_sum_expenses_fixed);
    // console.log("new_sum_expenses_fixed", new_sum_expenses_fixed);
    // console.log("new_sum_expenses_invest_saving", new_sum_expenses_invest_saving);

    setBalanceData({
      ...formBL,
      sum_expenses_invest_saving: +new_sum_expenses_invest_saving,
      temp_net_cash_flow: +new_temp_cash_flow,
      sum_expenses_fixed: +new_sum_expenses_fixed,
      old_sum_expenses_fixed: +old_sum_expenses_fixed,
      expenses_propotion: +new_expenses_propotion,
      property_propotion: +property_propotion,
      sum_property_private: +new_sum_property_private,
      debt_long_car_personal_list: global_car_list,
      debt_long_place_personal_list: global_place_list,
      is_change_debt: true,
    });
    setFormBL({
      ...formBL,
      sum_expenses_invest_saving: +new_sum_expenses_invest_saving,
      temp_net_cash_flow: +new_temp_cash_flow,
      sum_expenses_fixed: +new_sum_expenses_fixed,
      old_sum_expenses_fixed: +old_sum_expenses_fixed,
      expenses_propotion: +new_expenses_propotion,
      property_propotion: +property_propotion,
      sum_property_private: +new_sum_property_private,
      debt_long_car_personal_list: global_car_list,
      debt_long_place_personal_list: global_place_list,
    });
    onclose();

    // const old_sum_expenses_fixed = formBL?.old_sum_expenses_fixed || 0;
    // const sum_expenses_fixed = formBL?.expenses_propotion
    //   ? formBL?.expenses_propotion -
    //     formBL?.sum_expenses_invest_saving -
    //     formBL?.sum_expenses_variable -
    //     old_sum_expenses_fixed
    //   : 0;

    // let new_temp_cash_flow = formBL?.temp_net_cash_flow || 0;
    // let old_expenses_propotion = formBL?.expenses_propotion || 0;
    // let diff_sum_expenses_fixed = sum_expenses_fixed - old_sum_expenses_fixed;
    // let old_sum_expenses_invest_saving =
    //   formBL?.sum_expenses_invest_saving || 0;
    // let new_expenses_propotion = 0;
    // let new_sum_expenses_invest_saving = 0;

    // if (old_expenses_propotion > 0) {
    //   old_expenses_propotion =
    //     old_expenses_propotion -
    //     formBL?.temp_net_cash_flow -
    //     old_sum_expenses_fixed;
    // }

    // if (old_sum_expenses_invest_saving > 0) {
    //   old_sum_expenses_invest_saving =
    //     old_sum_expenses_invest_saving - formBL?.temp_net_cash_flow;
    // }

    // if (sum_expenses_fixed > 0) {
    //   new_temp_cash_flow = formBL?.temp_net_cash_flow - diff_sum_expenses_fixed;
    //   if (new_temp_cash_flow < 0) {
    //     new_temp_cash_flow = 0;
    //   }
    // }

    // new_expenses_propotion =
    //   old_expenses_propotion + sum_expenses_fixed + new_temp_cash_flow;
    // new_sum_expenses_invest_saving =
    //   old_sum_expenses_invest_saving + new_temp_cash_flow;

    // console.log("sum_expenses_fixed", sum_expenses_fixed);
    // console.log("local", formBL);
    // console.log("diff_sum_expenses_fixed", diff_sum_expenses_fixed);
    // console.log("old_sum_expenses_fixed", formBL?.old_sum_expenses_fixed);
    // console.log("sum_expenses_fixed", sum_expenses_fixed);
    // console.log("new_temp_cash_flow", new_temp_cash_flow);
    // console.log("old_expenses_propotion", old_expenses_propotion);
    // console.log("new expenses_propotion", new_expenses_propotion);
    // console.log(
    //   "new_sum_expenses_invest_saving",
    //   new_sum_expenses_invest_saving
    // );
    // console.log("property_propotion", property_propotion);
    // console.log("new_sum_property_private", new_sum_property_private);
    // console.log("old_sum_property_private", old_sum_property_private);
    // console.log(
    //   "123",
    //   formBL?.expenses_propotion -
    //     formBL?.sum_expenses_fixed -
    //     formBL?.sum_expenses_variable
    // );

    // console.log("1", sumAllDebtLong());
    // console.log("2", sumAllDebtShort());

    // setBalanceData({
    //   ...formBL,
    //   sum_expenses_invest_saving: new_sum_expenses_invest_saving,
    //   temp_net_cash_flow: new_temp_cash_flow,
    //   sum_expenses_fixed,
    //   old_sum_expenses_fixed: sum_expenses_fixed,
    //   expenses_propotion: new_expenses_propotion,
    //   property_propotion: +property_propotion,
    //   sum_property_private: +new_sum_property_private,
    //   debt_long_car_personal_list: global_car_list,
    //   debt_long_place_personal_list: global_place_list
    // });
    // setFormBL({
    //   ...formBL,
    //   sum_expenses_invest_saving: new_sum_expenses_invest_saving,
    //   temp_net_cash_flow: new_temp_cash_flow,
    //   sum_expenses_fixed,
    //   expenses_propotion: new_expenses_propotion,
    //   old_sum_expenses_fixed: sum_expenses_fixed,
    //   property_propotion: +property_propotion,
    //   sum_property_private: +new_sum_property_private,
    //   debt_long_car_personal_list: global_car_list,
    //   debt_long_place_personal_list: global_place_list
    // });
    // onclose();
  };

  // LOAD DATA
  useEffect(() => {
    const newSumAllDebt = (sumDebtShortPerMonth() + sumDebtLongPerMonth()) * 12;
    const oldSumAllDept =
      Number(formBL?.sum_debt_short_permonth + formBL?.sum_debt_long_permonth) *
      12;
    setFormBL({
      ...formBL,
      debt_short_list: addShortDebt,
      sum_debt_short_permonth: sumDebtShortPerMonth(),
      sum_debt_short: sumAllDebtShort(),
      debt_long_list: addLongDebt,
      sum_debt_long_permonth: sumDebtLongPerMonth(),
      sum_debt_long: sumAllDebtLong(),
      debt_propotion: sumAllDebtShort() + sumAllDebtLong(),
      // update ร่ายจ่ายคงที่
      sum_expenses_fixed: formBL?.sum_expenses_fixed
        ? formBL?.sum_expenses_fixed + newSumAllDebt - oldSumAllDept
        : "",
      expenses_propotion: formBL?.expenses_propotion
        ? formBL?.expenses_propotion + newSumAllDebt - oldSumAllDept
        : "",
    });
  }, [
    addShortDebt,
    addLongDebt,
    sumCreditCard(),
    sumShortTerm(),
    sumInformalShortTerm(),
    sumShortTermOther(),
    sumCreditCardPerMonth(),
    sumShortTermPerMonth(),
    sumInformalShortTermPerMonth(),
    sumShortTermOtherPerMonth(),
    sumCar(),
    sumPlace(),
    sumLongOther(),
    sumCarPerMonth(),
    sumPlacePerMonth(),
    sumLongOtherPerMonth(),
  ]);

  // console.log("addShortDebt : ", addShortDebt);
  // console.log("addLongDebt : ", addLongDebt);

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onclose}
      className=" overflow-y-scroll !max-w-[100%] "
    >
      <div
        style={{
          backgroundImage: `url(/images/circle.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: " left  ",
        }}
        className={`min-h-screen  `}
      >
        <div className={STYLE_MAX_W_DRAWER}>
          <HeaderDrawer title={`สัดส่วนหนี้สิน`} onClickGoBack={onclose} />

          <div className={STYLE_PADDING_BALANCE_DRAWER}>
            <div className=" flex flex-col gap-5 relative">
              {/*---------------------------------------- หนี้สินระยะสั้น ----------------------------------------*/}
              <CardAccordian
                zIndex="2"
                open={openAccordion === 1}
                onClickOpen={() => {
                  handleOpen(1);
                  if (openAccordion === 1) setOpenAccordion(false);
                }}
                add
                title=" หนี้สินระยะสั้น"
                textColor="text-red500"
                icon="/images/debt_short2.png"
                value={formBL?.sum_debt_short}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_debt_short: "",
                      status: "normal",
                    },
                  ];
                  setAddShortDebt((prev) => {
                    return [...prev, ...result];
                  });
                  handleOpen(1);
                }}
                panel={
                  <div>
                    {addShortDebt
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div className=" pt-3 ">
                              <DropDownMain
                                value={options_debt_short?.filter((option) => {
                                  if (option?.value === row?.options_debt_short)
                                    return option?.label;
                                })}
                                onChange={onChangeSelectedShort.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_debt_short}
                                zIndex={2}
                              />
                            </div>
                            {/*---- หนี้บัตรเครดิต ----*/}
                            {row?.options_debt_short === "debt_creditcard" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนบัตรเครดิต (ใบ)
                                  </div>
                                  <ButtonCounter
                                    value={row?.credit_cards?.length}
                                    handleNext={handleCreditCardNext?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.credit_cards
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    
                                    return (
                                      <div key={indexCard}>
                                        <CardDebt
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          title={row?.title}
                                          creditor_name={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          typePayment={row?.type_payment}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          placeholder={`ระบุชื่อบัตรเครดิต`}
                                          status={row?.status}
                                          cardType="creditcard"
                                          onClickDelete={onClickDeleteCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "creditcard"
                                          )}
                                          onChangeTitle={onChangeTitleCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCreditName={onChangeTitleCreditorCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChangeType={handleCheckboxChangeTypePaymentCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthCreditCard?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "creditcard"}
                                  onClick={onClickConfirmDeleteCreditCard}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---- หนี้เงินกู้ระยะสั้น ----*/}
                            {row?.options_debt_short === "debt_shortterm" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนหนี้เงินกู้ระยะสั้น
                                  </div>
                                  <ButtonCounter
                                    value={row?.short_terms?.length}
                                    handleNext={handleShortTermNext?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.short_terms
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebt
                                          creditor={true}
                                          optionCompounding={
                                            OPTIONS_COMPOUNDING
                                          }
                                          title={row?.title}
                                          creditor_name={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          compoundingType={
                                            row?.compounding_type
                                          }
                                          period={row?.period}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          isCheckedNormalPayment={
                                            row?.normal_payment
                                          }
                                          cardType="shortterm"
                                          status={row?.status}
                                          placeholderShortTerm={`ระบุชื่อเจ้าหนี้เงินกู้ระยะสั้น`}
                                          onClickDelete={onClickDeleteShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_shortterm"
                                          )}
                                          onChangeCreditName={onChangeTitleCreditorShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCompoundingType={onChangeCompoundingTypeShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePeriod={onChangePeriodShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxNormalPaymentChangeShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_shortterm"}
                                  onClick={onClickConfirmDeleteShortTerm}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---- หนี้นอกระบบ ----*/}
                            {row?.options_debt_short ===
                              "debt_informal_shortterm" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนหนี้นอกระบบ
                                  </div>
                                  <ButtonCounter
                                    value={row?.informal_short_terms?.length}
                                    handleNext={handleInformalShortTermNext?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.informal_short_terms
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebt
                                          creditor={true}
                                          optionCompounding={
                                            OPTIONS_COMPOUNDING
                                          }
                                          title={row?.title}
                                          creditor_name={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          compoundingType={
                                            row?.compounding_type
                                          }
                                          year={row?.year}
                                          period={row?.period}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          isCheckedNormalPayment={
                                            row?.normal_payment
                                          }
                                          status={row?.status}
                                          placeholderShortTerm={`ระบุชื่อเจ้าหนี้นอกระบบ`}
                                          number_of_days={row?.number_of_days}
                                          onChangeNumberOfDaysInformalShortTerm={onChangeNumberOfDaysInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeYear={onChangeYearShortTerm.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onClickDelete={onClickDeleteInformalShortTerm.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_informal_shortterm"
                                          )}
                                          onChangeCreditName={onChangeTitleCreditorInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTitle={onChangeTitleInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountInformalShortTerms?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCompoundingType={onChangeCompoundingTypeInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePeriod={onChangePeriodInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxNormalPaymentChangeInformalShortTerm?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          cardType="debt_informal_shortterm"
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_informal_shortterm"}
                                  onClick={
                                    onClickConfirmDeleteInformalShortTerm
                                  }
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---- หนี้เงินกู้ระยะสั้นอื่น ๆ ----*/}
                            {row?.options_debt_short ===
                              "debt_shortterm_other" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนหนี้เงินกู้ระยะสั้นอื่น ๆ
                                  </div>
                                  <ButtonCounter
                                    value={row?.short_term_others?.length}
                                    handleNext={handleShortTermOtherNext?.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.short_term_others
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebt
                                          creditor={true}
                                          optionCompounding={
                                            OPTIONS_COMPOUNDING
                                          }
                                          cardType="debt_shortterm_other"
                                          title={row?.title}
                                          creditor_name={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          compoundingType={
                                            row?.compounding_type
                                          }
                                          period={row?.period}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          isCheckedNormalPayment={
                                            row?.normal_payment
                                          }
                                          status={row?.status}
                                          placeholderShortTerm={`ระบุชื่อเจ้าหนี้เงินกู้ระยะสั้นอื่น ๆ`}
                                          onClickDelete={onClickDeleteShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_shortterm_other"
                                          )}
                                          onChangeTitle={onChangeTitleShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCreditName={onChangeTitleCreditorShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCompoundingType={onChangeCompoundingTypeShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePeriod={onChangePeriodShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          handleCheckboxChange={handleCheckboxNormalPaymentChangeShortTermOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_shortterm_other"}
                                  onClick={onClickConfirmDeleteShortTermOther}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                // const result = [...addShortDebt];
                                // result.splice(index, 1);
                                // setAddShortDebt(result);
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addShortDebt];
                                result.splice(dataIndexDeleteAll, 1);
                                setAddShortDebt(result);
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert(false);
                                setOpenAlert2(false);
                              }}
                            />

                            {addShortDebt?.length > 1 &&
                              index !== addShortDebt?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />

              {/* mark */}
              {/*---------------------------------------- หนี้สินระยะยาว ----------------------------------------*/}
              <CardAccordian
                zIndex="1"
                open={openAccordion === 2}
                onClickOpen={() => {
                  handleOpen(2);
                  if (openAccordion === 2) setOpenAccordion(false);
                }}
                add
                title=" หนี้สินระยะยาว "
                textColor="text-mainorange"
                icon="/images/debt_long.png"
                value={formBL?.sum_debt_long}
                onClickAccordion={() => {
                  const date = new Date();
                  const created = date.toISOString();
                  const result = [
                    {
                      created: created,
                      options_debt_long: "",
                      status: "normal",
                    },
                  ];
                  setAddLongDebt((prev) => {
                    return [...prev, ...result];
                  });
                  handleOpen(2);
                }}
                panel={
                  <div>
                    {addLongDebt
                      ?.sort(
                        (a, b) => new Date(b.created) - new Date(a.created)
                      )
                      ?.map((row, index) => {
                        return (
                          <div key={index} className=" ">
                            <div
                              style={{
                                zIndex: 1,
                              }}
                              className=" pt-3 "
                            >
                              <DropDownMain
                                value={options_debt_long?.filter((option) => {
                                  if (option?.value === row?.options_debt_long)
                                    return option?.label;
                                })}
                                onChange={onChangeSelectedLong.bind(
                                  this,
                                  row?.created,
                                  index
                                )}
                                options={options_debt_long}
                                zIndex={1}
                              />
                            </div>

                            {/* mark2 */}
                            {/*---------- สินเชื่อรถยนต์ ----------*/}
                            {row?.options_debt_long === "debt_car" && (
                              <div className=" mt-4">
                                {row?.debt_long_cars?.length > 0 && (
                                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                    <div className=" text-xs text-black font-medium">
                                      จำนวนสินเชื่อรถยนต์ / จักรยานยนต์
                                    </div>
                                    <ButtonCounter
                                      value={row?.debt_long_cars?.length}
                                      handleNext={handleNextLongCar.bind(
                                        this,
                                        index
                                      )}
                                    />
                                  </div>
                                )}

                                {row?.debt_long_cars
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebtLong
                                          keyIndex={index}
                                          options_debt_long={
                                            row?.options_debt_long
                                          }
                                          indexCard={indexCard}
                                          isCar={true}
                                          title={row?.title_car}
                                          typeCar={row?.type_car}
                                          registration={row?.registration_car}
                                          currentPrice={row?.current_price}
                                          personalOrInvestment={
                                            row?.personal_or_investment
                                          }
                                          status={row?.status}
                                          creditorName={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          period={row?.period}
                                          guarantorNameOne={
                                            row?.guarantor_name_one
                                          }
                                          guarantorRelationshipOne={
                                            row?.guarantor_relationship_one
                                          }
                                          guarantorNameTwo={
                                            row?.guarantor_name_two
                                          }
                                          guarantorRelationshipTwo={
                                            row?.guarantor_relationship_two
                                          }
                                          optionsDropdown={OPTIONS_TYPE_CAR}
                                          placeholder="ระบุยี่ห้อรถยนต์"
                                          onChangePeriod={onChangePeriodCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onClickDelete={onClickDeleteCars?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_car"
                                          )}
                                          onChangeTitle={onChangeTitleCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeDropdown={onChangeSelectedTypeCar.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeRegistration={onChangeRegistrationCar.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCurrentPrice={onChangeCurrentPriceCar.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePersonalOrInvestment={onChangePersonalOrInvestmentCar.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCreditName={onChangeCreditorNameCar.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorNameOne={onChangeGuarantorNameOneCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorRelationshipOne={onChangeGuarantorRelationshipOneCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorNameTwo={onChangeGuarantorNameTwoCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorRelationshipTwo={onChangeGuarantorRelationshipTwoCar?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_car"}
                                  onClick={onClickConfirmDeleteCars}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- สินเชื่อที่อยู่อาศัย ----------*/}
                            {row?.options_debt_long === "debt_place" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวนที่อยู่
                                  </div>
                                  <ButtonCounter
                                    value={row?.debt_long_places?.length}
                                    handleNext={handleNextLongPlace.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.debt_long_places
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebtLong
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          isPlace={true}
                                          addressPlace={row?.address_place}
                                          typePlace={row?.type_place}
                                          typePlaceOther={row?.type_place_other}
                                          currentPrice={row?.current_price}
                                          collateralAssets={
                                            row?.use_collateral_assets
                                          }
                                          collateralAssetData={
                                            row?.collateral_asset_data
                                          }
                                          collateralAssetValue={
                                            row?.collateral_asset_value
                                          }
                                          personalOrInvestment={
                                            row?.personal_or_investment
                                          }
                                          creditorName={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          payPermonth={row?.pay_permonth}
                                          payPeryear={row?.pay_peryear}
                                          period={row?.period}
                                          guarantorNameOne={
                                            row?.guarantor_name_one
                                          }
                                          guarantorRelationshipOne={
                                            row?.guarantor_relationship_one
                                          }
                                          guarantorNameTwo={
                                            row?.guarantor_name_two
                                          }
                                          guarantorRelationshipTwo={
                                            row?.guarantor_relationship_two
                                          }
                                          optionsDropdown={OPTIONS_TYPE_PLACE}
                                          placeholder="ระบุที่อยู่ ..."
                                          status={row?.status}
                                          onChangePeriod={onChangePeriodPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onClickDelete={onClickDeletePlace?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_place"
                                          )}
                                          onChangeAddressPlace={onChangeAddressPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeDropdown={onChangeSelectedTypePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeTypeOther={onChangeTypeOtherPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCurrentPrice={onChangeCurrentPricePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssets={onChangeCollateralAssetsPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssetData={onChangeCollateralAssetDataPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssetValue={onChangeCollateralAssetValuePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePersonalOrInvestment={onChangePersonalOrInvestmentPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCreditName={onChangeCreditorNamePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorNameOne={onChangeGuarantorNameOnePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorRelationshipOne={onChangeGuarantorRelationshipOnePlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorNameTwo={onChangeGuarantorNameTwoPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeGuarantorRelationshipTwo={onChangeGuarantorRelationshipTwoPlace?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_place"}
                                  onClick={onClickConfirmDeletePlace}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            {/*---------- สินเชื่อระยะยาวอื่น ๆ ----------*/}
                            {row?.options_debt_long === "debt_long_other" && (
                              <div className=" mt-4">
                                <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                                  <div className=" text-sm text-black font-medium">
                                    จำนวน
                                  </div>
                                  <ButtonCounter
                                    value={row?.debt_long_others?.length}
                                    handleNext={handleNextLongOther.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </div>
                                {row?.debt_long_others
                                  ?.sort(
                                    (a, b) =>
                                      new Date(b.created) - new Date(a.created)
                                  )
                                  ?.map((row, indexCard) => {
                                    return (
                                      <div key={indexCard}>
                                        <CardDebtLong
                                          keyIndex={index}
                                          indexCard={indexCard}
                                          title={row?.title}
                                          currentPrice={row?.current_price}
                                          collateralAssets={
                                            row?.use_collateral_assets
                                          }
                                          collateralAssetData={
                                            row?.collateral_asset_data
                                          }
                                          collateralAssetValue={
                                            row?.collateral_asset_value
                                          }
                                          personalOrInvestment={
                                            row?.personal_or_investment
                                          }
                                          creditorName={row?.creditor_name}
                                          startAmount={row?.start_amount}
                                          startUnpaidAmount={
                                            row?.start_unpaid_amount
                                          }
                                          unpaidAmount={row?.unpaid_amount}
                                          interest={row?.interest}
                                          payPermonth={row?.pay_permonth}
                                          period={row?.period}
                                          payPeryear={row?.pay_peryear}
                                          placeholder={`ระบุหนี้สินระยะยาวอื่น ๆ`}
                                          status={row?.status}
                                          onChangePeriod={onChangePeriodLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStatus={onChangeStatusLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onClickDelete={onClickDeleteLongOther?.bind(
                                            this,
                                            index,
                                            indexCard,
                                            "debt_long_other"
                                          )}
                                          onChangeTitle={onChangeTitleLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCurrentPrice={onChangeCurrentPriceLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssets={onChangeCollateralAssetsLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssetData={onChangeCollateralAssetDataLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCollateralAssetValue={onChangeCollateralAssetValueLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePersonalOrInvestment={onChangePersonalOrInvestmentLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeCreditName={onChangeCreditNameLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartAmount={onChangeStartAmountLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeStartUnpaidAmount={onChangeStartUnpaidAmountLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeUnpaidAmount={onChangeUnpaidAmountLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangeInterest={onChangeInterestLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                          onChangePayPerMonth={onChangePayPerMonthLongOther?.bind(
                                            this,
                                            index,
                                            indexCard
                                          )}
                                        />
                                      </div>
                                    );
                                  })}

                                <PopupMain
                                  title={"ยืนยันการลบข้อมูล"}
                                  text={
                                    <div className=" text-base">
                                      คุณต้องการลบข้อมูลรายการนี้ใช่หรือไม่
                                    </div>
                                  }
                                  button2={true}
                                  textButton={"ยืนยัน"}
                                  textButton2={"ยกเลิก"}
                                  open={openAlert === "debt_long_other"}
                                  onClick={onClickConfirmDeleteLongOther}
                                  onClickSecond={() => {
                                    setDataIndexDeleteOne(null);
                                    setOpenAlert(false);
                                  }}
                                />
                              </div>
                            )}

                            <DeleteButtonCard
                              onClick={() => {
                                // const result = [...addLongDebt];
                                // result.splice(index, 1);
                                // setAddLongDebt(result);
                                setDataIndexDeleteAll(index);
                                setOpenAlert2(true);
                              }}
                            />
                            <PopupMain
                              title={"ยืนยันการลบข้อมูลทั้งหมด"}
                              text={
                                <div className=" text-base">
                                  คุณต้องการลบข้อมูลทั้งหมดนี้ใช่หรือไม่
                                </div>
                              }
                              button2={true}
                              textButton={"ยืนยัน"}
                              textButton2={"ยกเลิก"}
                              open={openAlert2}
                              onClick={() => {
                                const result = [...addLongDebt];

                                result.splice(dataIndexDeleteAll, 1);
                                setAddLongDebt(result);
                                setDataIndexDeleteAll(null);

                                setOpenAlert2(false);
                              }}
                              onClickSecond={() => {
                                setDataIndexDeleteAll(null);
                                setOpenAlert2(false);
                              }}
                            />
                            {addLongDebt?.length > 1 &&
                              index !== addLongDebt?.length - 1 && (
                                <div className=" border-b border-gray80 pb-3 my-2"></div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                }
              />
            </div>

            <div className="flex justify-center mb-6">
              <MTButton
                text="บันทึก"
                // loading={loading}
                onClick={onClickSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
