import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { useBalanceStore } from "../../../_store/balanceStore";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { HeaderReport } from "../components/header.main";
import { CardReport } from "../components/cardMenu";
import { ImageMain } from "../../../components/img/Img.Main";
import { useEffect, useState } from "react";
import { PopupBalanceMain } from "../../balance/styles/PopupBalance";
import numeral from "numeral";
import { useMemo } from "react";
import { ICON_INFO } from "../../../utils/useIcons";
import { MTButton } from "../../../components/button/MTButton";
import { first_reload } from "../../tax_plan/functions/firstLoad";

const ReportFinancialPage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);
  const [openPopupIncome, setOpenPopupIncome] = useState(false);
  const [openPopupOutcome, setOpenPopupOutcome] = useState(false);
  const [openPopupProperty, setOpenPopupProperty] = useState(false);
  const [openPopupConservation, setOpenPopupConservation] = useState(false);
  const [openPopupDebtToAsset, setOpenPopupDebtToAsset] = useState(false);
  const [openPopupDebtToSalary, setOpenPopupDebtToSalary] = useState(false);
  const [openPopupInvest, setOpenPopupInvest] = useState(false);
  // console.log("AAAA", balanceData);

  //======================================== รายได้ ========================================//
  const countPositiveValues = () => {
    const positiveValues = [
      balanceData?.sum_salary_peryear,
      balanceData?.sum_interest_peryear,
      balanceData?.sum_other_income,
    ]?.filter((value) => value > 0);
    return positiveValues?.length;
  };
  const getResult = () => {
    const numPositiveValues = countPositiveValues();
    if (numPositiveValues === 1) {
      return "normal";
    } else if (numPositiveValues > 1) {
      return "good";
    } else {
      return "false";
    }
  };
  const checkIncomeTitle = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div
          className={`bg-yellowopacity text-mainyellow  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center`}
        >
          พอใช้
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#b9b9b9]/30 px-[14px] py-[2px] text-gray80 font-medium text-sm rounded-[5px] w-fit ">
          ไม่มีข้อมูล
        </div>
      );
    }
  };
  const checkIncomeIcon = () => {
    const status = getResult();
    if (status === "normal") {
      return "../images/medium_finance.svg";
    }
    if (status === "good") {
      return "../images/happy_finance.svg";
    } else {
      return "../images/nodata.png";
    }
  };

  const checkIncomeSubtitle = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div className="font-semibold text-center   text-mainyellow">
          คุณมีช่องทางรายได้ไม่หลากหลาย
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="font-semibold text-center  text-maingreen">
          คุณมีรายได้หลากหลายช่องทาง
        </div>
      );
    } else {
      return (
        <div className="font-semibold text-center  text-maingray">
          ท่านยังไม่ได้กรอกข้อมูลในงบดุล
        </div>
      );
    }
  };
  const checkIncomeDes = () => {
    const status = getResult();
    if (status === "normal") {
      return (
        <div className="">
          คุณน่าจะเพิ่มช่องทางการหารายได้
          <div>
            เช่น การลงทุนให้ได้เงินปันผล, การออมเงินเพื่อดอกเบี้ย
            หรือเพิ่มอาชีพที่ 2 เพื่อช่วยให้คุณบรรลุเป้าหมายทางการเงิน
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="">
          <div>คุณมีรายได้มากกว่า 1 ช่องทาง</div>
          รายได้เหล่านี้สามารถช่วยให้คุณบรรลุเป้าหมายทางการเงินได้แน่นอน
        </div>
      );
    } else {
      return (
        <div className="">
          <div>คุณยังไม่ได้กรอกข้อมูลในงบดุล</div>
          กรุณากรอกข้อมูลเพื่อทำการวิเคราะห์
        </div>
      );
    }
  };
  //======================================== รายจ่าย ========================================//
  const checkResultExpenses = () => {
    // รายรับ
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;
    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;
    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม
    const expenseValue = balanceData?.expenses_propotion
      ? balanceData?.expenses_propotion - sumExpensesInvestSaving
      : 0;
    if (expenseValue > salaryValue / 2) {
      return "normal";
    } else {
      return "good";
    }
  };
  const checkOutcomeTitle = () => {
    const status = checkResultExpenses();
    if (status === "normal") {
      return (
        <div className=" bg-yellowopacity text-mainyellow  px-3 py-1 rounded-[6px] font-semibold w-[108px] text-center">
          พอใช้
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#b9b9b9]/30 px-[14px] py-[2px] text-gray80 font-medium text-sm rounded-[5px] w-fit ">
          ไม่มีข้อมูล
        </div>
      );
    }
  };
  const checkOutcomeIcon = () => {
    const status = checkResultExpenses();
    if (status === "normal") {
      return "/images/medium_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    } else {
      return "../images/nodata.png";
    }
  };
  const checkOutcomeSubtitle = (balanceData) => {
    const status = checkResultExpenses();

    // รายรับ
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;

    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;

    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม

    const expenseValue = balanceData?.expenses_propotion
      ? balanceData?.expenses_propotion - sumExpensesInvestSaving
      : 0;

    // หาเปอร์เซ็นรายจ่ายต่อรายรับ
    const expensesPerSalary = ((expenseValue / salaryValue) * 100)?.toFixed(2);

    if (status === "normal") {
      return (
        <div className=" font-semibold text-center my-2.5  text-mainyellow">
          คุณมีรายจ่ายรวม{" "}
          <span className=" font-semibold ">
            {numeral(expenseValue)?.format("0,0")}
          </span>{" "}
          บาท
          <div>
            หรือ {String(expensesPerSalary) === "Infinity" ? 0 : expensesPerSalary} %
            ซึ่งมากกว่าครึ่งนึงของรายรับ{" "}
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className=" font-semibold text-center my-2.5  text-maingreen">
          คุณมีรายจ่ายรวม{" "}
          <span className=" font-semibold ">
            {numeral(expenseValue)?.format("0,0")}
          </span>{" "}
          บาท
          <div>หรือ {expensesPerSalary} % ซึ่งน้อยกว่าครึ่งนึงของรายรับ </div>
        </div>
      );
    } else {
      return (
        <div className="font-semibold text-center  text-maingray">
          ท่านยังไม่ได้กรอกข้อมูลในงบดุล
        </div>
      );
    }
  };
  const checkOutcomeDes = () => {
    const status = checkResultExpenses();
    //รายรับ
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;

    // ค่าใช้จ่ายการออมและลงทุน
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;
    // รายจ่ายที่ไม่รวมค่าใช้จ่ายเพื่อการออม
    const expenseValue = balanceData?.expenses_propotion
      ? balanceData?.expenses_propotion - sumExpensesInvestSaving
      : 0;

    // ลดค่าใช้จ่าย
    const reduceExpense = expenseValue - salaryValue / 2;

    if (status === "normal") {
      return (
        <div>
          คุณควรประหยัดและลดค่าใช้จ่าย{" "}
          <span className=" text-mainblue font-bold">
            {numeral(reduceExpense).format("0,0")} บาท
          </span>
          <div>เพื่อให้บรรลุเป้าหมายทางการเงินของคุณ</div>
          <div className=" text-maingray  mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              ครึ่งหนึ่งของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValue / 2)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (status === "good") {
      return (
        <div>
          คุณมีการใช้จ่ายในสัดส่วนที่ดี
          <div className=" text-maingray  mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              ครึ่งหนึ่งของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValue / 2)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== สภาพคล่องพื้นฐาน ========================================//
  const checkProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.property_propotion) {
      sum =
        expenses_permonth > 0
          ? balanceData?.property_propotion / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  });
  const checkResultProperty = () => {
    if (checkProperty < 6) {
      return "bad";
    } else if (checkProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };
  const checkPropertyIcon = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return "/images/bad_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    }
    return "/images/chance_finance.svg";
  };
  const checkAmouthProperty = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return `${checkProperty} เท่า`;
    }
    if (status === "good") {
      return `${checkProperty} เท่า`;
    }
    return `${checkProperty} เท่า`;
  };
  const checkPropertyTitle = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
    return (
      <div className="bg-yellowopacity text-mainyellow px-3 py-1 rounded-[6px] text-xl font-semibold w-fit text-center">
        เพิ่มโอกาส
      </div>
    );
  };
  const checkPropertySubtitle = () => {
    const status = checkResultProperty();
    const value = checkAmouthProperty();
    const expenses_permonth = balanceData?.expenses_propotion / 12;

    const MethodBox = ({ title, subtitle, des, icon, onClick }) => {
      return (
        <div className="">
          <div className=" text-maingray font-normal text-sm text-center">
            <span className=" font-semibold text-mainblue">
              อัตราส่วนสภาพคล่อง
            </span>{" "}
            = สินทรัพย์สภาพคล่อง/รายจ่ายต่อเดือน
          </div>
          <div className=" text-maingray text-xs text-center mt-1">
            ({" "}
            <span className=" text-mainblue font-semibold">
              {numeral(value)?.format("0,0")}
            </span>{" "}
            = {numeral(balanceData?.property_propotion).format("0,0")} /{" "}
            {numeral(expenses_permonth).format("0,0")} )
          </div>
        </div>
      );
    };

    if (status === "bad") {
      return (
        <div className="text-lg font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนสภาพคล่อง {numeral(value)?.format("0,0")} เท่า
          ซึ่งน้อยกว่า 6 เท่า
          <MethodBox />
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนสภาพคล่องพื้นฐาน 6 เท่า
          <MethodBox />
        </div>
      );
    }
    return (
      <div className="text-lg font-semibold text-center my-2.5  text-mainyellow ">
        คุณมีอัตราส่วนสภาพคล่อง {numeral(value)?.format("0,0")} เท่า ซึ่งมากกว่า
        6 เท่า
        <MethodBox />
      </div>
    );
  };
  const checkPropertyDes = () => {
    const status = checkResultProperty();
    const value = checkAmouthProperty();
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    const asset_value = +expenses_permonth * (6 - checkProperty);
    const expenses_permonth_six = expenses_permonth * 6;
    let remain = balanceData?.property_propotion - expenses_permonth_six;
    if (remain < 0) {
      remain = 0;
    }

    if (status === "bad") {
      return (
        <div className=" mb-4 font-medium">
          คุณต้องหาเงินสำรองเพิ่มอีก
          <span className=" px-1 font-bold text-red-500 ">
            {numeral(asset_value).format("0,0")}
          </span>
          บาท*
          <div>เพื่อความไม่ประมาทในการวางแผนการเงิน</div>
          <div className=" text-sm text-maingray mt-4 font-normal ">
            * ค่าใช้จ่ายแต่ละเดือน x ( 6 - สภาพคล่อง )
            <div className=" text-xs mt-1">
              (
              <span className=" text-mainblue font-semibold pl-1">
                {numeral(expenses_permonth).format("0,0")}
              </span>{" "}
              x ( 6 - {numeral(value)?.format("0,0")}) ={" "}
              {numeral(asset_value).format("0,0")} )
            </div>
          </div>
        </div>
      );
    }

    if (status === "good") {
      return (
        <div className=" mb-10">
          คุณมีความสามารถในการรับมือกับ
          <div>
            เหตุการณ์ไม่คาดฝันได้ดี เช่น ตกงาน อุบัติเหตุทำให้สูญเสียรายได้
            เป็นต้น
          </div>
        </div>
      );
    }
    return (
      <div className="mb-4 font-medium">
        คุณมีเงินสำรองจำนวนมาก
        <div>
          จะดีกว่าไหมหากนำเงิน
          <span className=" px-1 font-bold text-mainyellow ">
            {numeral(remain).format("0,0")}
          </span>
          บาท*
          <div> ไปลงทุนเพิ่มเติม เพื่อให้บรรลุเป้าหมายทางการเงิน</div>
        </div>
        <div className=" text-sm text-maingray mt-4 font-normal ">
          * สินทรัพย์สภาพคล่อง - 6เท่าของค่าใช้จ่ายแต่ละเดือน
          <div className=" text-xs mt-1">
            (
            <span className=" text-mainblue font-semibold pl-1">
              {numeral(balanceData?.property_propotion).format("0,0")}
            </span>{" "}
            - {numeral(expenses_permonth_six).format("0,0")} ={" "}
            {numeral(remain).format("0,0")} )
          </div>
        </div>
      </div>
    );
  };

  //======================================== หนี้สินต่อทรัพย์สิน ========================================//
  const getDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  });
  const checkDebttoAssetIcon = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoAssetTitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    } else {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
  };
  const checkDebttoAssetSubtitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)} %
          <div>ซึ่งมากกว่า 50%</div>
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)}%
          <div>ซึ่งน้อยกว่า 50% </div>
        </div>
      );
    }
  };
  const checkDebttoAssetDes = () => {
    const status = getDebttoTotalAsset;

    if (status > 50) {
      return (
        <div>
          ภาระหนี้สินมากเกินไป
          <div>โดยหาทางเพิ่มรายได้ ลดรายจ่ายที่ไม่จำเป็น</div>
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold">
              อัตราส่วนหนี้สินต่อทรัพย์สิน =
            </span>{" "}
            (หนี้สิน/ทรัพย์สิน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoTotalAsset?.toFixed(2)} % =
              </span>{" "}
              (( {numeral(balanceData?.debt_propotion)?.format("0,0")} /{" "}
              {numeral(balanceData?.property_propotion)?.format("0,0")}) * 100 )
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สินในสัดส่วนที่ดี
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold">
              อัตราส่วนหนี้สินต่อทรัพย์สิน =
            </span>{" "}
            (หนี้สิน/ทรัพย์สิน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoTotalAsset?.toFixed(2)} % =
              </span>{" "}
              (( {numeral(balanceData?.debt_propotion)?.format("0,0")} /{" "}
              {numeral(balanceData?.property_propotion)?.format("0,0")}) * 100 )
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== ชำระหนี้สินต่อรายได้ ========================================//
  const getDebttoSalary = useMemo(() => {
    let sum = 0;

    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  });
  const checkDebttoSalaryIcon = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoSalaryTitle = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    } else {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    }
  };
  const checkDebttoSalarySubtitle = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งมากกว่า 50% *
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งน้อยกว่า 35% - 50% *
        </div>
      );
    }
  };
  const checkDebttoSalaryDes = () => {
    const status = getDebttoSalary;
    const salary_propotion_per_month = balanceData?.salary_propotion / 12;

    if (status > 50) {
      return (
        <div>
          ควรวางแผนบริหารหนี้เช่น หาทางเพิ่มรายได้ ลดรายจ่ายไม่จำเป็น
          รักษาสภาพคล่อง
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold pr-1">
              อัตราส่วนการชำระหนี้ต่อรายได้ =
            </span>
            (เงินชำระหนี้สินต่อเดือน/รายได้ต่อเดือน)x100
            <div className=" text-xs mt-1">
              <span className=" text-mainblue font-bold">
                {getDebttoSalary?.toFixed(2)} % =
              </span>{" "}
              ({" "}
              {numeral(
                balanceData?.sum_debt_short_permonth +
                  balanceData?.sum_debt_long_permonth
              ).format("0,0")}{" "}
              / {numeral(salary_propotion_per_month).format("0,0")} * 100 )
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          ภาระหนี้สินไม่มากเกิน หนี้สินที่ดีก่อให้เกิดรายได้และ สินทรัพย์ในอนาคต
          <div className=" text-maingray text-sm mt-3">
            <span className=" text-mainblue font-semibold pr-1">
              อัตราส่วนการชำระหนี้ต่อรายได้ =
            </span>
            (เงินชำระหนี้สินต่อเดือน/รายได้ต่อเดือน)x100
            <div className=" text-xs mt-1">
              ({" "}
              <span className=" text-mainblue font-bold">
                {getDebttoSalary?.toFixed(2)} % =
              </span>{" "}
              {numeral(
                balanceData?.sum_debt_short_permonth +
                  balanceData?.sum_debt_long_permonth
              ).format("0,0")}{" "}
              / {numeral(salary_propotion_per_month).format("0,0")} * 100 )
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== การออม ========================================//
  const getConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  });
  const getInvestSavingMore = useMemo(() => {
    // หาเปอเซ็นคงเหลือการออมใน 10%
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;
    const conservation = (salaryValue * 10) / 100;
    const result = conservation - sumExpensesInvestSaving;
    return result;
  });
  const checkConservationIcon = () => {
    const status = getConservation;
    if (status > 10) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkConservationTitle = () => {
    const status = getConservation;
    if (status > 10) {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
  };
  const checkConservationSubtitle = () => {
    const status = getConservation;

    if (status > 10) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการออม{" "}
          {numeral(balanceData?.sum_expenses_invest_saving).format("0,0")} บาท
          หรือ {getConservation?.toFixed(2)} % ซึ่งมากกว่า 10 % *
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการออม
          {numeral(balanceData?.sum_expenses_invest_saving).format(
            "0,0.00"
          )}{" "}
          บาท หรือ {getConservation?.toFixed(2)} % ซึ่งน้อยกว่า 10 % *
        </div>
      );
    }
  };
  const checkConservationDes = () => {
    const status = getConservation;
    const salaryValue = balanceData?.salary_propotion
      ? balanceData?.salary_propotion
      : 0;
    const salaryValuePercent = (salaryValue * 10) / 100;

    if (status > 10) {
      return (
        <div>
          คุณมีอัตราส่วนการออมและการลงทุนในสัดส่วนที่ดี
          <div className=" text-maingray text-sm mt-3">
            <div className="font-semibold"> อัตราส่วนการออม = </div>
            <div className=" mb-1">
              (ค่าใช้จ่ายเพื่อการออมและการลงทุน/รายได้รวม) x 100 *รายรับ{" "}
            </div>

            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              10% ของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValuePercent)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          คุณควรเพิ่มการออมอีก{" "}
          <span className=" font-bold text-red-500 ">
            {numeral(getInvestSavingMore).format("0,0.00")}
          </span>{" "}
          บาท
          <div className="font-semibold"> อัตราส่วนการออม = </div>
          <div className=" mb-1">
            (ค่าใช้จ่ายเพื่อการออมและการลงทุน/รายได้รวม) x 100 *รายรับ{" "}
          </div>
          <div className=" text-maingray text-sm mt-3">
            *รายรับ{" "}
            <span className=" text-mainblue font-bold">
              {numeral(salaryValue)?.format("0,0")} บาท
            </span>
            <div>
              10% ของรายรับคือ{" "}
              <span className=" text-mainblue font-bold">
                {numeral(salaryValuePercent)?.format("0,0")} บาท
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  //======================================== การลงทุน ========================================//
  const getInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        balanceData?.net_wealth > 0
          ? (balanceData?.sum_property_invest / balanceData?.net_wealth) * 100
          : 0;
    }
    return sum;
  });
  const checkInvestIcon = () => {
    const status = getInvest;
    if (status > 50) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkInvestTitle = () => {
    const status = getInvest;
    if (status > 50) {
      return (
        <div className="bg-greenopacity text-maingreen px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ยอดเยี่ยม
        </div>
      );
    } else {
      return (
        <div className=" bg-[#FF0000]/30 text-[#FF0000]  px-3 py-1 rounded-[6px] text-xl font-semibold w-[108px] text-center">
          ปรับปรุง
        </div>
      );
    }
  };
  const checkInvestSubtitle = () => {
    const status = getInvest;

    const MethodBox = ({ value }) => {
      return (
        <div className="">
          <div className=" text-maingray font-normal text-sm text-center">
            <span className=" font-semibold text-mainblue">
              อัตราส่วนการลงทุน
            </span>{" "}
            = (สินทรัพย์ลงทุน/ความมั่งคั่งสุทธิ) x 100
          </div>
          <div className=" text-maingray text-xs text-center mt-1">
            ( <span className=" text-mainblue font-semibold">{value}</span> =
            &nbsp;{numeral(balanceData?.sum_property_invest).format("0,0")} /{" "}
            {numeral(balanceData?.net_wealth).format("0,0")} * 100 )
          </div>
        </div>
      );
    };

    if (status > 50) {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-maingreen">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} %
          <div>ซึ่งมากกว่า 50 %</div>
          <MethodBox value={getInvest?.toFixed(2)} />
        </div>
      );
    } else {
      return (
        <div className="text-xl font-semibold text-center my-2.5  text-[#FF0000]">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} %
          <div>ซึ่งน้อยกว่า 50 %</div>
          <MethodBox value={getInvest?.toFixed(2)} />
        </div>
      );
    }
  };
  const checkInvestDes = () => {
    const status = getInvest;
    if (status > 50) {
      return (
        <div>
          คุณมีแนวโน้มที่จะบรรลุเป้าหมายการเงินที่ต้องการ
          จัดสรรการลงทุนให้เหมาะสมกับลำดับความสำคัญ
        </div>
      );
    } else {
      return (
        <div>
          คุณมีสินทรัพย์ที่ก่อให้เกิดรายได้น้อยเกินไป
          ควรจัดสรรการลงทุนเพิ่มพูนมั่งคั่ง
        </div>
      );
    }
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div
          className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5 mb-[30px]`}
        >
          <HeaderReport
            icon={"/images/financial-analysis-report.png"}
            title={"อัตราส่วนทางการเงิน"}
            className={" z-20"}
          />

          <CardReport
            onClick={() => {
              setOpenPopupIncome(true);
            }}
            title={
              <div className=" flex items-center gap-[5px]">
                <ImageMain
                  src="/images/income-report.png"
                  className="w-6 h-6"
                />
                <div className=" font-semibold mt-1">รายรับ</div>
              </div>
            }
            panel={
              <div>
                <div className=" flex flex-col justify-center items-center gap-2.5">
                  <div className=" flex flex-col justify-center items-center gap-[5px]">
                    <div className=" w-11 h-11 mx-auto">
                      <ImageMain
                        src={checkIncomeIcon()}
                        className="w-full h-full"
                      />
                    </div>
                    <div className=" text-sm">{checkIncomeTitle()}</div>
                  </div>
                  <div className="text-sm">{checkIncomeSubtitle()}</div>
                  <div className="text-xs">{checkIncomeDes()}</div>
                </div>
              </div>
            }
          />
          <CardReport
            onClick={() => {
              setOpenPopupOutcome(true);
            }}
            title={
              <div className=" flex items-center gap-[5px]">
                <ImageMain
                  src="/images/outcome-report.png"
                  className="w-6 h-6"
                />
                <div className=" font-semibold mt-1">รายจ่าย</div>
              </div>
            }
            panel={
              <div>
                <div className=" flex flex-col justify-center items-center gap-2.5">
                  <div className=" flex flex-col justify-center items-center gap-[5px]">
                    <div className=" w-11 h-11 mx-auto">
                      <ImageMain
                        src={checkOutcomeIcon()}
                        className="w-full h-full"
                      />
                    </div>
                    <div className=" text-sm">{checkOutcomeTitle()}</div>
                  </div>
                  <div className="text-sm">
                    {checkOutcomeSubtitle(balanceData)}
                  </div>
                  <div className="text-xs">{checkOutcomeDes()}</div>
                </div>
              </div>
            }
          />
          <div className=" grid grid-cols-2 gap-2.5 ">
            <CardReport
              classNameHead="rounded-[10px]"
              onClick={() => {
                setOpenPopupProperty(true);
              }}
              title={
                <div className=" flex items-center gap-[5px]">
                  <ImageMain
                    src="/images/proporty_asset2.png"
                    className="w-[18px] h-[18px] "
                  />
                  <div className=" font-semibold pt-1 text-xs flex ">
                    สภาพคล่องพื้นฐาน
                    <ICON_INFO
                      className={" w-[15px] h-[15px] ml-1 text-maingray"}
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" mt-5 text-xs text-mainblue font-medium ">
                  คุณมีอัตราส่วนสภาพคล่อง
                  <div className=" text-xl font-semibold">
                    {checkAmouthProperty()}
                  </div>
                </div>
              }
            />
            <CardReport
              classNameHead="rounded-[10px]"
              onClick={() => {
                setOpenPopupDebtToAsset(true);
              }}
              title={
                <div className=" flex items-center gap-[5px]">
                  <ImageMain
                    src="/images/finance_variable2.png"
                    className="w-[18px] h-[18px] "
                  />
                  <div className=" font-semibold pt-1 text-xs flex ">
                    หนี้สินต่อทรัพย์สิน
                    <ICON_INFO
                      className={" w-[15px] h-[15px] ml-1 text-maingray"}
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" text-xs text-mainblue font-medium ">
                  {getDebttoTotalAsset > 50 ? (
                    <div className="">
                      คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน{" "}
                      <div className=" text-xl font-semibold">
                        {getDebttoTotalAsset?.toFixed(2)} %
                      </div>
                      <div>ซึ่งมากกว่า 50%</div>
                    </div>
                  ) : (
                    <div className="">
                      คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน{" "}
                      <div className=" text-xl font-semibold">
                        {getDebttoTotalAsset?.toFixed(2)} %
                      </div>
                      <div>ซึ่งน้อยกว่า 50% </div>
                    </div>
                  )}
                </div>
              }
            />
            <CardReport
              classNameHead="rounded-[10px]"
              onClick={() => {
                setOpenPopupDebtToSalary(true);
              }}
              title={
                <div className="  flex items-center gap-[4px]">
                  <ImageMain
                    src="/images/debt_short2.png"
                    className="w-[18px] h-[18px] "
                  />
                  <div className=" font-semibold pt-1 text-xs flex ">
                    <div className="flex-none"> การชำระหนี้ต่อรายได้</div>
                    <ICON_INFO
                      className={
                        " w-[15px] h-[15px] ml-1 text-maingray flex-none "
                      }
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" text-xs text-mainblue font-medium ">
                  {getDebttoSalary > 50 ? (
                    <div className=" text-center ">
                      คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้{" "}
                      <div className=" text-xl font-semibold">
                        {getDebttoSalary?.toFixed(2)} %
                      </div>
                      <div>ซึ่งมากกว่า 50% </div>
                    </div>
                  ) : (
                    <div className=" text-center">
                      คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้{" "}
                      <div className=" text-xl font-semibold">
                        {getDebttoSalary?.toFixed(2)} %
                      </div>
                      <div>ซึ่งน้อยกว่า 35% - 50% </div>
                    </div>
                  )}
                </div>
              }
            />
            <CardReport
              classNameHead="rounded-[10px]"
              onClick={() => {
                setOpenPopupConservation(true);
              }}
              title={
                <div className=" flex items-center gap-[5px]">
                  <ImageMain
                    src="/images/finance_invest2.png"
                    className="w-[18px] h-[18px] "
                  />
                  <div className=" font-semibold pt-1 text-xs flex ">
                    การออม
                    <ICON_INFO
                      className={" w-[15px] h-[15px] ml-1 text-maingray"}
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" text-xs text-mainblue font-medium ">
                  {getConservation > 10 ? (
                    <div className="text-center">
                      คุณมีอัตราส่วนการออม{" "}
                      <div className=" text-xl font-semibold">
                        {getConservation?.toFixed(2)} %
                      </div>
                      <div>ซึ่งมากกว่า 10 %</div>
                    </div>
                  ) : (
                    <div className=" text-center ">
                      คุณมีอัตราส่วนการออม
                      <div className="text-xl font-semibold">
                        {getConservation?.toFixed(2)} %
                      </div>
                      <div>ซึ่งน้อยกว่า 10 %</div>
                    </div>
                  )}
                </div>
              }
            />
            <CardReport
              classNameHead="rounded-[10px]"
              onClick={() => {
                setOpenPopupInvest(true);
              }}
              title={
                <div className=" flex items-center gap-[5px]">
                  <ImageMain
                    src="/images/invest-report.png"
                    className="w-[18px] h-[18px] "
                  />
                  <div className=" font-semibold mt-1 text-xs flex ">
                    การลงทุน
                    <ICON_INFO
                      className={" w-[15px] h-[15px] ml-1 text-maingray"}
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" text-xs text-mainblue font-medium ">
                  {getInvest > 50 ? (
                    <div className="font-semibold text-center ">
                      คุณมีอัตราส่วนการลงทุน
                      <div className="text-xl font-semibold">
                        {getInvest?.toFixed(2)} %
                      </div>
                      <div>ซึ่งมากกว่า 50 %</div>
                    </div>
                  ) : (
                    <div className="font-semibold text-center">
                      คุณมีอัตราส่วนการลงทุน
                      <div className="text-xl font-semibold">
                        {getInvest?.toFixed(2)} %
                      </div>
                      <div>ซึ่งน้อยกว่า 50 %</div>
                    </div>
                  )}
                </div>
              }
            />
          </div>
          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
        {openPopupIncome && (
          <PopupBalanceMain
            icon={checkIncomeIcon()}
            open={openPopupIncome}
            onClose={() => {
              setOpenPopupIncome(false);
            }}
            head={<div className=" text-xl">{checkIncomeTitle()}</div>}
            subtitle={<div className="text-xl">{checkIncomeSubtitle()}</div>}
            discription={checkIncomeDes()}
          />
        )}
        {openPopupOutcome && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkOutcomeIcon()}
            open={openPopupOutcome}
            onClose={() => {
              setOpenPopupOutcome(false);
            }}
            head={<div className="text-xl">{checkOutcomeTitle()}</div>}
            subtitle={
              <div className=" text-xl">
                {checkOutcomeSubtitle(balanceData)}
              </div>
            }
            discription={<div className=" text-sm">{checkOutcomeDes()}</div>}
          />
        )}
        {openPopupProperty && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkPropertyIcon()}
            open={openPopupProperty}
            onClose={() => {
              setOpenPopupProperty(false);
            }}
            head={checkPropertyTitle()}
            subtitle={checkPropertySubtitle()}
            discription={checkPropertyDes()}
          />
        )}
        {openPopupDebtToAsset && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkDebttoAssetIcon()}
            open={openPopupDebtToAsset}
            onClose={() => {
              setOpenPopupDebtToAsset(false);
            }}
            head={checkDebttoAssetTitle()}
            subtitle={checkDebttoAssetSubtitle()}
            discription={checkDebttoAssetDes()}
          />
        )}
        {openPopupDebtToSalary && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkDebttoSalaryIcon()}
            open={openPopupDebtToSalary}
            onClose={() => {
              setOpenPopupDebtToSalary(false);
            }}
            head={checkDebttoSalaryTitle()}
            subtitle={checkDebttoSalarySubtitle()}
            discription={checkDebttoSalaryDes()}
          />
        )}
        {openPopupConservation && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkConservationIcon()}
            open={openPopupConservation}
            onClose={() => {
              setOpenPopupConservation(false);
            }}
            head={checkConservationTitle()}
            subtitle={checkConservationSubtitle()}
            discription={checkConservationDes()}
          />
        )}

        {openPopupInvest && (
          <PopupBalanceMain
            padding="px-4"
            icon={checkInvestIcon()}
            open={openPopupInvest}
            onClose={() => {
              setOpenPopupInvest(false);
            }}
            head={checkInvestTitle()}
            subtitle={checkInvestSubtitle()}
            discription={checkInvestDes()}
          />
        )}
      </div>
    </Container>
  );
};

export default ReportFinancialPage;
