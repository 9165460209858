import { ImageMain } from "../../../components/img/Img.Main";
import { CardTest } from "../styles/Card";

const ViewNumberSeven = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        seven_question_choice_one: true,
        seven_question_choice_two: false,
        seven_question_choice_three: false,
        seven_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        seven_question_choice_one: false,
        seven_question_choice_two: true,
        seven_question_choice_three: false,
        seven_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        seven_question_choice_one: false,
        seven_question_choice_two: false,
        seven_question_choice_three: true,
        seven_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        seven_question_choice_one: false,
        seven_question_choice_two: false,
        seven_question_choice_three: false,
        seven_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 7 จาก 10</div>
      <CardTest
        choice1={
          <div>
            กลุ่มการลงทุนที่ 1
            <div>
              มีโอกาสได้รับผลตอบแทน 2.5%{" "}
              <div className=" text-red500">โดยไม่ขาดทุนเลย</div>
            </div>
          </div>
        }
        choice2={
          <div>
            กลุ่มการลงทุนที่ 2
            <div>
              มีโอกาสได้รับผลตอบแทนสูงสุด 7%
              <div className=" text-red500"> แต่อาจมีผลขาดทุนได้ถึง 1%</div>
            </div>
          </div>
        }
        choice3={
          <div>
            กลุ่มการลงทุนที่ 3
            <div>
              มีโอกาสได้รับผลตอบแทนสูงสุด 15%
              <div className=" text-red500"> แต่อาจมีผลขาดทุนได้ถึง 5%</div>
            </div>
          </div>
        }
        choice4={
          <div>
            กลุ่มการลงทุนที่ 4
            <div>
              มีโอกาสได้รับผลตอบแทนสูงสุด 25%
              <div className=" text-red500"> แต่อาจมีผลขาดทุนได้ถึง 15%</div>
            </div>
          </div>
        }
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest.seven_question_choice_one}
        checked2={formInvest.seven_question_choice_two}
        checked3={formInvest.seven_question_choice_three}
        checked4={formInvest.seven_question_choice_four}
        question={
          <div>
            <ImageMain
              src="../images/invest_choice_seven.png"
              className="w-[276px] h-[186px] mx-auto mb-1"
            />
            7. เมื่อพิจารณารูปแสดงตัวอย่างผลตอบแทน
            ของกลุ่มการลงทุนที่อาจเกิดขึ้นด้านล่าง
            ท่านเต็มใจที่จะลงทุนในกลุ่มการลงทุนใด มากที่สุด
          </div>
        }
      />
    </div>
  );
};

export default ViewNumberSeven;
