import { Progress } from "@material-tailwind/react";
import {
  STYLE_CARD_BG,
  STYLE_IMG_DRAWER,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_INSET,
  STYLE_PADDING_BALANCE_DRAWER,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import { CardMain } from "../../../components/card/CardMain";
import { ICON_CLOSE_NO_CIIRCLE } from "../../../utils/useIcons";
import numeral from "numeral";
import { NumericFormat } from "react-number-format";

export const PanelTax = ({
  title,
  title2,
  subtitle,
  remain,
  value,
  valueDisplay,
  maxvalue,
  valueprogress,
  onChange,
  img,
  error,
  errortext = true,
  full,
  etc,
  textetc,
  textetc2,
  errorstyle1,
  errorstyle2,
  disabled,
  maxLength,
  panel2,
}) => {
  return (
    <div>
      <div className="mt-16">
        <div className={`${STYLE_TITLE_DRAWER} `}>
          {title}
          {title2 && <div>{title2}</div>}
        </div>
        {subtitle && (
          <div className=" text-md text-center text-mainblue font-semibold mt-1">
            {subtitle}
          </div>
        )}
        <div className=" pt-6">
          <div className={`${STYLE_CARD_BG} p-[10px] mb-5`}>
            <div className=" flex justify-between">
              <div className="  md:text-lg text-sm font-medium pl-[14px] text-black">
                ใช้สิทธิไปแล้ว
              </div>
              {full ? (
                ""
              ) : (
                <div className=" md:text-lg text-sm font-normal text-maingray">
                  ( คงเหลือ {error ? 0 : numeral(remain).format("0,0")} บาท )
                </div>
              )}
            </div>
            <div className="flex justify-between items-end w-full ">
              <div className=" md:w-2/3 ">
                <div className=" font-semibold text-black md:text-2xl  text-xl w-full mb-1 flex gap-1">
                  {value ? (
                    error ? (
                      <div className="text-red-500">
                        {numeral(value).format("0,0")}
                      </div>
                    ) : (
                      // value
                      numeral(Number(value)).format("0,0")
                    )
                  ) : (
                    0
                  )}
                  {""} / {numeral(maxvalue).format("0,0")} ต่อปี
                </div>
                <Progress
                  value={valueprogress}
                  size=" md:lg sm"
                  className=" bg-white w-full"
                  color="blue"
                />
              </div>
              <img src={img} className={STYLE_IMG_DRAWER} />
            </div>
          </div>
          <CardMain
            title="เบี้ยประกันที่จ่ายตลอดทั้งปี"
            panel={
              <div className="">
                {full && (
                  <div className=" text-center font-semibold text-red-500 ">
                    เต็มสิทธิ
                  </div>
                )}
                <div className=" md:text-lg  text-md flex justify-center items-center gap-2.5 mt-5">
                  <div>จำนวนเงิน</div>

                  <NumericFormat
                    disabled={disabled}
                    maxLength={maxLength}
                    value={valueDisplay ? valueDisplay : value}
                    placeholder="0"
                    onChange={onChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={
                      error
                        ? " px-[14px] py-2 placeholder:text-gray80 md:text-lg text-sm font-semibold focus:outline-none md:w-40  w-28 h-8 rounded-[8px] border border-red-500 text-red-500 "
                        : STYLE_INPUT_INSET
                    }
                  />

                  <div>บาท</div>
                </div>

                {error && (
                  <div className=" md:text-lg  text-xs  md:pt-2 pt-1 text-red-500 pl-4 font-medium text-center mt-1">
                    {errortext && (
                      <div>ไม่เกิน {numeral(errortext).format("0,0")} บาท</div>
                    )}
                  </div>
                )}
                {panel2}
              </div>
            }
          />

          {etc && (
            <div className=" md:text-[16px] text-xs mt-3 text-mainblue font-medium leading-relaxed ">
              <div className={errorstyle1 ? "text-red-500" : ""}>
                {" "}
                * {textetc}
              </div>
              {textetc2 && (
                <div className={errorstyle2 ? "text-red-500 mt-3" : " mt-3"}>
                  {" "}
                  ** <span>{textetc2}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const PanelSumTaxDetails = ({
  title,
  value,
  value2,
  total,
  total_name = "รวม",
  head_1 = "รายการ",
  head_2 = "จำนวนเงินที่ได้รับ (บาท)",
  positionHead = "text-left ",
  subHead = false,
  subhead_name,
  positiontitle = "text-left",
  positionnvalue_1 = "text-right",
  positionnvalue_2 = "text-right",
  etc,
  etc_text,
  cols = 3,
}) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          {cols === 2 ? "" : <div className={""}>{}</div>}
          <div className={`${positionHead} ${cols === 2 ? "" : "text-right"} `}>
            {head_1}
          </div>
          <div className="text-right font-medium">{head_2}</div>
        </div>
        {subHead ? (
          <div className="  px-2.5 text-base font-semibold mb-1">
            {subhead_name}
          </div>
        ) : (
          ""
        )}
        <div
          className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 font-medium`}
        >
          <div className={`${positiontitle}  font-semibold  `}>{title}</div>

          <div className={`${positionnvalue_1} text-base font-semibold`}>
            {value}
          </div>
          {cols === 2 ? (
            ""
          ) : (
            <div className={`${positionnvalue_2} text-base font-semibold`}>
              {value2}
            </div>
          )}
        </div>
        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>{total_name}</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
        {etc && (
          <div className=" text-right text-xs text-maingray">{etc_text}</div>
        )}
      </div>
    </div>
  );
};

export const PanelNetIncome = ({ income, expense, deduct, total }) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-2 mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          <div className={` font-medium `}>{"รายการ"}</div>
          <div className=" text-right font-medium">{"จำนวน"}</div>
        </div>

        <div
          className={` grid grid-cols-2 gap-[5px] text-sm px-2.5 font-medium`}
        >
          <div className={` font-semibold  `}>{"รายได้"}</div>
          <div className={` text-base font-semibold text-right`}>{income}</div>
          <div className={` font-semibold  `}>{"หักค่าใช้จ่าย"}</div>
          <div className={` text-base font-semibold text-right`}>{expense}</div>
          <div className={` font-semibold  `}>{"หักค่าลดหย่อน"}</div>
          <div className={` text-base font-semibold text-right`}>{deduct}</div>
        </div>
        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>{"เงินได้สุทธิ"}</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
        <div className=" text-right text-xs text-maingray mt-2.5">
          {" "}
          * ({income} - {expense} - {deduct} = {total})
        </div>
      </div>
    </div>
  );
};

export const PanelDeduct = ({ deductList, deductionAFList, total }) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-2 mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          <div className={` font-medium `}>{"รายการ"}</div>
          <div className=" text-right font-medium">{"จำนวน"}</div>
        </div>

        {/*---- รายการค่าลดหย่อน ----*/}
        {deductList?.length > 0 ? (
          <div className="">
            <div className={`text-mainblue text-sm  font-medium mb-1.5`}>
              รายการค่าลดหย่อน
            </div>
            {deductList?.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={` grid grid-cols-5 items-center gap-[5px] text-sm px-2.5 font-medium mb-0.5`}
                  >
                    <div className={` col-span-3 font-semibold`}>
                      {item.title}
                    </div>
                    <div
                      className={` col-span-2 text-base font-semibold text-right`}
                    >
                      {numeral(item.amount)?.format("0,0")}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {/*---- รายการค่าลดหย่อนที่วางแผน เพิ่มเติม ----*/}
        {deductionAFList?.length > 0 ? (
          <div className="mt-2.5">
            <div className={`text-mainblue text-sm  font-medium mb-1.5`}>
              รายการค่าลดหย่อนที่วางแผน เพิ่มเติม
            </div>
            {deductionAFList?.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={` grid grid-cols-5 items-center gap-[5px] text-sm px-2.5 font-medium mb-0.5`}
                  >
                    <div className={` col-span-3 font-semibold`}>
                      {item.title}
                    </div>
                    <div
                      className={` col-span-2 text-base font-semibold text-right`}
                    >
                      {numeral(item.amount)?.format("0,0")}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>รวม</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
      </div>
    </div>
  );
};
