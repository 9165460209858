import numeral from "numeral";
import { CardHeadgray } from "../../../components/card/CardMain";
import {
  STYLE_INPUT_SLIDER,
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../../utils/useStyle";
import { ViewTableDebt } from "../../debt/component/VIDebtTable";
import { MTButton } from "../../../components/button/MTButton";
import { useRetireStore } from "../../../_store/retireStore";
import { useBalanceStore } from "../../../_store/balanceStore";
import { NumericFormat } from "react-number-format";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { cn } from "../../../utils/useFunctions";

const ProfileUser = ({ onChangeForm }) => {
  const retireData = useRetireStore((state) => state.retireData);
  const futurePlan = useFuturePlanStore((state) => state.futurePlanData);

  return (
    <div>
      <div className=" text-center text-black font-semibold text-xl mb-5">
        ข้อมูลปัจจุบัน
      </div>
      <div className=" grid grid-cols-3 justify-center items-center">
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt="present_age"
            src="/images/present_age2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-maincyan">
            อายุปัจจุบัน
          </div>
          <div className="text-mainblue font-bold">
            {/* {retireData?.age_current || 25} ปี */}
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              value={futurePlan?.age_current || ""}
              onChange={onChangeForm("age_current")}
            />
          </div>
        </div>
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt=""
            src="/images/old_age2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-[#B95940]">
            อายุเกษียณ
          </div>
          <div className="text-mainblue font-bold">
            {/* {retireData?.age_retire || 60} ปี */}
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              value={futurePlan?.age_retire || ""}
              onChange={onChangeForm("age_retire")}
            />
          </div>
        </div>
        <div className=" mx-auto flex flex-col justify-center items-center gap-1">
          <img
            alt=""
            src="/images/dead2.png"
            className=" mx-auto w-[50px] h-[50px]"
          />
          <div className="mt-2 text-sm font-semibold text-mainorange">
            สิ้นอายุขัย
          </div>
          <div className="text-mainblue font-bold text-center">
            {/* {retireData?.age_dead || 80} ปี */}
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={STYLE_INPUT_SLIDER}
              value={futurePlan?.age_dead || ""}
              onChange={onChangeForm("age_dead")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FutureProfile = ({
  age_current,
  age_retire,
  age_dead,
  salary,
  salary_peryear,
  out_come,
  investment_assets,
  debt_short_list,
  debt_long_list,
  onClick,
  onChangeForm,
}) => {
  const retireData = useRetireStore((state) => state.retireData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const futurePlan = useFuturePlanStore((state) => state.futurePlanData);

  return (
    <div className={`${STYLE_SPACE_GRAY_MENU} ${STYLE_PADDING_MAIN}`}>
      <ProfileUser onChangeForm={onChangeForm} />
      <CardHeadgray
        title={"เงินเดือน (ต่อเดือน) "}
        text_color="text-mainblue font-semibold text-xl"
        panel={
          <div className=" py-5 flex justify-center items-center space-x-1">
            {/* {numeral(retireData?.salary)?.format("0,0")} บาท */}
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={cn(STYLE_INPUT_SLIDER, "w-32")}
              value={futurePlan?.salary || ""}
              onChange={onChangeForm("salary")}
            />
            <div>บาท</div>
          </div>
        }
      />
      <CardHeadgray
        title={"เงินเดือนที่เพิ่มขึ้นต่อปี (%) "}
        text_color="text-mainblue font-semibold text-xl"
        panel={
          <div className=" py-5 flex justify-center items-center space-x-1">
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={cn(STYLE_INPUT_SLIDER, "w-32")}
              value={futurePlan?.salary_peryear || ""}
              onChange={onChangeForm("salary_peryear")}
            />
            <div>%</div>
            {/* {retireData?.salary_peryear} % */}
          </div>
        }
      />
      <CardHeadgray
        title={" รายจ่ายปัจจุบัน (ต่อเดือน) "}
        text_color="text-mainblue font-semibold text-xl"
        panel={
          <div className=" py-5 flex justify-center items-center space-x-1">
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={cn(STYLE_INPUT_SLIDER, "w-32")}
              value={futurePlan?.out_come || ""}
              onChange={onChangeForm("out_come")}
            />
            <div>บาท</div>
            {/* {numeral(retireData?.out_come)?.format("0,0")} บาท */}
          </div>
        }
      />
      <CardHeadgray
        title={" รายจ่ายที่เพิ่มขึ้นต่อปี (%) "}
        text_color="text-mainblue font-semibold text-xl"
        panel={
          <div className=" py-5 flex justify-center items-center space-x-1">
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={cn(STYLE_INPUT_SLIDER, "w-32")}
              value={futurePlan?.out_come_peryear || ""}
              onChange={onChangeForm("out_come_peryear")}
            />
            <div>%</div>
            {/* {retireData?.out_come_peryear} % */}
          </div>
        }
      />
      <CardHeadgray
        title={" สินทรัพย์มีไว้ลงทุน "}
        text_color="text-mainblue font-semibold text-xl"
        panel={
          <div className=" py-5 flex justify-center items-center space-x-1">
            {/* {numeral(retireData?.investment_assets)?.format("0,0")} บาท */}
            <NumericFormat
              maxLength={11}
              placeholder="0"
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={cn(STYLE_INPUT_SLIDER, "w-32")}
              value={futurePlan?.investment_assets || ""}
              onChange={onChangeForm("investment_assets")}
            />
            <div>บาท</div>
          </div>
        }
      />
      <div className=" text-black font-semibold ">รายการหนี้สินทั้งหมด</div>
      <ViewTableDebt
        debtShortList={balanceData?.debt_short_list}
        debtLongList={balanceData?.debt_long_list}
      />
      <div className=" flex justify-center items-center">
        <MTButton
          text={"วางแผนอนาคต"}
          color="bg-maincyan"
          width="w-40"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default FutureProfile;
