import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_GRAY_11,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
  mapIcon,
  mapStatus,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageFinacialTwo({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
    ? balanceData?.sum_expenses_invest_saving
    : 0;
  const expenseValue = balanceData?.expenses_propotion
    ? balanceData?.expenses_propotion - sumExpensesInvestSaving
    : 0;
  const salary_propotion_per_month = balanceData?.salary_propotion / 12;

  // รายรับ
  const salaryValue = balanceData?.salary_propotion
    ? balanceData?.salary_propotion
    : 0;
  const expensesPerSalary = ((expenseValue / salaryValue) * 100)?.toFixed(2);

  const checkResultExpenses = () => {
    if (expenseValue > salaryValue / 2) {
      return "normal";
    } else {
      return "good";
    }
  };
  const getDesc = () => {
    if (checkResultExpenses() === "normal") {
      return `คุณมีช่องทางรายได้ไม่หลากหลาย`;
    }
    return `คุณมีรายได้หลากหลายช่องทาง`;
  };

  // รายรับ
  const salaryValueIncome = balanceData?.salary_propotion
    ? balanceData?.salary_propotion
    : 0;
  const expensesPerSalaryOutcome = (
    (expenseValue / salaryValueIncome) *
    100
  )?.toFixed(2);
  const getDescOutcome = () => {
    if (checkResultExpenses() === "normal") {
      return `คุณมีรายจ่าย รวม ${numeral(expenseValue)?.format(
        "0,0"
      )} บาท หรือ ${
        String(expensesPerSalaryOutcome) === "Infinity"
          ? 0
          : expensesPerSalaryOutcome
      } % ซึ่งน้อยกว่าครึ่งนึงของรายรับ`;
    }

    return `คุณมีรายจ่าย รวม ${numeral(expenseValue)?.format(
      "0,0"
    )} บาท หรือ ${expensesPerSalaryOutcome} % ซึ่งมากกว่าครึ่งนึงของรายรับ`;
  };

  // สภาพคล่องพื้นฐาน
  const checkProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.property_propotion) {
      sum =
        expenses_permonth > 0
          ? balanceData?.property_propotion / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const checkResultProperty = () => {
    if (checkProperty < 6) {
      return "bad";
    } else if (checkProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };
  const checkPropertyIcon = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return "/images/bad_finance.svg";
    }
    if (status === "good") {
      return "/images/happy_finance.svg";
    }
    if (status === "chance") {
      return "/images/chance_finance.svg";
    }
  };
  const checkPropertTitle = () => {
    const status = checkResultProperty();
    if (status === "bad") {
      return (
        <div className="text-[14px] font-semibold text-center  text-[#FF0000]">
          คุณมีอัตราส่วนสภาพคล่อง {numeral(checkProperty)?.format("0,0")} เท่า
          ซึ่งน้อยกว่า 6 เท่า
        </div>
      );
    }
    if (status === "good") {
      return (
        <div className="text-[14px] font-semibold text-center   text-maingreen">
          คุณมีอัตราส่วนสภาพคล่องพื้นฐาน 6 เท่า
        </div>
      );
    }
    return (
      <div className="text-[14px] font-semibold text-center   text-mainyellow ">
        คุณมีอัตราส่วนสภาพคล่อง {numeral(checkProperty)?.format("0,0")} เท่า
        ซึ่งมากกว่า 6 เท่า
      </div>
    );
  };

  // หนี้สินต่อทรัพย์สิน
  const getDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const checkDebttoAssetIcon = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoAssetTitle = () => {
    const status = getDebttoTotalAsset;
    if (status > 50) {
      return (
        <div className="text-[13px] font-semibold text-center  text-[#FF0000]">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)} %
          ซึ่งมากกว่า 50%
        </div>
      );
    } else {
      return (
        <div className="text-[13px] font-semibold text-center  text-maingreen">
          คุณมีอัตราส่วนหนี้สินต่อทรัพย์สิน {getDebttoTotalAsset?.toFixed(2)}%
          ซึ่งน้อยกว่า 50%
        </div>
      );
    }
  };

  // การชำระหนี้สินต่อรายได้
  const getDebttoSalary = useMemo(() => {
    let sum = 0;
    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  }, [balanceData]);
  const checkDebttoSalaryIcon = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return "/images/bad_finance.svg";
    } else {
      return "/images/happy_finance.svg";
    }
  };
  const checkDebttoSalaryTitle = () => {
    const status = getDebttoSalary;
    if (status > 50) {
      return (
        <div className="text-[13px] font-semibold text-center text-[#FF0000]">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งมากกว่า 50% *
        </div>
      );
    } else {
      return (
        <div className="text-[13px] font-semibold text-center text-maingreen">
          คุณมีอัตราส่วนการชำระหนี้ต่อต่อรายได้ {getDebttoSalary?.toFixed(2)} %
          ซึ่งน้อยกว่า 35% - 50% *
        </div>
      );
    }
  };

  // การออม
  const getConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  }, [balanceData]);
  const salaryValuePercent = (salaryValue * 10) / 100;
  const checkConservationIcon = () => {
    const status = getConservation;
    if (status > 10) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkConservationTitle = () => {
    const status = getConservation;

    if (status > 10) {
      return (
        <div className="text-[13px] font-semibold text-center text-maingreen">
          คุณมีอัตราส่วนการออม{" "}
          {numeral(balanceData?.sum_expenses_invest_saving).format("0,0")} บาท
          หรือ {getConservation?.toFixed(2)} % ซึ่งมากกว่า 10 % *
        </div>
      );
    } else {
      return (
        <div className="text-[13px] font-semibold text-center text-[#FF0000]">
          คุณมีอัตราส่วนการออม
          {numeral(balanceData?.sum_expenses_invest_saving).format(
            "0,0.00"
          )}{" "}
          บาท หรือ {getConservation?.toFixed(2)} % ซึ่งน้อยกว่า 10 % *
        </div>
      );
    }
  };

  const getNetWealth = useMemo(() => {
    let result = 0;
    if (balanceData?.property_propotion) {
      result = balanceData?.debt_propotion
        ? Number(balanceData?.property_propotion) -
          Number(balanceData?.debt_propotion)
        : 0;
    }
    if (result > 0) {
      return result;
    } else {
      return 0;
    }
  }, [balanceData?.property_propotion, balanceData?.debt_propotion]);

  // การลงทุน
  const getInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        getNetWealth > 0
          ? (balanceData?.sum_property_invest / getNetWealth) * 100
          : 0;
    }
    return sum;
  }, []);
  const checkInvestIcon = () => {
    const status = getInvest;
    if (status > 50) {
      return "/images/happy_finance.svg";
    } else {
      return "/images/bad_finance.svg";
    }
  };
  const checkInvestSubtitle = () => {
    const status = getInvest;
    if (status > 50) {
      return (
        <div className="text-[13px] font-semibold text-center  text-maingreen">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} % ซึ่งมากกว่า 50 %
        </div>
      );
    } else {
      return (
        <div className="text-[13px] font-semibold text-center  text-[#FF0000]">
          คุณมีอัตราส่วนการลงทุน {getInvest?.toFixed(2)} % ซึ่งน้อยกว่า 50 %
        </div>
      );
    }
  };

  const evulatePropertyItems = useMemo(() => {
    const newPropertyItems = [
      {
        label: "การชำระหนี้ต่อรายได้",
        label_gray: "(ไม่ควรเกิน 35 - 45%)",
        label_icon: "credit-card.svg",
        icon: checkDebttoSalaryIcon(),
        title: checkDebttoSalaryTitle(),
        desc: `อัตราการชำระหนี้ต่อรายได้ = หนี้สินต่อเดือน / รายได้ต่อเดือน`,
        desc_gray: `${getDebttoSalary?.toFixed(2)} % = (
        ${numeral(
          balanceData?.sum_debt_short_permonth +
            balanceData?.sum_debt_long_permonth
        ).format("0,0")}
        / ${numeral(salary_propotion_per_month).format("0,0")} * 100 )`,
        detail: `ภาระหนี้สินไม่มากเกิน หนี้สินที่ดีก่อให้เกิดรายได้ และสินทรัพย์ในอนาคต`,
      },
      {
        label: "การออม",
        label_gray: "(ควรมากกว่า 10 % ของรายได้)",
        label_icon: "piggy-bank.svg",
        icon: checkConservationIcon(),
        title: checkConservationTitle(),
        desc: `รายจ่ายเพื่อการออมและลงทุนมีมากกว่า ร้อยละ 10 ของรายได้`,
        desc_gray: `(${numeral(salaryValue).format("0,0")} > ${numeral(
          salaryValuePercent
        ).format("0,0")})`,
        detail: `มีความเป็นไปได้ที่คุณจะสามารถบรรลุเป้าหมายทางการเงินที่ตั้งไว้นำเงินไปจัดสรรการลงทุนให้เหมาะสมกับความสามารถ ในการรับความเสี่ยงของคุณลำดับความสำคัญของเป้าหมายบริหารความเสี่ยง อย่างเหมาะสม`,
      },
      {
        label: "การลงทุน",
        label_gray: "(ควรมากกว่า 50% ของรายได้)",
        label_icon: "chart_bar_pdf.svg",
        icon: checkInvestIcon(),
        title: checkInvestSubtitle(),
        desc: `การลงทุน = สินทรัพย์เพื่อการลงทุน / ความมั่งคั่ง * 100`,
        desc_gray: `${numeral(getInvest).format("0,0.00")} = ${numeral(
          balanceData?.sum_property_invest
        ).format("0,0")} /
        ${numeral(balanceData?.net_wealth).format("0,0")} * 100 )`,
        detail: `คุณมีแนวโน้มที่จะบรรลุเป้าหมายการเงินที่ต้องการ จัดสรรการลงทุนให้เหมาะสมกับลำดับความสำคัญ และความสามารถในการรับความเสี่ยงและบริหาร ความเสี่ยงความเหมาะสม บรรลุเป้าหมายแน่นอน`,
      },
    ];
    return newPropertyItems;
  }, []);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="อัตราส่วนทางการเงิน"
        icon="/images/financial_analysis.svg"
      />
      <PdfCard title={"ประเมินรายรับ - รายจ่าย"}>
        {evulatePropertyItems.map((propertyItem, index) => {
          return (
            <PdfCardWhite key={index} className={"flex flex-col mt-3"}>
              <div className="flex flex-col mx-2">
                <div className="flex space-x-2 items-center">
                  <div>
                    <img
                      src={`/images/${propertyItem.label_icon}`}
                      className="bg-cover"
                    />
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {propertyItem.label}
                  </div>
                  <div
                    className={cn("text-[#7E8393]", STYLE_PDF_TEXT_MEDIUM_11)}
                  >
                    {propertyItem.label_gray}
                  </div>
                </div>

                <div className="flex flex-col items-center mt-1">
                  <div className="flex space-x-2 items-center justify-center">
                    <div>
                      <img
                        src={propertyItem.icon}
                        className="bg-cover w-[24px] h-[24px]"
                      />
                    </div>
                    <div className={cn(STYLE_PDF_TEXT_SEMIBOLD_BLUE_14)}>
                      {propertyItem.title}
                    </div>
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11, "mt-1")}>
                    {propertyItem.desc}
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_GRAY_11, "mt-1")}>
                    {propertyItem.desc_gray}
                  </div>
                  <PdfCardGray className={cn("py-2")}>
                    <div className="flex justify-center items-center px-10">
                      <img src="/images/Lamp.svg" className={cn("")} />
                      <div className={cn("  ml-1", STYLE_PDF_TEXT_NORMAL_11)}>
                        {propertyItem.detail}
                      </div>
                    </div>
                  </PdfCardGray>
                </div>
              </div>
            </PdfCardWhite>
          );
        })}
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageFinacialTwo;
