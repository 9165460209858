import React, { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import { OPTIONS_TYPE_CAR, OPTIONS_TYPE_PLACE } from "../functions";

const ListPrivateMain = ({}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const sumDebtLongCarPersonal = useMemo(() => {
    const sum_debt_long_car_personal =
      balanceData?.debt_long_car_personal_list?.reduce((prev, curr) => {
        return prev + curr?.amount;
      }, 0);
    return sum_debt_long_car_personal;
  }, [balanceData]);

  const sumDebtLongPlacePersonal = useMemo(() => {
    const sum_debt_long_place_personal =
      balanceData?.debt_long_place_personal_list?.reduce((prev, curr) => {
        return prev + curr?.amount;
      }, 0);
    return sum_debt_long_place_personal;
  }, [balanceData]);

  return (
    <div className="mb-1.5">
      <div className=" border-b border-gray60 pb-3 mb-3"></div>
      <div className="flex flex-col items-center gap-2.5">
        <div className="flex justify-between p-2 bg-secoundblue rounded-[10px] w-full text-sm text-mainblue font-medium">
          <div>รายการ</div>
          <div>มูลค่าปัจจุบัน</div>
        </div>

        {balanceData?.debt_long_car_personal_list?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                รถยนต์
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- รถยนต์ ----*/}
                {balanceData?.debt_long_car_personal_list?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center text-xs font-normal"
                      >
                        <div>{item?.registration_car}</div>
                        <div className="text-mainblue">
                          {numeral(item?.amount).format("0,0")}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}

        {balanceData?.debt_long_place_personal_list?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                ที่อยู่อาศัย
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- ที่อยู่อาศัย ----*/}
                {balanceData?.debt_long_place_personal_list?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center text-xs font-normal"
                      >
                        <div>{item?.address}</div>
                        <div className="text-mainblue">
                          {numeral(item?.amount).format("0,0")}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListPrivateMain;
