import { Drawer } from "@material-tailwind/react";
import { MTButton } from "../button/MTButton";

export const InfoDrawer = ({
  open,
  onClose,
  head,
  text,
  bottom = "bottom-[-80%]",
  fontHead = "text-[28px]",
}) => {
  return (
    <Drawer
      zindex={100}
      placement="bottom"
      open={open}
      onClose={onClose}
      className=" rounded-t-[40px] !max-h-[80vh] overflow-y-scroll  "
    >
      <div
        className={` w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 mt-5`}
      ></div>
      <div className="animate-bounce mx-auto w-60 h-auto">
        <img src="/images/img_popup.png" className="w-full h-auto" alt="" />
      </div>
      <div className=" h-fit md:h-full relative ">
        <div className="max-w-2xl mx-auto">
          <div className=" px-10">
            <div
              className={` ${fontHead} font-IBMPlexSansThai font-semibold text-mainblue`}
            >
              {head}
            </div>
            <div className=" mt-2 text-maingray font-normal font-IBMPlexSansThaileading-relaxed">
              {text}
            </div>
          </div>
          <div className=" flex justify-center pt-5">
            {" "}
            <MTButton text="ปิด" onClick={onClose} />
          </div>
        </div>

        <img
          src="/images/bgblue.png"
          // className={`absolute ${bottom} -z-10 w-full md:h-full h-fit`}
          className={`absolute ${bottom} -z-10 w-full  h-fit`}
          alt=""
        />
      </div>
    </Drawer>
  );
};
