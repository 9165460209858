import { CardTest } from "../styles/Card";

const ViewNumberOne = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        one_question_choice_one: true,
        one_question_choice_two: false,
        one_question_choice_three: false,
        one_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        one_question_choice_one: false,
        one_question_choice_two: true,
        one_question_choice_three: false,
        one_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        one_question_choice_one: false,
        one_question_choice_two: false,
        one_question_choice_three: true,
        one_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        one_question_choice_one: false,
        one_question_choice_two: false,
        one_question_choice_three: false,
        one_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 1 จาก 10</div>
      <CardTest
        choice1="มากกว่า 55 ปี"
        choice2="45 - 55 ปี"
        choice3="35 - 44 ปี"
        choice4="น้อยกว่า 35 ปี"
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest.one_question_choice_one}
        checked2={formInvest.one_question_choice_two}
        checked3={formInvest.one_question_choice_three}
        checked4={formInvest.one_question_choice_four}
        question="1. ปัจจุบันท่านอายุ"
      />
    </div>
  );
};

export default ViewNumberOne;
