import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { cn } from "../../utils/useFunctions";
import fontSarabunTH from "./font/fontSarabunTH";
import {
  STYLE_PAGE,
  STYLE_PAGE_LANDING,
  STYLE_PDF_CONTENT,
} from "./styles/pdf.style";
import PageLanding from "./view/pdf.v.pageLanding";
import PdfPageBalananceOne from "./view/pdf.v.pageBalanceOne";
import PdfPageBalananceTwo from "./view/pdf.v.pageBalanceTwo";
import PdfPageBalananceThree from "./view/pdf.v.pageBalanceThree";
import PdfPageBalananceFour from "./view/pdf.v.pageBalanceFour";
import PdfPageBalananceSeven from "./view/pdf.v.pageBalanceSeven";
import PageOverviewTwo from "./view/pdf.v.pageOverviewTwo";
import PdfPageBalananceSix from "./view/pdf.v.pageBalanceSix";
import PdfPageFinacialOne from "./view/pdf.v.pageFinacialOne";
import PdfPageFinacialTwo from "./view/pdf.v.pageFinalcialTwo";
import PdfPageFinacialLanding from "./view/pdf.v.pageFinalcialLanding";
import PdfPageRiskOne from "./view/pdf.v.pageRiskOne";
import PdfPageFamilyOne from "./view/pdf.v.pageFamilyOne";
import PdfPageFamilyTwo from "./view/pdf.v.pageFamilyTwo";
import PdfPageFamilyThree from "./view/pdf.v.pageFamilyThree";
import PdfPageInvestOne from "./view/pdf.v.pageInvestOne";
import PdfPageSavingOne from "./view/pdf.v.pageSavingOne";
import PdfPageFutureOne from "./view/pdf.v.pageFutureOne";
import PdfPageFutureTwo from "./view/pdf.v.pageFutureTwo";
import PdfPageFutureThree from "./view/pdf.v.pageFutureThree";
import PdfPageFutureFour from "./view/pdf.v.pageFutureFour";
import PdfPageDebtOne from "./view/pdf.v.pageDebtOne";
import PdfPageDebtTwo from "./view/pdf.v.pageDebtTwo";
import PdfPageDebtThree from "./view/pdf.v.pageDebtThree";
import PdfPageTaxOne from "./view/pdf.v.pageTaxOne";
import PdfPageTaxTwo from "./view/pdf.v.pageTaxTwo";
import PdfPageTaxThree from "./view/pdf.v.pageTaxThree";
import PdfPageEducationOne from "./view/pdf.v.pageEducation";
import PdfPageRetireOne from "./view/pdf.v.pageRetireOne";
import PdfPageRetireTwo from "./view/pdf.v.pageRetireTwo";
import PdfPageRetireThree from "./view/pdf.v.pageRetireThree";
import PdfPageOverviewOne from "./view/pdf.v.pageOverviewOne";
import PdfPageOverviewTwo from "./view/pdf.v.pageOverviewTwo";
import PdfPageOverviewThree from "./view/pdf.v.pageOverviewThree";
import PdfPageAssumeOne from "./view/pdf.v.pageAssumeOne";
import PdfPageOVIncomeOne from "./view/pdf.v.pageOVIncomeOne";
import PdfPageOVDebtOne from "./view/pdf.v.pageOVDebtOne";
import PdfPageOVExpenseOne from "./view/pdf.v.pageOVExpenseOne";
import PdfPageOVPropertyOne from "./view/pdf.v.pageOVPropertyOne";
import PdfPageAppendix from "./view/pdf.v.pageAppendix";
import PdfPageAppendixEducationOne from "./view/pdf.v.pageAppendixEducationOne";
import PdfPageInvestTwo from "./view/pdf.v.pageInvestTwo";
import PdfPageBalananceFive from "./view/pdf.v.pageBalanceFive";
import PdfPageInvestThree from "./view/pdf.v.pageInvestThree";
import PdfPageInvestFour from "./view/pdf.v.pageInvestFour";
import PdfPageInvestFive from "./view/pdf.v.pageInvestFive";
import PdfPageDebtOneSum from "./view/pdf.v.pageDebtOneSum";
import PdfPageDebtFour from "./view/pdf.v.pageDebtFour";

const PDFPreviewer = () => {
  const [pdfDataUri, setPdfDataUri] = useState(null);

  const generatePDF = async () => {
    const input = document.getElementById("pdf-content");

    const doc = new jsPDF("p", "px", "a4");
    doc.addFileToVFS("THSarabun.ttf", fontSarabunTH);
    doc.addFont("THSarabun.ttf", "THSarabun", "normal");
    doc.setFont("THSarabun");

    const pages = input.querySelectorAll(".page");

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const isHorizontal = page.classList.contains("page-horizontal");

      const canvas = await html2canvas(page, {
        scale: 1.7,
        useCORS: true,
      });
      const image = canvas.toDataURL("image/png");

      if (i !== 0) {
        // doc.addPage(null, "p");
        if (isHorizontal) {
          doc.addPage(null, "l");
        } else {
          doc.addPage(null, "p");
        }
      }

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = Math.min(widthRatio, heightRatio);

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
    }

    // doc.setProperties({
    //   title: "invoice-pdf",
    //   subject: "This is the subject",
    //   author: "Author Name",
    //   keywords: "generated, javascript, web 2.0, ajax",
    //   creator: "kabir",
    // });

    const dataUri = doc.output("bloburi");
    setPdfDataUri(dataUri);
  };

  return (
    <div className="h-screen">
      <button onClick={generatePDF}>Generate PDF</button>
      <div id="pdf-content" className={cn(STYLE_PDF_CONTENT)}>
        <PdfPageDebtThree page={1}/>
        {/* <PdfPageDebtOneSum page={2}/>
        <PdfPageDebtTwo page={3}/>
        <PdfPageDebtThree page={4}/>
        <PdfPageDebtFour page={5} /> */}
      </div>
      {pdfDataUri && (
        <iframe
          title="Preview PDF"
          style={{ width: "100%", height: "100%", margin: "0 auto" }}
          src={pdfDataUri}
        />
      )}
    </div>
  );
};

export default PDFPreviewer;
