import { useNavigate } from "react-router-dom";
import Container from "../../../components/layout/Container";
import { HeaderTitle } from "../../../components/header/Header";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import { HeaderReport } from "../components/header.main";
import { useTaxStore } from "../../../_store/taxplanStore";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
  TableReport,
} from "../components/table.main";
import TabPanelReport from "../components/tab.main";
import { useEffect, useMemo, useState } from "react";
import { result } from "lodash";
import numeral from "numeral";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { MTButton } from "../../../components/button/MTButton";
import { cn } from "../../../utils/useFunctions";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { ICON_BAHT, ICON_GRAPH } from "../../../utils/useIcons";

const GraphSection = ({
  taxData,
  getCaltax,
  displaySumAfterTaxPaid,
  displayTaxPaid,
  displaySumTax,
  checkPercent,
}) => {
  return (
    <div>
      <div className=" px-2.5">
        <HalfChartMain
          chartWidth={"w-[180px] mt-5"}
          fontSize="text-2xl"
          text={false}
          value={`0`}
          beforevalue={getCaltax()}
          aftervalue={taxData?.after_tax_paid}
          maxvalue={displayTaxPaid}
          mtText="-mt-20"
        />
        <div className=" flex justify-center gap-2.5 w-full -mt-10 ">
          <div
            className={`bg-gray50 rounded-md py-[7px] px-2 w-20 text-mainblue text-sm font-semibold  h-6 flex gap-[5px] justify-center items-center`}
          >
            <ICON_GRAPH className="w-6 h-6 text-maincyan " />
            <span className=" flex-none"> {checkPercent()} %</span>
          </div>
          <div
            className={`bg-gray50 rounded-md py-[7px] px-2 w-20 text-mainblue text-sm font-semibold  h-6 flex gap-[5px] justify-center items-center`}
          >
            <ICON_BAHT className="w-6 h-6 text-maincyan " />
            {numeral(displaySumTax).format("0,0")}
          </div>
        </div>
        <div className="flex flex-col space-y-0.5 mt-1 text-sm">
          {[
            {
              title: "ก่อนวางแผน",
              value: String(getCaltax()) === "Infinity" ? "0" : getCaltax(),
              classNameValue: "text-mainred",
            },
            {
              title: "หลังวางแผน",
              value:
                String(displaySumAfterTaxPaid()) === "Infinity"
                  ? "0"
                  : displaySumAfterTaxPaid(),
              classNameValue: "text-maingreen",
            },
            {
              title: "ภาษีหัก ณ ที่จ่าย",
              value: taxData?.tax_withholding,
              classNameValue: "text-mainblue",
            },
          ].map((item, index) => {
            return (
              <div key={index} className="flex justify-between">
                <div className=" font-medium text-black">{item?.title}</div>
                <div className={cn("  font-medium")}>
                  <span
                    className={cn(" mr-2.5 font-bold", item?.classNameValue)}
                  >
                    {" "}
                    {numeral(item?.value).format("0,0")}
                  </span>{" "}
                  บาท
                </div>
              </div>
            );
          })}
        </div>
        <div className=" flex justify-center items-center gap-[5px] font-bold mt-1 text-sm">
          <div className=" text-maincyan">ภาษีหักที่ต้องจ่าย</div>
          <div className=" text-mainorange">
            {numeral(taxData?.final_tax)?.format("0,0")} บาท
          </div>
        </div>
      </div>
    </div>
  );
};

const TableTax = ({ taxData, table_income, table_expense }) => {
  return (
    <div className="bg-gray50 rounded-[20px] w-full h-ful p-3">
      <TableReport
        headTable="แหล่งที่มาของรายได้"
        data={table_income}
        isHeadMonth={false}
      />
      <div className="border-b border-gray55 mt-2.5 w-full "></div>
      <TableReport
        headTable="แหล่งที่มาของรายจ่าย"
        data={table_expense}
        isHeadMonth={false}
      />
    </div>
  );
};

const ExplanSection = ({ taxData }) => {
  return (
    <div className=" text-center flex flex-col gap-[6px]">
      <div className=" text-base font-medium ">
        (รายได้ - หักค่าใช้จ่าย + หักค่าลดหย่อน ={" "}
        <span className="text-mainblue font-semibold">เงินได้สุทธิ</span>)
      </div>
      <div className="text-gray90 text-xs">
        (รายได้ - หักค่าใช้จ่าย + หักค่าลดหย่อน = เงินได้สุทธิ)
      </div>
      <div className=" text-base font-medium text-maincyan">
        เงินได้สุทธิ{" "}
        <span className=" text-mainblue font-semibold">
          {numeral(taxData?.net_income)?.format("0,0")}
        </span>
      </div>
    </div>
  );
};

const BeforeTable = ({ deductionItems, total }) => {
  return (
    <div>
      <HeaderTableReport
        title="รายการลดหย่อนต่อปี"
        // textjusify="text-center"
        monthly=""
      />
      <HeaderTitleTableReport
        img={`/images/proporty_asset2.png`}
        title={"ค่าลดหย่อนส่วนตัวและครอบครัว"}
      />
      <div className="bg-[#F9F9F9] py-1 rounded-[10px]  px-2 items-center font-semibold text-mainblue text-sm">
        {deductionItems.map((item, index) => {
          return (
            <div key={index} className={cn(" ")}>
              <div key={index} className="flex justify-between space-x-1 mt-1">
                <div className=" text-black">{item?.label}</div>
                <div>{numeral(item?.value).format("0,0")} บาท</div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-between space-x-1 mt-1 text-base">
          <div className=" text-black">รวม</div>
          <div className=" text-maincyan">
            {numeral(total).format("0,0")} บาท
          </div>
        </div>
      </div>
    </div>
  );
};

const AfterTable = ({ deductionItems2, total2 }) => {
  return (
    <div>
      <HeaderTableReport
        title="รายการลดหย่อนต่อปี"
        // textjusify="text-center"
        monthly=""
      />
      <HeaderTitleTableReport
        img={`/images/proporty_asset2.png`}
        title={"ค่าลดหย่อน/ยกเว้น ด้านการออมและการลงทุน"}
      />
      <div className="bg-[#F9F9F9] py-1 rounded-[10px]  px-2 items-center font-semibold text-mainblue text-sm">
        {deductionItems2.map((item, index) => {
          return (
            <div key={index} className={cn(" ")}>
              <div key={index} className="flex justify-between space-x-1 mt-1">
                <div className=" text-black">{item?.label}</div>
                <div>{numeral(item?.value).format("0,0")} บาท</div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-between space-x-1 mt-1 text-base">
          <div className=" text-black">รวม</div>
          <div className=" text-maincyan">
            {numeral(total2).format("0,0")} บาท
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportTaxPage = () => {
  const taxData = useTaxStore((state) => state.taxData);
  const navigate = useNavigate();
  const [isTab, setTab] = useState("before");

  const table_income = [
    // เงินเดือน

    {
      source: "รายได้จากเงินเดือน",
      yearly: taxData?.tax_income,
      icon: "/images/finance_income2.png",
      head: "เงินได้่",
    },
  ];

  const table_expense = [
    {
      source: "เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ",
      source_subtitle: "(อัตราค่าใช้จ่ายร้อยละ 50)",
      yearly: "100,000",
      icon: "/images/outcome-report.png",
      head: "หักค่าใช้จ่าย",
    },
  ];

  //ก่อน
  const deductionItems = useMemo(() => {
    const newDeductionItems = [
      {
        label: "ลดหย่อนผู้มีเงินได้",
        value: 60000 || "",
      },
      {
        label: "คู่สมรส",
        value: taxData?.tax_spouse || "",
      },
      {
        label: "ลูก",
        value: taxData?.deduct_child || "",
      },
      {
        label: "แม่",
        value: taxData?.deduct_mother || "",
      },
      {
        label: "พ่อ",
        value: taxData?.deduct_father || "",
      },
      {
        label: "เลี้ยงดูคนพิการ",
        value: taxData?.deduct_disable_people || "",
      },
      {
        label: "เลี้ยงดูคนทุพพลภาพ",
        value: taxData?.deduct_disabilities_people || "",
      },
      {
        label: "เบี้ยประกันสุขภาพบิดามารดา",
        value: taxData?.deduct_insurance_premiums_parents || "",
      },
    ];
    return newDeductionItems;
  }, [taxData]);

  const total = useMemo(() => {
    let sum_total = deductionItems.reduce((acc, item) => {
      return Number(acc) + Number(item.value);
    }, 0);
    return Number(sum_total);
  }, [taxData]);

  //หลัง
  const deductionItems2 = useMemo(() => {
    const newDeductionItems = [
      {
        label: "ประกันชีวิต",
        value: taxData?.tax_plan_insurance_life || "",
      },
      {
        label: "ประกันสุขภาพ",
        value: taxData?.tax_plan_insurance_health || "",
      },
      {
        label: "ประกันชีวิตแบบบำนาญ",
        value: taxData?.tax_plan_insurance_annuity || "",
      },
    ];
    return newDeductionItems;
  }, [taxData]);

  const total2 = useMemo(() => {
    let sum_total = deductionItems2.reduce((acc, item) => {
      return Number(acc) + Number(item.value);
    }, 0);
    return Number(sum_total);
  }, [taxData]);

  // กราฟภาษี
  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;
    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div
          className={`${STYLE_REPORT_CONTAIN} flex flex-col gap-5 mb-[30px]`}
        >
          <HeaderReport
            icon={"/images/tax-report.png"}
            title={"แผนภาษี"}
            className={" z-20"}
          />
          <GraphSection
            taxData={taxData}
            getCaltax={getCaltax}
            displaySumAfterTaxPaid={displaySumAfterTaxPaid}
            displayTaxPaid={displayTaxPaid}
            displaySumTax={displaySumTax}
            checkPercent={checkPercent}
          />
          <TableTax
            table_income={table_income}
            table_expense={table_expense}
            taxData={taxData}
          />
          <div className="bg-gray50 rounded-[20px] w-full h-ful">
            <div className=" p-3">
              <TabPanelReport
                title_tab1={"ก่อนวางแผน"}
                title_tab2={"หลังวางแผน"}
                onTab1={() => setTab("before")}
                tab1={isTab === "before" ? true : false}
                onTab2={() => setTab("after")}
                tab2={isTab === "after" ? true : false}
              />
            </div>
            <div className=" p-3 bg-[#FCFCFC] rounded-b-[20px]">
              {isTab === "before" ? (
                <div>
                  <BeforeTable deductionItems={deductionItems} total={total} />
                </div>
              ) : (
                <div>
                  <AfterTable
                    deductionItems2={deductionItems2}
                    total2={total2}
                  />
                </div>
              )}
            </div>
          </div>

          <div className=" bg-gray50 rounded-[20px] w-full h-full px-[15px] py-2.5 flex flex-col gap-[6px]">
            <div className=" flex justify-between">
              <div className=" font-medium">รายได้</div>
              <div className=" font-bold text-mainblue">
                {numeral(taxData?.tax_income)?.format("0,0")}
              </div>
            </div>
            <div className=" flex justify-between">
              <div className=" font-medium">หักค่าใช้จ่าย</div>
              <div className=" font-bold text-mainblue">
                {numeral(taxData?.deduction_expenses)?.format("0,0")}
              </div>
            </div>
            <div className=" flex justify-between">
              <div className=" font-medium">หักค่าลดหย่อน</div>
              <div className=" font-bold text-mainblue">
                {isTab === "before"
                  ? numeral(taxData?.deduct_summary)?.format("0,0")
                  : numeral(taxData?.deduct_summary_af)?.format("0,0")}
              </div>
            </div>
          </div>
          <ExplanSection taxData={taxData} />
          <div className=" flex justify-center ">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportTaxPage;
