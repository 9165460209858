import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Progress,
} from "@material-tailwind/react";
import {
  STYLE_CARD_BG,
  STYLE_INPUT,
  STYLE_INPUT_INSET,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_NUMBER_PLAN_BAR,
  STYLE_PARENT_TWO_BUTTON,
} from "../../../utils/useStyle";
import {
  CardDeduction2,
  CardDetailsTax,
  CardMain,
} from "../../../components/card/CardMain";
import { MTButton } from "../../../components/button/MTButton";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import Container from "../../../components/layout/Container";
import {
  ICON_BAHT,
  ICON_GRAPH,
  ICON_NAVIGATE,
  ICON_RIGHT,
  ICON_RLOCNG_RIGHT,
} from "../../../utils/useIcons";
import { useNavigate } from "react-router-dom";
import {
  calBadget,
  calculateTax,
  checkSum,
  getPercent,
  getcalPaid,
  getcalSum,
} from "../functions";
import numeral from "numeral";
import { useTaxStore } from "../../../_store/taxplanStore";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { ImageMain } from "../../../components/img/Img.Main";
import { ViewDetailsTaxIncome } from "./ViDetailsTax.Main";
import { first_reload } from "../functions/firstLoad";

const TaxPlanContext = createContext();
export const useTaxPlanContext = () => useContext(TaxPlanContext);

const ViewStepFourTaxPlan = ({
  onClick,
  setForm,
  form,
  error,
  setError,
  openTaxPlanInfo,
  setOpenTaxPlanInfo,
  setTab,
  tab,
}) => {
  // const form = useTaxStore((state) => state.form);
  const clearTaxData = useTaxStore((state) => state.clearTaxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [open, setOpen] = useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const navigate = useNavigate();

  const displayTaxPaid = useMemo(
    () => calculateTax(form.tax_income),
    [form?.tax_income]
  );

  const getCaltax = () => {
    let result = 0;
    result = form.tax_income - form?.deduction_expenses - form?.deduct_summary;

    return calculateTax(result);
  };

  const displaySumBudget = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf) +
      Number(form?.tax_plan_political_donate) +
      Number(form?.tax_fund_esg);
    return Number(sum);
  };

  // const displaySumBudget = useMemo(
  //   () =>
  //     calBadget(
  //       taxData?.tax_plan_insurance_life,
  //       taxData?.tax_plan_insurance_health,
  //       taxData?.tax_plan_rmf,
  //       taxData?.tax_plan_donate
  //     ),
  //   [
  //     taxData?.tax_plan_insurance_life,
  //     taxData?.tax_plan_insurance_health,
  //     taxData?.tax_plan_rmf,
  //     taxData?.tax_plan_donate,
  //   ]
  // );
  // const displaySumAfterTaxPaid = useMemo(() => {
  //   if (
  //     taxData?.tax_income -
  //     taxData?.deduction_expenses -
  //     taxData?.deduct_summary -
  //     displaySumBudget
  //   ) {
  //     return calculateTax(
  //       taxData?.tax_income -
  //         taxData?.deduction_expenses -
  //         taxData?.deduct_summary -
  //         displaySumBudget
  //     );
  //   }
  // });

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(form?.tax_income) -
      Number(form?.deduction_expenses) -
      Number(form?.deduct_summary_af);

    return calculateTax(result);
  };

  const getSumAllInsurance = useMemo(
    () => getcalSum(form?.tax_plan_insurance_life, form?.insurance_life),
    [form?.tax_plan_insurance_life, form?.insurance_life]
  );

  const getSumAllInsuranceHelth = useMemo(
    () => getcalSum(form?.tax_plan_insurance_health, form?.insurance_health),
    [form?.tax_plan_insurance_health, form?.insurance_health]
  );

  const getSumAllFund = useMemo(
    () => getcalSum(form?.tax_plan_rmf, form?.fund_provident_rmf_value),
    [form?.tax_plan_rmf, form?.fund_provident_rmf_value]
  );

  const RemainInsuaLife = useMemo(
    () => getcalPaid(getSumAllInsurance, 100000),
    [getSumAllInsurance, 100000]
  );

  const RemianFund = useMemo(
    () => getcalPaid(getSumAllFund, 100000),
    [getSumAllFund, 100000]
  );

  const RemainInsuaHealth = useMemo(
    () => getcalPaid(getSumAllInsuranceHelth, 100000),
    [getSumAllInsuranceHelth, 100000]
  );

  // คำนวณ SliderBar ก่อนวางแผน
  const getPercentBar = useMemo(
    () => getPercent(getCaltax(), displayTaxPaid),
    [getCaltax(), displayTaxPaid]
  );

  // คำนวณ SliderBar หลังวางแผน
  const getPercentBarAfter = useMemo(
    () => getPercent(displaySumAfterTaxPaid(), displayTaxPaid),
    [displaySumAfterTaxPaid(), displayTaxPaid]
  );

  // คำนวณ SliderBar ยอดรวมสรุปภาษี
  const getPercentBarFinalTax = useMemo(
    () =>
      getPercent(
        form.final_tax < 0 ? form.final_tax * -1 : form.final_tax,
        displayTaxPaid
      ),
    [form.final_tax, displayTaxPaid]
  );

  const getPercentInsuaranceLife = useMemo(
    () =>
      getPercent(form?.tax_plan_insurance_life + form?.insurance_life, 100000),
    [form?.tax_plan_insurance_life + form?.insurance_life, 100000]
  );
  const getPercentDonate = useMemo(
    () => getPercent(form?.tax_plan_donate, 100000),
    [form?.tax_plan_donate, 100000]
  );

  // Percent Sum Tax
  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - form.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };
  // Display Sum Tax
  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), form.after_tax_paid, 0, 0),
    [getCaltax(), form.after_tax_paid, 0, 0]
  );

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="first_reload">
      <div className={`my-9`}>
        {/*---------- กราฟ เปรียบเทียบ ก่อนวางแผนหลัง และ หลังวางแผน ----------*/}
        {form.final_tax > 0 && (
          <div className="mb-5">
            <HalfChartMain
              text={false}
              value={`0`}
              beforevalue={getCaltax()}
              aftervalue={form.after_tax_paid}
              maxvalue={displayTaxPaid}
            />
            <div className=" flex justify-center gap-5 w-full -mt-10">
              <div
                className={`bg-gray50 rounded-[10px] py-[7px] px-2  text-mainblue text-xl font-semibold  w-[127px] md:w-40 flex gap-[15px] justify-center items-center`}
              >
                <ICON_GRAPH className="w-6 h-6 text-maincyan " />
                <span className=" flex-none"> {checkPercent()} %</span>
              </div>
              <div
                className={`${STYLE_CARD_BG} text-mainblue text-xl font-semibold w-[127px] md:w-40 flex gap-[15px]  justify-center items-center`}
              >
                <ICON_BAHT className="w-6 h-6 text-maincyan " />
                {numeral(displaySumTax).format("0,0")}
              </div>
            </div>
          </div>
        )}

        {/*---------- คุณขอคืนภาษีได้ ----------*/}
        {form.final_tax < 0 && (
          <div className="flex flex-col items-center -mt-9 mb-5">
            <ImageMain src="../images/taxicon1.png" className=" w-20 h-20 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              คุณขอคืนภาษีได้ !
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-center gap-2">
                <div className="text-mainblue font-semibold text-sm">
                  ขอคืนภาษีได้
                </div>
                <div className="text-maincyan font-semibold text-[20px]">
                  {numeral(form.final_tax * -1)?.format("0,0")} บาท
                </div>
              </div>
              <div className="text-xs text-gray100">
                * ( {numeral(form.tax_withholding)?.format("0,0")} -{" "}
                {numeral(form.after_tax_paid)?.format("0,0")} ={" "}
                {numeral(form.final_tax * -1)?.format("0,0")} )
              </div>
            </div>
          </div>
        )}

        {/*---------- คุณไม่ต้องเสียภาษี ----------*/}
        {form.final_tax === 0 && (
          <div className="flex flex-col items-center -mt-9 mb-5">
            <ImageMain src="../images/taxicon1.png" className=" w-20 h-20 " />
            <div className=" text-mainblue font-semibold text-[20px]">
              คุณไม่ต้องเสียภาษี !
            </div>
          </div>
        )}

        {/*---------- ก่อนวางแผนหลัง (ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย) ----------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className=" text-red-500 text-sm font-semibold">
              ก่อนวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {String(getCaltax()) === "Infinity"
                ? "0"
                : numeral(getCaltax())?.format("0,0")}{" "}
              บาท
            </div>
          </div>
          <Progress value={getPercentBar} color="red" className="bg-white" />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*---------- หลังวางแผน (ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย) ----------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className="text-maingreen text-sm font-semibold">
              หลังวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {String(displaySumAfterTaxPaid()) === "Infinity"
                ? "0"
                : numeral(displaySumAfterTaxPaid())?.format("0,0")}{" "}
              บาท
            </div>
          </div>
          <Progress
            value={getPercentBarAfter}
            color="green"
            className="bg-white"
          />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*------------------ ภาษีหัก ณ ที่จ่าย -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
          <div className=" flex justify-between ">
            <div className=" text-mainblue text-sm font-semibold">
              ภาษีหัก ณ ที่จ่าย
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {numeral(form?.tax_withholding)?.format("0,0")} บาท
            </div>
          </div>
        </div>

        {/*------------------ ยอดรวมสรุปภาษีทั้งหมด -------------------*/}
        {form.final_tax > 0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-mainorange text-sm font-semibold">
                  ภาษีที่ต้องจ่าย
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>
                  {numeral(form?.final_tax)?.format("0,0")} บาท
                </div>
              </div>
              <Progress
                value={getPercentBarFinalTax}
                color="orange"
                className="bg-white"
              />
            </div>
          </div>
        )}
        {form.final_tax < 0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-blue400 text-sm font-semibold">
                  ภาษีที่ขอคืนได้
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>
                  {numeral(form?.final_tax * -1)?.format("0,0")} บาท
                </div>
              </div>
              <Progress
                value={getPercentBarFinalTax}
                color="blue"
                className="bg-white"
              />
            </div>
          </div>
        )}
        {form.final_tax === 0 && (
          <div className=" mb-4">
            <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5`}>
              <div className=" flex justify-between mb-[6px]">
                <div className=" text-blue400 text-sm font-semibold">
                  คุณไม่ต้องเสียภาษี
                </div>
                <div className={STYLE_NUMBER_PLAN_BAR}>{0} บาท</div>
              </div>
              <Progress value={0} color="blue" className="bg-white" />
            </div>
          </div>
        )}

        {/*----------------------------------------------------------*/}

        <CardMain
          icon={false}
          title="รายละเอียดหลังวางแผนภาษี"
          panel={
            <div className=" space-y-2.5 ">
              <CardDetailsTax
                title={"รายได้"}
                value={numeral(form?.tax_income)?.format("0,0")}
                path={() => {
                  setTab("income");
                }}
              />
              <CardDetailsTax
                title={"ค่าใช้จ่าย"}
                value={numeral(form?.deduction_expenses)?.format("0,0")}
                path={() => {
                  setTab("expense");
                }}
              />
              <CardDetailsTax
                title={"ค่าลดหย่อน"}
                value={numeral(form?.deduct_summary_af)?.format("0,0")}
                path={() => {
                  setTab("deduct");
                }}
              />
              <CardDetailsTax
                title={"เงินได้สุทธิ"}
                value={numeral(form?.net_income)?.format("0,0")}
                path={() => {
                  setTab("netincome");
                }}
              />
            </div>
          }
        />

        {/*-------------------- มาทำแผนให้เป็นจริงกันเถอะ ! --------------------*/}
        <FooterAfterSum />

        <div className={STYLE_PARENT_TWO_BUTTON}></div>
      </div>
    </div>
  );
};
export default ViewStepFourTaxPlan;
