import { useState } from "react";
import { ImageMain } from "../../../components/img/Img.Main";
import {
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../../utils/useStyle";
import { CardFutureCheckBox } from "../styles/Card";
import { FutureStepOne } from "./VIFuture_StepOne";
import { MTButton } from "../../../components/button/MTButton";
import { FutureStepTwo } from "./VIFuture_StepTwo";
import { FutureStepThree } from "./VIFuture_StepThree";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { useRetireStore } from "../../../_store/retireStore";
import _ from "lodash";

const FuturePlan = ({ isPlan, setIsPlan, tab, setTab }) => {
  const retireData = useRetireStore((state) => state.retireData);
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);
  const setFuturePlanData = useFuturePlanStore(
    (state) => state.setFuturePlanData
  );

  const calculateCashFlow = () => {
    let cash_flow_items = [];
    let future_keys = Object.keys(futurePlanData);
    let diff_age = futurePlanData?.age_retire - futurePlanData?.age_current;
    let sum_expenese_total = 0;
    let sum_income_total = 0;

    const futureMoreKeys = [];
    for (const future_key of future_keys) {
      if (
        futurePlanData[future_key]?.length > 0 &&
        future_key !== "cash_flow_items" &&
        future_key !== "cash_flow_keys"
      ) {
        futureMoreKeys.push(future_key);
      }
    }

    for (let index = 0; index < diff_age + 1; index++) {
      const re_salary_peryear = futurePlanData?.salary * 12;
      const re_outcome_peryear = futurePlanData?.out_come * 12;
      const age = futurePlanData?.age_current + index;

      const income_peryear =
        re_salary_peryear * (1 + futurePlanData?.salary_peryear / 100) ** index;
      const outcome_peryear =
        re_outcome_peryear *
        (1 + futurePlanData?.out_come_peryear / 100) ** index;
      const remain = income_peryear - outcome_peryear;
      const prev_remain = index === 0 ? 0 : cash_flow_items[index - 1].remain;
      const remain_accumulate = prev_remain + remain;

      let createItem = {
        index,
        age,
        income_peryear,
        outcome_peryear,
        remain,
        remain_accumulate,
      };

      const itemKeys = Object.keys(createItem);
      for (const itemKey of itemKeys) {
        if (_.isNaN(createItem[itemKey])) {
          createItem[itemKey] = 0;
        }
      }

      for (const futureMoreKey of futureMoreKeys) {
        if (
          [`expenese_home_items`, `expenese_car_items`].includes(futureMoreKey)
        ) {
          const year = futurePlanData[futureMoreKey][0]?.year;
          const end_year =
            futurePlanData[futureMoreKey][0]?.year +
            futurePlanData[futureMoreKey][0]?.period -
            1;

          let item_more_amount =
            futurePlanData[futureMoreKey][0]?.sum_amount_per_period;

          if (index >= year && end_year >= index) {
            const prevItem = index === 0 ? 0 : cash_flow_items[index - 1];

            if (!prevItem?.[futureMoreKey]) {
              item_more_amount += futurePlanData[futureMoreKey][0]?.down;
            }
            createItem = {
              ...createItem,
              [futureMoreKey]: item_more_amount,
            };
          } else {
            createItem = {
              ...createItem,
              [futureMoreKey]: 0,
            };
          }
        } else {
          const year = futurePlanData[futureMoreKey][0]?.year;

          if (index === year) {
            let item_more_amount = futurePlanData[futureMoreKey].reduce(
              (acc, item) => {
                return acc + item?.amount;
              },
              0
            );
            createItem = {
              ...createItem,
              [futureMoreKey]: item_more_amount,
            };
          } else {
            createItem = {
              ...createItem,
              [futureMoreKey]: 0,
            };
          }
        }
      }

      cash_flow_items.push(createItem);
    }

    for (const futureMoreKey of futureMoreKeys) {
      if (futureMoreKey.startsWith("expenese")) {
        sum_expenese_total = cash_flow_items.reduce((acc, item) => {
          return acc + item[futureMoreKey];
        }, 0);
      } else if (futureMoreKey.startsWith("income")) {
        sum_income_total = cash_flow_items.reduce((acc, item) => {
          return acc + item[futureMoreKey];
        }, 0);
      }
    }

    let sum_diff_total = sum_income_total - sum_expenese_total;

    setFuturePlanData({
      ...futurePlanData,
      sum_expenese_total,
      sum_income_total,
      sum_diff_total,
      cash_flow_keys: futureMoreKeys,
      cash_flow_items,
    });
  };

  return (
    <div className={`${STYLE_SPACE_GRAY_MENU} ${STYLE_PADDING_MAIN}`}>
      {tab === 0 && <FutureStepOne />}
      {tab === 1 && <FutureStepTwo />}
      {tab === 2 && <FutureStepThree />}
      <div className=" flex justify-center gap-4 mb-10 px-5">
        {tab === 2 ? (
          <MTButton
            text="ย้อนกลับ"
            color="border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setTab((prev) => prev - 1);
            }}
          />
        ) : (
          <div className=" flex justify-center items-center gap-2">
            <MTButton
              text="ย้อนกลับ"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                tab === 0 ? setIsPlan(false) : setTab((prev) => prev - 1);
              }}
            />
            <MTButton
              text={"ถัดไป"}
              color="bg-maincyan"
              onClick={(e) => {
                if (tab === 1) {
                  calculateCashFlow();
                }
                setTab((prev) => (tab === 2 ? false : prev + 1));
              }}
            />
          </div>
        )}
      </div>
      {tab === 2 && <FooterAfterSum />}
    </div>
  );
};
export default FuturePlan;
