import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useInvestStore } from "../../../_store/investStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useSavingStore } from "../../../_store/savingStore";

function PdfPageSavingOne({ page }) {
  const balanceData = useBalanceStore((state) => state?.balanceData);
  const investData = useInvestStore((state) => state.investData);
  const savingData = useSavingStore((state) => state.savingData);

  const dataAge = () => {
    let sum = [];
    for (let i = 1; i <= savingData?.saving_per_year; i++) {
      sum.push(i);
    }
    return sum;
  };
  const getSavingYield = () => {
    let sum = 0;
    sum = savingData?.saving_yield / 100 / 12;
    return Number(sum);
  };
  const getSavingYear = () => {
    let sum = 0;
    sum =
      (savingData?.saving_money_year *
        (Math.pow(1 + getSavingYield(), savingData?.saving_per_year) - 1)) /
      getSavingYield();

    return Number(sum);
  };
  const getSavingYieldYear = () => {
    let sum = 0;
    sum =
      getSavingYear() -
      savingData?.saving_money_year * savingData?.saving_per_year;
    return Number(sum);
  };
  const getSum = () => {
    let results = [];
    let saving = savingData?.saving_money_year;
    for (let i = 1; i <= savingData?.saving_per_year; i++) {
      if (i === 0) {
        results.push(0);
      } else if (i === 1) {
        results.push(saving);
      } else {
        const newSaving =
          (saving * (Math.pow(1 + getSavingYield(), i) - 1)) / getSavingYield();
        results.push(newSaving);
      }
    }
    return results;
  };

  const dataExpenseAge = () => {
    let sum = [];
    for (let i = 1; i <= savingData?.expenses_per_year; i++) {
      sum.push(i);
    }
    return sum;
  };
  const getExpensesYield = () => {
    let sum = 0;
    sum = savingData?.expenses_invest_yield / 12 / 100;
    return Number(sum);
  };
  const getExpensesYear = () => {
    let sum = 0;
    sum =
      (savingData?.expenses_reduce_year *
        (Math.pow(1 + getExpensesYield(), savingData?.expenses_per_year) - 1)) /
      getExpensesYield();
    return Number(sum);
  };
  const getExpensesYieldYear = () => {
    let sum = 0;
    sum =
      getExpensesYear() -
      savingData?.expenses_reduce_year * savingData?.expenses_per_year;
    return Number(sum);
  };
  const getExpenseSum = () => {
    let results = [];
    let expenses = savingData?.expenses_reduce_year;

    for (let i = 0; i <= savingData?.expenses_per_year; i++) {
      if (i === 0) {
        results.push(0);
      } else if (i === 1) {
        results.push(expenses);
      } else {
        const newSaving =
          (expenses * (Math.pow(1 + getExpensesYield(), i) - 1)) /
          getExpensesYield();
        results.push(newSaving);
      }
    }
    return results;
  };

  const expensesLabels = dataExpenseAge();
  const expensesDataAmount = getExpenseSum();

  const dataAmount = getSum();
  const labels = dataAge();

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="การออมวางแผนการออม"
        icon="/images/saving_save.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* การออม */}
      <PdfCard className={"flex flex-col space-y-3"}>
        <PdfCardWhite className={cn("flex flex-col space-y-2 px-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-left")}>
            ผลลัพธ์วางแผนการออม
          </div>
          <div className="flex justify-center">
            <LineChartSavingMain
              labels={labels}
              dataPlan={dataAmount}
              head={`เงินออม`}
              title_x=""
              title_y=""
            />
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>อีก</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {savingData?.saving_per_year}
            </div>
            <div>ปี ข้างหน้า</div>
            <div>ด้วยการออมเงินในอัตราดอกเบี้ยทบต้น</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {savingData?.saving_yield}
            </div>
            <div>% ต่อปี (ลงทุนปลายงวด)</div>
            <div>
              เดือนละ{" "}
              <span className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {numeral(savingData?.saving_money_month).format("0,0")}
              </span>{" "}
              บาท{" "}
            </div>
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>จะมีเงินเก็บจำนวน</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(getSavingYear()).format("0,0.00")}
            </div>
            <div>บาท</div>
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>เป็นดอกเบี้ย</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(getSavingYieldYear())?.format("0,0.00")}
            </div>
            <div>บาท</div>
          </div>
        </PdfCardWhite>
      </PdfCard>

      {/* วางแผนลดค่าใช้จ่าย */}
      <PdfHeaderText
        title="วางแผนลดค่าใช้จ่าย"
        icon="/images/saving_expenses.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      <PdfCard className={"flex flex-col space-y-3"}>
        <PdfCardWhite className={cn("flex flex-col space-y-2 px-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-left")}>
            ผลลัพธ์วางแผนลดค่าใช้จ่าย
          </div>
          <div className="flex justify-center">
            <LineChartSavingMain
              labels={expensesLabels}
              dataPlan={expensesDataAmount}
              head={`วางแผนลดค่าใช้จ่าย`}
              title_x=""
              title_y=""
            />
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>อีก</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {savingData?.expenses_per_year}
            </div>
            <div>ปี ข้างหน้า</div>
            <div>ด้วยการออมเงินในอัตราดอกเบี้ยทบต้น</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {savingData?.expenses_invest_yield}
            </div>
            <div>% ต่อปี (ลงทุนปลายงวด)</div>

            <div>
              เดือนละ{" "}
              <span className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {numeral(savingData?.expenses_reduce_month).format("0,0")}
              </span>{" "}
              บาท{" "}
            </div>
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>จะมีเงินเก็บจำนวน</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(getExpensesYear()).format("0,0.00")}
            </div>
            <div>บาท</div>
          </div>
          <div
            className={cn(
              "flex items-center space-x-1",
              STYLE_PDF_TEXT_NORMAL_11
            )}
          >
            <div>เป็นดอกเบี้ย</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(getExpensesYieldYear())?.format("0,0.00")}
            </div>
            <div>บาท</div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageSavingOne;
