import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../../components/header/Header";
import Container from "../../../../components/layout/Container";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import {
  STYLE_INPUT,
  STYLE_INPUT_CARD_BALANCE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
  STYLE_SPACE_TAX_PROFILE,
  STYLE_TEXT_BLUE,
} from "../../../../utils/useStyle";
import { CardMain } from "../../../../components/card/CardMain";
import {
  InputMain,
  InputWithLabel,
} from "../../../../components/input/InputMain";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";
import { useState } from "react";
import { ICON_INFO } from "../../../../utils/useIcons";
import { CheckBoxSecond } from "../../../../components/checkbox/CheckboxSecond";
import { NumericFormat } from "react-number-format";
import DropDownMain from "../../../../components/dropdown/DropDownMain";
import { MTButton } from "../../../../components/button/MTButton";
import { option_cal_express, option_first_cal } from "../../functions/options";
import { useTaxStore } from "../../../../_store/taxplanStore";

export const SalaryInfo = ({ openSalaryInfo, setOpenSalaryInfo }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(1)"}
      text={
        <div>
          เงินได้เนื่องจากการจ้างแรงงาน เช่น เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส
          เบี้ยหวัด เงินค่าเช่าบ้าน และทรัพย์สินหรือประโยชน์ใดๆ
          ที่ได้เนื่องจากการจ้างแรงงาน เป็นต้น
          <div>
            *** กรณีได้รับเงินเดือนและค่านายหน้าจากนายจ้างผู้จ่ายเงินได้
            รายเดียวกันถือเป็นเงินได้ตามมาตรา 40(1) ***
          </div>
        </div>
      }
      open={openSalaryInfo}
      onClose={() => {
        setOpenSalaryInfo(false);
      }}
    />
  );
};

export const WithholdingTaxInfo = ({
  openWithholdingTaxInfo,
  setOpenWithholdingTaxInfo,
}) => {
  return (
    <InfoDrawer
      head={"ภาษีหัก ณ ที่จ่าย"}
      text={
        <div className=" mb-4">
          ภาษีเงินได้หัก ณ ที่จ่าย
          เป็นการจัดเก็บภาษีล่วงหน้ากำหนดให้ผู้จ่ายเงินได้มีหน้าที่หักภาษีจากเงินที่จ่ายให้แก่ผู้รับทุกครั้งที่จ่าย
          ซึ่งการหักภาษีต้องเป็นไปตามหลักเกณฑ์ วิธีการ และเงื่อนไขที่กำหนด
          หลังจากนั้นให้นำเงินส่งกรมสรรพากร
        </div>
      }
      open={openWithholdingTaxInfo}
      onClose={() => {
        setOpenWithholdingTaxInfo(false);
      }}
    />
  );
};

export const QuitJobTaxInfo = ({
  openQuitJobTaxInfo,
  setOpenQuitJobTaxInfo,
}) => {
  return (
    <InfoDrawer
      head={" เงินได้ที่นายจ้างจ่ายให้ครั้งเดียวเพราะเหตุออกจากงาน"}
      text={
        <div className="">
          เงินได้ที่นายจ้างจ่ายให้ครั้งเดียวเพราะเหตุออกจากงาน หมายถึง
          <li>
            เงินได้ที่คำนวณตามหลักเกณฑ์
            และวิธีการเช่นเดียวกับบำเหน็จตามกฎหมายว่าด้วยบำเหน็จบำนาญข้าราชการ{" "}
          </li>
          <li>
            {" "}
            เงินที่จ่ายจากกองทุนสำรองเลี้ยงชีพ หรือกองทุนบำเหน็จบำนาญข้าราชการ{" "}
          </li>
          <li>เงินชดเชยตามกฎหมายแรงงาน</li>
          <li> เงินได้ที่จ่ายให้ครั้งเดียวที่มีวิธีคำนวณต่างไปจากข้อ 1.</li>
          <div> *** ระยะเวลาการทำงานต้องไม่น้อยกว่า 5 ปี ***</div>
        </div>
      }
      open={openQuitJobTaxInfo}
      onClose={() => {
        setOpenQuitJobTaxInfo(false);
      }}
    />
  );
};

const ViewIncomeSalaryPremium = () => {
  const navigate = useNavigate();
  const [openSalaryInfo, setOpenSalaryInfo] = useState(false);
  const [openWithholdingTaxInfo, setOpenWithholdingTaxInfo] = useState(false);
  const [openQuitJobTaxInfo, setOpenQuitJobTaxInfo] = useState(false);
  const [isHasQuitJob, setIsHasQuitJob] = useState(false);
  const taxData = useTaxStore((state) => state.taxData);

  console.log("taxData", taxData);

  return (
    <div>
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            title="ข้อมูลส่วนตัวผู้เสียภาษีได้"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            <CardMain
              onClick={() => {
                setOpenSalaryInfo(true);
              }}
              icon={true}
              marginyellow="mt-3"
              title={
                <div className="">
                  เงินเดือนหรือเงินได้ตามสัญญาจ้างแรงงาน
                  <div>(มาตรา 40(1))</div>
                </div>
              }
              panel={
                <div className=" px-3 -mt-3">
                  <InputMain placeholder="20,000" />
                </div>
              }
            />
            <CardMain
              onClick={() => {
                setOpenWithholdingTaxInfo(true);
              }}
              icon={true}
              title={<div className="">ภาษีหัก ณ ที่จ่าย   </div>}
              panel={
                <div className=" px-3 -mt-3">
                  <InputMain placeholder="20,000" />
                </div>
              }
            />

            <CardMain
              border={false}
              icon={false}
              marginyellow="mt-3"
              title={
                <div className=" pt-3">
                  เงินได้ที่นายจ้างจ่ายให้ครั้งเดียวเพราะเหตุออกจากงาน
                  (กรณีไม่นำไปรวมคำนวณภาษี)
                  <div className=" flex items-center gap-1 ">
                    (มาตรา 40(1))
                    <ICON_INFO
                      className=" w-[15px] h-[15px] cursor-pointer hover:text-maincyan duration-100 flex-none "
                      onClick={() => {
                        setOpenQuitJobTaxInfo(true);
                      }}
                    />
                  </div>
                </div>
              }
              panel={
                <div className=" px-3 mt-3 ">
                  <div className="flex space-x-4">
                    <CheckBoxSecond
                      name={"quitJob"}
                      label_title={<div className=" mt-1">ไม่มี</div>}
                      isLeft={true}
                      checked={!isHasQuitJob}
                      onChange={(e) => {
                        setIsHasQuitJob(!e.target.checked);
                      }}
                    />
                    <CheckBoxSecond
                      name={"quitJob"}
                      label_title={<div className=" mt-1">มี</div>}
                      isLeft={true}
                      checked={isHasQuitJob}
                      onChange={(e) => {
                        setIsHasQuitJob(e.target.checked);
                      }}
                    />
                  </div>
                  {isHasQuitJob && (
                    <div className=" space-y-2.5 mt-2.5">
                      <InputWithLabel
                        label={<div className=" text-sm">ชื่อนายจ้าง</div>}
                        placeholder="ระบุ"
                      />
                      <InputWithLabel
                        label={<div className=" text-sm">จำนวนปีที่ทำงาน</div>}
                        placeholder="ระบุ"
                      />
                      <div className="font-semibold text-black">
                        การคำนวณเงินได้
                      </div>
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          เงินบำเหน็จฯ ข้าราชการ
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div>
                        <div className=" flex  ">
                          <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                            เงินที่จ่ายจากกองทุน เช่น กองทุนสำรองเลี้ยงชีพ กบข.{" "}
                          </div>
                          <ICON_INFO
                            className={" w-4 h-4 text-gray400 mt-0.5"}
                          />
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          เงินชดเชยตามกฎหมายแรงงานที่ได้รับการยกเว้นแล้ว
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          เงินที่จ่ายให้ครั้งเดียวมีวิธีการดำเนินการต่างจาก
                          บำเหน็จฯ ข้าราชการ
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div className="font-semibold text-black">
                        การคำนวณค่าใช้จ่าย
                      </div>
                      <DropDownMain
                        placeholder="เลือกวิธีที่ใช้คำนวณ"
                        options={option_cal_express}
                      />
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          เงินเดือนรับจากนายจ้าง
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          จำนวนปี
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                      <div className="font-semibold text-black">
                        ค่าใช้จ่ายส่วนแรกคำนวณจาก
                      </div>
                      <DropDownMain
                        placeholder="เลือกค่าใช้จ่ายส่วนแรก"
                        options={option_first_cal}
                      />
                      <div>
                        <div className={`${STYLE_TEXT_BLUE} text-sm mb-1`}>
                          ภาษีหัก ณ ที่จ่าย
                        </div>
                        <NumericFormat
                          maxLength={11}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={`${STYLE_INPUT}`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              }
            />

            {/* สรุปการคำนวณภาษีเงินได้สุทธิ  */}
            {isHasQuitJob && (
              <div className=" bg-gray50 rounded-[10px] py-4 px-2.5">
                <div className=" flex justify-between items-center">
                  <div className=" text-black text-sm font-semibold">
                    สรุปการคำนวณภาษีเงินได้สุทธิ
                  </div>
                  <div className=" text-maincyan text-xl font-medium">
                    {" "}
                    {0} บาท
                  </div>
                </div>
                <div className=" flex justify-between items-center">
                  <div className=" text-black text-sm font-semibold">
                    ภาษีคำนวณจากเงินได้สุทธิ
                  </div>
                  <div className=" text-maincyan text-xl font-medium">
                    {" "}
                    {0} บาท
                  </div>
                </div>
                <div className=" flex justify-between items-center">
                  <div className=" text-black text-sm font-semibold">
                    ภาษีที่ชำระไว้เกิน
                  </div>
                  <div className=" text-maincyan text-xl font-medium">
                    {" "}
                    {0} บาท
                  </div>
                </div>
                <div className=" flex justify-between items-center">
                  <div className=" text-black text-sm font-semibold">
                    ภาษีที่ต้องชำระเพิ่ม
                  </div>
                  <div className=" text-maincyan text-xl font-medium">
                    {" "}
                    {0} บาท
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-between gap-2.5 w-full mt-[26px] mb-[38px]">
              <MTButton
                text="ย้อนกลับ"
                color="bg-white"
                border="border border-maincyan"
                colortext="text-maincyan group-hover:text-white"
                hovercolor="hover:bg-maincyan group "
                width="w-full"
                onClick={() => {
                  NavigateGoBack(navigate);
                }}
              />
              <MTButton
                text={"บันทึก"}
                color="bg-maincyan"
                width="w-full"
                // onClick={onSubmitLogin}
              />
            </div>
          </div>
        </div>
      </Container>

      {openSalaryInfo && (
        <SalaryInfo
          openSalaryInfo={openSalaryInfo}
          setOpenSalaryInfo={setOpenSalaryInfo}
        />
      )}

      {openWithholdingTaxInfo && (
        <WithholdingTaxInfo
          openWithholdingTaxInfo={openWithholdingTaxInfo}
          setOpenWithholdingTaxInfo={setOpenWithholdingTaxInfo}
        />
      )}
      {openQuitJobTaxInfo && (
        <QuitJobTaxInfo
          openQuitJobTaxInfo={openQuitJobTaxInfo}
          setOpenQuitJobTaxInfo={setOpenQuitJobTaxInfo}
        />
      )}
    </div>
  );
};

export default ViewIncomeSalaryPremium;
