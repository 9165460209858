import React, { useEffect, useMemo, useState } from "react";
import { MTButton } from "../../../components/button/MTButton";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_TITE_DRAWER_INFO,
} from "../../../utils/useStyle";

import numeral from "numeral";
import { calculateTax } from "../functions";
import ErrorMain from "../../../components/error/Error.Main";
import { NumericFormat } from "react-number-format";
import { Drawer } from "@material-tailwind/react";
import { ICON_NAVIGATE_RIGHT } from "../../../utils/useIcons";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import ViewStepOnePremium from "./Premium/VIStep1_Premium";

export const IncomeInfo = ({ openIncomeInfo, setOpenIncomeInfo }) => {
  return (
    <InfoDrawer
      head={" เงินเดือน (ต่อปี)"}
      text={
        // " รายได้ตลอดทั้งปีจะมาจากเงินเดือนในแต่ละเดือนที่นายจ้างมีการจ่ายเป็นค่าจ้าง ลบด้วยค่าใช้จ่ายของรายได้จากงานประจำ 50% แต่ไม่เกิน 100,000 บาท ลบด้วยลดหย่อนส่วนตัว คนละ 60,000 บาท"

        <div className=" space-y-2">
          <div className={STYLE_TITE_DRAWER_INFO}>รายได้จากเงินเดือน</div>
          <li>
            เงินได้ตามมาตรา 40(1) เงินได้เนื่องจากการจ้างแรงงาน เช่น เงินเดือน
            ค่าจ้าง เบี้ยเลี้ยง โบนัส เบี้ยหวัด
            เงินค่าเช่าบ้านและสินทรัพย์สินหรือประโยชน์ใด ๆ
            ที่ได้เนื่องจากการจ้างแรงงานเป็นต้น{" "}
            <div className=" text-sm  mt-2">
              {" "}
              *** กรณีได้รับเงินเดือนและค่านายหน้าจากนายจ้างผู้จ่ายเงินได้
              รายเดียวกันถือเป็นเงินได้ตามมาตรา 40(1)
            </div>
          </li>
          <div className=" text-center pt-2 text-mainblue text-sm">
            หากมีรายได้มาตราอื่น 40(2) - 40(8)
            <div className=" text-base mt-1">
              ท่านสามารถสมัครโหมดพรีเมี่ยม{" "}
              <span
                onClick={() => {
                  window?.open(
                    "https://liff.line.me/1645278921-kWRPP32q/?accountId=assurewealth"
                  );
                }}
                className=" font-medium underline underline-offset-4 text-maincyan "
              >
                ได้ที่นี่
              </span>
            </div>
          </div>
        </div>
      }
      open={openIncomeInfo}
      onClose={() => {
        setOpenIncomeInfo(false);
      }}
    />
  );
};

const ViewStepOneTaxPlan = ({
  error,
  setError,
  form,
  setForm,
  openIncomeInfo,
  setOpenIncomeInfo,
  userData,
}) => {
  const onIncomeChange = (e) => {
    e?.preventDefault();
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    if (Number(output) < 0) return;
    if (Number(output) > 1000000000) return;
    else {
      setError({
        ...error,
        tax_income: false,
      });
    }
    const tax_paid = calculateTax(output);
    setForm({
      ...form,
      tax_income: output,
      tax_paid: tax_paid,
    });
  };

  const onWithoutTaxChange = (e) => {
    e?.preventDefault();
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : 0;
    if (isNaN(output)) return;
    if (Number(output) < 0) return;
    if (Number(output) > 1000000000) return;
    else {
      setError({
        ...error,
        tax_withholding: false,
      });
    }

    setForm({
      ...form,
      tax_withholding: output,
    });
  };

  // userData?.is_premium 

  return (
    <div className=" my-9">
      {false ? (
        //-------------------- Premium --------------------//
        <React.Fragment>
          {form?.tax_income && (
            <div className=" -mt-2 flex flex-col justify-center gap-1 items-center font-IBMPlexSansThai font-medium mb-[15px]">
              {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
                <img
                  src="/images/clarity_coin_bag_yellow.svg"
                  className=" w-[61px] h-[61px]"
                  alt=""
                />
              ) : (
                <React.Fragment>
                  {form?.tax_paid ? (
                    <img
                      src="/images/clarity_coin_bag_yellow.svg"
                      className=" w-[61px] h-[61px]"
                      alt=""
                    />
                  ) : (
                    <img
                      src="/images/clarity_coin_bag.svg"
                      className=" w-[61px] h-[61px]"
                      alt=""
                    />
                  )}
                </React.Fragment>
              )}

              <div className=" text-black text-sm text-center ">
                ภาษีที่ต้องจ่าย
              </div>

              {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
                <div className=" text-mainblue ">
                  *ท่านกรอกรายได้ต่อปีไม่ถึงเกณฑ์ที่ต้องเสียภาษี
                </div>
              ) : (
                <React.Fragment>
                  {form?.tax_paid > 0 ? (
                    <div className=" text-maincyan text-center text-md ">
                      {numeral(form?.tax_paid).format("0,0")} บาท
                    </div>
                  ) : (
                    <div className=" text-maingray text-center text-md ">
                      คำนวณจากรายได้ต่อปี
                    </div>
                  )}
                </React.Fragment>
              )}

              <div className=" text-xs font-normal text-maingray ">
                *ยังไม่ได้หักค่าใช้จ่ายและค่าลดหย่อนส่วนตัว
              </div>
            </div>
          )}
          <ViewStepOnePremium form={form} setForm={setForm} />
        </React.Fragment>
      ) : (
        //-------------------- Not Premium --------------------//
        <React.Fragment>
          <div className=" flex flex-col justify-center gap-1 items-center font-IBMPlexSansThai font-medium mb-[15px]">
            {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
              <img
                src="/images/clarity_coin_bag_yellow.svg"
                className=" w-[61px] h-[61px]"
                alt=""
              />
            ) : (
              <React.Fragment>
                {form?.tax_paid ? (
                  <img
                    src="/images/clarity_coin_bag_yellow.svg"
                    className=" w-[61px] h-[61px]"
                    alt=""
                  />
                ) : (
                  <img
                    src="/images/clarity_coin_bag.svg"
                    className=" w-[61px] h-[61px]"
                    alt=""
                  />
                )}
              </React.Fragment>
            )}

            <div className=" text-black text-sm text-center ">
              ภาษีที่ต้องจ่าย
            </div>

            {form?.tax_income > 0 && form?.tax_income <= 150000 ? (
              <div className=" text-mainblue ">
                *ท่านกรอกรายได้ต่อปีไม่ถึงเกณฑ์ที่ต้องเสียภาษี
              </div>
            ) : (
              <React.Fragment>
                {form?.tax_paid > 0 ? (
                  <div className=" text-maincyan text-center text-md ">
                    {numeral(form?.tax_paid).format("0,0")} บาท
                  </div>
                ) : (
                  <div className=" text-maingray text-center text-md ">
                    คำนวณจากรายได้ต่อปี
                  </div>
                )}
              </React.Fragment>
            )}

            <div className=" text-xs font-normal text-maingray ">
              *ยังไม่ได้หักค่าใช้จ่ายและค่าลดหย่อนส่วนตัว
            </div>
          </div>
          <div className=" space-y-5">
            <CardMain
              onClick={() => {
                setOpenIncomeInfo(true);
              }}
              icon={true}
              title="รายได้จากเงินเดือน  (ต่อปี)"
              panel={
                <div className="mt-5 px-3">
                  <NumericFormat
                    maxLength={11}
                    value={form?.tax_income}
                    placeholder="1,000,000"
                    onChange={onIncomeChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={
                      error?.tax_income ? STYLE_INPUT_ERROR : STYLE_INPUT
                    }
                  />

                  {error?.tax_income && (
                    <ErrorMain errortext="กรุณากรอกรายได้ต่อปี" />
                  )}
                  {/* <button onClick={calculateTax}>Calculate Tax</button> */}
                  <div className="text-right text-xs text-maingray mt-2.5">
                    * รายได้ที่ยังไม่ได้หักภาษี 
                  </div>
                </div>
              }
            />

            <CardMain
              onClick={() => {
                setOpenIncomeInfo(true);
              }}
              icon={false}
              title=" ภาษีที่ถูกหัก ณ ที่จ่าย / เครดิตภาษี"
              panel={
                <div className="mt-5 px-3">
                  <NumericFormat
                    maxLength={11}
                    value={
                      form?.tax_withholding <= 0 ? "" : form?.tax_withholding
                    }
                    placeholder="100,000"
                    onChange={onWithoutTaxChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={STYLE_INPUT}
                  />
                  {/* <div className="text-right text-xs text-maingray mt-2.5">
                * รายได้ที่ยังยังไม่ได้หักภาษี 
              </div> */}
                </div>
              }
            />
          </div>
        </React.Fragment>
      )}

      {openIncomeInfo && (
        <IncomeInfo
          openIncomeInfo={openIncomeInfo}
          setOpenIncomeInfo={setOpenIncomeInfo}
        />
      )}
    </div>
  );
};
export default ViewStepOneTaxPlan;
