import {
  DonutChartInvestMain,
  DonutChartInvestMain2,
  DonutChartInvestMain3,
  DonutChartInvestMain4,
} from "../../../components/chart/DonutChartMain";
import { cn } from "../../../utils/useFunctions";
import { CardResult } from "../styles/Card";

export const CardReturns = ({ items = [] }) => {
  return (
    <div className="flex bg-white mt-2 rounded p-3 flex-col space-y-2 items-center">
      {items.map((item, index) => {
        return (
          <div key={index} className={cn("flex space-x-2 text-[12px]")}>
            <div>{item?.label}</div>
            <div>{item?.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export const ViewLevelOne = () => {
  return (
    <div className=" space-y-5">
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะสั้น  0-3 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "เงินฝาก",
                  value: 50,
                },
                {
                  label: "ตราสารหนี้",
                  value: 50,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "1.23%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "0.80%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "0.40%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะกลาง  3-7 ปี"
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 90,
                },
                {
                  label: "ตราสารทุน",
                  value: 10,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "2.56%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "0.00%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-2.60%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 80,
                },
                {
                  label: "ตราสารทุน",
                  value: 20,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "2.73%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.20%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-5.20%",
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};
export const ViewLevelTwo = () => {
  return (
    <div className=" space-y-5">
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะสั้น  0-3 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "เงินฝาก",
                  value: 10,
                },
                {
                  label: "ตราสารหนี้",
                  value: 75,
                },
                {
                  label: "ตราสารทุน",
                  value: 15,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "1.34%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-0.50%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-2.30%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะกลาง  3-7 ปี"
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 70,
                },
                {
                  label: "ตราสารทุน",
                  value: 30,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "3.82%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.90%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-7.60%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 70,
                },
                {
                  label: "ตราสารทุน",
                  value: 30,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "3.82%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.90%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-7.60%",
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};

export const ViewLevelThree = () => {
  return (
    <div className=" space-y-5">
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะสั้น  0-3 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 75,
                },
                {
                  label: "ตราสารทุน",
                  value: 25,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "2.49%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.10%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-4.70%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะกลาง  3-7 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 65,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 5,
                },
                {
                  label: "ตราสารทุน",
                  value: 30,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "4.43%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.90%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-8.10%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 60,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 10,
                },
                {
                  label: "ตราสารทุน",
                  value: 30,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "4.33%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-2.50%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-9.40%",
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};

export const ViewLevelFour = () => {
  return (
    <div className=" space-y-5">
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะสั้น  0-3 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 75,
                },
                {
                  label: "ตราสารทุน",
                  value: 25,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "2.49%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.10%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-4.70%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะกลาง  3-7 ปี "
        panel={
          <div>
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 50,
                },
                {
                  label: "ตราสารทุน",
                  value: 40,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 10,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "5.41%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-3.20%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-11.80%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป  "
        panel={
          <div>
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 40,
                },
                {
                  label: "ตราสารทุน",
                  value: 40,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 20,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "5.93%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-3.70%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-13.30%",
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};

export const ViewLevelFive = () => {
  return (
    <div className=" space-y-5">
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะสั้น  0-3 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 75,
                },
                {
                  label: "ตราสารทุน",
                  value: 25,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "2.49%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-1.10%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-4.70%",
                },
              ]}
            />
          </div>
        }
      />
      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะกลาง  3-7 ปี "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารหนี้",
                  value: 50,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 40,
                },
                {
                  label: "ตราสารทุน",
                  value: 40,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "5.41%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-3.20%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-11.80%",
                },
              ]}
            />
          </div>
        }
      />

      <CardResult
        icon={false}
        title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
        panel={
          <div className="flex flex-col">
            <DonutChartInvestMain
              items={[
                {
                  label: "ตราสารทุน",
                  value: 80,
                },
                {
                  label: "สินทรัพย์ทางเลือก",
                  value: 20,
                },
              ]}
            />
            <CardReturns
              items={[
                {
                  label: "ผลตอบแทนคาดหวัง",
                  value: "8.79%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-1SD)",
                  value: "-7.50%",
                },
                {
                  label: "ผลตอบแทนปรับฐาน (-2SD)",
                  value: "-23.80%",
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};
