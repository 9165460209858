import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfBalancePropertyHeaderItemCard,
  PdfBalancePropertyItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import { STYLE_PAGE, STYLE_PDF_ITEM_TOTAL } from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";

function PdfPageBalananceOne({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const mergeOptions = useMemo(() => {
    return [
      {
        label: "สินทรัพย์สภาพคล่อง",
        key: "property_asset_list",
        key_option: "options_property_asset",
        options: options_property_asset,
        icon: "passbook.svg",
      },
      {
        label: "สินทรัพย์ที่ไม่มีตัวตน",
        key: "property_intangible_list",
        key_option: "options_property_intangible",
        options: options_property_intangible,
        icon: "contract.svg",
      },
      {
        label: "สินทรัพย์ใช้ส่วนตัว",
        key: "property_private_list",
        key_option: "options_property_private",
        options: options_property_private,
        icon: "homepdf.svg",
      },
    ];
  }, []);

  const cloneMergeOptions = _.cloneDeep([
    ...mergeOptions,
    {
      label: "สินทรัพย์เพื่อการลงทุน",
      key: "property_invest_list",
      key_option: "options_property_invest",
      options: options_property_invest,
      icon: "invest.svg",
    },
  ]);

  const propertyItems = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptions, calPropertyLocalOption);
  }, [balanceData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="งบดุล" icon="/images/balance.svg" />
      <PdfCard title={"ความมั่งคั่ง"}>
        {/* สินทรัพย์ */}
        <PdfCardWhite className={"flex flex-col"}>
          <PdfHeaderCardText
            title="รวมสินทรัพย์ทั้งหมด"
            titleEnd={`${numeral(
              balanceData?.sum_property_asset +
                balanceData?.sum_property_invest +
                balanceData?.sum_property_private +
                balanceData?.sum_property_intangible
            ).format("0,0")} บาท`}
            icon=""
            classNameRoot="mt-2"
          />

          <PdfBalancePropertyHeaderItemCard title={"แหล่งที่มาของรายจ่าย"} />
          <PdfLine className={"my-2"} />

          <div className="mx-2 font-[500] text-[11px] my-1">สินทรัพย์</div>

          {propertyItems.map((propertyItem, index) => {
            return (
              <div key={index} className="flex flex-col mx-2">
                <div className="flex space-x-2 items-center">
                  <div>
                    <img
                      src={`/images/${propertyItem?.icon}`}
                      className="bg-cover"
                    />
                  </div>
                  <div className="text-[11px] font-[500]">
                    {propertyItem?.label}
                  </div>
                </div>

                <PdfCardGray className={"my-1 flex flex-col space-y-2"}>
                  {propertyItem.items.map((item, index) => {
                    return <PdfBalancePropertyItemCard key={index} item={item} />;
                  })}
                </PdfCardGray>
              </div>
            );
          })}
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageBalananceOne;
