import numeral from "numeral";
import { ImageMain } from "../../../components/img/Img.Main";
import { ChartCashFlow } from "./VIChartCashFlow.Main";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import { useMemo } from "react";
import { toFloat2 } from "../../../utils/useFunctions";

export const FutureStepThree = ({ age, value, valueAll }) => {
  const futurePlanData = useFuturePlanStore((state) => state.futurePlanData);

  // const dataDebt = [
  //   [-500, -400, -300, -200, -100, 0, 100],
  //   [-200, -300, -100, -50, 0, 100, 200],
  //   [-1000, -200, 250, 400, 500, 600, 700],
  //   [200, 200, 300, 400, 500, 600, 700],
  //   [100, 200, 300, 400, 500, 600, 700],
  // ];

  // console.log("futurePlanData", futurePlanData);

  const merge_keys = useMemo(() => {
    let income_keys = futurePlanData.cash_flow_keys.filter((cash_flow_key) => {
      return cash_flow_key.includes("income");
    });
    let expenese_keys = futurePlanData.cash_flow_keys.filter(
      (cash_flow_key) => {
        return cash_flow_key.includes("expenese");
      }
    );
    let merge_income_keys = [
      ...income_keys,
      "income_peryear",
      "remain_accumulate",
    ];
    let merge_expenese_keys = [...expenese_keys, "outcome_peryear"];
    let merge_keys = [...merge_expenese_keys, ...merge_income_keys];
    return merge_keys;
  }, [futurePlanData]);

  const dataDebt = useMemo(() => {
    let dataDebt = [];

    for (const merge_key of merge_keys) {
      let dataDebtItems = [];
      for (const cash_flow_item of futurePlanData.cash_flow_items) {
        if (cash_flow_item[merge_key] !== undefined) {
          let amount = String(merge_key).startsWith("expenese")
            ? -cash_flow_item[merge_key]
            : cash_flow_item[merge_key];

          amount = Math.round(amount);
          if ([0, -0].includes(amount)) {
            amount = "";
          }

          dataDebtItems.push(amount);
        }
      }
      dataDebt.push(dataDebtItems);
    }

    return dataDebt;
  }, [futurePlanData]);

  const labels = useMemo(() => {
    let labels = [];
    for (const cash_flow_item of futurePlanData.cash_flow_items) {
      labels.push(cash_flow_item.age);
    }
    return labels;
  }, [futurePlanData]);

  // console.log("dataDebt", dataDebt);

  return (
    <div className=" flex flex-col justify-center items-center gap-2.5">
      <div className=" text-mainblue font-bold text-center ">
        Cash flow รายรับรายจ่ายของแผนอนาคต
      </div>
      <ChartCashFlow
        labels={labels}
        dataDebt={dataDebt}
        merge_keys={merge_keys}
        width={320}
      />
      <div className=" bg-gray50 w-full rounded-lg h-full p-3 space-y-[6px]">
        {/* <div className=" text-maingray text-sm ">
          อายุ {age} ปี ต้องหารายรับเพิ่มอีก {numeral(value)?.format?.("0,0")}{" "}
          บาท
        </div> */}
        <ImageMain
          src="../images/cashflow.png"
          className=" w-12 h-12 mx-auto"
        />
        <div className=" text-center text-mainblue text-sm ">
          เพื่อทำให้แผนอนาคตของคุณเป็นไปตามเป้าหมาย
          <div>
            คุณต้องหารายรับเพิ่มอีก
            <span className=" font-semibold">
              {" "}
              {numeral(futurePlanData?.sum_diff_total)?.format("0,0")} บาท
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
