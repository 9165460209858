import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { useUserStore } from "./userStore";

const insuranceStore = (set) => ({
  insuranceData: {
    is_plan: false,

    // tab 1
    income_salary: "",
    income_salary_per_year: "",
    income_bonus: "",

    personal_expenses_saving: 0,
    personal_expenses_tax: 0,
    personal_expenses_life_fund: 0,
    personal_expenses_social_insurance: 0,
    personal_expenses_consumption: 0,
    personal_expenses_food: 0,
    personal_expenses_entertainment: 0,
    personal_expenses_travel: 0,
    personal_expenses_life_insurance: 0,
    personal_expenses_debt: 0,

    asset_all: "",
    asset_insurance_private: "",
    asset_insurance_group: "",
    asset_invest_rmf: "",
    asset_is_social_insurance: "",
    asset_is_debt: "",
    asset_social_dead: 0,
    asset_social_retire: "",

    family_year_survive: 0,
    family_year_survive_max: 100,
    family_income_rate: 5,
    family_income_rate_max: 20,
    family_investment_rate: 1,
    family_investment_rate_max: 20,

    sum_income: "",
    sum_personal_expenses: "",
    sum_family_survive: "",
    sum_family_debt: "",
    sum_family: "",
    sum_family_invest: "",
    sum_family_remain: "",
    sum_family_five_year: "",
    sum_family_expenses: "",
    sum_insurance_fund: "",
    sum_insurance_education: "",
    sum_insurance_net: "",
    sum_insurance_more: "",
    sum_insurance_current: "",
    sum_asset: 0,
    sum_asset_current: 0,

    // tab 2
    disabled_asset_all: "",

    disabled_income_salary: "",
    disabled_income_salary_per_year: "",
    disabled_income_bonus: "",

    disabled_personal_expenses_saving: "",
    disabled_personal_expenses_tax: "",
    disabled_personal_expenses_life_fund: "",
    disabled_personal_expenses_social_insurance: "",

    disabled_current_age: "",
    disabled_dead_age: 90,
    disabled_income_year: 5,
    disabled_return_year: 1,

    sum_disabled_income: "",
    sum_disabled_insurance_net: "",
    sum_disabled_family_expenses: "",
    sum_disabled_personal_expenses: "",
    sum_disabled_protect: "",
    sum_disabled_have_to_money: "",
    sum_disabled_return: "",
    sum_disabled_remain: "",
    sum_disabled_family_five_year: "",
    sum_disabled_insurance_more: "",
    sum_disabled_insurance_current: "",
    sum_disabled_asset_current: ""
  },
  setInsuranceData: (insuranceData) => set(() => ({ insuranceData })),
  clearInsuranceData: () => set(() => ({ insuranceData: null })),
});

export const useInsuranceStore = create(
  persist(insuranceStore, {
    name: "insuranceStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
