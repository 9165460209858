import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
} from "../styles/pdf.style";
import {
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useRetireStore } from "../../../_store/retireStore";

function PdfPageRetireOne({page}) {
  const retireData = useRetireStore((state) => state.retireData);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="วางแผนเกษียณ" icon="/images/grandparents.svg" />
      <PdfCard title={"ข้อมูลก่อนวางแผน"}>
        {/* ข้อมูลก่อนวางแผน */}
        <PdfCardWhite className={"flex flex-col px-3"}>
          {/* อายุ */}
          <div className="flex items-center space-x-1">
            <div>
              <img src="/images/user_empty.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>เงินเดือน</div>
          </div>
          <PdfCardGray className="flex flex-col space-y-1 mt-2">
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>อายุปัจจุบัน</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {retireData?.age_current} ปี
              </div>
            </div>
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>อายุเกษียณ</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {retireData?.age_retire} ปี
              </div>
            </div>
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>สิ้นอายุขัย</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {retireData?.age_dead} ปี
              </div>
            </div>
          </PdfCardGray>

          {/* เงินเดือน */}
          <div className="flex items-center space-x-1 mt-2">
            <div>
              <img src="/images/money_cash_blue.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>รายจ่าย</div>
          </div>
          <PdfCardGray className="flex flex-col space-y-1 mt-2">
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                เงินเดือน (บาท/เดือน)
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {numeral(retireData?.salary).format("0,0")} บาท
              </div>
            </div>
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                เงินเดือนที่เพิ่มขึ้นต่อปี
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {retireData?.salary_peryear}%
              </div>
            </div>
          </PdfCardGray>

          {/* รายจ่าย */}
          <div className="flex items-center space-x-1 mt-2">
            <div>
              <img src="/images/money_cash_blue.svg" />
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>อายุ</div>
          </div>
          <PdfCardGray className="flex flex-col space-y-1 mt-2">
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                รายจ่าย (บาท/เดือน)
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {numeral(retireData?.out_come).format("0,0")} บาท
              </div>
            </div>
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>คงเหลือ</div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {numeral(retireData?.outcome_remain).format("0,0")} บาท
              </div>
            </div>
            <div className="flex justify-between">
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                รายจ่ายที่เพิ่มขึ้นต่อปี
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
                {retireData?.out_come_peryear}%
              </div>
            </div>
          </PdfCardGray>

          {/* เงินบำนาญ */}
          <div className="flex items-center justify-between space-x-1 mt-2">
            <div className="flex items-center space-x-1 mt-2">
              <div>
                <img src="/images/money_cash_blue.svg" />
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                บำนาญ (ต่อเดือน)
              </div>
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(retireData?.pension).format("0,0")} บาท
            </div>
          </div>

          {/* สินทรัพย์ลงทุน */}
          <div className="flex items-center justify-between space-x-1 mt-2">
            <div className="flex items-center space-x-1 mt-1">
              <div>
                <img src="/images/graph_blue.svg" />
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>สินทรัพย์ลงทุน</div>
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(retireData?.investment_assets).format("0,0")} บาท
            </div>
          </div>

          {/* ผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี (%) */}
          <div className="flex items-center justify-between space-x-1 mt-2">
            <div className="flex items-center space-x-1 mt-1">
              <div>
                <img src="/images/graph_blue.svg" />
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี (%)</div>
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {retireData?.investment_bf_retirement}%
            </div>
          </div>

          {/* ผลตอบแทนจากการลงทุนหลังเกษียณต่อปี (%) */}
          <div className="flex items-center justify-between space-x-1 mt-2">
            <div className="flex items-center space-x-1 mt-1">
              <div>
                <img src="/images/graph_blue.svg" />
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ผลตอบแทนจากการลงทุนหลังเกษียณต่อปี (%)</div>
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {retireData?.investment_af_retirement}%
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page}/>
    </div>
  );
}

export default PdfPageRetireOne;
