import {
  STYLE_BG_CIRCLE,
  STYLE_INPUT_SLIDER,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_TEXT_BLUE,
} from "../../utils/useStyle";
import { HeaderTitle } from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { NavigatePath } from "../../functions/Navigate.Goback";
import Container from "../../components/layout/Container";
import { ImageMain } from "../../components/img/Img.Main";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { UploadMain } from "../../components/upload/Upload.Main";
import { InputMain, InputWithLabel } from "../../components/input/InputMain";
import { MTButton } from "../../components/button/MTButton";
import { useUserStore } from "../../_store/userStore";
import ErrorMain from "../../components/error/Error.Main";
import {
  Button,
  Drawer,
  IconButton,
  Typography,
} from "@material-tailwind/react";

import Api from "../../api/api.main";
import { PopupMain } from "../../components/popup/PopupMain";
import { uploadData } from "aws-amplify/storage";
import { dateFormatTH, getPathURL } from "../../utils/useFunctions";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import { ICON_CALENDAR, ICON_CALENDAR2, ICON_PLUS } from "../../utils/useIcons";
import { DateTime } from "luxon";
import { range } from "lodash";
import DropDownMain from "../../components/dropdown/DropDownMain";
import { NumericFormat } from "react-number-format";
import { SliderSingle } from "../../components/slider/SliderSingle";
import { useWindowSize } from "../../utils/useWindowSize";
registerLocale("th", th);

const dataCurrentDate = () => {
  // Create a new Date object
  var currentDate = new Date();
  // Get the current year
  var currentYear = currentDate.getFullYear();

  return { dataDate: currentDate, year: currentYear };
};

const years = range(1890, new Date().getFullYear() + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const RELATION_LIST = [
  {
    value: "husband",
    label: "สามี",
  },
  {
    value: "wife",
    label: "ภรรยา",
  },
  {
    value: "child",
    label: "บุตร",
  },
  {
    value: "father",
    label: "บิดา",
  },
  {
    value: "mother",
    label: "มารดา",
  },
];

const ProfileMain = () => {
  const navigate = useNavigate();
  const [cover, setCover] = useState(null);
  const [coverDisplay, setCoverDisplay] = useState(null);

  const size = useWindowSize();

  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  // หาปีปัจจุบัน
  const dataCurrDate = dataCurrentDate();
  const currentYear = dataCurrDate.year;

  const [openDrawer, setOpenDrawer] = useState(false);

  const [isEditFamily, setIsEditFamily] = useState(false);
  const [indexEditFamily, setIndexEditFamily] = useState(null);

  const [loading, setLoading] = useState(false);

  const [formLogin, setFormLogin] = useState({
    firstname: userData.firstname,
    lastname: userData.lastname,
    birthday: userData.birthday,
    age: userData.age,
    sex: userData.sex,
    life_status: userData.life_status,
    phone_number: userData.phone_number,
    tax_id: userData.tax_id,
    image_key: userData.image_key,
    family_tree: userData.family_tree,
    healing_rights: userData.healing_rights,
    referral_code: userData.referral_code,
  });

  const [errorForm, setErrorForm] = useState({
    firstname: { isError: false, message: "" },
    lastname: { isError: false, message: "" },
    birthday: { isError: false, message: "" },
    age: { isError: false, message: "" },
    sex: { isError: false, message: "" },
    life_status: { isError: false, message: "" },
    phone_number: { isError: false, message: "" },
    tax_id: { isError: false, message: "" },
    family_tree: { isError: false, message: "" },
    healing_rights: { isError: false, message: "" },
    referral_code: { isError: false, message: "" },
  });

  //--------------------------- Form Family Tree ----------------------------//
  // Form Data Family Tree
  const [formFamilyTree, setFormFamilyTree] = useState({
    firstname: "",
    lastname: "",
    relation: {
      value: "",
      label: "",
    },
    age: 25,
  });

  const maxAgeFamily = 150;

  // Check Error
  const [errorFormFamily, setErrorFormFamily] = useState({
    firstname: { isError: false, message: "" },
    lastname: { isError: false, message: "" },
    relation: { isError: false, message: "" },
    age: { isError: false, message: "" },
  });
  //------------------------------------------------------------------------//

  // Check Error Year ห้ามเกิดในอนาคต
  const [isErrorYear, setIsErrorYear] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  //------------------------------------------------------------------------//

  // Custom Datepicker

  const CustomInputBirthday = forwardRef((props, ref) => {
    const { value, onClick } = props;

    let thaiDate = "";
    if (value) {
      const splitDate = String(value)?.split("/");
      const date = splitDate[0];
      const month = Number(splitDate[1]);
      const year = Number(splitDate[2]) + 543;
      thaiDate = `${date}/${month}/${year}`;
    }

    return (
      <label
        onClick={onClick}
        className=" relative flex items-center rounded-full w-full h-10 mt-[2px]"
      >
        <div className="px-[14px] w-full h-[40px] border border-gray-200 items-center flex rounded-full caret-transparent">
          {value ? (
            thaiDate
          ) : (
            <span className="text-gray-400">วัน/เดือน/ปี</span>
          )}
        </div>
        <ICON_CALENDAR className={" absolute mx-[14px] right-0 w-6 h-6"} />
      </label>
    );
  });

  const CustomInputBirthdayHearder = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <div className="flex items-center gap-5 justify-center">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>
        <select
          value={date ? new Date(date).getFullYear() : new Date().getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option + 543}
            </option>
          ))}
        </select>

        <select
          value={
            months[date ? new Date(date).getMonth() : new Date().getMonth()]
          }
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>
      </div>
    );
  };

  //---------------------------------------------------------------------------------//

  // Firstname
  const onChangeFirstname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      firstname: formLogin.firstname ? false : true,
    });
    return setFormLogin({ ...formLogin, firstname: value });
  };

  // Lastname
  const onChangeLastname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      lastname: formLogin.lastname ? false : true,
    });
    return setFormLogin({ ...formLogin, lastname: value });
  };

  // Birthday
  const onChangeBirthday = (date) => {
    let value = date;
    const dateFormated = DateTime.fromJSDate(new Date(value)).toFormat(
      "yyyy-MM-dd"
    );

    setErrorForm({
      ...errorForm,
      birthday: formLogin.birthday ? false : true,
      age: formLogin.age ? false : true,
    });

    let dateArray = String(dateFormated).split("-");
    let year = parseInt(dateArray[0]);

    let currentAge = currentYear - year;

    if (currentAge <= 0) {
      setIsErrorYear(true);
    } else {
      setIsErrorYear(false);
    }

    return setFormLogin({
      ...formLogin,
      birthday: value,
      age: currentAge,
    });
  };

  // Age
  const onChangeAge = (e) => {
    let value = Number(e.target.value.slice(0, 4));
    if (value === 0) {
      return setFormLogin({ ...formLogin, age: "" });
    }
    setErrorForm({
      ...errorForm,
      age: formLogin.age ? false : true,
    });
    return setFormLogin({ ...formLogin, age: value });
  };

  // Sex
  const onChangeSex = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      sex: formLogin.sex ? false : true,
    });
    return setFormLogin({ ...formLogin, sex: value });
  };

  // Phone Number
  const onChangePhoneNumber = (e) => {
    let value = e.target.value.slice(0, 10);
    setErrorForm({
      ...errorForm,
      phone_number: formLogin.phone_number ? false : true,
    });
    return setFormLogin({ ...formLogin, phone_number: value });
  };
  const validatePhoneNumber = (phoneNumber) => {
    const checkPhoneNumber = /^\d{10}$/;
    const result = checkPhoneNumber.test(phoneNumber);
    return result;
  };

  // TAX ID
  const onChangeTaxId = (e) => {
    let value = e.target.value.slice(0, 13);
    setErrorForm({
      ...errorForm,
      tax_id: false,
    });
    return setFormLogin({ ...formLogin, tax_id: value });
  };

  const onChangeHealingRight = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      healing_rights: false,
    });
    return setFormLogin({ ...formLogin, healing_rights: value });
  };

  // Referral code
  const onChangeReferral = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      referral_code: false,
    });
    return setFormLogin({ ...formLogin, referral_code: value });
  };

  //------------------------------------------------------------------------//

  const validateFormLogin = () => {
    let checkError = false;
    let localErrorForm = { ...errorForm };

    if (!formLogin.firstname) {
      localErrorForm.firstname = {
        isError: true,
        message: "กรุณากรอกชื่อ",
      };
      checkError = true;
    }

    if (!formLogin.lastname) {
      localErrorForm.lastname = {
        isError: true,
        message: "กรุณากรอกนามสกุล",
      };
      checkError = true;
    }

    if (!formLogin.birthday) {
      localErrorForm.birthday = {
        isError: true,
        message: "กรุณาระบุวันเกิด",
      };
      checkError = true;
    }

    if (!formLogin.age) {
      localErrorForm.age = {
        isError: true,
        message: "กรุณากรอกอายุ",
      };
      checkError = true;
    }

    if (!formLogin.sex) {
      localErrorForm.sex = {
        isError: true,
        message: "กรุณาระบุเพศ",
      };
      checkError = true;
    }

    if (!formLogin.healing_rights) {
      localErrorForm.healing_rights = {
        isError: true,
        message: "กรุณาระบุสิทธิในการเบิกค่ารักษาพยาบาล",
      };
      checkError = true;
    }

    if (!formLogin.referral_code) {
      localErrorForm.referral_code = {
        isError: true,
        message: "กรุณาระบุ Referral code",
      };
      checkError = true;
    }

    const checkPhoneNumber = validatePhoneNumber(formLogin.phone_number);
    if (!formLogin.phone_number || !checkPhoneNumber) {
      localErrorForm.phone_number = {
        isError: true,
        message: "กรุณากรอกเบอร์โทร",
      };
      checkError = true;
    }

    setErrorForm(localErrorForm);
    return checkError;
  };

  //--------------------------------- onChang FamilyTree ---------------------------------------//

  // Firstname Family
  const onChangeFirstnameFamily = (e) => {
    let value = e.target.value;
    setErrorFormFamily({
      ...errorFormFamily,
      firstname: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, firstname: value });
  };

  // Lastname Family
  const onChangeLastnameFamily = (e) => {
    let value = e.target.value;
    setErrorFormFamily({
      ...errorFormFamily,
      lastname: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, lastname: value });
  };

  // Relation Family
  const onChangeSelectedRelation = (data) => {
    let value = data;
    setErrorFormFamily({
      ...errorFormFamily,
      relation: false,
    });
    return setFormFamilyTree({ ...formFamilyTree, relation: value });
  };

  // Age Family
  const onChangeAgeFamily = (e) => {
    let value = Number(e.target.value);
    setErrorFormFamily({
      ...errorFormFamily,
      age: false,
    });
    if (value > maxAgeFamily) {
      return setFormFamilyTree({ ...formFamilyTree, age: maxAgeFamily });
    }
    return setFormFamilyTree({ ...formFamilyTree, age: value });
  };

  //------------------------------------------------------------------------//

  const validateFormFamilyTree = () => {
    let checkErrorFamily = false;
    let localErrorFormFamily = { ...errorFormFamily };

    if (!formFamilyTree.firstname) {
      localErrorFormFamily.firstname = {
        isError: true,
        message: "กรุณากรอกชื่อ",
      };
      checkErrorFamily = true;
    }

    if (!formFamilyTree.lastname) {
      localErrorFormFamily.lastname = {
        isError: true,
        message: "กรุณากรอกนามสกุล",
      };
      checkErrorFamily = true;
    }
    if (!formFamilyTree.relation.value) {
      localErrorFormFamily.relation = {
        isError: true,
        message: "กรุณาระบุความสัมพันธ์",
      };
      checkErrorFamily = true;
    }

    if (formFamilyTree.age <= 0) {
      localErrorFormFamily.age = {
        isError: true,
        message: "กรุณากรอกอายุให้ถูกต้อง",
      };
      checkErrorFamily = true;
    }

    setErrorFormFamily(localErrorFormFamily);
    return checkErrorFamily;
  };

  //------------------------------------------------------------------------//

  // Submit
  const onSubmitForm = async () => {
    const isError = validateFormLogin();
    if (isError) {
      return;
    }
    try {
      setLoading(true);
      // const dateFormated = DateTime.fromJSDate(
      //   new Date(formLogin.birthday)
      // ).toFormat("yyyy-MM-dd");

      let imageKey = userData.image_key;
      if (cover) {
        const extension = cover.name.split(".").pop();
        imageKey = await uploadData({
          key: `${new Date().getTime()}.${extension}`,
          data: cover,
        }).result;
        imageKey = imageKey.key;
      }

      let payload = {
        ...formLogin,
        first_login: false,
        image_key: imageKey,
        // birthday: dateFormated,
      };

      const id = userData._id;

      await Api.put(`profiles/${id}`, payload);

      const { data: userDataApi } = await Api.get(`profiles/${id}`);
      const currentUserData = userDataApi.profile;
      setUserData(currentUserData);
      setOpenAlert(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDrawer = () => {
    setErrorFormFamily({
      firstname: { isError: false, message: "" },
      lastname: { isError: false, message: "" },
      relation: { isError: false, message: "" },
      age: { isError: false, message: "" },
    });
    setOpenDrawer(!openDrawer);
  };

  // Add
  const onClickAddFamily = () => {
    console.log("Add Family");
    handleOpenDrawer();
  };
  const onClickConfirmAddFamily = () => {
    const isError = validateFormFamilyTree();
    if (isError) {
      return;
    }
    let tempDataFamilyTree = [...formLogin.family_tree, formFamilyTree];
    setFormLogin({ ...formLogin, family_tree: tempDataFamilyTree });
    setFormFamilyTree({
      firstname: "",
      lastname: "",
      relation: {
        value: "",
        label: "",
      },
      age: 25,
    });
    handleOpenDrawer();
  };

  // Edit
  const onClickEditFamily = (data, index) => {
    console.log("Edit Family");
    setIsEditFamily(true);
    setIndexEditFamily(index);
    setFormFamilyTree(data);
    handleOpenDrawer();
  };
  const onClickConfirmFormEditFamily = () => {
    const isError = validateFormFamilyTree();
    if (isError) {
      return;
    }
    let tempDataFamilyTree = [...formLogin.family_tree];
    tempDataFamilyTree[indexEditFamily] = formFamilyTree;
    setFormLogin({ ...formLogin, family_tree: tempDataFamilyTree });
    setFormFamilyTree({
      firstname: "",
      lastname: "",
      relation: {
        value: "",
        label: "",
      },
      age: 25,
    });
    setIndexEditFamily(null);
    setIsEditFamily(false);
    handleOpenDrawer();
  };

  // Delete
  const onClickDeleteFamily = () => {
    let tempDataFamilyTree = [...formLogin.family_tree];
    tempDataFamilyTree.splice(indexEditFamily, 1);
    setFormLogin({ ...formLogin, family_tree: tempDataFamilyTree });
    setFormFamilyTree({
      firstname: "",
      lastname: "",
      relation: {
        value: "",
        label: "",
      },
      age: 25,
    });
    setIndexEditFamily(null);
    setIsEditFamily(false);
    handleOpenDrawer();
  };

  // console.log("userData : ", userData);
  // console.log("formLogin : ", formLogin);
  // console.log("formFamilyTree : ", formFamilyTree);

  return (
    <React.Fragment>
      <Container>
        <div
          style={{
            backgroundImage: `url(/images/circle_right.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
          className={` ${STYLE_BG_CIRCLE} `}
        >
          <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
            <HeaderTitle
              isGoback
              onClickGoBack={() => {
                NavigatePath(navigate, "/");
              }}
              title={`ข้อมูลผู้วางแผน`}
            />
            <div className=" xxs:px-4 xs:px-7  ">
              {/*---------- รูปโปรไฟล์ ----------*/}
              <div className=" flex flex-col gap-2 justify-center items-center mt-5">
                <ImageMain
                  className=" bg-gray30 w-[100px] h-[100px] rounded-[10px] object-cover"
                  src={
                    coverDisplay
                      ? coverDisplay
                      : userData.image_key
                      ? getPathURL(userData.image_key)
                      : "/images/user_profile.png"
                  }
                />
                <UploadMain
                  value={cover}
                  setValue={setCover}
                  setValueDisplay={setCoverDisplay}
                />
              </div>

              {/*---------- วันหมดอายุ ----------*/}
              <div className=" flex items-center justify-center gap-2.5 mt-5 ">
                <div>ใช้งานได้อีก</div>
                <div className=" flex items-center gap-1">
                  <ICON_CALENDAR2 className=" w-4 h-4 " />
                  <span className=" text-mainblue font-medium">
                    วันที่{" "}
                    {userData?.createdAt && dateFormatTH(userData?.createdAt)}
                  </span>
                </div>
              </div>

              <div className=" space-y-5">
                {/*---------- ชื่อจริง ----------*/}
                <div>
                  <InputWithLabel
                    className={`${
                      errorForm.firstname.isError && "border-red-500"
                    }  focus:outline-none  focus:border-maincyan `}
                    label={`ชื่อ`}
                    placeholder="ระบุ"
                    value={formLogin?.firstname}
                    onChange={onChangeFirstname}
                  />
                  {errorForm.firstname.isError && (
                    <ErrorMain errortext={errorForm.firstname.message} />
                  )}
                </div>

                {/*---------- นามสกุล ----------*/}
                <div>
                  <InputWithLabel
                    className={`${
                      errorForm.lastname.isError && "border-red-500"
                    }  focus:outline-none  focus:border-maincyan `}
                    label={`นามสกุล`}
                    placeholder="ระบุ"
                    value={formLogin?.lastname}
                    onChange={onChangeLastname}
                  />
                  {errorForm.lastname.isError && (
                    <ErrorMain errortext={errorForm.lastname.message} />
                  )}
                </div>

                {/*---------- อีเมล ----------*/}
                <InputWithLabel
                  className="  focus:outline-none  focus:border-maincyan "
                  label={`อีเมล`}
                  value={userData?.email || ""}
                  disabled={true}
                />

                {/*---------- วันเกิด ----------*/}
                <div>
                  <div className={STYLE_TEXT_BLUE}>วันเกิด</div>
                  <div
                    className={`flex items-center bg-gray-50 px-[14px] w-full h-[40px] border border-gray-200 rounded-full`}
                  >
                    {dateFormatTH(userData?.birthday)}
                  </div>
                  {/* <InputWithLabel
                  type="date"
                  className="  focus:outline-none  focus:border-maincyan "
                  label={`วันเกิด`}
                  value={userData?.birthday || ""}
                  disabled={true}
                /> */}
                </div>

                {/*---------- อายุ ----------*/}
                <InputWithLabel
                  type="number"
                  className="  focus:outline-none  focus:border-maincyan "
                  label={`อายุ`}
                  value={userData?.age || ""}
                  disabled={true}
                />

                {/*---------- เพศ ----------*/}
                <div>
                  <div className={STYLE_TEXT_BLUE}>เพศ</div>
                  <div
                    className={`bg-gray-50 flex items-center gap-2.5 px-[14px] w-full h-[40px] border border-gray-200 rounded-full`}
                  >
                    {formLogin?.sex === "ชาย" ? (
                      <img src="/images/icon_male.png" className="w-6 h-6" />
                    ) : (
                      <img src="/images/icon_female.png" className="w-6 h-6" />
                    )}
                    {formLogin?.sex}
                  </div>
                </div>

                {/*---------- สถานะ ----------*/}
                <div>
                  <div className={STYLE_TEXT_BLUE}>สถานะ</div>
                  <div
                    className={`bg-gray-50 flex items-center px-[14px] w-full h-[40px] border border-gray-200 rounded-full`}
                  >
                    {formLogin?.life_status}
                  </div>
                </div>

                {/*---------- เบอร์โทร ----------*/}
                <div>
                  <InputWithLabel
                    type="number"
                    className={`${
                      errorForm.phone_number.isError && "border-red-500"
                    }  focus:outline-none  focus:border-maincyan `}
                    label={`เบอร์โทร`}
                    placeholder="ระบุ"
                    value={formLogin.phone_number}
                    onChange={onChangePhoneNumber}
                  />
                  {errorForm.phone_number.isError && (
                    <ErrorMain errortext={errorForm.phone_number.message} />
                  )}
                </div>

                {/*---------- เลขประจำตัวผู้เสียภาษีอากร ----------*/}
                <div>
                  <InputWithLabel
                    type="number"
                    className={`focus:outline-none  focus:border-maincyan `}
                    label={`เลขประจำตัวผู้เสียภาษีอากร`}
                    placeholder="เช่น 0994000158441"
                    value={formLogin.tax_id}
                    onChange={onChangeTaxId}
                  />
                </div>

                {/*---------- สิทธิในการเบิกค่ารักษาพยาบาล ----------*/}
                <div>
                  <InputWithLabel
                    type="text"
                    className={`focus:outline-none  focus:border-maincyan `}
                    label={`สิทธิในการเบิกค่ารักษาพยาบาล`}
                    placeholder="ระบุ"
                    value={formLogin?.healing_rights}
                    onChange={onChangeHealingRight}
                  />
                </div>

                {/*---------- Referral code ----------*/}
                <div>
                  <InputWithLabel
                    type="text"
                    className={`focus:outline-none  focus:border-maincyan `}
                    label={`Referral code`}
                    placeholder="ระบุ"
                    value={formLogin?.referral_code}
                    onChange={onChangeReferral}
                  />
                </div>

                {/*---------- ข้อมูลของคนที่คุณรักและห่วงใย ----------*/}
                <div>
                  <div className=" text-maincyan font-bold text-[24px] leading-relaxed mt-[36px]">
                    ข้อมูลครอบครัว
                  </div>
                  <div className="flex items-center justify-between">
                    <div className={STYLE_TEXT_BLUE}>
                      ข้อมูลของคนที่คุณรักและห่วงใย
                    </div>
                    <MTButton
                      text={"เพิ่ม"}
                      color="bg-maincyan"
                      iconleft
                      icon={<ICON_PLUS className={`w-5 h-5 -mr-4`} />}
                      width="w-[78px]"
                      onClick={onClickAddFamily}
                    />
                  </div>
                  <div className="grid grid-cols-4 my-3 px-2.5 py-2.5 gap-[5px]  bg-[#DCEBEB] rounded-[10px] text-mainblue text-sm font-medium">
                    <div>ชื่อ</div>
                    <div>นามสกุล</div>
                    <div className="text-nowrap text-center">ความสัมพันธ์</div>
                    <div className="text-right">อายุ</div>
                  </div>

                  {formLogin?.family_tree?.length > 0 ? (
                    <div className="h-[210px] overflow-y-auto mb-[32px]">
                      {formLogin.family_tree?.map((item, index) => {
                        return (
                          <div
                            onClick={() => onClickEditFamily(item, index)}
                            className="grid grid-cols-4 mb-2.5 px-2.5 py-2.5 gap-[5px] rounded-[10px] text-mainblue text-xs font-normal border-b hover:bg-greenopacity"
                          >
                            <div>{item.firstname}</div>
                            <div>{item.lastname}</div>
                            <div className="text-nowrap text-center">
                              {item.relation.label}
                            </div>
                            <div className="text-right font-medium">
                              {item.age}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mb-[32px]">
                      <div className="flex flex-col items-center bg-white py-5 border border-gray55 rounded-[10px]">
                        <img
                          src="/images/family_tree.png"
                          className="w-[140px] h-[85px]"
                        />
                        <div className="text-mainblue text-sm font-medium">
                          กรุณาเพิ่มข้อมูลของคนที่คุณรักและห่วงใย
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex justify-center mb-6">
                  <MTButton
                    text="บันทึก"
                    width="w-full"
                    onClick={onSubmitForm}
                    // loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupMain
          open={openAlert}
          text={`บันทึกเสร็จสิ้น`}
          img={"/images/homeicon_smile_1.png"}
          onClick={() => {
            setOpenAlert(false);
          }}
        />
      </Container>

      {/*---------- Form FamilyTree */}
      {openDrawer && (
        <Drawer
          size={size.width}
          placement="right"
          open={openDrawer}
          // onClose={handleClose}
          className={` overflow-y-scroll !max-w-[100%]`}
        >
          <div className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl">
            <div className=" relative pt-[105px] px-[32px]">
              {/* <img
              src="/images/circle_login1.svg"
              className=" absolute top-0 right-0"
              alt=""
            /> */}
              <div className="flex flex-col gap-[13px]">
                <img
                  src="/images/logo_assure.png"
                  className=" w-fit h-[37px]"
                  alt=""
                />
                <div className=" text-maincyan font-bold text-[32px] leading-relaxed">
                  ข้อมูลครอบครัว
                </div>
              </div>
              <div className="mt-[32px]">
                {/*--------- Is Edit ----------*/}
                {isEditFamily ? (
                  <div className=" flex flex-col gap-4">
                    {/*--- ชื่อ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>ชื่อ</label>
                      <InputMain
                        placeholder="ระบุ"
                        className={
                          errorFormFamily.firstname.isError && "border-red-500"
                        }
                        value={formFamilyTree.firstname}
                        onChange={onChangeFirstnameFamily}
                      />
                      {errorFormFamily.firstname.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.firstname.message}
                        />
                      )}
                    </div>

                    {/*--- นามสกุล ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>นามสกุล</label>
                      <InputMain
                        placeholder="ระบุ"
                        className={
                          errorFormFamily.lastname.isError && "border-red-500"
                        }
                        value={formFamilyTree.lastname}
                        onChange={onChangeLastnameFamily}
                      />
                      {errorFormFamily.lastname.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.lastname.message}
                        />
                      )}
                    </div>

                    {/*--- ความสัมพันธ์ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>ความสัมพันธ์</label>
                      <div
                        className={`border rounded-full ${
                          errorFormFamily.relation.isError
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                      >
                        <DropDownMain
                          placeholder={`กรุณาเลือกความสัมพันธ์`}
                          options={RELATION_LIST}
                          value={RELATION_LIST?.filter((item) => {
                            if (item.value === formFamilyTree.relation.value)
                              return item.label;
                          })}
                          onChange={onChangeSelectedRelation}
                        />
                      </div>
                      {errorFormFamily.relation.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.relation.message}
                        />
                      )}
                    </div>

                    {/*--- อายุ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>อายุ</label>
                      <div className="flex justify-center">
                        <NumericFormat
                          // type="number"
                          maxLength={3}
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          placeholder="0"
                          className={`${STYLE_INPUT_SLIDER} mb-1.5`}
                          value={
                            formFamilyTree.age > 0 ? formFamilyTree.age : ""
                          }
                          onChange={onChangeAgeFamily}
                        />
                      </div>
                      <SliderSingle
                        year
                        placeholder="0"
                        valuemax={maxAgeFamily}
                        showInputMax={false}
                        value={formFamilyTree.age}
                        onChange={onChangeAgeFamily}
                      />
                      {errorFormFamily.age.isError && (
                        <ErrorMain errortext={errorFormFamily.age.message} />
                      )}
                    </div>
                    <div className="flex justify-between gap-2.5 w-full my-[16px]">
                      <MTButton
                        text="ย้อนกลับ"
                        color="bg-white"
                        border="border border-maincyan"
                        colortext="text-maincyan group-hover:text-white"
                        hovercolor="hover:bg-maincyan group "
                        width="w-full"
                        onClick={() => {
                          setIsEditFamily(false);
                          setFormFamilyTree({
                            firstname: "",
                            lastname: "",
                            relation: {
                              value: "",
                              label: "",
                            },
                            age: 25,
                          });
                          handleOpenDrawer();
                        }}
                      />
                      <MTButton
                        text={"บันทึก"}
                        color="bg-maincyan"
                        width="w-full"
                        onClick={onClickConfirmFormEditFamily}
                      />
                    </div>
                    <div className="mb-[38px]">
                      <MTButton
                        text={"ลบ"}
                        color="bg-gray60"
                        hovercolor="hover:bg-mainred"
                        width="w-full"
                        rounded="rounded-[10px]"
                        onClick={onClickDeleteFamily}
                      />
                    </div>
                  </div>
                ) : (
                  //*--------- Is Add ----------*/
                  <div className=" flex flex-col gap-4">
                    {/*--- ชื่อ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>ชื่อ</label>
                      <InputMain
                        placeholder="ระบุ"
                        className={
                          errorFormFamily.firstname.isError && "border-red-500"
                        }
                        value={formFamilyTree.firstname}
                        onChange={onChangeFirstnameFamily}
                      />
                      {errorFormFamily.firstname.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.firstname.message}
                        />
                      )}
                    </div>

                    {/*--- นามสกุล ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>นามสกุล</label>
                      <InputMain
                        placeholder="ระบุ"
                        className={
                          errorFormFamily.lastname.isError && "border-red-500"
                        }
                        value={formFamilyTree.lastname}
                        onChange={onChangeLastnameFamily}
                      />
                      {errorFormFamily.lastname.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.lastname.message}
                        />
                      )}
                    </div>

                    {/*--- ความสัมพันธ์ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>ความสัมพันธ์</label>
                      <div
                        className={`border rounded-full ${
                          errorFormFamily.relation.isError
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                      >
                        <DropDownMain
                          placeholder={`กรุณาเลือกความสัมพันธ์`}
                          options={RELATION_LIST}
                          value={RELATION_LIST?.filter((item) => {
                            if (item.value === formFamilyTree.relation.value)
                              return item.label;
                          })}
                          onChange={onChangeSelectedRelation}
                        />
                      </div>
                      {errorFormFamily.relation.isError && (
                        <ErrorMain
                          errortext={errorFormFamily.relation.message}
                        />
                      )}
                    </div>

                    {/*--- อายุ ---*/}
                    <div>
                      <label className={STYLE_TEXT_BLUE}>อายุ</label>
                      <div className="flex justify-center">
                        <NumericFormat
                          // type="number"
                          maxLength={3}
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          placeholder="0"
                          className={`${STYLE_INPUT_SLIDER} mb-1.5`}
                          value={
                            formFamilyTree.age > 0 ? formFamilyTree.age : ""
                          }
                          onChange={onChangeAgeFamily}
                        />
                      </div>
                      <SliderSingle
                        year
                        placeholder="0"
                        valuemax={maxAgeFamily}
                        showInputMax={false}
                        value={formFamilyTree.age}
                        onChange={onChangeAgeFamily}
                      />
                      {errorFormFamily.age.isError && (
                        <ErrorMain errortext={errorFormFamily.age.message} />
                      )}
                    </div>
                    <div className="flex justify-between gap-2.5 w-full mt-[16px] mb-[38px]">
                      <MTButton
                        text="ย้อนกลับ"
                        color="bg-white"
                        border="border border-maincyan"
                        colortext="text-maincyan group-hover:text-white"
                        hovercolor="hover:bg-maincyan group "
                        width="w-full"
                        onClick={handleOpenDrawer}
                      />
                      <MTButton
                        text={"บันทึก"}
                        color="bg-maincyan"
                        width="w-full"
                        onClick={onClickConfirmAddFamily}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </React.Fragment>
  );
};

export default ProfileMain;
