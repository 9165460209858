import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { useRetireStore } from "../_store/retireStore";

const retireData = useRetireStore.getState((state) => state.retireData);

const futurePlanStore = (set) => ({
  futurePlanData: {
    age_current: retireData?.age_current || 25,
    age_retire: retireData?.age_retire || 60,
    age_dead: retireData?.age_dead || 80,

    salary: retireData?.salary || 0,
    salary_peryear: retireData?.salary_peryear || 0,
    out_come: retireData?.out_come || 0,
    out_come_peryear: retireData?.out_come_peryear || 0,
    investment_assets: retireData?.investment_assets || 0,

    expenese_home_items: [],
    expenese_car_items: [],
    expenese_children_items: [],
    expenese_travel_items: [],
    expenese_collection_items: [],
    expenese_other_items: [],

    income_legacy_items: [],
    income_business_items: [],
    income_other_items: [],
    income_property_items: [],

    sum_expenese_total: 0,
    sum_income_total: 0,
    sum_diff_total: 0,

    cash_flow_items: [],
    cash_flow_keys: [],
  },
  setFuturePlanData: (futurePlanData) => set(() => ({ futurePlanData })),
  clearFuturePlanData: () => set(() => ({ futurePlanData: null })),
});

export const useFuturePlanStore = create(
  persist(futurePlanStore, {
    name: "futurePlanStore",
    storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
  })
);
