import { useNavigate } from "react-router-dom";
import { useBalanceStore } from "../../../_store/balanceStore";
import Container from "../../../components/layout/Container";
import { HeaderTitle } from "../../../components/header/Header";
import { HeaderReport } from "../components/header.main";
import numeral from "numeral";
import { CardBorderYellow } from "../components/cardMenu";
import {
  HeaderTableReport,
  HeaderTitleTableReport,
  TableReport,
} from "../components/table.main";
import { MTButton } from "../../../components/button/MTButton";
import TabPanelReport from "../components/tab.main";
import { useEffect, useMemo, useState } from "react";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import {
  STYLE_BODY_DETAILS,
  STYLE_BODY_REPORT,
  STYLE_REPORT_CONTAIN,
} from "../../../utils/useStyle";
import {
  options_debt_long,
  options_debt_short,
  options_expenses_fixed,
  options_expenses_invest,
  options_expenses_variable,
  options_other_income,
  options_other_income_interest,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import {
  calDebtLocalOption,
  calExpenseFixedOption,
  calExpenseVariableMonthOption,
  calExpenseVariableOption,
  calIncomeInterestLocalOption,
  calIncomeInterestOtherOption,
  calPropertyLocalOption,
  getBalanceItems,
} from "../../pdf/lib/pdf.lib";
import _ from "lodash";
import { PdfBalanceItemCard } from "../../pdf/component/pdf.c.card";
import { cn } from "../../../utils/useFunctions";

const NetWealthSection = ({ balanceData }) => {
  // console.log("first", balanceData);

  const getNetWealth = useMemo(() => {
    let result = 0;
    if (balanceData?.property_propotion) {
      result = balanceData?.debt_propotion
        ? Number(balanceData?.property_propotion) -
          Number(balanceData?.debt_propotion)
        : 0;
    }
    if (result > 0) {
      return result;
    } else {
      return 0;
    }
  }, [balanceData?.property_propotion, balanceData?.debt_propotion]);

  return (
    <CardBorderYellow
      title="ความมั่งคั่งสุทธิ"
      results={numeral(balanceData?.net_wealth).format("0,0")}
      yellowWidth="w-[119px]"
      panel={
        <div className=" p-7">
          <div className=" grid grid-cols-2 gap-2 w-full h-full">
            <div className=" w-full h-full bg-[#C4F3BD] rounded-[4px]  ">
              <div className=" flex flex-col justify-center items-center h-full ">
                <img
                  src="/images/wealth_green.svg"
                  className="w-[54px] h-[54px]"
                  alt=""
                />
                <div className=" text-mainblue font-medium text-base mt-[3px] ">
                  สินทรัพย์
                </div>
                <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                  {numeral(balanceData?.property_propotion).format("0,0")} บาท
                </div>
              </div>
            </div>
            <div className=" grid grid-rows-2 gap-[6px] ">
              <div className=" bg-[#FFCCCC] w-full h-full  rounded-[4px] py-2">
                <div className=" flex flex-col justify-center items-center h-full ">
                  <img
                    src="/images/debt_pdf2.svg"
                    className="w-[54px] h-[54px]"
                    alt=""
                  />
                  <div className=" text-mainblue font-medium text-base mt-[3px] ">
                    หนี้สิน
                  </div>
                  <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                    {numeral(balanceData?.debt_propotion).format("0,0")} บาท
                  </div>
                </div>
              </div>
              <div className=" bg-[#CEE3FF] w-full h-full rounded-[4px] py-2">
                <div className=" flex flex-col justify-center items-center h-full ">
                  <img
                    src="/images/wealth_blue.svg"
                    className="w-[54px] h-[54px]"
                    alt=""
                  />
                  <div className=" text-mainblue font-medium text-base mt-[3px] ">
                    ความมั่งคั่งสุทธิ
                  </div>
                  <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                    {numeral(getNetWealth).format("0,0")} บาท
                    {/* {numeral(balanceData?.net_wealth).format("0,0")} บาท */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const TableNetWealth = ({ propertyItems, propertyItemsDebt, balanceData }) => {
  // console.log("sss", balanceData);

  const PropertySection = () => {
    return (
      <div>
        <HeaderTableReport />
        {propertyItems.map((propertyItem, index) => {
          return (
            <div key={index} className="">
              <HeaderTitleTableReport
                img={`/images/${propertyItem?.icon}`}
                title={propertyItem?.label}
              />
              <div className={STYLE_BODY_REPORT}>
                {propertyItem.items.map((item, index) => {
                  return (
                    <div className={cn(STYLE_BODY_DETAILS)}>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? " text-base font-semibold "
                            : "font-medium "
                        }  text-left col-span-5 `}
                      >
                        {item?.label}
                      </div>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? "text-base font-bold text-maincyan"
                            : "font-semibold text-mainblue"
                        }  col-span-3 text-right  `}
                      >
                        {numeral(item?.amount_per_year).format("0,0")} บาท
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">รวมสินทรัพย์ทั้งหมด</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.property_propotion)?.format("0,0")} บาท
          </div>
        </div>
      </div>
    );
  };

  const DebtSection = () => {
    return (
      <div>
        <HeaderTableReport title="แหล่งที่มาของหนี้สิน" />
        {propertyItemsDebt.map((propertyItem, index) => {
          return (
            <div key={index} className="">
              <HeaderTitleTableReport
                img={`/images/${propertyItem?.icon}`}
                title={propertyItem?.label}
              />
              <div className={STYLE_BODY_REPORT}>
                {propertyItem.items.map((item, index) => {
                  return (
                    <div className={cn(STYLE_BODY_DETAILS)}>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? " text-base font-semibold "
                            : "font-medium "
                        }  text-left col-span-5 `}
                      >
                        {item?.label}
                      </div>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? "text-base font-bold text-maincyan"
                            : "font-semibold text-mainblue"
                        }  col-span-3 text-right  `}
                      >
                        {numeral(item?.amount_per_year).format("0,0")} บาท
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">รวมสินทรัพย์ทั้งหมด</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.debt_propotion)?.format("0,0")} บาท
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PropertySection />
      <div className=" border-b border-gray55"></div>
      <DebtSection />
    </>
  );
};

const TableCashFlow = ({
  propertyItemsIncome,
  propertyItemsExpense,
  balanceData,
}) => {
  // console.log("sss", balanceData);

  const IncomeSection = () => {
    return (
      <div>
        <HeaderTableReport title="แหล่งที่มาของรายได้" />
        {propertyItemsIncome.map((propertyItem, index) => {
          return (
            <div key={index} className="">
              <HeaderTitleTableReport
                img={`/images/${propertyItem?.icon}`}
                title={propertyItem?.label}
              />
              <div className={STYLE_BODY_REPORT}>
                {propertyItem.items.map((item, index) => {
                  return (
                    <div className={cn(STYLE_BODY_DETAILS)}>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? " text-base font-semibold "
                            : "font-medium "
                        }  text-left col-span-5 `}
                      >
                        {item?.label}
                      </div>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? "text-base font-bold text-maincyan"
                            : "font-semibold text-mainblue"
                        }  col-span-3 text-right  `}
                      >
                        {numeral(item?.amount_per_year).format("0,0")} บาท
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">รวมรายได้ทั้งหมด</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.salary_propotion)?.format("0,0")} บาท
          </div>
        </div>
      </div>
    );
  };
  const ExpenseSection = () => {
    return (
      <div>
        <HeaderTableReport title="แหล่งที่มาของรายได้" />
        {propertyItemsExpense.map((propertyItem, index) => {
          return (
            <div key={index} className="">
              <HeaderTitleTableReport
                img={`/images/${propertyItem?.icon}`}
                title={propertyItem?.label}
              />
              <div className={STYLE_BODY_REPORT}>
                {propertyItem.items.map((item, index) => {
                  return (
                    <div className={cn(STYLE_BODY_DETAILS)}>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? " text-base font-semibold "
                            : "font-medium "
                        }  text-left col-span-5 `}
                      >
                        {item?.label}
                      </div>
                      <div
                        className={` ${
                          item?.label === "รวม"
                            ? "text-base font-bold text-maincyan"
                            : "font-semibold text-mainblue"
                        }  col-span-3 text-right  `}
                      >
                        {numeral(item?.amount_per_year).format("0,0")} บาท
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">รายรับทั้งหมด</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.salary_propotion)?.format("0,0")} บาท
          </div>
        </div>
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">รายจ่ายทั้งหมด</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.expenses_propotion)?.format("0,0")} บาท
          </div>
        </div>
        <div className=" flex justify-between my-2.5">
          <div className=" text-sm font-medium">กระแสเงินสดสุทธิ</div>
          <div className="font-bold text-mainblue ">
            {numeral(balanceData?.net_wealth)?.format("0,0")} บาท
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <IncomeSection />
      <div className=" border-b border-gray55"></div>
      <ExpenseSection />
    </>
  );
};

const ReportBalancePage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);

  const [isTab, setTab] = useState("netwealth");

  // สินทรัพย์
  const mergeOptions = useMemo(() => {
    return [
      {
        label: "สินทรัพย์สภาพคล่อง",
        key: "property_asset_list",
        key_option: "options_property_asset",
        options: options_property_asset,
        icon: "proporty_asset2.png",
      },
      {
        label: "สินทรัพย์เพื่อการลงทุน",
        key: "property_invest_list",
        key_option: "options_property_invest",
        options: options_property_invest,
        icon: "proporty_invest2.png",
      },
      {
        label: "สินทรัพย์ที่ไม่มีตัวตน",
        key: "property_intangible_list",
        key_option: "options_property_intangible",
        options: options_property_intangible,
        icon: "proporty_invest3.png",
      },
      {
        label: "สินทรัพย์ใช้ส่วนตัว",
        key: "property_private_list",
        key_option: "options_property_private",
        options: options_property_private,
        icon: "proporty_Intangible2.png",
      },
    ];
  }, []);

  const propertyItems = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptions, calPropertyLocalOption);
  }, [balanceData]);

  // หนี้สิน
  const mergeOptionsDebt = useMemo(() => {
    return [
      {
        label: "หนี้สินระยะสั้น",
        key: "debt_short_list",
        key_option: "options_debt_short",
        options: options_debt_short,
        icon: "debt_short2.png",
      },
      {
        label: "หนี้สินระยะยาว",
        key: "debt_long_list",
        key_option: "options_debt_long",
        options: options_debt_long,
        icon: "debt_long2.png",
      },
    ];
  }, []);

  const propertyItemsDebt = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptionsDebt, calDebtLocalOption);
  }, [balanceData]);

  // เงินเดือน
  const mergeOptionsIncome = useMemo(() => {
    return [
      {
        label: "ดอกเบี้ย",
        key: "interest_peryear_list",
        key_option: "option_interest",
        options: options_other_income_interest,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestLocalOption,
      },
      {
        label: "โบนัส",
        key: "other_income_list",
        key_option: "option_income_other",
        options: options_other_income,
        icon: "credit-card.svg",
        calLocalOption: calIncomeInterestOtherOption,
      },
    ];
  }, []);

  const propertyItemsIncome = useMemo(() => {
    const tempPropertyItems = getBalanceItems(balanceData, mergeOptionsIncome);
    const newPropertyItems = [];

    let lastItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "รวม",
      returns: 100,
    };

    let salaryItem = {
      amount_per_year: 0,
      amount_per_month: 0,
      label: "เงินเดือนรวมทั้งปี",
      returns: 0,
    };

    if (balanceData?.salary_peryear_list?.length > 0) {
      for (const salary_peryear_item of balanceData.salary_peryear_list) {
        salaryItem.amount_per_year += +salary_peryear_item.amount;
        salaryItem.amount_per_month += +salary_peryear_item.salary_permonth;
        lastItem.amount_per_year += +salary_peryear_item.amount;
        lastItem.amount_per_month += +salary_peryear_item.salary_permonth;
      }
    }

    newPropertyItems.push(salaryItem);

    for (const tempPropertyItem of tempPropertyItems) {
      const items = tempPropertyItem.items;
      for (const item of items) {
        if (item.label === "รวม") {
          continue;
        }
        lastItem.amount_per_year += item.amount_per_year;
        lastItem.amount_per_month += item.amount_per_month;
        newPropertyItems.push(item);
      }
    }

    for (const newPropertyItem of newPropertyItems) {
      newPropertyItem.returns =
        (newPropertyItem.amount_per_year / lastItem.amount_per_year) * 100;
    }

    newPropertyItems.push(lastItem);
    return [
      {
        label: "เงินเดือน",
        icon: "salary_pdf.svg",
        items: newPropertyItems,
      },
    ];
  }, [balanceData]);

  // ค่าใช้จ่าย
  const mergeOptionsExpense = useMemo(() => {
    return [
      {
        label: "ค่าใช้จ่ายคงที่",
        key: "expenses_fixed_list",
        key_option: "options_expenses_fixed",
        options: options_expenses_fixed,
        icon: "accounting.svg",
        calLocalOption: calExpenseFixedOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
      {
        label: "ค่าใช้จ่ายเพื่อออม/ลงทุน",
        key: "expenses_invest_saving_list",
        key_option: "options_expenses_invest",
        options: options_expenses_invest,
        icon: "piggy-bank.svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: calExpenseVariableMonthOption,
      },
      {
        label: "ค่าใช้จ่ายแปรผัน",
        key: "expenses_variable_list",
        key_option: "options_expenses_variable",
        options: options_expenses_variable,
        icon: "cost (1).svg",
        calLocalOption: calExpenseVariableOption,
        calLocalMonthOption: () => {
          return 0;
        },
      },
    ];
  }, [balanceData]);

  const propertyItemsExpense = useMemo(() => {
    return getBalanceItems(balanceData, mergeOptionsExpense);
  }, [balanceData]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div id="first_reload" className="pt-[14px]">
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />

        <div className={STYLE_REPORT_CONTAIN}>
          <HeaderReport icon={"/images/balance-report.png"} title={"งบดุล"} />
          <div className=" flex flex-col gap-5 mt-[17px] ">
            <NetWealthSection balanceData={balanceData} />

            <div className="bg-gray50 rounded-[20px] w-full h-ful">
              <div className=" p-3">
                <TabPanelReport
                  title_tab1={"ความมั่งคั่ง"}
                  title_tab2={"กระแสเงินสด"}
                  onTab1={() => setTab("netwealth")}
                  tab1={isTab === "netwealth" ? true : false}
                  onTab2={() => setTab("cashflow")}
                  tab2={isTab === "cashflow" ? true : false}
                />
              </div>
              <div className=" p-3 bg-[#FCFCFC] rounded-b-[20px]">
                {isTab === "netwealth" ? (
                  <TableNetWealth
                    propertyItems={propertyItems}
                    propertyItemsDebt={propertyItemsDebt}
                    balanceData={balanceData}
                  />
                ) : (
                  <TableCashFlow
                    propertyItemsIncome={propertyItemsIncome}
                    propertyItemsExpense={propertyItemsExpense}
                    balanceData={balanceData}
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" flex justify-center mt-5">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportBalancePage;
