import numeral from "numeral";
import {
  STYLE_BODY_EDU,
  STYLE_BORDER_BODY_TAX,
  STYLE_TABLE_EDU,
} from "../../../utils/useStyle";
import { useMemo } from "react";
import _ from "lodash";
import { key_education_rank } from "../functions/options";

const ViewTableExpenses = ({
  primary_value,
  secondery_value = 457783,
  college_value = 685044,
  localEducationData,
}) => {
  const labelHeaders = useMemo(() => {
    const group_label = _.groupBy(
      localEducationData?.education_items,
      "education.key"
    );
    const group_label_keys = Object.keys(group_label);
    let label_items = [];
    let latest_item = {
      label: "ออมเงิน / ลงทุน เพื่อเป้าหมาย",
      total: 0,
    };
    for (const group_label_key of group_label_keys) {
      const items = group_label[group_label_key];
      let create_label_item = {
        label: key_education_rank?.[group_label_key],
        total: 0,
        is_invset: false,
      };
      for (const item of items) {
        create_label_item.total += item.sum_under_education;
        create_label_item.is_invset = item.is_invset;
      }
      label_items.push(create_label_item);
    }
    label_items.push(latest_item);
    return label_items;
  }, [localEducationData]);

  const rows = useMemo(() => {
    return localEducationData?.education_items;
  }, [localEducationData]);

  const footer = useMemo(() => {
    let create_footer = {
      sum_total_education: localEducationData?.sum_total_education,
      sum_total_education_accumulate:
        localEducationData?.sum_total_education_accumulate,
      sum_total_invest: localEducationData?.sum_total_invest,
      invest_keys: [],
    };
    const first_education_item = localEducationData?.education_items[0];
    create_footer.invest_keys = first_education_item?.invest_keys;
    return create_footer;
  }, [localEducationData]);

  const columnWidth = 128; // Assuming each column has a width of 128 pixels
  const width = (columns) => {
    let numColumns = 0; // Initialize numColumns

    // Find the maximum number of columns among all rows
    columns.forEach((row) => {
      const currentNumColumns = row?.invest_keys?.length || 0;
      if (currentNumColumns > numColumns) {
        numColumns = currentNumColumns;
      }
    });
    // Calculate total width based on the maximum number of columns
    const sum = (numColumns + 1) * columnWidth;
    return `${sum}px`;
  };

  // const numColumns = rows?.row?.invest_keys.length;
  // console.log("AAA", numColumns);

  // const width = (columns) => {
  //   let width = numColumns
  //   const sum = width * columnWidth;

  //   return `${sum}px`;
  // };

  // console.log("2", rows?.invest_keys);

  return (
    <div className=" w-full overflow-hidden overflow-x-scroll rounded-t-[20px] ">
      {/* Header */}

      <div className="bg-[#F9F9F9] w-full  h-full">
        <div className="bg-[#F9F9F9]  h-full flex w-screen ">
          <div
            className={`bg-[#F9F9F9] h-16 w-[384px]  text-mainblue flex-none  border-b border-mainblue font-bold text-sm text-center border-r border-r-mainblue flex justify-center items-center px-3`}
          >
            ค่าใช้จ่ายเพื่อการศึกษา
          </div>
          <div
            style={{
              width: width(rows),
            }}
            className={`bg-[#F9F9F9] h-16  text-mainblue flex-none border-b border-mainblue font-bold text-sm text-center border-r border-r-gray60 flex justify-center items-center px-3`}
          >
            ออมเงิน / ลงทุน ดอกเบี้ย 5% ต่อปี
          </div>
        </div>
        <div className=" flex h-20">
          <div className={STYLE_TABLE_EDU}>ระดับชั้น</div>
          <div className={STYLE_TABLE_EDU}>ค่าใช้จ่ายเพื่อการศึกษาต่อปี</div>
          <div className={`${STYLE_TABLE_EDU} border-r border-r-mainblue`}>
            ค่าใช้จ่ายสะสมเพื่อการศึกษาต่อปี
          </div>
          {labelHeaders.map((header, index) => {
            if (index === labelHeaders.length - 1) {
              return (
                <div
                  key={index}
                  className={STYLE_TABLE_EDU}
                  // className={` bg-[#F9F9F9]  text-mainblue font-semibold text-nowrap w-[128px] text-sm text-center border-r border-r-gray60 flex justify-center items-center px-3 border-b  border-mainblue h-full `}
                >
                  ออมเงิน / ลงทุน เพื่อเป้าหมาย
                </div>
              );
            }

            return (
              <div key={index} className={`${STYLE_TABLE_EDU} flex-col `}>
                {header?.label}
                {header?.is_invset ? (
                  <div className=" text-maincyan">
                    ({numeral(header.total)?.format("0,0")})
                  </div>
                ) : (
                  <div className=" text-mainblue text-xs">
                    (ไม่ได้วางแผนออม)
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* Body */}
      {rows.map((row, index) => {
        return (
          <div key={index} className={STYLE_BORDER_BODY_TAX}>
            {/* ------- 1 ------ */}
            <div className={`${STYLE_BODY_EDU} text-mainblue`}>
              {row.education?.label}
            </div>
            <div className={`${STYLE_BODY_EDU} text-maincyan`}>
              {numeral(row?.sum_under_education)?.format?.("0,0")}
            </div>
            <div
              className={`${STYLE_BODY_EDU} text-maincyan border-r border-r-mainblue`}
            >
              {" "}
              {numeral(row?.sum_under_education_accumulate)?.format?.("0,0")}
            </div>

            {row?.invest_keys.map((key, index) => {
              const value = row.invest_dynamic_education_item_5[`${key}_5`];

              return (
                <div key={index} className={`${STYLE_BODY_EDU} text-maincyan`}>
                  {value ? numeral(value)?.format?.("0,0") : ""}
                </div>
              );
            })}

            <div
              className={`bg-white  w-[128px] flex-none text-sm text-center border-r border-r-gray60 h-[60px] flex justify-center items-center px-3 border-b border-b-gray60 text-maincyan`}
            >
              {row?.sum_invest ? numeral(row?.sum_invest)?.format?.("0,0") : ""}
            </div>
          </div>
        );
      })}
      {/* ---------  ยอดรวม ---------- */}
      <div className={`${STYLE_BORDER_BODY_TAX} `}>
        <div
          className={`${STYLE_BODY_EDU} text-mainblue font-semibold border-t border-mainblue`}
        >
          รวม
        </div>

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue `}
        >
          {numeral(footer.sum_total_education)?.format?.("0,0")}
        </div>

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue border-r border-r-mainblue`}
        ></div>

        {footer.invest_keys.map((key, index) => {
          return (
            <div
              key={index}
              className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue`}
            >
              {""}
            </div>
          );
        })}

        <div
          className={`${STYLE_BODY_EDU} text-maincyan border-t border-mainblue w-[128px]`}
        >
          {numeral(footer.sum_total_invest)?.format?.("0,0")}
        </div>
      </div>
    </div>
  );
};

export default ViewTableExpenses;
