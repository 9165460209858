import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { MTButton } from "../button/MTButton";
import { hover } from "@testing-library/user-event/dist/hover";

export const PopupMain = ({
  title,
  text,
  open,
  img = "/images/bad_face.png",
  onClick,
  isHasButton = true,
  width = "w-20",
  height = "h-20",
  textButton = "ตกลง",
  iconleft,
  iconright,
  icon,
  iconleft2,
  iconright2,
  icon2,
  textButton2,
  onClickSecond,
  button2,
  color = "bg-maincyan",
  colortext = "text-white group-hover:text-white",
  hover = "hover:bg-maincyan group ",
  color2 = "border border-maincyan",
  colortext2 = "text-maincyan group-hover:text-white",
  hover2 = "hover:bg-maincyan group ",
  flex = "flex",
}) => {
  return (
    <div>
      <Dialog size="xs" open={open} className=" shadow-none !rounded-[20px]">
        <DialogHeader className=" flex flex-col justify-center ">
          {img && <img src={img} className={` ${width} ${height} `} alt="" />}
          <div className="font-IBMPlexSansThai text-mainblue text-lg ">
            {" "}
            {title}{" "}
          </div>
        </DialogHeader>
        <DialogBody className="p-0 px-4">
          <div className="font-IBMPlexSansThai text-center text-black text-lg ">
            {text}
          </div>
        </DialogBody>
        <DialogFooter>
          <div className={` ${flex} gap-2 w-full `}>
            {isHasButton && (
              <MTButton
                iconleft={iconleft}
                iconright={iconright}
                icon={icon}
                text={textButton}
                width="w-full gap-1"
                onClick={onClick}
                color={color}
                colortext={colortext}
                hovercolor={hover}
              />
            )}
            {button2 ? (
              <MTButton
                iconleft={iconleft2}
                iconright={iconright2}
                icon={icon2}
                text={textButton2}
                width="w-full gap-1"
                onClick={onClickSecond}
                color={color2}
                colortext={colortext2}
                hovercolor={hover2}
              />
            ) : (
              ""
            )}
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export const PopupMain2 = ({
  title,
  text,
  open,
  img = "/images/bad_face.png",
  onClick,
}) => {
  return (
    <div>
      <Dialog size="xs" open={open} className=" shadow-none">
        <DialogHeader className=" font-IBMPlexSansThai text-mainblue flex justify-center">
          {title}
          <div>
            <img src={img} className=" w-20 h-20" alt="" />
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="font-IBMPlexSansThai text-center text-black text-lg ">
            {text}
          </div>
        </DialogBody>
        <DialogFooter>
          <MTButton text="ตกลง" width="w-full" onClick={onClick} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
