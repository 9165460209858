import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../../components/header/Header";
import Container from "../../../../components/layout/Container";
import NavigateGoBack from "../../../../functions/Navigate.Goback";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PARENT_PADDING,
} from "../../../../utils/useStyle";
import { CardMain } from "../../../../components/card/CardMain";
import { ICON_INFO } from "../../../../utils/useIcons";
import { ButtonCounter } from "../../../../components/button/MTButton";
import { CardTaxPremium } from "../../styles/Card/CardMenuPremium";
import {
  option_income_business_tax_40_3,
  option_income_business_tax_40_5,
  option_income_business_tax_40_7,
  option_income_business_tax_40_8,
  option_income_business_tax_40_8_asset,
} from "../../functions/options";
import { useState } from "react";
import { PayerIncomeInfo } from "./VIIncome_Freelance";
import { InfoDrawer } from "../../../../components/drawer/InfoDrawer";

const Income403Info = ({ open403Info, setOpen403Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(3)"}
      text={
        <div className=" mb-4">
          ค่าแห่งกู๊ดวิลล์ ค่าแห่งลิขสิทธิ์ หรือสิทธิอย่างอื่นในทำนอง เดียวกัน
          หรือเงินได้ที่มีลักษณะเป็นเงินได้รายปี
          เช่นค่าเขียน/ค่าพัฒนาโปรแกรมคอมพิวเตอร์
        </div>
      }
      open={open403Info}
      onClose={() => {
        setOpen403Info(false);
      }}
    />
  );
};

const Income405Info = ({ open405Info, setOpen405Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(5)"}
      text={
        <div className=" mb-4">
          เงินหรือประโยชน์ที่ได้รับเนื่องจากการให้เช่าทรัพย์สิน เช่น ค่าเช่าบ้าน
          ค่าเช่ารถยนต์ การผิดสัญญาเช่าซื้อ หรือการผิดสัญญาซื้อขายเงินผ่อน
          เป็นต้น
        </div>
      }
      open={open405Info}
      onClose={() => {
        setOpen405Info(false);
      }}
    />
  );
};

const Income407Info = ({ open407Info, setOpen407Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(7)"}
      text={
        <div className=" mb-4">
          เงินหรือประโยชน์ที่ได้รับเนื่องจากการให้เช่าทรัพย์สิน เช่น ค่าเช่าบ้าน
          ค่าเช่ารถยนต์ การผิดสัญญาเช่าซื้อ หรือการผิดสัญญาซื้อขายเงินผ่อน
          เป็นต้น
        </div>
      }
      open={open407Info}
      onClose={() => {
        setOpen407Info(false);
      }}
    />
  );
};

const Income408Info = ({ open408Info, setOpen408Info }) => {
  return (
    <InfoDrawer
      head={"เงินได้ตามมาตรา 40(8)"}
      text={
        <div className=" mb-4">
          เงินได้จากธุรกิจ การพาณิชย์ ฯลฯ เงินส่วนแบ่งกำไรจากกองทุนรวม
          (ก่อนวันที่ 20 สิงหาคม 2562)
          เงินได้จากการขายอสังหาริมทรัพย์อันเป็นมรดก
          หรือได้มาโดยมิได้มุ่งในทางการค้า เงินหรือผลประโยชน์ใดๆ
          ที่ได้รับเนื่องจากการขายหน่วยลงทุนคืนให้แก่กองทุนรวมเพื่อการเลี้ยงชีพตามกฎหมายว่าด้วยหลักทรัพย์และตลาดหลักทรัพย์
          ทั้งที่ได้รับยกเว้นภาษีเงินได้และที่ไม่ได้รับยกเว้นภาษีเงินได้
        </div>
      }
      open={open408Info}
      onClose={() => {
        setOpen408Info(false);
      }}
    />
  );
};

const Income408AssetInfo = ({ open408AssetInfo, setOpen408AssetInfo }) => {
  return (
    <InfoDrawer
      head={
        <div className=" text-xl">
          เงินได้จากการขายอสังหาริมทรัพย์
          ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไรและเลือกเสียภาษีโดยไม่นำไปรวมคำนวณภาษีกับเงินได้อื่นๆ
        </div>
      }
      text={
        <div className="">
          เงินได้จากการขายอสังหาริมทรัพย์อันเป็นมรดกอสังหาริมทรัพย์ที่ได้รับจากการให้โดยเสน่หา
          หรืออสังหาริมทรัพย์ที่ได้มาโดยมิได้มุ่งในทางการค้าหรือหากำไร
          และเลือกเสียภาษีโดยไม่นำไปรวมคำนวณภาษีกับเงินได้อื่นๆ
          หากมีความประสงค์จะขอหักค่าใช้จ่ายตามความจำเป็น และสมควร
          ให้คำนวณภาษีที่ต้องชำระ
          โดยนำเงินได้จากการขายอสังหาริมทรัพย์หักค่าใช้จ่ายตามความจำเป็นและสมควร
          เหลือเป็นเงินได้สุทธิให้หารด้วยจำนวนปีที่ถือครอง
          ได้ผลลัพธ์เท่าใดให้คำนวณภาษีตามอัตราภาษีเงินได้บุคคลธรรมดา
          (ไม่ได้รับยกเว้น 150,000 บาท)
          ได้เท่าใดให้คูณด้วยจำนวนปีที่ถือครองผลลัพธ์เป็นภาษีที่ต้องชำระจำนวนภาษีดังกล่าวข้างต้นต้องไม่เกินร้อยละ
          20 ของราคาขาย
        </div>
      }
      open={open408AssetInfo}
      onClose={() => {
        setOpen408AssetInfo(false);
      }}
    />
  );
};

const ViewIncomeBusiness = () => {
  const navigate = useNavigate();
  const [openInfo, setOpenInfo] = useState(false);
  const [open403Info, setOpen403Info] = useState(false);
  const [open405Info, setOpen405Info] = useState(false);
  const [open407Info, setOpen407Info] = useState(false);
  const [open408Info, setOpen408Info] = useState(false);
  const [open408AssetInfo, setOpen408AssetInfo] = useState(false);

  return (
    <div>
      <Container>
        <div className={STYLE_MARGIN_TOP_PARENT}>
          <HeaderTitle
            title=" รายได้จากทรัพย์สิน, การทำธุรกิจ "
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />
          <div className={STYLE_PARENT_PADDING}>
            {/* ------ 40(3) ------ */}
            <CardMain
              marginyellow=" mt-14 "
              title={
                <div className=" pt-12 pr-3 flex">
                  ค่าลิขสิทธิ์ ค่าสิทธิ์ในทรัพย์สินทางปัญญา (Royalty) และ
                  ค่ากู๊ดวิลล์ (Goodwill) หรือ เงินได้รายปีที่ได้ มาจากนิติกรรม
                  และคำพิพากษาของศาล (มาตรา 40(3))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen403Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                    // value={row?.credit_cards?.length}
                    // handleNext={handleCreditCardNext?.bind(
                    //   this,
                    //   index
                    // )}
                    />
                  </div>
                  <CardTaxPremium
                    options={option_income_business_tax_40_3}
                    expressTitle="วิธีการหักค่าใช้จ่าย"
                    openInfo={() => {
                      setOpenInfo(true);
                    }}
                  />
                </div>
              }
            />
            {/* ------ 40(5) ------ */}
            <CardMain
              marginyellow=" mt-4"
              title={
                <div className=" pt-4 pr-3 flex">
                  ค่าเช่า ค่าผิดสัญญาเช่าซื้อหรือซื้อขายเงินผ่อน (มาตรา 40(5))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen405Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                    // value={row?.credit_cards?.length}
                    // handleNext={handleCreditCardNext?.bind(
                    //   this,
                    //   index
                    // )}
                    />
                  </div>
                  <CardTaxPremium
                    expressTitle="วิธีการหักค่าใช้จ่าย"
                    options={option_income_business_tax_40_5}
                    openInfo={() => {
                      setOpenInfo(true);
                    }}
                  />
                </div>
              }
            />

            {/* ------ 40(7) ------ */}
            <CardMain
              marginyellow=" mt-14"
              title={
                <div className=" pt-12 pr-3 flex">
                  เงินได้จากการรับเหมาที่ผู้รับเหมาทั้งค่าแรง และค่าของ
                  ที่ต้องลงทุนด้วยการจัดหาสัมภาระ ในส่วนสำคัญ นอกจากเครื่องมือ
                  (มาตรา 40(7))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen407Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                    // value={row?.credit_cards?.length}
                    // handleNext={handleCreditCardNext?.bind(
                    //   this,
                    //   index
                    // )}
                    />
                  </div>
                  <CardTaxPremium
                    options={option_income_business_tax_40_7}
                    expressTitle="วิธีการหักค่าใช้จ่าย"
                    openInfo={() => {
                      setOpenInfo(true);
                    }}
                  />
                </div>
              }
            />

            {/* ------ 40(8) ------ */}
            <CardMain
              marginyellow=" mt-10"
              title={
                <div className=" pt-8 pr-3 flex">
                  เงินได้จากธุรกิจ การพาณิชย์ การเกษตร การอุตสาหกรรม การขนส่ง
                  และเงินได้อื่นๆ (มาตรา 40(8))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen408Info(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                    // value={row?.credit_cards?.length}
                    // handleNext={handleCreditCardNext?.bind(
                    //   this,
                    //   index
                    // )}
                    />
                  </div>
                  <CardTaxPremium
                    options={option_income_business_tax_40_8}
                    optionsGroup={true}
                    expressTitle="วิธีการหักค่าใช้จ่าย"
                    openInfo={() => {
                      setOpenInfo(true);
                    }}
                  />
                </div>
              }
            />
            {/* ------ 40(8) อสังหา------ */}
            <CardMain
              marginyellow=" mt-10"
              title={
                <div className=" pt-8 pr-3 flex">
                  เงินได้จากธุรกิจ การพาณิชย์ การเกษตร การอุตสาหกรรม การขนส่ง
                  และเงินได้อื่นๆ (มาตรา 40(8))
                  <ICON_INFO
                    className={` w-4 h-4 text-gray-900 flex-none`}
                    onClick={() => {
                      setOpen408AssetInfo(true);
                    }}
                  />
                </div>
              }
              panel={
                <div className=" px-3 -mt-3 space-y-[15px]">
                  <div className=" bg-white rounded-full px-4 py-[6px] flex justify-between items-center h-9 ">
                    <div className=" text-sm text-black font-medium">
                      เพิ่มรายการ
                    </div>
                    <ButtonCounter
                    // value={row?.credit_cards?.length}
                    // handleNext={handleCreditCardNext?.bind(
                    //   this,
                    //   index
                    // )}
                    />
                  </div>
                  <CardTaxPremium
                    expressTitle="วิธีการหักค่าใช้จ่าย"
                    options={option_income_business_tax_40_8_asset}
                    openInfo={() => {
                      setOpenInfo(true);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      </Container>
      {openInfo && (
        <PayerIncomeInfo openInfo={openInfo} setOpenInfo={setOpenInfo} />
      )}
      {open403Info && (
        <Income403Info
          open403Info={open403Info}
          setOpen403Info={setOpen403Info}
        />
      )}
      {open405Info && (
        <Income405Info
          open405Info={open405Info}
          setOpen405Info={setOpen405Info}
        />
      )}
      {open407Info && (
        <Income407Info
          open407Info={open407Info}
          setOpen407Info={setOpen407Info}
        />
      )}
      {open408Info && (
        <Income408Info
          open408Info={open408Info}
          setOpen408Info={setOpen408Info}
        />
      )}
      {open408AssetInfo && (
        <Income408AssetInfo
          open408AssetInfo={open408AssetInfo}
          setOpen408AssetInfo={setOpen408AssetInfo}
        />
      )}
    </div>
  );
};

export default ViewIncomeBusiness;
