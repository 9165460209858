import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import { STYLE_MARGIN_TOP_PARENT } from "../../utils/useStyle";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ViewIncomeProportion from "./component/VIIncomePropotion";
import ViewOutComeProportion from "./component/VIOutcomePropotion";
import ViewDebtProportion from "./component/VIDebtPropotion";
import ViewPropertyProportion from "./component/VIPropertyPropotion";
import { useBalanceStore } from "../../_store/balanceStore";
import ViewNetFlowMain from "./component/VINetFlowPropotion";
import ViNetWealthMian from "./component/VINetWealthProperty";
import numeral from "numeral";
import SumFinancial from "./component/VISumFinancial";
import { DrawerBalanceIncome } from "./component/VIDrawerIncome.Main";
import { DrawerBalanceOutCome } from "./component/VIDrawerOutcome.Main";
import { DrawerBalanceDebt } from "./component/VIDrawerDebt.Main";
import { DrawerBalanceProperty } from "./component/VIDrawerProperty.Main";
import NavigateGoBack from "../../functions/Navigate.Goback";
import { useNavigate } from "react-router-dom";
import api from "../../api/api.main";
import { useUserStore } from "../../_store/userStore";

export const useBalanceContext = () => useContext(BalanceContext);
const BalanceContext = createContext();

const BalanceMain = () => {
  const balanceData = useBalanceStore((state) => state?.balanceData);
  const setBalanceData = useBalanceStore((state) => state?.setBalanceData);

  const user = useUserStore((state) => state?.userData);

  const navigate = useNavigate();

  const [formBL, setFormBL] = useState({
    salary_peryear_list: [],
    sum_salary_peryear: "",
    interest_peryear_list: [],
    sum_interest_peryear: "",
    other_income_list: [],
    sum_other_income: "",
    salary_propotion: "",

    debt_short_list: [],
    sum_debt_short_permonth: "",
    sum_debt_short: "",
    debt_long_list: [],
    sum_debt_long_permonth: "",
    sum_debt_long: "",
    debt_propotion: "",
    debt_long_car_personal_list: [],
    debt_long_place_personal_list: [],

    expenses_fixed_list: [],
    sum_expenses_fixed: "",
    old_sum_expenses_fixed: 0,
    expenses_variable_list: [],
    sum_expenses_variable: 0,
    expenses_invest_saving_list: [],
    sum_expenses_invest_saving: "",
    expenses_propotion: "",

    property_asset_list: [],
    sum_property_asset: "",
    property_invest_list: [],
    sum_property_invest: 0,
    property_private_list: [],
    sum_property_private: "",
    property_intangible_list: [],
    sum_property_intangible: "",
    property_propotion: "",
    sum_debt_property_private: 0,

    net_wealth: "",
    net_cash_flow: "",
    temp_net_cash_flow: "",
    net_cash_flow_yes_no: "",
  });

  const [openDrawer, setOpenDrawer] = useState();

  const [open, setOpen] = useState();
  const [openAccordion, setOpenAccordion] = useState(0);

  //---------- รายได้ ----------//
  const [addIncome, setAddIncome] = useState(
    balanceData?.salary_peryear_list ? balanceData.salary_peryear_list : []
  );
  const [addInterest, setAddInterest] = useState(
    balanceData?.interest_peryear_list ? balanceData.interest_peryear_list : []
  );
  const [addOtherIncome, setAddOtherIncome] = useState(
    balanceData?.other_income_list ? balanceData.other_income_list : []
  );

  //---------- หนี้ ----------//
  const [addShortDebt, setAddShortDebt] = useState(
    balanceData?.debt_short_list ? balanceData.debt_short_list : []
  );
  const [addLongDebt, setAddLongDebt] = useState(
    balanceData?.debt_long_list ? balanceData.debt_long_list : []
  );

  //---------- ค่าใช้จ่าย ----------//
  const [addExpensesFixed, setAddExpensesFixed] = useState(
    balanceData?.expenses_fixed_list ? balanceData.expenses_fixed_list : []
  );
  const [addExpensesVariable, setAddExpensesVariable] = useState(
    balanceData?.expenses_variable_list
      ? balanceData.expenses_variable_list
      : []
  );
  const [addExpensesInvest, setAddExpensesInvest] = useState(
    balanceData?.expenses_invest_saving_list
      ? balanceData.expenses_invest_saving_list
      : []
  );

  //---------- ทรัพย์สิน ----------//
  const [addAsset, setAddAsset] = useState(
    balanceData?.property_asset_list ? balanceData?.property_asset_list : []
  );
  const [addInvest, setAddInvest] = useState(
    balanceData?.property_invest_list ? balanceData?.property_invest_list : []
  );
  const [addPrivate, setAddPrivate] = useState(
    balanceData?.property_private_list ? balanceData?.property_private_list : []
  );
  const [addIntangible, setAddIntangible] = useState(
    balanceData?.property_intangible_list
      ? balanceData?.property_intangible_list
      : []
  );

  //popup
  const [openPopupIncome, setOpenPopupIncome] = useState(false);
  const [openPopupOutcome, setOpenPopupOutcome] = useState(false);
  const [openPopupProperty, setOpenPopupProperty] = useState(false);
  const [openPopupDebtToAsset, setOpenPopupDebtToAsset] = useState(false);
  const [openPopupDebtToSalary, setOpenPopupDebtToSalary] = useState(false);
  const [openPopupConservation, setOpenPopupConservation] = useState(false);
  const [openPopupInvest, setOpenPopupInvest] = useState(false);
  const [openInfoSumFinancial, setOpenInfoSumFinancial] = useState(false);

  // console.log("balanceData : ", balanceData);

  //-------------------- กระแสเงินสดสุทธิ -------------------//
  const getNetCashflow = useMemo(() => {
    let sum = 0;
    if (balanceData?.salary_propotion) {
      sum = balanceData?.expenses_propotion
        ? Number(balanceData?.salary_propotion) -
          Number(balanceData?.expenses_propotion)
        : Number(balanceData?.salary_propotion);
    }
    return sum;
    // return sum > balanceData?.temp_net_cash_flow
    //   ? sum - balanceData?.temp_net_cash_flow
    //   : sum;
  }, [
    balanceData?.salary_propotion,
    balanceData?.expenses_propotion,
    balanceData?.temp_net_cash_flow,
  ]);

  // mark
  const handleYesNoNetCashFlow = (e) => {
    let value = e.target.value;
    const sumExpensesInvestSaving = balanceData?.sum_expenses_invest_saving
      ? balanceData?.sum_expenses_invest_saving
      : 0;
    const expensesPropotion = balanceData?.expenses_propotion
      ? balanceData?.expenses_propotion
      : 0;
    const netCashFlow = formBL?.net_cash_flow ? formBL?.net_cash_flow : 0;

    if (value === "yes") {
      setBalanceData({
        ...formBL,
        // net_cash_flow_yes_no: value,
        net_cash_flow_yes_no: "",
        // net_cash_flow: 0,
        temp_net_cash_flow: Number(netCashFlow + formBL?.temp_net_cash_flow),
        expenses_propotion: expensesPropotion + netCashFlow,
        sum_expenses_invest_saving: sumExpensesInvestSaving + netCashFlow,
      });
    } else {
      setBalanceData({
        ...formBL,
        net_cash_flow_yes_no: value,
        expenses_propotion:
          sumExpensesInvestSaving > 0
            ? expensesPropotion - netCashFlow
            : expensesPropotion,
        sum_expenses_invest_saving:
          sumExpensesInvestSaving > 0
            ? sumExpensesInvestSaving - netCashFlow
            : sumExpensesInvestSaving,
      });
    }
  };

  //-------------------- ค่าความมั่งคั่ง -------------------//
  const getNetWealth = useMemo(() => {
    let result = 0;
    if (balanceData?.property_propotion) {
      result = balanceData?.debt_propotion
        ? Number(balanceData?.property_propotion) -
          Number(balanceData?.debt_propotion)
        : 0;
    }
    if (result > 0) {
      return result;
    } else {
      return 0;
    }
  }, [balanceData?.property_propotion, balanceData?.debt_propotion]);

  //---------- Set Form ----------//
  useEffect(() => {
    if (balanceData) {
      setFormBL({
        ...balanceData,
        net_wealth: getNetWealth,
        net_cash_flow: getNetCashflow,
      });

      // LATEST
      setAddPrivate(balanceData?.property_private_list);
      setAddAsset(balanceData?.property_asset_list);
      setAddInvest(balanceData?.property_invest_list);
      setAddIntangible(balanceData?.property_intangible_list);
    }

    let amountData = balanceData?.property_invest_list

    const Amounts = balanceData?.property_invest_list?.map(
      (item) => Object.entries(item)?.[4]?.[1]?.[0]?.amount || 0
    );
    const totalAmount = Amounts?.reduce((prev, curr) => prev + curr, 0);

    const sendBalanceData = async () => {
      try {
        await api.put(`/profiles/${user?._id}`, {
          ...user,
          balance: {
            invest: {
              status: user?.balance?.invest?.status,
              expected: user?.balance?.invest?.expected,
              amount: balanceData?.property_invest_list?.map((item) => {
                return {
                  label: item?.options_property_invest_title
                    ? item?.options_property_invest_title
                        .split("(")[1]
                        .split(")")[0]
                    : "",
                  value:
                    ((Object.entries(item)?.[4]?.[1]?.[0]?.amount || 0) /
                      totalAmount) *
                    100,
                  real_value: Object.entries(item)?.[4]?.[1]?.[0]?.amount || 0,
                };
              }),
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    };

    sendBalanceData();
  }, [balanceData, getNetCashflow, getNetWealth]);

  return (
    <Container>
      {/*---------- สัดส่วนรายได้ ----------*/}
      {openDrawer === 1 && (
        <DrawerBalanceIncome
          balanceData={balanceData}
          formBL={formBL}
          setFormBL={setFormBL}
          open={openDrawer === 1}
          onclose={() => {
            setOpenDrawer(false);
          }}
          addIncome={addIncome}
          setAddIncome={setAddIncome}
          addInterest={addInterest}
          setAddInterest={setAddInterest}
          addOtherIncome={addOtherIncome}
          setAddOtherIncome={setAddOtherIncome}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
        />
      )}

      {/*---------- สัดส่วนรายจ่าย ----------*/}
      {openDrawer === 2 && (
        <DrawerBalanceOutCome
          formBL={formBL}
          setFormBL={setFormBL}
          open={openDrawer === 2}
          onclose={() => {
            setFormBL({
              ...balanceData,
            });
            setOpenDrawer(false);
          }}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
          addExpensesFixed={addExpensesFixed}
          setAddExpensesFixed={setAddExpensesFixed}
          addExpensesVariable={addExpensesVariable}
          setAddExpensesVariable={setAddExpensesVariable}
          addExpensesInvest={addExpensesInvest}
          setAddExpensesInvest={setAddExpensesInvest}
        />
      )}

      {/*---------- สัดส่วนหนี้สิน ----------*/}
      {openDrawer === 3 && (
        <DrawerBalanceDebt
          formBL={formBL}
          setFormBL={setFormBL}
          open={openDrawer === 3}
          onclose={() => {
            setOpenDrawer(false);
          }}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
          addShortDebt={addShortDebt}
          setAddShortDebt={setAddShortDebt}
          addLongDebt={addLongDebt}
          setAddLongDebt={setAddLongDebt}
        />
      )}

      {/*---------- สัดส่วนทรัพย์สิน ----------*/}
      {openDrawer === 4 && (
        <DrawerBalanceProperty
          formBL={formBL}
          setFormBL={setFormBL}
          open={openDrawer === 4}
          onclose={() => {
            setOpenDrawer(false);
          }}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
          addAsset={addAsset}
          setAddAsset={setAddAsset}
          addInvest={addInvest}
          setAddInvest={setAddInvest}
          addPrivate={addPrivate}
          setAddPrivate={setAddPrivate}
          addIntangible={addIntangible}
          setAddIntangible={setAddIntangible}
        />
      )}

      {/*-----------------------------------------------------------------------------------*/}
      <div className=" mb-20">
        <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
          <HeaderTitle
            title="งบดุล"
            isGoback
            onClickGoBack={() => {
              NavigateGoBack(navigate);
            }}
          />

          <div className=" mt-5 space-y-5 px-6">
            {/*----------  สรุปอัตราส่วนทางการเงิน ----------*/}
            <SumFinancial
              formBL={formBL}
              openPopupIncome={openPopupIncome}
              setOpenPopupIncome={setOpenPopupIncome}
              openPopupOutcome={openPopupOutcome}
              setOpenPopupOutcome={setOpenPopupOutcome}
              openPopupProperty={openPopupProperty}
              setOpenPopupProperty={setOpenPopupProperty}
              openPopupDebtToAsset={openPopupDebtToAsset}
              setOpenPopupDebtToAsset={setOpenPopupDebtToAsset}
              openPopupDebtToSalary={openPopupDebtToSalary}
              setOpenPopupDebtToSalary={setOpenPopupDebtToSalary}
              openPopupConservation={openPopupConservation}
              setOpenPopupConservation={setOpenPopupConservation}
              openPopupInvest={openPopupInvest}
              setOpenPopupInvest={setOpenPopupInvest}
              openInfoSumFinancial={openInfoSumFinancial}
              setOpenInfoSumFinancial={setOpenInfoSumFinancial}
              open={open}
              onClick={() => {
                setOpen(!open);
              }}
            />

            {/*---------- กระแสเงินสดสุทธิ ----------*/}
            {formBL?.salary_propotion && formBL?.expenses_propotion ? (
              <ViewNetFlowMain
                value={
                  formBL?.temp_net_cash_flow
                    ? formBL?.temp_net_cash_flow
                    : formBL?.net_cash_flow
                }
                status={formBL?.net_cash_flow < 0 ? "minus" : "zero"}
                onChange={handleYesNoNetCashFlow}
                valueChecked={formBL?.net_cash_flow_yes_no}
              />
            ) : (
              ""
            )}

            {/*---------- ความมั่งคั่งสุทธิ ----------*/}
            {formBL?.property_propotion && formBL?.debt_propotion ? (
              <ViNetWealthMian
                status={
                  formBL?.debt_propotion > formBL?.property_propotion
                    ? "bad"
                    : "good"
                }
                amount={numeral(formBL?.net_wealth).format("0,0")}
                property_value={numeral(formBL?.property_propotion).format(
                  "0,0"
                )}
                debt_value={numeral(formBL?.debt_propotion).format("0,0")}
                net_wealth_value={numeral(formBL?.net_wealth).format("0,0")}
              />
            ) : (
              ""
            )}

            {/*---------- สัดส่วนรายได้ ----------*/}
            <ViewIncomeProportion
              formBL={formBL}
              setFormBL={setFormBL}
              onClick={() => {
                setOpenDrawer(1);
              }}
            />

            {/*---------- สัดส่วนหนี้สิน ----------*/}
            <ViewDebtProportion
              formBL={formBL}
              setFormBL={setFormBL}
              onClick={() => {
                setOpenDrawer(3);
              }}
            />

            {/*---------- สัดส่วนรายจ่าย ----------*/}
            <ViewOutComeProportion
              formBL={formBL}
              setFormBL={setFormBL}
              onClick={() => {
                setOpenDrawer(2);
              }}
            />

            {/*---------- สัดส่วนทรัพธ์สิน ----------*/}
            <ViewPropertyProportion
              formBL={formBL}
              setFormBL={setFormBL}
              onClick={() => {
                setOpenDrawer(4);
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default BalanceMain;
