import { Radio } from "@material-tailwind/react";

export const RadioMain = ({
  label = "label",
  name = "name",
  value = "value",
  onChange = () => {},
  onClick = () => {},
  checked,
}) => {
  return (
    <Radio
      color="cyan"
      className="bg-white"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClick}
      checked={checked}
    />
  );
};
