import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../components/header/Header";
import Container from "../../components/layout/Container";
import { NavigatePath } from "../../functions/Navigate.Goback";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_SPACE_GRAY_MENU,
} from "../../utils/useStyle";
import { CardGrayMenu } from "../../components/card/CardMain";
import { useEffect } from "react";
import { first_reload } from "./functions/firstLoad";

const InsuarancePlanMain = () => {
  const navigate = useNavigate();

  const onClickToWebsite = () => {
    window.open("https://assurewealth.co.th/", "_blank");
  };

  return (
    <Container>
      <div id="first_reload" className="">
        <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
          <HeaderTitle
            title="วางแผนประกัน"
            isGoback
            onClickGoBack={() => {
              NavigatePath(navigate, "/");
            }}
          />
          <div className={`${STYLE_SPACE_GRAY_MENU} ${STYLE_PADDING_MAIN}`}>
            <CardGrayMenu
              img={"../images/homewithheart.png"}
              title={"ประกันคุ้มครองค่าใช้จ่ายเพื่อการดำรงชีพของครอบครัว"}
              onClick={() => {
                navigate("/insuranceplan/family");
              }}
            />
            <CardGrayMenu
              img={"../images/risk_diabled.svg"}
              title={"ประกันคุ้มครองกรณีทุพพลภาพ"}
              onClick={() => {
                navigate("/insuranceplan/disabled");
              }}
            />
            <CardGrayMenu
              img={"../images/sick_insuarance_all.png"}
              title={"ประกันสุขภาพ"}
              onClick={onClickToWebsite}
            />
            <CardGrayMenu
              img={"../images/risk_accident.svg"}
              title={"ประกันอุบัติเหตุ"}
              onClick={onClickToWebsite}
            />
            <CardGrayMenu
              img={"../images/risk_fire.svg"}
              title={"ประกันอัคคีภัย"}
              onClick={onClickToWebsite}
            />
            <CardGrayMenu
              img={"../images/car_insuarance_all.png"}
              title={"ประกันรถยนต์"}
              onClick={onClickToWebsite}
            />
            <CardGrayMenu
              img={"../images/two_hearts.png"}
              title={"ประกันอื่น ๆ"}
              onClick={onClickToWebsite}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InsuarancePlanMain;
