import {
  DonutChartInvestMain,
  DonutChartInvestMain2,
} from "../../../components/chart/DonutChartMain";
import { ImageMain } from "../../../components/img/Img.Main";
import { CardResult } from "../styles/Card";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useMemo } from "react";
import _ from "lodash";
import { key_property_invest_category } from "../../balance/functions";
import { toFloat2 } from "../../../utils/useFunctions";
import { useUserStore } from "../../../_store/userStore";
import api from "../../../api/api.main";

const ViewMyPortResult = ({ status, isReport, isOverview }) => {
  const balanceData = useBalanceStore((state) => state?.balanceData);
  // let status = "good";


  const checkIcon = (status) => {
    if (status === "good") {
      return (
        <ImageMain
          src={"../images/good2.png"}
          className=" w-[71px] h-14 mx-auto"
        />
      );
    } else if (status === "bad") {
      return (
        <ImageMain
          src={"../images/bad_face.png"}
          className=" w-14 h-14 mx-auto"
        />
      );
    } else if (status === "getmore") {
      return (
        <ImageMain
          src={"../images/taxicon2.png"}
          className=" w-14 h-14 mx-auto"
        />
      );
    }
  };

  const checkDescription = (status) => {
    if (status === "good") {
      return "  พอร์ตลงทุนเป้าหมายระยะยาวของคุณยอดเยี่ยมตอนนี้คุณมีพอร์ตการลงทุนที่พอดีกับที่เราแนะนำ";
    } else if (status === "bad") {
      return (
        <div className=" text-center">
          พอร์ตลงทุนเป้าหมายระยะยาวของคุณเสี่ยงสูงเกินไป คุณควร
          <span className=" text-mainyellow font-bold ">
            {" "}
            เพิ่มตราสารทุนอีก 20%{" "}
          </span>
          เพื่อให้เหมาะสมกับนักลงทุนกล้าเสี่ยงสูงมาก
        </div>
      );
    } else if (status === "getmore") {
      return (
        <div className=" text-center">
          พอร์ตลงทุนเป้าหมายระยะยาวของคุณยังรับความเสี่ยงได้อีก คุณควร{" "}
          <span className=" text-mainyellow font-bold ">
            ลดตราสารทุนอีก 10%
          </span>{" "}
          และ{" "}
          <span className=" text-mainorange font-bold ">
            เพิ่มสินทรัพย์ทางเลือก อีก 10%
          </span>
          เพื่อให้เหมาะสมกับนักลงทุนกล้าเสี่ยงสูงมาก
        </div>
      );
    }
  };

  const items = useMemo(() => {
    if (!balanceData?.property_invest_list?.length) {
      return [];
    }

    const filterPropertyInvest = balanceData.property_invest_list.filter(
      (item) => item?.options_property_invest_category
    );

    const groupInvest = _.groupBy(
      filterPropertyInvest,
      "options_property_invest_category"
    );

    const convertGroupInvest = Object.keys(groupInvest).map((key) => {
      const amount = groupInvest[key].reduce((prev, curr) => {
        const itemKeys = Object.keys(curr);
        for (const itemKey of itemKeys) {
          const itemValue = curr[itemKey];
          if (_.isArray(itemValue)) {
            return (
              prev + itemValue.reduce((prev, curr) => prev + curr.amount, 0)
            );
          }
        }
      }, 0);
      return {
        key,
        label: key_property_invest_category[key],
        value: amount,
      };
    });

    const newItems = [];
    const total = convertGroupInvest.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    for (const convertGroupInvestItem of convertGroupInvest) {
      const createItem = {
        label: convertGroupInvestItem.label,
        value: (convertGroupInvestItem.value / total) * 100,
      };
      newItems.push(createItem);
    }

    // clean data
    const cleanItems = newItems.map((item) => {
      return {
        label: item.label,
        value: toFloat2(item.value),
      };
    });

    return cleanItems;
  }, [balanceData]);

  

  // console.log("my port",items)
  // console.log("balanceData",balanceData)

  return (
    <div className=" space-y-5 mt-5">
      {isReport ? (
        <div>
          <CardResult
            icon={false}
            title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
            panel={
              <div>
                <DonutChartInvestMain
                  className=" w-40 h-40 flex justify-center mx-auto -mt-3"
                  classNameDetail="flex justify-center items-center -mt-3"
                  width={200}
                  height={200}
                  rotate="0"
                  items={items}
                />
              </div>
            }
          />
        </div>
      ) : isOverview ? (
        <div>
          <DonutChartInvestMain
            className=" w-40 h-40 flex justify-center mx-auto -mt-10"
            classNameDetail="flex justify-center items-center -mt-2"
            width={200}
            height={200}
            rotate="0"
            items={items}
          />
        </div>
      ) : (
        <div>
          {" "}
          <div className=" text-mainblue font-semibold text-xl">
            {"พอร์ตของคุณ"}
          </div>
          <CardResult
            icon={false}
            title="พอร์ตเป้าหมายระยะยาว 7 ปีขึ้นไป "
            panel={
              <div>
                {items?.length > 0 ? (
                  <DonutChartInvestMain rotate="0" items={items} />
                ) : (
                  <div className="flex flex-col text-center text-[13px] font-medium mt-2">
                    “กรุณากรอข้อมูลใน งบดุล {">"} สินทรัพย์ {">"}{" "}
                    สินทรัพย์เพื่อการลงทุน
                    เพื่อให้ระบบมีข้อมูลในการวิเคราะห์การลงทุนของคุณ“
                  </div>
                )}
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default ViewMyPortResult;
