import { NumericFormat } from "react-number-format";
import DropDownMain from "../../../../components/dropdown/DropDownMain";
import { ImageMain } from "../../../../components/img/Img.Main";
import { ICON_DELETE, ICON_INFO, ICON_RIGHT } from "../../../../utils/useIcons";
import {
  STYLE_INPUT_CARD_BALANCE,
  STYLE_PARENT_CARD_BALANCE,
  STYLE_TEXTAREA_CARD_BALANCE,
  STYLE_TITLE_CARD_DRAWER_BL,
} from "../../../../utils/useStyle";
import { CheckBoxSecond } from "../../../../components/checkbox/CheckboxSecond";
import DropDownGroupMain from "../../../../components/dropdown/DropDownGroupMain";
import { InputMain } from "../../../../components/input/InputMain";

export const CardMenuPremium = ({
  img,
  title,
  onClick,
  hasDetails,
  onClickOpen,
  open,
}) => {
  return (
    <div
      className={`bg-gray50 group hover:bg-blueopacity duration-200 px-[18px] py-2.5 rounded-[20px]   cursor-pointer`}
    >
      <div className="flex items-center  ">
        <div className={`flex items-center space-x-[17px] w-full  `}>
          <ImageMain src={img} className=" w-[50px] h-[50px] " />
          <div className=" text-sm text-black font-semibold ">{title}</div>
        </div>
        <div className={`flex items-center justify-end `}>
          <ICON_RIGHT
            className={` w-6 h-6 flex-none ml-auto text-[#14181F] `}
            onClick={onClick}
          />
        </div>
      </div>

      {hasDetails ? (
        <div onClick={onClickOpen} className="  ">
          {open ? (
            <div className="text-xs  text-maingray  text-center underline underline-offset-2 w-full col-span-full">
              ซ่อนดูรายละเอียด
            </div>
          ) : (
            <div className="text-xs  text-maingray  text-center underline underline-offset-2  w-full col-span-full">
              ดูรายละเอียด
            </div>
          )}
          {open && (
            <div className=" grid grid-cols-2 mt-2 ">
              <div className=" text-maingray text-sm">รวมปีละ</div>
              <div className=" text-mainblue font-medium ml-auto">{0} บาท</div>
              <div className=" text-maingray text-sm">ภาษีหัก ณ ที่จ่าย</div>
              <div className=" text-mainblue font-medium ml-auto">{0} บาท</div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const CardTaxPremium = ({
  index,
  isActive = false,
  options,
  value_dropdown,
  optionsGroup = false,
  openInfo,
  expressTitle = " การคำนวณค่าใช้จ่าย ",
  ishasCal = true,
  dropdown_title = "ประเภทธุรกิจ",
  isHasCompany = false,
  isHastitle1 = true,
  title1 = "เงินได้ทั้งหมด",
  dropdown_title_2 = "อัตราภาษี",
  options_2,
  value_dropdown_2,
  isHasTaxPercent = false,
  isHasDividend = false,
  isHasDropDown1 = true,
  isHasDate1 = false,
  isHasDate2 = false,
  isHasPay = false,
  isHasLand = false,
}) => {
  return (
    <div className=" bg-white rounded-[20px] px-4 pt-3 pb-[14px] mt-4 ">
      <div className=" flex justify-between">
        <div className=" text-base text-mainblue font-semibold">
          รายการที่{index}
        </div>
        <ICON_DELETE
          className=" w-5 h-5 ml-auto cursor-pointer"
          // onClick={onClickDelete}
        />
      </div>

      {isHasDropDown1 ? (
        <div>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
            {dropdown_title}
          </div>
          {optionsGroup ? (
            <DropDownGroupMain options={options} value={value_dropdown} />
          ) : (
            <DropDownMain
              className="border border-gray80 rounded-full"
              options={options}
              value={value_dropdown}
            />
          )}
        </div>
      ) : (
        ""
      )}
      {isHasDate1 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            วัน/เดือน/ปีที่โอน
          </div>
          <InputMain
            type="date"
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHasPay ? (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ค่าตอบแทน
            </div>
            <div className="flex items-center mb-3 space-x-6 col-span-3 justify-end">
              <CheckBoxSecond
                isLeft={true}
                name={`type_save`}
                label_title={`มี`}
                value={`personal`}
              />
              <CheckBoxSecond
                isLeft={true}
                name={`type_save`}
                label_title={`ไม่มี`}
                value={`investment`}
              />
            </div>
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เลขที่ใบเสร็จรับเงิน
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {isHasDate2 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            วัน/เดือน/ปี เลขที่ใบเสร็จรับเงิน
          </div>
          <InputMain
            type="date"
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHasLand ? (
        <>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เลขที่โฉนด
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ระวางโฉนด
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              มูลค่า (ราคาประเมิน)
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              หัก ณ ที่จ่าย
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={``}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL}`}>รายละเอียด</div>
            <textarea
              //  value={value}
              className={`${STYLE_TEXTAREA_CARD_BALANCE} w-full`}
              placeholder="ระบุ"
            />
          </div>
        </>
      ) : (
        ""
      )}

      {isHasCompany ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            ชื่อบริษัท
          </div>
          <InputMain
            placeholder="ระบุ"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHastitle1 ? (
        <div className={STYLE_PARENT_CARD_BALANCE}>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
            {title1}
          </div>

          <NumericFormat
            maxLength={11}
            placeholder="0"
            // value={currentPrice}
            // onChange={onChangeCurrentPrice}
            thousandSeparator=","
            inputMode="decimal"
            pattern="[0-9],*"
            className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
          />
        </div>
      ) : (
        ""
      )}

      {isHasTaxPercent ? (
        <div>
          <div className={`${STYLE_TITLE_CARD_DRAWER_BL}  `}>
            {dropdown_title_2}
          </div>
          <DropDownMain
            className="border border-gray80 rounded-full"
            options={options_2}
            value={value_dropdown_2}
          />
        </div>
      ) : (
        ""
      )}

      {isHasDividend ? (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              จำนวนเงินปันผล/ ส่วนแบ่งกำไร
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              เครดิตภาษี
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ภาษีหัก ณ ที่จ่าย
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
        </div>
      ) : isHasLand ? (
        ""
      ) : (
        <div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              ภาษีหัก ณ ที่จ่าย
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          <div className={STYLE_PARENT_CARD_BALANCE}>
            <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
              <div className="flex gap-1">
                {" "}
                <div>เลขผู้จ่ายเงินได้</div>{" "}
                <ICON_INFO
                  className={` w-4 h-4 text-gray-900`}
                  onClick={openInfo}
                />
              </div>
            </div>
            <NumericFormat
              maxLength={11}
              placeholder="0"
              // value={currentPrice}
              // onChange={onChangeCurrentPrice}
              thousandSeparator=","
              inputMode="decimal"
              pattern="[0-9],*"
              className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
            />
          </div>
          {ishasCal ? (
            <>
              <div className="font-semibold text-black mt-2 text-sm">
                {expressTitle}
              </div>
              <CheckBoxSecond
                isLeft={true}
                label_title={"หักแบบเหมา  (xx %)"}
              />
              <div className=" flex justify-between items-center mt-3">
                <div className="font-semibold text-black  text-sm">
                  เงินที่ต้องไปคำนวณภาษี
                </div>
                <div
                  className={`${
                    isActive
                      ? "text-maincyan font-medium"
                      : "text-maingray font-medium"
                  } `}
                >
                  {"-"} บาท
                </div>
              </div>
              <div className="text-maingray text-xs space-y-1">
                *ไม่ต้องแสดงหลักฐาน
              </div>
              <CheckBoxSecond isLeft={true} label_title={"หักตามจริง"} />
              <div className={STYLE_PARENT_CARD_BALANCE}>
                <div className={`${STYLE_TITLE_CARD_DRAWER_BL} col-span-2 `}>
                  ระบุจำนวนเงิน
                </div>
                <NumericFormat
                  maxLength={11}
                  placeholder="0"
                  // value={currentPrice}
                  // onChange={onChangeCurrentPrice}
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT_CARD_BALANCE} col-span-3 ml-1`}
                />
              </div>
              <div className=" flex justify-between items-center mt-3">
                <div className="font-semibold text-black  text-sm">
                  เงินที่ต้องไปคำนวณภาษี
                </div>
                <div
                  className={`${
                    isActive
                      ? "text-maincyan font-medium"
                      : "text-maingray font-medium"
                  } `}
                >
                  {"-"} บาท
                </div>
              </div>
              <div className="text-maingray text-xs space-y-1">
                *ต้องแจกแจงและแสดงหลักฐานเพิ่มเติม
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
