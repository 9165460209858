import { useEffect, useMemo } from "react";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { HeaderReport } from "../components/header.main";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { useNavigate } from "react-router-dom";
import { CardReport } from "../components/cardMenu";
import numeral from "numeral";
import { useBalanceStore } from "../../../_store/balanceStore";
import { MTButton } from "../../../components/button/MTButton";
import { STYLE_REPORT_CONTAIN } from "../../../utils/useStyle";
import ViewMyPortResult from "../../invest/component/VIMyPortResult.Main";
import { cn, mapStatus } from "../../../utils/useFunctions";
import { useRetireStore } from "../../../_store/retireStore";
import { useTaxStore } from "../../../_store/taxplanStore";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  ICON_BAHT,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_GRAPH,
  ICON_SAVING,
  ICON_STAR,
  ICON_WARNING,
} from "../../../utils/useIcons";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import {
  dataAge,
  dataAgeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
} from "../../retirement_plan/functions/functionRetriementPlan";
import { calPMT } from "../../education/functions/function";
import _ from "lodash";
import LineChartMain from "../../../components/chart/LineChartMain";

const OverAllSection = ({ balanceData }) => {
  return (
    <div className=" grid grid-cols-2 gap-2.5 items-center">
      <CardReport
        title={"รายรับรวมทั้งหมด"}
        panel={
          <div className=" font-semibold text-maingreen text-xl  py-4">
            {numeral(balanceData?.salary_propotion)?.format("0,0")} บาท
          </div>
        }
      />
      <CardReport
        title={"หนี้สินรวมทั้งหมด"}
        panel={
          <div className=" font-semibold text-mainred text-xl py-4">
            {numeral(balanceData?.debt_propotion)?.format("0,0")} บาท
          </div>
        }
      />
      <CardReport
        title={"รายจ่ายรวมทั้งหมด"}
        panel={
          <div className=" font-semibold text-mainyellow text-xl  py-4">
            {numeral(balanceData?.expenses_propotion)?.format("0,0")} บาท
          </div>
        }
      />
      <CardReport
        title={"สินทรัพย์รวมทั้งหมด"}
        panel={
          <div className=" font-semibold text-mainpurple text-xl  py-4">
            {numeral(balanceData?.property_propotion)?.format("0,0")} บาท
          </div>
        }
      />
    </div>
  );
};

const OverAllSection2 = ({
  valueProperty,
  getPropertyStatus,
  valueDebttoTotalAsset,
  getDebttoTotalAssetStatus,
  valueDebttoSalary,
  getDebttoSalaryStatus,
  valueConservation,
  getConservationStatus,
  valueInvest,
  getInvestStatus,
}) => {
  return (
    <div className=" bg-gray50 w-full h-full rounded-[20px] py-2.5 px-[15px] flex flex-col gap-[6px] ">
      <div className="flex flex-col space-y-[6px]">
        {[
          {
            title: "สภาพคล่อง",
            value: valueProperty,
            status: getPropertyStatus(),
            prefixValue: "เท่า",
          },
          {
            title: "หนี้สินต่อทรัพย์สิน",
            value: valueDebttoTotalAsset,
            status: getDebttoTotalAssetStatus(),
            prefixValue: "%",
            formatValue: "0.00",
          },
          {
            title: "การชำระหนี้ต่อรายได้",
            value: valueDebttoSalary,
            status: getDebttoSalaryStatus(),
            prefixValue: "%",
            formatValue: "0.00",
          },
          {
            title: "การออม",
            value: valueConservation,
            status: getConservationStatus(),
            prefixValue: "%",
            formatValue: "0.00",
          },
          {
            title: "การลงทุน",
            value: valueInvest,
            status: getInvestStatus(),
            prefixValue: "%",
            formatValue: "0.00",
          },
        ].map((item, index) => {
          return (
            <div key={index} className="flex justify-between">
              <div className="flex space-x-2 text-sm">
                <div className=" font-semibold ">{item?.title}</div>
                <div className=" text-mainblue font-bold ">
                  {numeral(item?.value).format(item.formatValue ?? "0")}{" "}
                  {item?.prefixValue}
                </div>
              </div>

              <div
                className={cn(
                  " font-bold ",
                  {
                    "text-[#FF0000]": item?.status === "bad",
                    "text-maingreen": item?.status === "good",
                    "text-mainblue": item?.status === "chance",
                    "text-maingray": mapStatus(item?.status) === "ไม่มีข้อมูล",
                  },
                  item?.classNameValue
                )}
              >
                {mapStatus(item?.status)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const NetWealthSection = ({ balanceData }) => {
  return (
    <div>
      <CardReport
        icon_head={"/images/moneybag-report.png"}
        title={"ความมั่งคั่งสุทธิ"}
        panel={
          <div className=" px-2.5 py-[14px]">
            <div className=" grid grid-cols-2 gap-2 w-full h-full">
              <div className=" w-full h-full bg-[#C4F3BD] rounded-[4px]  ">
                <div className=" flex flex-col justify-center items-center h-full ">
                  <img
                    src="/images/wealth_green.svg"
                    className="w-[54px] h-[54px]"
                    alt=""
                  />
                  <div className=" text-mainblue font-medium text-base mt-[3px] ">
                    สินทรัพย์
                  </div>
                  <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                    {numeral(balanceData?.property_propotion).format("0,0")} บาท
                  </div>
                </div>
              </div>
              <div className=" grid grid-rows-2 gap-[6px] ">
                <div className=" bg-[#FFCCCC] w-full h-full  rounded-[4px] py-2">
                  <div className=" flex flex-col justify-center items-center h-full ">
                    <img
                      src="/images/debt_pdf2.svg"
                      className="w-[54px] h-[54px]"
                      alt=""
                    />
                    <div className=" text-mainblue font-medium text-base mt-[3px] ">
                      หนี้สิน
                    </div>
                    <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                      {numeral(balanceData?.debt_propotion).format("0,0")} บาท
                    </div>
                  </div>
                </div>
                <div className=" bg-[#CEE3FF] w-full h-full rounded-[4px] py-2">
                  <div className=" flex flex-col justify-center items-center h-full ">
                    <img
                      src="/images/wealth_blue.svg"
                      className="w-[54px] h-[54px]"
                      alt=""
                    />
                    <div className=" text-mainblue font-medium text-base mt-[3px] ">
                      ความมั่งคั่งสุทธิ
                    </div>
                    <div className="text-mainblue font-semibold text-base leading-3 pt-1">
                      {numeral(balanceData?.net_wealth).format("0,0")} บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export const RetireBeforeSection = ({ dataPlanBf, dataAge, getDetailBF }) => {
  return (
    <div>
      <CardReport
        icon_head={"/images/grandparents-report.png"}
        title={"ก่อนวางแผน (ไม่สามารถเกษียณได้)"}
        panel={
          <div>
            <div className=" px-4  mx-auto">
              <LineChartMain
                axisSize={6}
                labels={dataAge()}
                dataPlan={dataPlanBf}
                fontSize="text-sm"
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className="flex flex-col space-y-1 mt-1">
              {getDetailBF().map((item, index) => {
                return (
                  <div key={index} className="flex gap-2.5">
                    <div>{item?.icon}</div>
                    {item?.render && item?.render()}
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    </div>
  );
};

export const RetireAfterSection = ({ dataPlanAF, dataAgeAF, getDetailAF }) => {
  return (
    <div>
      <CardReport
        icon_head={"/images/grandparents-report.png"}
        title={"หลังปรับกลยุทธ์ (สามารถเกษียณได้) "}
        panel={
          <div>
            <div className=" px-4 mx-auto">
              <LineChartMain
                axisSize={6}
                labels={dataAgeAF()}
                dataPlan={dataPlanAF}
                fontSize="text-sm"
                head={`สินทรัพย์เพื่อการลงทุนสุทธิ`}
              />
            </div>
            <div className="flex flex-col space-y-1 mt-1">
              {getDetailAF().map((item, index) => {
                return (
                  <div key={index} className="flex gap-2.5">
                    <div>{item?.icon}</div>
                    {item?.render && item?.render()}
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    </div>
  );
};

const TaxSection = ({
  taxData,
  getCaltax,
  displaySumAfterTaxPaid,
  displayTaxPaid,
  displaySumTax,
  checkPercent,
}) => {
  return (
    <div>
      <CardReport
        icon_head={"/images/tax-report.png"}
        title={"ภาษีที่ต้องจ่าย"}
        panel={
          <div>
            <div className=" px-2.5">
              <HalfChartMain
                chartWidth={"w-[180px] mt-10"}
                fontSize="text-xl"
                text={false}
                value={`0`}
                beforevalue={getCaltax()}
                aftervalue={taxData?.after_tax_paid}
                maxvalue={displayTaxPaid}
                mtText="-mt-20"
              />
              <div className=" flex justify-center gap-2.5 w-full -mt-10 ">
                <div
                  className={`bg-gray50 rounded-md py-[7px] px-2 w-20 text-mainblue text-sm font-semibold  h-6 flex gap-[5px] justify-center items-center`}
                >
                  <ICON_GRAPH className="w-6 h-6 text-maincyan " />
                  <span className=" flex-none"> {checkPercent()} %</span>
                </div>
                <div
                  className={`bg-gray50 rounded-md py-[7px] px-2 w-20 text-mainblue text-sm font-semibold  h-6 flex gap-[5px] justify-center items-center`}
                >
                  <ICON_BAHT className="w-6 h-6 text-maincyan " />
                  {numeral(displaySumTax).format("0,0")}
                </div>
              </div>
              <div className="flex flex-col space-y-0.5 mt-1 text-sm">
                {[
                  {
                    title: "ก่อนวางแผน",
                    value: String(getCaltax()) === "Infinity" ? "0" : getCaltax(),
                    classNameValue: "text-mainred",
                  },
                  {
                    title: "หลังวางแผน",
                    value:
                      String(displaySumAfterTaxPaid()) === "Infinity"
                        ? "0"
                        : displaySumAfterTaxPaid(),
                    classNameValue: "text-maingreen",
                  },
                  {
                    title: "ภาษีหัก ณ ที่จ่าย",
                    value: taxData?.tax_withholding,
                    classNameValue: "text-mainblue",
                  },
                ].map((item, index) => {
                  return (
                    <div key={index} className="flex justify-between">
                      <div className=" font-medium text-black">
                        {item?.title}
                      </div>
                      <div className={cn("  font-medium")}>
                        <span
                          className={cn(
                            " mr-2.5 font-bold",
                            item?.classNameValue
                          )}
                        >
                          {" "}
                          {numeral(item?.value).format("0,0")}
                        </span>{" "}
                        บาท
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" flex justify-center items-center gap-[5px] font-bold mt-1 text-sm">
                <div className=" text-maincyan">ภาษีหักที่ต้องจ่าย</div>
                <div className=" text-mainorange">
                  {numeral(taxData?.final_tax)?.format("0,0")} บาท
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

const InvestSection = () => {
  return (
    <div>
      <CardReport
        icon_head={"/images/revenue-report.png"}
        title={"สัดส่วนการลงทุน"}
        panel={
          <div>
            <ViewMyPortResult
              // isReport={true}
              status={"status"}
              isOverview={true}
            />
          </div>
        }
      />
    </div>
  );
};

const ReacteportOverViewPage = () => {
  const navigate = useNavigate();
  const balanceData = useBalanceStore((state) => state.balanceData);
  const retireData = useRetireStore((state) => state.retireData);
  const taxData = useTaxStore((state) => state.taxData);

  // สภาพคล่อง
  const valueProperty = useMemo(() => {
    let sum = 0;
    const expenses_permonth = balanceData?.expenses_propotion / 12;
    if (balanceData?.property_propotion) {
      sum =
        expenses_permonth > 0
          ? balanceData?.property_propotion / expenses_permonth
          : 0;
    }
    return Math.round(sum);
  }, [balanceData]);
  const getPropertyStatus = () => {
    if (!balanceData?.property_propotion) return;

    if (valueProperty < 6) {
      return "bad";
    } else if (valueProperty === 6) {
      return "good";
    } else {
      return "chance";
    }
  };

  // หนี้สินต่อทรัพย์สิน
  const valueDebttoTotalAsset = useMemo(() => {
    let sum = 0;
    if (balanceData?.debt_propotion) {
      sum =
        balanceData?.property_propotion > 0
          ? (balanceData?.debt_propotion / balanceData?.property_propotion) *
            100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getDebttoTotalAssetStatus = () => {
    if (!balanceData?.debt_propotion) return;

    if (valueDebttoTotalAsset > 50) {
      return "bad";
    }
    return "good";
  };

  // ชำระหนี้ต่อรายได้
  const valueDebttoSalary = useMemo(() => {
    let sum = 0;
    const debtPermonth =
      balanceData?.sum_debt_short_permonth +
      balanceData?.sum_debt_long_permonth;
    const salaryPermonth = balanceData?.salary_propotion
      ? balanceData?.salary_propotion / 12
      : 0;
    sum = salaryPermonth > 0 ? (debtPermonth / salaryPermonth) * 100 : 0;
    return sum;
  }, [balanceData]);
  const getDebttoSalaryStatus = () => {
    if (
      !balanceData?.sum_debt_short_permonth &&
      !balanceData?.sum_debt_long_permonth
    )
      return;

    if (valueDebttoSalary > 50) {
      return "bad";
    }
    return "good";
  };

  // การออม
  const valueConservation = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_expenses_invest_saving) {
      sum = balanceData?.sum_expenses_invest_saving;
    }
    const finalResult =
      sum > 0 ? (sum / balanceData?.salary_propotion) * 100 : 0;
    return finalResult;
  }, [balanceData]);
  const getConservationStatus = () => {
    if (!balanceData?.sum_expenses_invest_saving) return;

    if (valueConservation < 10) {
      return "bad";
    }
    return "good";
  };

  // การลงทุน
  const valueInvest = useMemo(() => {
    let sum = 0;
    if (balanceData?.sum_property_invest) {
      sum =
        balanceData?.net_wealth > 0
          ? (balanceData?.sum_property_invest / balanceData?.net_wealth) * 100
          : 0;
    }
    return sum;
  }, [balanceData]);
  const getInvestStatus = () => {
    if (!balanceData?.sum_property_invest) return;

    if (valueInvest > 50) {
      return "good";
    }
    return "bad";
  };

  // ภาษี
  const getCaltax = () => {
    let result = 0;
    result =
      taxData?.tax_income -
      taxData?.deduction_expenses -
      taxData?.deduct_summary;
    return calculateTax(result);
  };

  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(taxData?.tax_income) -
      Number(taxData?.deduction_expenses) -
      Number(taxData?.deduct_summary_af);

    return calculateTax(result);
  };

  const displayTaxPaid = useMemo(
    () => calculateTax(taxData?.tax_income),
    [taxData?.tax_income]
  );

  const displaySumTax = useMemo(
    () => checkSum(getCaltax(), taxData?.after_tax_paid, 0, 0),
    [getCaltax(), taxData?.after_tax_paid, 0, 0]
  );

  const checkPercent = () => {
    let result = 0;
    result = ((getCaltax() - taxData?.after_tax_paid) / getCaltax()) * 100;
    if (isNaN(result)) {
      return 0;
    }
    return Number(result)?.toFixed(2);
  };

  // วางแผนเกษียณ
  const getInvestSum = loopInvestmentAssets();
  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainBF = getremainBF?.age - 1;
  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = beforeDiffYear * 12;
    const sumPMT = calPMT(
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultBF?.results * -1
    );
    return sumPMT;
  }, [retireData]);
  const strategyChange = retireData?.strategy_sum ? true : false;

  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSumAF = loopInvestmentAssetsAF();
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];
  const remainAF = Number(getremainAF?.age) - 1;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMT(
      retireData?.strategy_investment_bf_retirement / 100 / 12,
      convertedMonth,
      -lastResultAF?.results * -1
    );
    return sumPMT;
  }, [retireData]);

  const getDetailBF = () => {
    if (strategyChange) {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-6 h-6 text-maincyan" />,

            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ
                  </div>
                  <div
                    className={cn(
                      "text-sm font-semibold text-mainblue flex space-x-1"
                    )}
                  >
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}
                    </div>
                    <span className=" font-normal">ปี</span>
                  </div>
                </div>
              );
            },
          },
          {
            icon: <ICON_WARNING className=" text-mainyellow" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-sm flex space-x-1")}>
                    <div className="font-semibold ">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className=" font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,

          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1 ")}>
                  <div className="font-semibold">
                    {_.isNaN(remainBF) ? "-" : remainBF}
                  </div>
                  <span className=" font-normal">ปี</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-6 h-6 text-mainred" />,
          render: () => {
            return (
              <div className="">
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="text-sm ">
                    ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ
                  </div>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
                <div className=" flex gap-1 text-sm">
                  <div>ยังขาดอยู่ทั้งหมด</div>
                  <div className={cn("text-sm font-semibold")}>
                    {lastResultBF?.results
                      ? numeral(
                          lastResultBF?.results < 0
                            ? lastResultBF?.results * -1
                            : lastResultBF?.results
                        ).format("0,0")
                      : "-"}
                    <span className="text-mainblue font-normal">บาท</span>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_SAVING className=" w-6 h-6 text-maincyan" />,

          render: () => {
            return (
              <div className="">
                <div className="text-sm flex gap-1">
                  ด้วยการออมเดือนละ
                  <div className={cn("text-sm font-semibold text-mainblue")}>
                    {_.isNaN(sumBeforeRetireInvest)
                      ? "-"
                      : numeral(sumBeforeRetireInvest).format("0,0")}
                    <span className=" font-normal">บาท</span>
                  </div>
                </div>

                <div className="text-sm text-left">
                  จะทำให้คุณสามารถเกษียณได้
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-6 h-6 text-maincyan" />,

            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ
                  </div>
                  <div className={cn("text-sm font-semibold flex space-x-1")}>
                    <div>จำนวน</div>
                    <div className="">
                      {_.isNaN(retireBF)
                        ? "-"
                        : numeral(retireBF)?.format("0,0.00")}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-sm flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(remainBF) ? "-" : remainBF}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-6 h-6 text-mainred" />,

          render: () => {
            return (
              <div className=" flex gap-1 flex-wrap ">
                <div className="text-sm ">ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
                <div className="  text-sm">ยังขาดอยู่ทั้งหมด</div>
                <div className={cn("text-sm font-semibold")}>
                  {_.isNaN(lastResultBF?.results)
                    ? "-"
                    : numeral(
                        lastResultBF?.results < 0
                          ? lastResultBF?.results * -1
                          : lastResultBF?.results
                      ).format("0,0")}
                  <span className="text-mainblue font-normal ml-1">บาท</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-6 h-6 text-maincyan" />,

          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">ควรวางแผนลงทุนผลตอบแทน</div>
                <div className={cn("text-sm font-semibold")}>
                  {retireData?.investment_bf_retirement}
                  <span className="text-mainblue font-normal">
                    เป็นระยะเวลา{" "}
                    {_.isNaN(beforeDiffYear) ? "-" : beforeDiffYear} ปี เดือนละ{" "}
                  </span>
                  {_.isNaN(sumBeforeRetireInvest)
                    ? "-"
                    : numeral(sumBeforeRetireInvest)?.format("0,0")}
                </div>
              </div>
            );
          },
        },
      ];
    }
  };

  const getDetailAF = () => {
    if (strategyChange) {
      if (lastResultAF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-6 h-6 text-maincyan" />,
            // title: "คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ",
            // value: retireData?.age_current,
            // unit: "ปี",
            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ
                  </div>
                  <div className={cn("text-sm font-semibold flex space-x-1")}>
                    <div className="">
                      {_.isNaN(retireData?.age_current)
                        ? "-"
                        : retireData?.age_current}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                  </div>
                </div>
              );
            },
          },
          {
            icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,

            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-sm flex space-x-1")}>
                    <div className="font-semibold">
                      {retireData?.strategy_age_dead
                        ? retireData?.strategy_age_dead
                        : retireData?.age_dead || "-"}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,

          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(remainAF) ? "-" : remainAF}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-6 h-6 text-mainred" />,

          render: () => {
            return (
              <div className=" flex gap-1 flex-wrap ">
                <div className="text-sm ">ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
                <div className="  text-sm">ยังขาดอยู่ทั้งหมด</div>
                <div className={cn("text-sm font-semibold")}>
                  {_.isNaN(lastResultAF?.results)
                    ? "-"
                    : numeral(
                        lastResultAF?.results < 0
                          ? lastResultAF?.results * -1
                          : lastResultAF?.results
                      ).format("0,0")}
                  <span className="text-mainblue font-normal ml-1">บาท</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_SAVING className=" w-6 h-6 text-maincyan" />,

          render: () => {
            return (
              <div className=" flex gap-1 flex-wrap">
                <div className="text-sm ">ด้วยการออมเดือนละ</div>
                <div className={cn("text-sm font-semibold")}>
                  {_.isNaN(sumAfterRetireInvest)
                    ? "-"
                    : numeral(sumAfterRetireInvest).format("0,0")}
                  <span className="text-mainblue font-normal ml-1">บาท</span>
                </div>
                <div className="text-sm ">จะทำให้คุณสามารถเกษียณได้</div>
              </div>
            );
          },
        },
      ];
    } else {
      if (lastResultBF?.results > 0) {
        return [
          {
            icon: <ICON_CHECK className=" w-6 h-6 text-maincyan" />,

            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">
                    คาดการณ์สินทรัพย์เพื่อการลงทุน ณ อายุ
                  </div>
                  <div className={cn("text-sm font-semibold flex space-x-1")}>
                    <div>จำนวน</div>
                    <div className="">
                      {_.isNaN(remainAF)
                        ? "-"
                        : numeral(remainAF)?.format("0,0.00")}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,

            render: () => {
              return (
                <div className=" flex gap-1">
                  <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                  <div className={cn("text-sm flex space-x-1")}>
                    <div className="font-semibold">
                      {_.isNaN(retireData?.age_dead)
                        ? "-"
                        : retireData?.age_dead}
                    </div>
                    <span className="text-mainblue font-normal">ปี</span>
                    <div>แต่อย่าประมาทนะ</div>
                  </div>
                </div>
              );
            },
          },
        ];
      }
      return [
        {
          icon: <ICON_WARNING className=" w-6 h-6 text-mainyellow" />,

          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">สามารถใช้จนได้ถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {" "}
                    {_.isNaN(remainAF) ? "-" : remainAF}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_CLOSE className=" w-6 h-6 text-mainred" />,

          render: () => {
            return (
              <div className=" flex gap-1 flex-wrap ">
                <div className="text-sm ">ถ้าต้องการมีเงินใช้จ่ายจนถึงอายุ</div>
                <div className={cn("text-sm flex space-x-1")}>
                  <div className="font-semibold">
                    {_.isNaN(retireData?.age_dead) ? "-" : retireData?.age_dead}
                  </div>
                  <span className="text-mainblue font-normal">ปี</span>
                </div>
                <div className="  text-sm">ยังขาดอยู่ทั้งหมด</div>
                <div className={cn("text-sm font-semibold")}>
                  {_.isNaN(lastResultAF?.results)
                    ? "-"
                    : numeral(
                        lastResultAF?.results < 0
                          ? lastResultAF?.results * -1
                          : lastResultAF?.results
                      ).format("0,0")}
                  <span className="text-mainblue font-normal ml-1">บาท</span>
                </div>
              </div>
            );
          },
        },
        {
          icon: <ICON_STAR className=" w-6 h-6 text-maincyan" />,
          render: () => {
            return (
              <div className=" flex gap-1">
                <div className="text-sm ">ควรวางแผนลงทุนผลตอบแทน</div>
                <div className={cn("text-sm font-semibold")}>
                  {retireData?.investment_bf_retirement}
                  <span className="text-mainblue font-normal">
                    เป็นระยะเวลา {_.isNaN(afterDiffYear) ? "-" : afterDiffYear}{" "}
                    ปี เดือนละ{" "}
                  </span>
                  {_.isNaN(sumAfterRetireInvest)
                    ? "-"
                    : numeral(sumAfterRetireInvest)?.format("0,0")}
                </div>
              </div>
            );
          },
          // title: "ควรวางแผนออมเงิน / ลงทุนผลตอบแทน",
          // value: retireData?.investment_bf_retirement,
          // unit: `เป็นระยะเวลา ${afterDiffYear} ปี เดือนละ ${numeral(
          //   sumAfterRetireInvest
          // )?.format("0,0")}`,
        },
      ];
    }
  };

  const dataPlanBf = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      return bf_investment_assets?.results;
    }
  );

  // console.log("AAA ", balanceData);
  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Container>
      <div
        // id="first_reload"
        className="pt-[14px]"
      >
        <HeaderTitle
          title="รายงาน"
          isGoback
          onClickGoBack={() => {
            navigate("/report");
          }}
        />
        <div className={STYLE_REPORT_CONTAIN}>
          <HeaderReport
            icon={"/images/coin-report.png"}
            title={"ภาพรวมเป้าหมายทางการเงินของคุณ ​"}
          />
          <div className=" flex flex-col gap-5 mt-[17px] ">
            <OverAllSection balanceData={balanceData} />
            <OverAllSection2
              valueProperty={valueProperty}
              getPropertyStatus={getPropertyStatus}
              valueDebttoTotalAsset={valueDebttoTotalAsset}
              getDebttoTotalAssetStatus={getDebttoTotalAssetStatus}
              valueDebttoSalary={valueDebttoSalary}
              getDebttoSalaryStatus={getDebttoSalaryStatus}
              valueConservation={valueConservation}
              getConservationStatus={getConservationStatus}
              valueInvest={valueInvest}
              getInvestStatus={getInvestStatus}
            />
            <NetWealthSection balanceData={balanceData} />

            <RetireBeforeSection
              dataPlanBf={dataPlanBf}
              dataAge={dataAge}
              getDetailBF={getDetailBF}
            />
            <RetireAfterSection
              dataPlanAF={dataPlanAF}
              dataAgeAF={dataAgeAF}
              getDetailAF={getDetailAF}
            />
            <TaxSection
              taxData={taxData}
              getCaltax={getCaltax}
              displaySumAfterTaxPaid={displaySumAfterTaxPaid}
              displayTaxPaid={displayTaxPaid}
              displaySumTax={displaySumTax}
              checkPercent={checkPercent}
            />
            <InvestSection />
          </div>
          <div className=" flex justify-center">
            <MTButton
              onClick={() => {
                navigate("/report");
              }}
              text="ย้อนกลับ"
              color="bg-white"
              border="border border-maincyan"
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReacteportOverViewPage;
