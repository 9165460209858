import { Drawer } from "@material-tailwind/react";

import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_BALANCE,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_IMG_BALANCE,
  STYLE_TITLE_BALANCE,
  STYLE_TITLE_PROPOTION,
  STYLE_VALUE_BALANCE,
} from "../../../utils/useStyle";
import { useBalanceContext } from "../Balance.Main";
import numeral from "numeral";
import { useEffect, useMemo } from "react";
import { ICON_NAVIGATE_BALANCE } from "../../../utils/useIcons";
import {
  DonutChartEmpty,
  DonutChartMain,
  DonutChartProperty,
} from "../../../components/chart/DonutChartMain";
import { BoxWhite } from "../styles/Card";
import { useBalanceStore } from "../../../_store/balanceStore";

const ViewPropertyProportion = ({ onClick }) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  return (
    <div>
      <div className={STYLE_CARD_PROPOTION}>
        <div className={`${STYLE_HEADER_SECTION_PROPOTION} `}>
          <div className="">
            <div className={STYLE_TITLE_PROPOTION}>สัดส่วนสินทรัพย์</div>
            <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
          </div>
          <ICON_NAVIGATE_BALANCE onClick={onClick} />
        </div>

        {balanceData?.property_propotion <= 0 ||
        isNaN(balanceData?.property_propotion) ? (
          <DonutChartEmpty text={`สินทรัพย์`} />
        ) : (
          <DonutChartProperty
            text={`สินทรัพย์`}
            value={numeral(balanceData?.property_propotion).format("0,0")}
            data1={balanceData?.sum_property_asset}
            data2={balanceData?.sum_property_invest}
            data3={balanceData?.sum_property_private}
            data4={balanceData?.sum_property_intangible}
          />
        )}
        <BoxWhite
          title="สินทรัพย์สภาพคล่อง"
          icon="/images/proporty_asset2.png"
          colortext="text-purple700"
          value={balanceData?.sum_property_asset}
        />
        <BoxWhite
          title="สินทรัพย์เพื่อการลงทุน"
          icon="/images/proporty_invest3.svg"
          colortext="text-purple400"
          value={balanceData?.sum_property_invest}
        />
        <BoxWhite
          title="สินทรัพย์ใช้ส่วนตัว"
          icon="/images/proporty_private2.svg"
          colortext="text-purple500"
          value={balanceData?.sum_property_private}
        />
        <BoxWhite
          title="สินทรัพย์ไม่มีตัวตน"
          icon="/images/proporty_Intangible2.png"
          colortext="text-purple600"
          value={balanceData?.sum_property_intangible}
        />
      </div>
    </div>
  );
};

export default ViewPropertyProportion;
