import numeral from "numeral";
import { cn } from "../../../utils/useFunctions";
import { STYLE_PDF_ITEM_TOTAL } from "../styles/pdf.style";

export function PdfCard({
  title,
  title2,
  classNameRoot,
  children,
  className,
  classNameTitle,
}) {
  return (
    <div
      className={cn(
        "rounded-[20px] p-[15px] w-full bg-blueopacity my-4",
        classNameRoot
      )}
    >
      {title && (
        <div
          className={cn(
            "text-center text-[12px] mb-3 flex flex-col",
            classNameTitle
          )}
        >
          <div>{title}</div>
          {title2 && <div>{title2}</div>}
        </div>
      )}
      <div className={className}>{children}</div>
    </div>
  );
}

export function PdfCardWhite({ children, className }) {
  return (
    <div
      className={cn(
        "rounded-[10px] px-[5px] py-[10px] w-full bg-white h-full",
        className
      )}
    >
      {children}
    </div>
  );
}

export function PdfCardGray({ children, className }) {
  return (
    <div
      className={cn(
        "rounded-[10px] px-[10px] py-[5px] w-full bg-cardGray",
        className
      )}
    >
      {children}
    </div>
  );
}

export const PdfBalancePropertyItemCard = ({ item, className }) => {
  return (
    <div
      className={cn(
        " grid grid-cols-4 px-0 mt text-[11px] font-[600]",
        className
      )}
    >
      <div className="text-left col-span-2">{item?.label}</div>
      <div
        className={cn("text-center", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {numeral(item?.amount_per_year).format("0,0")}
      </div>
      <div
        className={cn("text-right", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {item?.returns ? `${numeral(item?.returns).format("0")} %` : "%"}
      </div>
    </div>
  );
};

export const PdfBalanceItemCard = ({ item, className }) => {
  return (
    <div
      className={cn(
        " grid grid-cols-5 px-0 mt text-[11px] font-[600]",
        className
      )}
    >
      <div className="text-left col-span-2">{item?.label}</div>
      <div
        className={cn("text-center", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {numeral(item?.amount_per_month).format("0,0")}
      </div>
      <div
        className={cn("text-center", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {numeral(item?.amount_per_year).format("0,0")}
      </div>
      <div
        className={cn("text-right", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {item?.returns ? `${numeral(item?.returns).format("0")} %` : "%"}
      </div>
    </div>
  );
};

export const PdfBalancePropertyInvestItemCard = ({ item, className }) => {
  return (
    <div
      className={cn(" flex w-full px-0 mt text-[11px] font-[600]", className)}
    >
      <div className="text-left w-[55%]">{item?.label}</div>
      <div
        className={cn("text-center w-[20%]", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {numeral(item?.amount_per_year).format("0,0")}
      </div>
      <div
        className={cn("text-center w-[16%]", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {numeral(item?.reward_amount_per_year).format("0.00")}
      </div>
      <div
        className={cn("text-right w-[8%]", {
          [STYLE_PDF_ITEM_TOTAL]: item?.label === "รวม",
        })}
      >
        {item?.returns ? `${numeral(item?.returns).format("0")} %` : "%"}
      </div>
    </div>
  );
};

export const PdfBalancePropertyHeaderItemCard = ({ title, className }) => {
  return (
    <div
      className={cn(
        " grid grid-cols-4 px-4 mt-2 text-[11px] font-[600]",
        className
      )}
    >
      <div className="text-left col-span-2">{title}</div>
      <div className="text-center">จำนวน (บาท)</div>
      <div className="text-right">%</div>
    </div>
  );
};

export const PdfBalanceHeaderItemCard = ({ title, className }) => {
  return (
    <div
      className={cn(
        " grid grid-cols-5 px-4 mt-2 text-[11px] font-[600]",
        className
      )}
    >
      <div className="text-left col-span-2">{title}</div>
      <div className="text-center">ต่อเดือน (บาท)</div>
      <div className="text-center">ต่อปี (บาท)</div>
      <div className="text-right">%</div>
    </div>
  );
};

export const PdfBalancePropertyInvestHeaderItemCard = ({
  title,
  className,
}) => {
  return (
    <div
      className={cn(" flex px-4 mt-2 w-full text-[11px] font-[600]", className)}
    >
      <div className="text-left w-[55%]">{title}</div>
      <div className="text-center w-[20%]">มูลค่าปัจจุบัน (บาท)</div>
      <div className="text-center w-[16%]">ผลตอบแทน (%)</div>
      <div className="text-right w-[8%]">%</div>
    </div>
  );
};

export const PdfFamilyHeaderItemCard = ({ title, className }) => {
  return (
    <div
      className={cn(
        " grid grid-cols-2 px-4 mt-2 text-[11px] font-[600]",
        className
      )}
    >
      <div className="text-left">{title}</div>
      <div className="text-right">ต่อปี (บาท)</div>
    </div>
  );
};
