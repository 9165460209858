import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useTaxStore } from "../../../_store/taxplanStore";

function PdfPageTaxTwo({ page }) {
  const taxData = useTaxStore((state) => state.taxData);

  const deductionItems = useMemo(() => {
    const newDeductionItems = [
      {
        label: "ลดหย่อนผู้มีเงินได้",
        value: 60000 || "",
      },
      {
        label: "คู่สมรส",
        value: taxData?.tax_spouse || "",
      },
      {
        label: "ลูก",
        value: taxData?.deduct_child || "",
      },
      {
        label: "แม่",
        value: taxData?.deduct_mother || "",
      },
      {
        label: "พ่อ",
        value: taxData?.deduct_father || "",
      },
      {
        label: "เลี้ยงดูคนพิการ",
        value: taxData?.deduct_disable_people || "",
      },
      {
        label: "เลี้ยงดูคนทุพพลภาพ",
        value: taxData?.deduct_disabilities_people || "",
      },
      {
        label: "เบี้ยประกันสุขภาพบิดามารดา",
        value: taxData?.deduct_insurance_premiums_parents || "",
      },
    ];
    return newDeductionItems;
  }, [taxData]);

  const deductionItems2 = useMemo(() => {
    const newDeductionItems = [
      {
        label: "ประกันชีวิต",
        value: taxData?.tax_plan_insurance_life || "",
      },
      {
        label: "ประกันสุขภาพ",
        value: taxData?.tax_plan_insurance_health || "",
      },
      {
        label: "ประกันชีวิตแบบบำนาญ",
        value: taxData?.tax_plan_insurance_annuity || "",
      },
      {
        label: "เงินสะสมการออมแห่งชาติ",
        value: taxData?.tax_fund_nsf || "",
      },
      {
        label: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
        value: taxData?.tax_fund_esg || "",
      },
      {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)",
        value: taxData?.tax_plan_rmf || "",
      },
      {
        label: "กองทุนรวมเพื่อการออม (SSF)",
        value: taxData?.tax_plan_ssf || "",
      },
      {
        label: "เงินบริจาคแก่พรรคการเมือง",
        value: taxData?.tax_plan_political_donate || "",
      },
    ];
    return newDeductionItems;
  }, [taxData]);

  const total = useMemo(() => {
    let sum_total = deductionItems.reduce((acc, item) => {
      return acc + item.value;
    }, 0);
    return sum_total;
  }, [taxData]);

  const total2 = useMemo(() => {
    let sum_total = deductionItems2.reduce((acc, item) => {
      return acc + item.value;
    }, 0);
    return sum_total;
  }, [taxData]);

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText
        title="วางแผนภาษี"
        icon="/images/tax_plan.svg"
        classNameIcon="w-[24px] h-[27px]"
      />
      {/* วางแผนภาษี */}
      <PdfCard className={"flex flex-col space-y-3"} title={"ค่าลดหย่อน"}>
        {/* ก่อนวางแผน */}
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn("flex items-center space-x-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>ก่อนวางแผน</div>
          </div>

          <div
            className={cn(
              "flex justify-between mx-1 mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>รายการลดหย่อน</div>
            <div>ต่อปี</div>
          </div>

          <PdfLine />

          <div className={cn("mt-1 flex items-center space-x-2")}>
            <div>
              <img src="/images/salary_2.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
              ค่าลดหย่อนส่วนตัวและครอบครัว
            </div>
          </div>

          <PdfCardGray className={cn("px-3 mt-1")}>
            {deductionItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between space-x-1 mt-1"
                >
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {item?.label}
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                    {numeral(item?.value).format("0,0")} บาท
                  </div>
                </div>
              );
            })}
          </PdfCardGray>

          <div className="flex space-x-1 justify-center mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>รวม</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(total).format("0,0")} บาท
            </div>
          </div>
        </PdfCardWhite>

        {/* หลังวางแผน */}
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn("flex items-center space-x-1")}>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>หลังวางแผน</div>
          </div>

          <div
            className={cn(
              "flex justify-between mx-1 mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>รายการลดหย่อนทั้งหมดหลังวางแผนเพิ่มเติม</div>
            <div>ต่อปี</div>
          </div>

          <PdfLine />

          <div className={cn("mt-1 flex items-center space-x-2")}>
            <div>
              <img src="/images/profit_growth_pdf.svg" />
            </div>

            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
              ค่าลดหย่อน/ยกเว้น ด้านการออมและการลงทุน
            </div>
          </div>

          <PdfCardGray className={cn("px-3 mt-1")}>
            {deductionItems2.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between space-x-1 mt-1"
                >
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                    {item?.label}
                  </div>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_11)}>
                    {numeral(item?.value).format("0,0")} บาท
                  </div>
                </div>
              );
            })}
          </PdfCardGray>

          <div className="flex space-x-1 justify-center mt-1">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>รวม</div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12)}>
              {numeral(total2).format("0,0")} บาท
            </div>
          </div>
        </PdfCardWhite>
      </PdfCard>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageTaxTwo;
