import { CardTest } from "../styles/Card";

const ViewNumberTwo = ({ formInvest, setFormInvest }) => {
  // Choice One
  const onChangeChoiceOne = (e) => {
    let value = e.target.value;
    if (value === "one") {
      setFormInvest({
        ...formInvest,
        two_question_choice_one: true,
        two_question_choice_two: false,
        two_question_choice_three: false,
        two_question_choice_four: false,
      });
    }
  };

  // Choice Two
  const onChangeChoiceTwo = (e) => {
    let value = e.target.value;
    if (value === "two") {
      setFormInvest({
        ...formInvest,
        two_question_choice_one: false,
        two_question_choice_two: true,
        two_question_choice_three: false,
        two_question_choice_four: false,
      });
    }
  };

  // Choice Three
  const onChangeChoiceThree = (e) => {
    let value = e.target.value;
    if (value === "three") {
      setFormInvest({
        ...formInvest,
        two_question_choice_one: false,
        two_question_choice_two: false,
        two_question_choice_three: true,
        two_question_choice_four: false,
      });
    }
  };

  // Choice Four
  const onChangeChoiceFour = (e) => {
    let value = e.target.value;
    if (value === "four") {
      setFormInvest({
        ...formInvest,
        two_question_choice_one: false,
        two_question_choice_two: false,
        two_question_choice_three: false,
        two_question_choice_four: true,
      });
    }
  };

  return (
    <div className=" bg-gray50 p-4 mt-5 rounded-[10px] ">
      <div className=" text-maincyan text-xs">คำถามที่ 2 จาก 10</div>
      <CardTest
        choice1={
          <div>
            มากกว่าร้อยละ 75
            <div>ของรายได้ทั้งหมด</div>
          </div>
        }
        choice2={
          <div>
            ระหว่างร้อยละ 50
            <div>ถึงร้อยละ 75 ของรายได้ทั้งหมด</div>
          </div>
        }
        choice3={
          <div>
            ระหว่างร้อยละ 25
            <div>ถึงร้อยละ 50 ของรายได้ทั้งหมด</div>
          </div>
        }
        choice4={
          <div>
            น้อยกว่าร้อยละ 25
            <div>ของรายได้ทั้งหมด</div>
          </div>
        }
        value1={"one"}
        value2={"two"}
        value3={"three"}
        value4={"four"}
        onChange1={onChangeChoiceOne}
        onChange2={onChangeChoiceTwo}
        onChange3={onChangeChoiceThree}
        onChange4={onChangeChoiceFour}
        checked1={formInvest.two_question_choice_one}
        checked2={formInvest.two_question_choice_two}
        checked3={formInvest.two_question_choice_three}
        checked4={formInvest.two_question_choice_four}
        question="2. ปัจจุบันท่านมีภาระทางการเงินและค่าใช้จ่าย
        ประจำ เช่น ค่าผ่อนบ้าน รถ ค่าใช้จ่ายส่วนตัว และค่าเลี้ยงดูครอบครัว เป็นสัดส่วนเท่าใด"
      />
    </div>
  );
};

export default ViewNumberTwo;
