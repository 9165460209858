/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  STYLE_CARD_BG,
  STYLE_HEAD_STRAGADY,
  STYLE_INPUT_SLIDER,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_TAB,
  STYLE_TAB_ACTIVE,
  STYLE_TAB_HEADER_CLASSNAME,
  STYLE_TAB_HEDER_PROPS,
  STYLE_TAB_INACTIVE,
  STYLE_TAB_PANEL,
  STYLE_TAB_PARENT,
} from "../../../utils/useStyle";
import Container from "../../../components/layout/Container";
import {
  Drawer,
  Input,
  List,
  Slider,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import ViewAfterRetirePlan_Tab from "./VIAfterRetirePlan_Tab";
import ViewBeforeRetirePlan_Tab from "./VIBeforeRetirePlan_Tab";
import { MTButton } from "../../../components/button/MTButton";
import CheckBoxMain from "../../../components/checkbox/CheckboxMain";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import LineChartMain from "../../../components/chart/LineChartMain";
import { HeaderTitle } from "../../../components/header/Header";
import NavigateGoBack from "../../../functions/Navigate.Goback";
import { useRetireStore } from "../../../_store/retireStore";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import StrategyMain from "./VIStrategy_Drawer";
import LineChart2Main from "../../../components/chart/LineChart2Main";
import numeral from "numeral";
import {
  generateLabel,
  normalLizeLabel,
  normalrizeDatatoLabel,
} from "../../../functions/main";
import { useStrategyStore } from "../../../_store/strategyRetireStore";
import { useIsMobile } from "../../../utils/useIsMobile";
import { avgWeight } from "../functions/functionMain";
import { useOpenStore } from "../../../_store/openStore";
import {
  dataAge,
  dataAgeAF,
  loopIncome,
  loopIncomeAF,
  loopInvestmentAssets,
  loopInvestmentAssetsAF,
  loopOutcome,
  loopOutcomeAF,
  loopRemain,
  loopRemainAF,
  loopReturnInvestment,
  loopReturnInvestmentAF,
} from "../functions/functionRetriementPlan";
import { useStore } from "../../../_store/useStore";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import { first_reload } from "../../tax_plan/functions/firstLoad";
import { useUserStore } from "../../../_store/userStore";
import { calPMT, calPMTFVA } from "../../education/functions/function";
import api from "../../../api/api.main";

const RetireContext = createContext();
export const useRetireContext = () => useContext(RetireContext);

const ViewSummaryRetirePlan = () => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);
  const strategy = useStrategyStore((state) => state.strategy);
  const setStrategy = useStrategyStore((state) => state.setStrategy);
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  const isMobile = useIsMobile();

  const [isPass, setIsPass] = useState("");
  const [tabPlan, setTabPlan] = useState(1);
  const [openStrategy, setOpenStrategy] = useState("");
  const labelhead = [
    {
      label: "ก่อนวางแผน",
      value: 1,
    },
    {
      label: "หลังวางแผน",
      value: 2,
    },
  ];
  const navigate = useNavigate();

  const [openInfo, setOpenInfo] = useState("");
  // const [strategy, setStrategy] = useState([]);

  const openOutcome = useOpenStore((state) => state.openOutcome);
  const setOpenOutcome = useOpenStore((state) => state.setOpenOutcome);

  const openPassive = useOpenStore((state) => state.openPassive);
  const setOpenPasssive = useOpenStore((state) => state.setOpenPasssive);

  const openIncome = useOpenStore((state) => state.openIncome);
  const setOpenIncome = useOpenStore((state) => state.setOpenIncome);

  const openAssetGen = useOpenStore((state) => state.openAssetGen);
  const setOpenAssetGen = useOpenStore((state) => state.setOpenAssetGen);

  const openSalary = useOpenStore((state) => state.openSalary);
  const setOpenSalary = useOpenStore((state) => state.setOpenSalary);

  const openInvestment = useOpenStore((state) => state.openInvestment);
  const setOpenInvestment = useOpenStore((state) => state.setOpenInvestment);

  const openControlRate = useOpenStore((state) => state.openControlRate);
  const setOpenControlRate = useOpenStore((state) => state.setOpenControlRate);

  const openRetire = useOpenStore((state) => state.openRetire);
  const setOpenRetire = useOpenStore((state) => state.setOpenRetire);

  const openDead = useOpenStore((state) => state.openDead);
  const setOpenDead = useOpenStore((state) => state.setOpenDead);

  const [error, setError] = useState();

  useEffect(() => {
    const newRetireData = {
      ...retireData,
      bf_plan_age: dataAge(),
      bf_plan_income: loopIncome(),
      bf_plan_outcome: loopOutcome(),
      bf_plan_remain: loopRemain(),
      bf_return_investment: loopReturnInvestment(),
      bf_investment_assets: loopInvestmentAssets(),

      af_plan_age: dataAgeAF(),
      af_plan_income: loopIncomeAF(),
      af_plan_outcome: loopOutcomeAF(),
      af_plan_remain: loopRemainAF(),
      af_return_investment: loopReturnInvestmentAF(),
      af_investment_assets: loopInvestmentAssetsAF(),
    };
    setRetireData(newRetireData);
  }, [
    setRetireData,
    retireData?.strategy_out_come,
    retireData?.strategy_income,
    retireData?.strategy_passive_income_retire,
    retireData?.strategy_assets_generate,
    retireData?.strategy_assets_generate_per_year,
    retireData?.strategy_salary,
    retireData?.strategy_salary_peryear,
    retireData?.strategy_control_rate_outcome,
    retireData?.strategy_age_retire,
    retireData?.strategy_age_dead,
    retireData?.strategy_investment_bf_retirement,
    retireData?.strategy_investment_af_retirement,
  ]);

  const dataInvest = () => {
    let sum = [];
    sum = retireData?.bf_return_investment?.map((bf_return_investment) => {
      return bf_return_investment?.invest;
    });
    return sum;
  };

  const lastResult = () => {
    if (
      !Array.isArray(getInvestSum) ||
      isNaN(getInvestSum[getInvestSum.length - 1])
    ) {
      return 0;
    } else {
      return getInvestSum[getInvestSum.length - 1];
    }
  };

  // console.log("bf_investment_assets", retireData?.bf_investment_assets);
  const dataPlan = retireData?.bf_investment_assets?.map(
    (bf_investment_assets) => {
      // console.log("bf_investment_assets", bf_investment_assets?.results);
      return bf_investment_assets?.results;
    }
  );
  const dataPlanAF = retireData?.af_investment_assets?.map(
    (af_investment_assets) => {
      return af_investment_assets?.results;
    }
  );

  const getInvestSum = loopInvestmentAssets();
  const getInvestSumAF = loopInvestmentAssetsAF();

  const lastResultBF = getInvestSum[getInvestSum?.length - 1];
  const lastResultAF = getInvestSumAF[getInvestSumAF?.length - 1];
  const retirementAge = retireData?.age_retire;
  const retireBF = getInvestSum.find(
    (result) => result.age === retirementAge
  )?.results;
  const retireAF = getInvestSumAF.find(
    (result) => result.age === retirementAge
  )?.results;

  const getremainBF = getInvestSum?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];

  const remainBF = getremainBF?.age - 1;

  const getremainAF = getInvestSumAF?.filter((thisRemain, i) => {
    if (thisRemain?.results <= 0) return { ...thisRemain };
  })?.[0];

  const remainAF = Number(getremainAF?.age) - 1;

  const checkError =
    retireData?.strategy_sum === 0 || retireData?.strategy_sum === "";

  let check = tabPlan === 2
    ? lastResultAF?.results
    : lastResultBF?.results;

  const onClickViewSTG = () => setOpenStrategy(!openStrategy);

  const convertYearToMonth = (year) => {
    return year * 12;
  };

  const beforeDiffYear = retireData?.age_retire - retireData?.age_current;
  const afterDiffYear =
    retireData?.strategy_age_retire - retireData?.age_current;

  // investment_bf_retirement ผลตอบแทนจากการลงทุนก่อนเกษียณต่อปี (%)
  const sumBeforeRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(beforeDiffYear);
    const sumPMT = calPMTFVA(
      lastResultBF?.results < 0
        ? Math.abs(lastResultBF?.results)
        : lastResultBF?.results,
      retireData?.investment_bf_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);

  // investment_bf_retirement
  const sumAfterRetireInvest = useMemo(() => {
    const convertedMonth = convertYearToMonth(afterDiffYear);
    const sumPMT = calPMTFVA(
      lastResultAF?.results < 0
        ? Math.abs(lastResultAF?.results)
        : lastResultAF?.results,
      retireData?.investment_af_retirement / 100 / 12,
      convertedMonth
    );
    return sumPMT;
  }, [retireData]);

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => { 
    sendRetireData();

  }, [retireData]);

  const sendRetireData = async () => {
    try {
      const id = userData._id;

      await api.put(`profiles/${id}`, {
        retirement_before: lastResultBF?.results,
        retirement_after: lastResultAF?.results,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="first_reload">
      <Container>
        <HeaderTitle
          title="วางแผนเกษียณ"
          isGoback
          onClickGoBack={() => {
            NavigateGoBack(navigate);
          }}
        />
        <div className={`${STYLE_MARGIN_TOP_PARENT} mb-10  `}>
          <div className="px-6">
            <div className=" mb-[18px]">
              {check > 0 ? (
                <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                  <img
                    src="/images/goodface.svg"
                    className="w-18 h-18 mx-auto"
                  />
                  <div>
                    <div>ยินดีด้วย...</div>
                    ท่านสามารถใช้ชีวิตหลังเกษียณได้ตามที่ท่านตั้งใจไว้
                  </div>
                </div>
              ) : (
                <div className=" text-center text-black text-md flex flex-col gap-[9px]">
                  <img
                    src="/images/badface.svg"
                    className="w-18 h-18 mx-auto"
                  />
                  <div>
                    ไม่นะ..เมื่อท่านอายุ {retireData?.age_dead} ปี
                    <div>เงินของท่านจะหมดลง ก่อนสิ้นอายุขัย</div>
                  </div>
                </div>
              )}
            </div>
            {retireData?.strategy_sum ? (
              <div className={`${STYLE_CARD_BG} mb-[18px]`}>
                <div className=" flex flex-col gap-2 mb-[8px] ">
                  <div className={STYLE_HEAD_STRAGADY}>
                    เปรียบเทียบระหว่างก่อน-หลังวางแผน
                  </div>
                  <div
                    className={`border-b border-mainyellow w-[295px] `}
                  ></div>
                </div>
                <div className="px-4">
                  <LineChart2Main
                    labels={dataAgeAF()}
                    dataPlan={dataPlan}
                    dataPlan2={dataPlanAF}
                    head={`ก่อนปรับกลยุทธ์`}
                    head2={`หลังปรับกลยุทธ์`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={`${STYLE_CARD_BG}  px-4`}>
              <div className={`${STYLE_TAB_HEADER_CLASSNAME} flex w-full `}>
                <div className={` w-full`}>
                  <div
                    onClick={() => {
                      setTabPlan(1);
                    }}
                    className={classNames(
                      tabPlan === 1
                        ? `${STYLE_TAB_HEDER_PROPS} font-semibold font-IBMPlexSansThai text-md md:text-lg  w-full text-center text-gray100 cursor-pointer `
                        : `${STYLE_TAB_INACTIVE} text-center text-gray100 flex justify-center items-center py-1 cursor-pointer `
                    )}
                  >
                    ก่อนวางแผน
                  </div>
                </div>
                <div className={` w-full`}>
                  <div
                    onClick={() => {
                      !checkError && setTabPlan(2);
                    }}
                    className={classNames(
                      tabPlan === 2
                        ? `${STYLE_TAB_HEDER_PROPS} font-semibold font-IBMPlexSansThai xxs:text-sm sm:text-md w-full text-center text-gray100 flex justify-center items-center cursor-pointer `
                        : `${STYLE_TAB_INACTIVE} text-center text-gray100 flex justify-center items-center py-1 cursor-pointer `
                    )}
                  >
                    หลังวางแผน
                  </div>
                </div>
              </div>

              <div className=" ">
                {/*-------------------- ตารางก่อนวางแผน --------------------*/}
                {tabPlan === 1 && (
                  <ViewBeforeRetirePlan_Tab
                    onClickSTG={onClickViewSTG}
                    labelsbf={dataAge()}
                    databf={dataPlan}
                    lastResult={lastResultBF}
                    retireResult={retireBF}
                    remain={remainBF}
                    retireData={retireData}
                    setRetireData={setRetireData}
                    strategyChange={retireData?.strategy_sum ? true : false}
                    onClick={() => {
                      setTabPlan(1);
                    }}
                    beforeDiffYear={beforeDiffYear}
                    sumBeforeRetireInvest={sumBeforeRetireInvest}
                  />
                )}

                {/*-------------------- ตารางหลังวางแผน --------------------*/}
                {tabPlan === 2 && (
                  <ViewAfterRetirePlan_Tab
                    onClickSTG={onClickViewSTG}
                    dataaf={dataPlanAF}
                    labelsaf={dataAgeAF()}
                    showPaire={retireData?.strategy_sum}
                    lastResult={lastResultAF}
                    retireResult={retireBF}
                    remain={remainAF}
                    strategyChange={retireData?.strategy_sum ? true : false}
                    strategy={strategy}
                    setStrategy={setStrategy}
                    isPass={isPass}
                    retireData={retireData}
                    setRetireData={setRetireData}
                    onClick={() => {
                      setTabPlan(2);
                    }}
                    afterDiffYear={afterDiffYear}
                    sumAfterRetireInvest={sumAfterRetireInvest}
                  />
                )}
              </div>

              <div className=" mt-3 mb-[6px] grid grid-cols-2 gap-[13px]  w-full">
                <MTButton
                  width="w-full"
                  onClick={() => {
                    if (tabPlan === 1) {
                      return navigate("/summarytablemovements?before");
                    }
                    return navigate("/summarytablemovements?after");
                  }}
                  text="ดูรายละเอียด"
                  color="border-maincyan bg-white"
                  colortext="text-maincyan group-hover:text-white font-medium"
                  hovercolor="hover:bg-maincyan group "
                  variant="outlined"
                />
                <MTButton
                  // loading={loading}
                  width="w-full"
                  text="ย้อนกลับ"
                  color="bg-gray60"
                  hovercolor="hover:bg-gray80"
                  onClick={() => {
                    const temp = { ...retireData };
                    // console.log("temp", temp);
                    setRetireData(temp);
                    NavigateGoBack(navigate);
                  }}
                />

                {/*---------- ทดสอบความอ่อนไหว หลังวางแผน ---------*/}
                {tabPlan === 2 && (
                  <>
                    {userData?._id ? (
                      userData?.is_premium ? (
                        <div className=" col-span-full">
                          <MTButton
                            onClick={() => navigate("/sensitivetest")}
                            text="ทดสอบความอ่อนไหว"
                            color="bg-maincyan "
                            colortext="text-white font-medium"
                            width="w-full"
                          />
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>

              {openStrategy && (
                <StrategyMain
                  setError={setError}
                  error={error}
                  strategy={strategy}
                  setStrategy={setStrategy}
                  openStrategy={openStrategy}
                  setOpenStrategy={setOpenStrategy}
                  openInfo={openInfo}
                  setOpenInfo={setOpenInfo}
                  openOutcome={openOutcome}
                  setOpenOutcome={setOpenOutcome}
                  openPassive={openPassive}
                  setOpenPasssive={setOpenPasssive}
                  openIncome={openIncome}
                  setOpenIncome={setOpenIncome}
                  openAssetGen={openAssetGen}
                  setOpenAssetGen={setOpenAssetGen}
                  openSalary={openSalary}
                  setOpenSalary={setOpenSalary}
                  openInvestment={openInvestment}
                  setOpenInvestment={setOpenInvestment}
                  openControlRate={openControlRate}
                  setOpenControlRate={setOpenControlRate}
                  openRetire={openRetire}
                  setOpenRetire={setOpenRetire}
                  openDead={openDead}
                  setOpenDead={setOpenDead}
                  setTabPlan={setTabPlan}
                  onClick={() => {
                    setIsPass(true);
                    setOpenStrategy(false);
                  }}
                />
              )}
            </div>
            <FooterAfterSum />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ViewSummaryRetirePlan;
