import { useLocation, useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { NavigatePath } from "../../../functions/Navigate.Goback";
import { CardKidsInfo } from "../styles/CardMain";
import {
  STYLE_BODY_EDU_FUND,
  STYLE_CARD_BG,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_TABLE_EDU_FUND,
  STYLE_TAB_HEADER_CLASSNAME,
  STYLE_TAB_HEDER_PROPS,
  STYLE_TAB_INACTIVE,
  STYLE_TEXT_BLUE,
} from "../../../utils/useStyle";
import { useEffect, useState } from "react";
import { CardMain } from "../../../components/card/CardMain";
import DropDownMain from "../../../components/dropdown/DropDownMain";
import {
  key_under_education,
  options_education_rank,
  options_educations_type,
  options_under_education,
} from "../functions/options";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import { RadioMain } from "../../../components/radio/Radio.Main";
import { MTButton } from "../../../components/button/MTButton";
import classNames from "classnames";
import { FooterAfterSum } from "../../../components/FooterAfterSum/FooterAfterSum";
import ViewExpensesEDU from "./ViewExpenses.EDU";
import ViewInsuaranceFundEDU from "./ViewInsuaranceFund.EDU";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { useEducationStore } from "../../../_store/educationStore";
import { fileToBase64 } from "../../../utils/useFunctions";
import _ from "lodash";
import { calPMT } from "../functions/function";
import numeral from "numeral";
import { roundUp } from "../../../functions/main";
import { PopupMain, PopupMain2 } from "../../../components/popup/PopupMain";
import { uploadData } from "aws-amplify/storage";
import api from "../../../api/api.main";

const ViewEduInfo = () => {
  const navigate = useNavigate();
  const mode = "default_value";
  const [loading, setLoading] = useState(false);
  const [isCal, setIsCal] = useState(false);
  const [tab, setTab] = useState(1);
  const [alertMessage, setAlertMessage] = useState("");
  const educationData = useEducationStore((state) => state.educationData);
  const setEducationData = useEducationStore((state) => state.setEducationData);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const currentEducation = educationData.find((item) => item.id === id);
  const findEducationIndex = educationData.findIndex((item) => item.id === id);
  const [localEducationData, setLocalEducationData] = useState({
    ...currentEducation,
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (localEducationData.under_education === "custom") {
      generateCustom();
    }
  }, [
    localEducationData.under_education,
    localEducationData.current_education,
    localEducationData.target_education,
  ]);

  const generateCustom = () => {
    let education_items = [];
    if (
      localEducationData.under_education === "custom" &&
      localEducationData.current_education &&
      localEducationData.target_education
    ) {
      const find_current_index = options_education_rank.findIndex((curr) => {
        return curr.value === localEducationData.current_education;
      });
      const find_target_index = options_education_rank.findIndex((curr) => {
        return curr.value === localEducationData.target_education;
      });
      const filter_education_duration_items = options_education_rank.filter(
        (curr, index) => {
          return index <= find_target_index && index >= find_current_index;
        }
      );
      for (const filter_education_duration_item of filter_education_duration_items) {
        let create_education_item = {
          education: filter_education_duration_item,
          under_education: "",
          under_education_data: null,
        };
        education_items.push(create_education_item);
      }
    }
    const newLocalEducationData = { ...localEducationData };
    newLocalEducationData.education_items = education_items;
    setLocalEducationData(newLocalEducationData);
    setIsCal(false);
  };

  // คำนวณ
  const onClickCal = () => {
    calDuringEducation();
    // setOpenAlert2(true);
  };

  // คำนวนค่าใช้จ่ายต่อปีการศึกษา
  const calExpenses = (under_education_data, year) => {
    let sum = 0;
    const all_expenses =
      under_education_data?.expenses_per_year +
      under_education_data?.expenses_living;
    const inflation_amount = 6 / 100;

    // =ROUNDUP(L3*(1+0.06)^(N2-1))
    sum = Math.ceil(all_expenses * Math.pow(1 + inflation_amount, year - 1));
    return sum;
  };

  // คำนวนค่าใช้จ่ายต่อปีการศึกษา mode default_value
  const calExpensesDefaultValue = (value, year) => {
    let sum = 0;
    const all_expenses = value;
    const inflation_amount = 6 / 100;

    // =ROUNDUP(L3*(1+0.06)^(N2-1))
    sum = Math.ceil(all_expenses * Math.pow(1 + inflation_amount, year - 1));
    return sum;
  };

  // คำนวนช่วงเวลาการศึกษา
  const calDuringEducation = () => {
    const current = localEducationData.current_education;
    const target = localEducationData.target_education;
    const under_education = localEducationData.under_education;

    if (!current || !target || !under_education) return;

    const find_current_index = options_education_rank.findIndex((curr) => {
      return curr.value === current;
    });
    const find_target_index = options_education_rank.findIndex((curr) => {
      return curr.value === target;
    });
    const filter_education_duration_items = options_education_rank.filter(
      (curr, index) => {
        return index <= find_target_index && index >= find_current_index;
      }
    );

    if (find_current_index > find_target_index) {
      return setAlertMessage("กรุณาเลือกเป้าหมายการศึกษาใหม่");
    }

    // ถ้ากรอกข้อมูล,สังกัดไม่ครบ
    if (under_education === "custom") {
      const someUnderEducationEmpty = localEducationData?.education_items?.some(
        (item) => !item.under_education
      );
      if (someUnderEducationEmpty) {
        return setAlertMessage("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    }

    const group_label = _.groupBy(filter_education_duration_items, "key");
    const group_label_keys = Object.keys(group_label);
    const first_group_key = group_label_keys?.[0];
    let sum_total_education = 0;
    let sum_total_education_accumulate = 0;
    let sum_total_invest = 0;

    let education_items = [];
    let index = 0;
    for (const filter_education_duration_item of filter_education_duration_items) {
      let create_education_item = {
        under_education,
        under_education_data: null,
        sum_under_education: 0,
        sum_under_education_accumulate: 0,
        sum_under_education_accumulate_reverse: 0,
        sum_insurance: 0,
        sum_invest: 0,
        education: filter_education_duration_item,
        is_invset: false,
        invest_keys: [],
      };

      if (localEducationData.under_education === "custom") {
        create_education_item["under_education"] =
          localEducationData?.education_items[index]?.under_education;
      }

      if (key_under_education?.[create_education_item.under_education]) {
        if (mode === "default_value") {
          create_education_item["under_education_data"] = null;
          create_education_item["sum_under_education"] =
            calExpensesDefaultValue(
              key_under_education?.[create_education_item.under_education]
                .default_value,
              filter_education_duration_item.year
            );
        } else {
          const under_education_data =
            key_under_education?.[create_education_item.under_education]?.[
              filter_education_duration_item?.key
            ];
          create_education_item["under_education_data"] = under_education_data;
          create_education_item["sum_under_education"] = calExpenses(
            under_education_data,
            filter_education_duration_item.year
          );
        }

        if (first_group_key !== filter_education_duration_item.key) {
          create_education_item.is_invset = true;
        }
        if (index > 0) {
          create_education_item["sum_under_education_accumulate"] +=
            create_education_item["sum_under_education"] +
            education_items[index - 1]["sum_under_education_accumulate"];
        } else {
          create_education_item["sum_under_education_accumulate"] =
            create_education_item["sum_under_education"];
        }
      }
      education_items.push(create_education_item);
      index++;
    }

    let create_invest_dynamic_education_item = {};
    let create_invest_dynamic_education_item_5 = {};
    for (const group_label_key of group_label_keys) {
      const sum_total = education_items
        .filter((item) => item.education.key === group_label_key)
        .reduce((sum, item) => sum + item.sum_under_education, 0);
      create_invest_dynamic_education_item = {
        ...create_invest_dynamic_education_item,
        [group_label_key]: sum_total,
      };
      create_invest_dynamic_education_item_5 = {
        ...create_invest_dynamic_education_item_5,
        [`${group_label_key}_5`]: 0,
      };
    }

    // คำนวน invest
    let new_education_items = [];
    let final_index = 0;

    for (const education_item of education_items) {
      let local_final_education_item = { ...education_item };
      let local_invest_dynamic_education_item_5 = {
        ...create_invest_dynamic_education_item_5,
      };
      let sum_invest = 0;

      // =pmt(0.02,3,0,-$F$77)
      let group_index = 0;
      for (const group_label_key of group_label_keys) {
        if (!education_item?.is_invset) {
          if (
            education_item.education.key === first_group_key &&
            group_label_key === first_group_key
          ) {
            local_invest_dynamic_education_item_5[`${group_label_key}_5`] =
              education_item.sum_under_education;
          } else {
            const find_target_index = options_education_rank.findIndex(
              (curr) => {
                return curr.key === group_label_key;
              }
            );
            const filter_items = options_education_rank.filter(
              (curr, index) => {
                return index < find_target_index && index >= find_current_index;
              }
            );
            let invest_percent = group_index <= 1 ? 0.02 : 0.05;
            const pmt = calPMT(
              invest_percent,
              filter_items.length,
              0,
              -create_invest_dynamic_education_item[group_label_key]
            );
            local_invest_dynamic_education_item_5[`${group_label_key}_5`] = pmt;
          }
        } else {
          const local_find_current_index = options_education_rank.findIndex(
            (curr) => {
              return curr.value === local_final_education_item.education.value;
            }
          );
          const filter_items = options_education_rank.filter((curr, index) => {
            return (
              index >= local_find_current_index &&
              curr.key !== local_final_education_item.education.key &&
              index <= find_target_index
            );
          });
          if (filter_items.length > 0) {
            const find_filter = filter_items.find(
              (curr) => curr.key === group_label_key
            );
            if (find_filter) {
              const find_target_index = options_education_rank.findIndex(
                (curr) => {
                  return curr.key === group_label_key;
                }
              );
              const filter_items = options_education_rank.filter(
                (curr, index) => {
                  return (
                    index < find_target_index && index >= find_current_index
                  );
                }
              );
              let invest_percent = group_index <= 1 ? 0.02 : 0.05;
              const pmt = calPMT(
                invest_percent,
                filter_items.length,
                0,
                -create_invest_dynamic_education_item[group_label_key]
              );
              local_invest_dynamic_education_item_5[`${group_label_key}_5`] =
                pmt;
            }
          }
        }
        group_index++;
      }

      sum_invest = Object.values(local_invest_dynamic_education_item_5).reduce(
        (sum, item) => sum + item,
        0
      );

      new_education_items.push({
        ...education_item,
        invest_keys: group_label_keys,
        invest_dynamic_education_item: create_invest_dynamic_education_item,
        invest_dynamic_education_item_5: local_invest_dynamic_education_item_5,
        sum_invest,
      });
      final_index++;
    }

    // ทุนประกัน
    let final_education_items = [];
    let clone_final_education_items = _.cloneDeep(new_education_items);
    let sort_education_accumulate = _.cloneDeep(
      clone_final_education_items
    ).sort(
      (a, b) =>
        b.sum_under_education_accumulate - a.sum_under_education_accumulate
    );
    for (let index = 0; index < clone_final_education_items.length; index++) {
      const final_education_item = { ...clone_final_education_items[index] };
      if (index === 0) {
        final_education_items.push({
          ...final_education_item,
          sum_under_education_accumulate_reverse:
            sort_education_accumulate[index].sum_under_education_accumulate,
          sum_insurance: roundUp(
            sort_education_accumulate[index].sum_under_education_accumulate,
            -5
          ),
        });
      } else {
        let sum_insurance =
          sort_education_accumulate[0].sum_under_education_accumulate -
          clone_final_education_items[index - 1].sum_under_education_accumulate;
        let sum_under_education_accumulate_reverse =
          sort_education_accumulate[index].sum_under_education_accumulate;
        final_education_items.push({
          ...final_education_item,
          sum_under_education_accumulate_reverse,
          sum_insurance: roundUp(sum_insurance, -5),
        });
      }
    }

    sum_total_education = final_education_items.reduce((prev, curr) => {
      return prev + curr.sum_under_education;
    }, 0);

    sum_total_education_accumulate = final_education_items.reduce(
      (prev, curr) => {
        return prev + curr.sum_under_education_accumulate;
      },
      0
    );

    sum_total_invest = final_education_items.reduce((prev, curr) => {
      return prev + curr.sum_invest;
    }, 0);

    let sameObj = {
      ...localEducationData,
      sum_total_education,
      sum_total_education_accumulate,
      sum_total_invest,
      sum_total_save: sum_total_education - sum_total_invest,
    };

    const newLocalEducationData = { ...sameObj };
    newLocalEducationData["education_items"] = final_education_items;
    setLocalEducationData(newLocalEducationData);

    const newEducationData = [...educationData];
    newEducationData[findEducationIndex] = sameObj;
    newEducationData[findEducationIndex]["education_items"] =
      final_education_items;
    setEducationData(newEducationData);
    setIsCal(true);
  };

  // เปลี่ยนแปลงข้อมูลการศึกษา
  const onChangeEducation = (name) => (e) => {
    const finalValue = e?.value ?? e?.target?.value;
    let education_items = [];

    // คำนวณอายุ
    if (name === "year") {
      const year = finalValue;
      const todayYear = new Date().getFullYear() + 543;
      const age = todayYear - year;
      const newLocalEducationData = { ...localEducationData };
      newLocalEducationData["year"] = year;
      newLocalEducationData["age"] = age;
      setLocalEducationData(newLocalEducationData);
      return;
    }
    const newLocalEducationData = { ...localEducationData };
    newLocalEducationData[name] = finalValue;
    setLocalEducationData(newLocalEducationData);
  };

  // แก้ไขข้อมูล
  const onClickEdit = () => {
    const newEducationData = [...educationData];
    newEducationData[findEducationIndex]["isEdit"] = true;
    setLocalEducationData({ ...currentEducation, isEdit: true });
    setEducationData(newEducationData);
  };

  function base64ToFile(base64String, fileName) {
    // Remove the data URL prefix if present
    const base64Header = 'data:';
    const base64ContentIndex = base64String.indexOf(base64Header) !== -1 ? base64String.indexOf(',') + 1 : 0;
    const base64Data = base64String.substring(base64ContentIndex);
  
    // Convert base64 to binary string
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    // Convert binary string to Uint8Array
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a Blob
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
  
    // Create a File object
    const file = new File([blob], fileName, { type: 'application/octet-stream' });
  
    return file;
  }

  // บันทึกข้อมูล
  const onClickSubmit = async () => {
    setLoadingSubmit(true);

    let newImg = `${new Date().getTime()}`;
    if (String(localEducationData.img).includes("base64")) {
      const uploaded = await uploadData({
        path: `public/${newImg}`,
        data: base64ToFile(localEducationData.img),
        options: {
          contentType: "image/png",
        },
      }).result
    }

    const newEducationData = [...educationData];
    newEducationData[findEducationIndex] = {
      ...localEducationData,
      img: newImg,
      isEdit: false,
    };
    setLocalEducationData({
      ...localEducationData,
      img: newImg,
      isEdit: false,
    });
    setEducationData(newEducationData);
    setLoadingSubmit(false);
  };

  // อัพโหลดรูป
  const onClickUpload = async (e) => {
    const file = e.target.files[0];
    const img = await fileToBase64(file);
    const newLocalEducationData = { ...localEducationData };
    newLocalEducationData["img"] = img;
    setLocalEducationData(newLocalEducationData);
  };

  // onchange custom under education
  const onChangeCustomUnderEducation = (index) => (e) => {
    const finalValue = e?.value ?? e?.target?.value;
    const newLocalEducationData = { ...localEducationData };
    newLocalEducationData["education_items"][index]["under_education"] =
      finalValue;
    setLocalEducationData(newLocalEducationData);
  };

  // console.log("local", localEducationData);

  return (
    <div>
      <Container>
        <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
          <HeaderTitle
            title="วางแผนการศึกษาบุตร"
            isGoback
            onClickGoBack={() => {
              navigate("/education");
            }}
          />
          <div className={`${STYLE_PADDING_MAIN} space-y-[30px] mb-10`}>
            <CardKidsInfo
              isEdit={localEducationData.isEdit}
              index={0}
              name={localEducationData.name}
              year={localEducationData.year}
              age={localEducationData.age}
              img={localEducationData.img}
              loading={loading || loadingSubmit}
              onChangeEducation={onChangeEducation}
              onClickEdit={onClickEdit}
              onClickSubmit={onClickSubmit}
              onClickUpload={onClickUpload}
            />
            <CardMain
              title={"การศึกษาปัจจุบัน"}
              panel={
                <div className=" px-3 -mt-3">
                  <DropDownMain
                    value={options_education_rank.find(
                      (option) =>
                        option.value === localEducationData.current_education
                    )}
                    options={options_education_rank}
                    onChange={onChangeEducation("current_education")}
                  />
                </div>
              }
            />
            <CardMain
              title={"เป้าหมายการศึกษา"}
              panel={
                <div className=" px-3 -mt-3">
                  <DropDownMain
                    options={options_education_rank}
                    onChange={onChangeEducation("target_education")}
                    value={options_education_rank.find(
                      (option) =>
                        option.value === localEducationData.target_education
                    )}
                  />
                </div>
              }
            />
            <CardMain
              title={"สังกัดการศึกษา"}
              panel={
                <div className=" px-3 -mt-3">
                  <div className=" grid grid-cols-2">
                    {options_under_education.map((option) => {
                      return (
                        <RadioMain
                          key={option.value}
                          name="under_education"
                          value={option.value}
                          checked={
                            localEducationData.under_education === option.value
                          }
                          onChange={onChangeEducation("under_education")}
                          label={
                            <div className={STYLE_TEXT_BLUE}>
                              {option.label}
                            </div>
                          }
                        />
                      );
                    })}
                  </div>
                  {localEducationData.under_education === "custom" ? (
                    <div className=" ">
                      <div className=" text-maingray font-medium text-center text-sm border-t border-t-gray60 pt-2 flex justify-center items-center gap-1">
                        ดูรายละเอียด
                        <ICON_ARROW_UP_DOWN
                          className={` ${
                            localEducationData.under_education === "custom"
                              ? " rotate-180"
                              : ""
                          } w-5 h-5`}
                        />
                      </div>
                      <div className=" mt-2">
                        {/* Header */}
                        <div className=" bg-[#F9F9F9] h-11 grid grid-cols-12 items-center w-full  rounded-t-[20px] ">
                          <div
                            className={`${STYLE_TABLE_EDU_FUND} rounded-tl-[20px] col-span-5 `}
                          >
                            ระดับการศึกษา
                          </div>
                          <div
                            className={`${STYLE_TABLE_EDU_FUND} border-r-0 rounded-tr-[20px] col-span-7 `}
                          >
                            สังกัดการศึกษา
                          </div>
                        </div>
                        {/* Body */}

                        {localEducationData?.education_items?.map(
                          (education_item, index) => {
                            const findValue = options_under_education.find(
                              (option) =>
                                option.value === education_item?.under_education
                            );

                            return (
                              <div
                                key={index}
                                className=" grid grid-cols-12 items-center justify-center"
                              >
                                <div
                                  className={`${STYLE_BODY_EDU_FUND} text-mainblue col-span-5`}
                                >
                                  {education_item?.education?.label}
                                </div>
                                <div
                                  className={`bg-white whitespace-nowrap  flex-none  text-sm text-center border-r border-r-gray60 h-[56px] flex justify-center items-center border-b border-b-gray60 text-maincyan  col-span-7 px-1 `}
                                >
                                  <div className=" border border-gray60 rounded-full">
                                    <DropDownMain
                                      value={findValue ? findValue : null}
                                      onChange={onChangeCustomUnderEducation(
                                        index
                                      )}
                                      options={options_under_education.filter(
                                        (option) => option.value !== "custom"
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              }
            />
            <div className={`flex justify-center ${isCal ? "" : "pb-10"}`}>
              <MTButton
                text="คำนวณ"
                loading={loading}
                onClick={onClickCal}
                width="w-[228px]"
              />
            </div>
            {isCal && (
              <div className={`${STYLE_CARD_BG}`}>
                <div
                  className={`${STYLE_TAB_HEADER_CLASSNAME} flex w-full px-4 `}
                >
                  <div className={` w-full`}>
                    <div
                      onClick={() => {
                        setTab(1);
                      }}
                      className={classNames(
                        tab === 1
                          ? ` bg-white shadowtab text-secondcyan rounded-[10px]  py-1  font-semibold font-IBMPlexSansThai text-sm w-full text-center  cursor-pointer `
                          : ` text-center text-gray100 flex justify-center items-center py-1 cursor-pointer text-sm `
                      )}
                    >
                      ค่าใช้จ่ายเพื่อการศึกษา
                    </div>
                  </div>
                  <div className={` w-full`}>
                    <div
                      onClick={() => {
                        setTab(2);
                      }}
                      className={classNames(
                        tab === 2
                          ? ` bg-white shadowtab text-secondcyan rounded-[10px]  py-1  font-semibold font-IBMPlexSansThai text-sm w-full text-center  cursor-pointer `
                          : ` text-center text-gray100 flex justify-center items-center py-1 cursor-pointer text-sm `
                      )}
                    >
                      ทุนประกัน
                    </div>
                  </div>
                </div>

                {tab === 1 && (
                  <ViewExpensesEDU localEducationData={localEducationData} />
                )}
                {tab === 2 && (
                  <ViewInsuaranceFundEDU
                    localEducationData={localEducationData}
                  />
                )}
              </div>
            )}
            {tab === 1 && isCal && (
              <div className=" text-center teaxt-black">
                ถ้าหากคุณวางแผนการศึกษาบุตรโดยนำเงินไปออม หรือลงทุนให้มีผลตอบแทน
                <div className=" mt-2 text-mainblue font-medium">
                  คุณจะประหยัดเงินไปทั้งสิ้น{" "}
                  <span className=" text-maincyan font-semibold text-xl">
                    {numeral(localEducationData.sum_total_save).format("0,0")}{" "}
                    บาท
                  </span>
                </div>
                <div className=" mt-3 text-xs text-maingray">
                  ( *หมายเหตุ สมมุติฐานหากจำนวนการเก็บออม/ลงทุน
                  น้อยกว่าหรือเท่ากับ 3 ปี อัตราผลตอบแทนจะอยู่ที่ 2 % ถ้ามากกว่า
                  3 ปีจะอยู่ที่ 5 % )
                </div>
              </div>
            )}
            <FooterAfterSum />

            {/* ---------- Popup Alert เลือกชั้นปี ---------- */}
            <PopupMain2
              text={alertMessage}
              open={Boolean(alertMessage)}
              onClick={() => {
                setAlertMessage("");
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ViewEduInfo;
