import { useEffect, useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import { cn, toFloat2 } from "../../../utils/useFunctions";
import {
  PdfBalanceHeaderItemCard,
  PdfBalanceItemCard,
  PdfCard,
  PdfCardGray,
  PdfCardWhite,
  PdfFamilyHeaderItemCard,
} from "../component/pdf.c.card";
import PdfHeader from "../component/pdf.c.header";
import {
  PdfHeaderCardText,
  PdfHeaderText,
} from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PAGE_HORIZONTAL,
  STYLE_PDF_BORDER_TABLE,
  STYLE_PDF_ITEM_TOTAL,
  STYLE_PDF_TEXT_MEDIUM_11,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_13,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_GRAY_13,
  STYLE_PDF_TEXT_NORMAL_10,
  STYLE_PDF_TEXT_NORMAL_11,
  STYLE_PDF_TEXT_NORMAL_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_10,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_BLUE_12,
  STYLE_PDF_TEXT_NORMAL_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_13,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_12,
  STYLE_PDF_TEXT_SEMIBOLD_BLUE_14,
} from "../styles/pdf.style";
import {
  key_property_invest_category,
  options_property_asset,
  options_property_intangible,
  options_property_invest,
  options_property_private,
} from "../../balance/functions";
import _ from "lodash";
import numeral from "numeral";
import {
  calPropertyLocalOption,
  getAllBalanceTotal,
  getBalanceItems,
} from "../lib/pdf.lib";
import PdfFooter from "../component/pdf.c.footer";
import { useFuturePlanStore } from "../../../_store/futurePlanStore";
import FamilyBarChart from "../component/pdf.c.familyChart";
import ViewTableFamily from "../../insuarance_plan/component/VITableFamily.Main";
import { StatusCard } from "../../insuarance_plan/styles/Card";
import { ImageMain } from "../../../components/img/Img.Main";
import { DonutChartInvestMain } from "../../../components/chart/DonutChartMain";
import LineChartSavingMain from "../../saving_plan/components/LineChartSaving.Main";
import { useTaxStore } from "../../../_store/taxplanStore";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import { calculateTax, checkSum } from "../../tax_plan/functions";
import { ICON_BAHT, ICON_GRAPH } from "../../../utils/useIcons";
import { STYLE_CARD_BG } from "../../../utils/useStyle";
import { useEducationStore } from "../../../_store/educationStore";
import {
  options_education_rank,
  options_under_education,
} from "../../education/functions/options";
import {
  LineChartEduExpensesMain,
  LineChartEduInvestMain,
} from "../../education/components/EduLineChart";
import { getUrl } from "aws-amplify/storage";

const InjectImage = ({ src }) => {
  const [imageKey, setImageKey] = useState(0);

  useEffect(() => {
    initData();
  }, [src]);

  const initData = async () => {
    if (!src) return;
    const imageKeyS3 = await getUrl({
      path: `public/${src}`,
      options: {
        expiresIn: 60 * 60 * 24 * 1,
      },
    });
    const convertedImageKeyBase64 = await convertUrlToBase64(
      imageKeyS3.url.href
    );
    setImageKey(convertedImageKeyBase64);
  };

  async function convertUrlToBase64(url) {
    try {
      // Fetch the file from the URL
      const response = await fetch(url, {
        mode: "cors",
      });

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the file as a blob
      const blob = await response.blob();

      // Create a FileReader to read the blob as a data URL (base64)
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      // Return a promise that resolves with the base64 string
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting URL to base64:", error);
      throw error;
    }
  }

  return (
    <ImageMain
      src={imageKey ? imageKey : "../images/user_circle.png"}
      className="w-[70px] h-[70px] object-cover "
    />
  );
};

function PdfPageEducationOne({ page }) {
  const educationData = useEducationStore((state) => state.educationData);

  const dataExpenses = (localEducationData) => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item.sum_under_education_accumulate;
      }
    );
    return expenses_items;
  };

  const dataLabels = (localEducationData) => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item?.education?.label;
      }
    );
    return expenses_items;
  };

  const dataInsurance = (localEducationData) => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item.sum_insurance;
      }
    );
    return expenses_items;
  };

  const dataInsuranceLabels = (localEducationData) => {
    const expenses_items = localEducationData?.education_items?.map(
      (education_item) => {
        return education_item?.education?.label;
      }
    );
    return expenses_items;
  };

  const headers = useMemo(() => {
    return [
      {
        label: "เริ่มแผน",
      },
      {
        label: "ควรมีทุนประกัน",
      },
    ];
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      {educationData.map((educationItem, index) => {
        return (
          <div key={index} className="flex flex-col space-y-2">
            {/* INFO */}
            <div className={cn(STYLE_PAGE)}>
              <PdfHeader />
              <PdfLine />
              <PdfHeaderText
                title="แผนการศึกษาบุตร"
                icon="/images/graduate.svg"
                classNameIcon="w-[24px] h-[27px]"
              />
              {/* แผนการศึกษาบุตร */}
              <PdfCard className={"flex flex-col space-y-3"}>
                {/* FIRST */}
                <PdfCardWhite className={cn("px-3 flex")}>
                  <div className="bg-white rounded-[10px] w-[70px] h-[70px] overflow-hidden">
                    {educationItem?.img && (
                      <InjectImage src={educationItem?.img} />
                    )}
                  </div>

                  <div className="flex justify-between w-full ">
                    {/* NAME LEFT */}
                    <div className="flex flex-col w-full">
                      <div className="flex items-center space-x-1 ml-3">
                        <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>
                          ชื่อ-สกุล
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_MEDIUM_GRAY_13,
                            "text-[14px]"
                          )}
                        >
                          {educationItem?.name}
                        </div>
                      </div>

                      <div className="flex items-center space-x-2 ml-3">
                        <div className="flex items-center space-x-1">
                          <div>
                            <img src="/images/calendar.svg" />
                          </div>
                          <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>
                            ปีเกิด
                          </div>
                          <div className={cn(STYLE_PDF_TEXT_MEDIUM_GRAY_13)}>
                            พ.ศ. {educationItem?.year}
                          </div>
                        </div>

                        <div className="flex items-center space-x-1">
                          <div>
                            <img src="/images/User.svg" />
                          </div>
                          <div className={cn(STYLE_PDF_TEXT_MEDIUM_13)}>
                            อายุ
                          </div>
                          <div className={cn(STYLE_PDF_TEXT_MEDIUM_GRAY_13)}>
                            {educationItem?.age} ปี
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* EDUCATION RIGHT */}
                    <div className="flex flex-col ml-4 w-full">
                      <div className="flex justify-between">
                        <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                          การศึกษาปัจจุบัน
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_13,
                            "ml-auto"
                          )}
                        >
                          {options_education_rank.find((rankItem) => {
                            return (
                              rankItem.value ===
                              educationItem?.current_education
                            );
                          })?.label || ""}
                        </div>
                      </div>

                      <div className="flex justify-between mt-2">
                        <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                          เป้าหมายการศึกษา
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_13,
                            "ml-auto"
                          )}
                        >
                          {
                            options_education_rank.find((rankItem) => {
                              return (
                                rankItem.value ===
                                educationItem?.target_education
                              );
                            }).label
                          }
                        </div>
                      </div>

                      <div className="flex justify-between mt-2">
                        <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                          สังกัดการศึกษา
                        </div>
                        <div
                          className={cn(
                            STYLE_PDF_TEXT_NORMAL_BLUE_13,
                            "ml-auto"
                          )}
                        >
                          {
                            options_under_education.find((rankItem) => {
                              return (
                                rankItem.value ===
                                educationItem?.under_education
                              );
                            }).label
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </PdfCardWhite>

                {/* SECOND */}
                <PdfCardWhite className={cn("px-3 flex flex-col")}>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                    วางแผนออมเงินเพื่อเป้าหมายทางการศึกษาบุตร
                  </div>
                  <div className="mt-2 flex justify-center">
                    <LineChartEduExpensesMain
                      dataExpenses={dataExpenses(educationItem)}
                      labels={dataLabels(educationItem)}
                      fontSizeX={9}
                      fontSizeY={9}
                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <div>
                      <img src="/images/education_icon.svg" />
                    </div>
                    <div className={cn(STYLE_PDF_TEXT_MEDIUM_11)}>
                      ค่าใช้จ่ายเพื่อการศึกษา / การออม
                    </div>
                  </div>

                  <div className=" text-center text-[12px] mt-2">
                    ถ้าหากคุณวางแผนการศึกษาบุตรโดยนำเงินไปออม
                    หรือลงทุนให้มีผลตอบแทน
                    <div className=" mt-2 text-mainblue font-medium">
                      คุณจะประหยัดเงินไปทั้งสิ้น{" "}
                      <span className=" text-maincyan font-semibold">
                        {numeral(educationItem.sum_total_save).format("0,0")}{" "}
                        บาท
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <div className=" mt-2 text-[10px] text-maingray text-center w-[80%]">
                        ( *หมายเหตุ สมมุติฐานหากจำนวนการเก็บออม/ลงทุน
                        น้อยกว่าหรือเท่ากับ 3 ปี อัตราผลตอบแทนจะอยู่ที่ 2 %
                        ถ้ามากกว่า 3 ปีจะอยู่ที่ 5 % )
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div
                      className={cn(STYLE_PDF_TEXT_MEDIUM_13, "text-center")}
                    >
                      ดูตารางประกอบหน้าภาคผนวก
                    </div>
                  </div>
                </PdfCardWhite>
              </PdfCard>
              <PdfFooter page={page + index * 2} />
            </div>

            {/* INFO INSURANCE */}
            <div className={cn(STYLE_PAGE)}>
              <PdfHeader />
              <PdfLine />
              <PdfHeaderText
                title="แผนการศึกษาบุตร"
                icon="/images/graduate.svg"
                classNameIcon="w-[24px] h-[27px]"
              />
              <div className={cn(STYLE_PDF_TEXT_NORMAL_12, "mt-2 text-center")}>
                แต่แผนการศึกษาบุตรจะไม่เป็นจริงหากพ่อหรือแม่มีเหตุไม่คาดฝันจากไปก่อนอันควร
              </div>

              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-center")}>
                ดังนั้น การซื้อประกันศึกษาบุตรเป็นสิ่งที่สำคัญ
              </div>

              <PdfCard classNameRoot={"my-2"} className={"flex space-y-3"}>
                <PdfCardWhite className={cn("px-3 flex flex-col")}>
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                    วางแผนซื้อประกันเพื่อการศึกษาบุตร
                  </div>

                  {/* GRAPH */}
                  <div className="flex justify-center mt-1">
                    <LineChartEduInvestMain
                      dataFund={dataInsurance(educationItem)}
                      labels={dataInsuranceLabels(educationItem)}
                      fontSizeX={9}
                      fontSizeY={9}
                      height={100}
                    />
                  </div>

                  {/* TABLE */}
                  <table className="mt-2 border-collapse w-full">
                    <thead>
                      <tr>
                        {headers.map((header, index) => {
                          return (
                            <th
                              key={index}
                              className={cn(
                                STYLE_PDF_BORDER_TABLE,
                                STYLE_PDF_TEXT_MEDIUM_BLUE_11,
                                header.className,
                                "text-[9px]",
                                "!p-0"
                              )}
                            >
                              {header.label}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {educationItem?.education_items.map(
                        (education_item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className={cn(
                                  STYLE_PDF_BORDER_TABLE,
                                  STYLE_PDF_TEXT_NORMAL_11,
                                  "text-maingray !p-0",
                                  "text-[9px]"
                                )}
                              >
                                {education_item?.education?.label}
                              </td>
                              <td
                                className={cn(
                                  STYLE_PDF_BORDER_TABLE,
                                  STYLE_PDF_TEXT_NORMAL_11,
                                  "text-maingray !p-0",
                                  "text-[9px]"
                                )}
                              >
                                {numeral(education_item?.sum_insurance).format(
                                  "0,0"
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </PdfCardWhite>
              </PdfCard>
              <PdfFooter page={page + 1 + index * 2} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PdfPageEducationOne;
