import { Radio } from "@material-tailwind/react";
import {
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_CARD_PROPOTION,
  STYLE_HEADER_SECTION_PROPOTION,
  STYLE_TITLE_PROPOTION,
} from "../../../utils/useStyle";
import numeral from "numeral";
import { MTButton } from "../../../components/button/MTButton";

const ViewNetFlowMain = ({
  value,
  status,
  onChange,
  valueChecked,
  onSubmit,
}) => {
  return (
    <div className={STYLE_CARD_PROPOTION}>
      <div className={STYLE_HEADER_SECTION_PROPOTION}>
        <div>
          <div className={STYLE_TITLE_PROPOTION}>
            {" "}
            {status === "minus" ? "กระแสเงินสดติดลบ" : "กระแสเงินสดสุทธิ"}
          </div>
          <div className={STYLE_BORDER_YELLOW_PROPOTION}></div>
        </div>
        <div className="text-center text-lg font-semibold text-maincyan">
          {value < 0 ? numeral(value)?.format("0,0") : 0} บาท
        </div>
      </div>

      {status === "zero" && (
        <div className=" px-5 pt-5 flex gap-2 items-center">
          <img src="/images/taxicon1.png" className=" w-14 h-14" alt="" />
          <div className=" text-mainblue text-sm font-medium">
            มีกระแสเงินสดเหลือจำนวน{" "}
            <span className=" font-bold">{numeral(value).format("0,0")} </span>
            เราจะนำเงินส่วนนี้ไปไว้ที่เงินเพื่อการออมและลงทุน
          </div>
        </div>
      )}

      {status === "plus" && (
        <div>
          <div className=" px-5 pt-5 flex gap-2 items-center">
            <img src="/images/ask_netflow.svg" className="" />
            <div className=" -mx-2.5">
              <div className={STYLE_TITLE_PROPOTION}>
                ต้องการนำเงินส่วนนี้เก็บออมหรือไม่
              </div>
              <div onChange={onChange} className=" flex gap-[31px] ml-2">
                <Radio
                  name="yesNoCashFlow"
                  color="light-blue"
                  className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0 "
                  label={<div className="text-mainblue font-medium">ใช่</div>}
                  value={`yes`}
                  checked={valueChecked === "yes" ? true : false}
                />
                <Radio
                  name="yesNoCashFlow"
                  color="light-blue"
                  className="border-gray55 bg-white p-0 transition-all hover:before:opacity-0"
                  label={<div className="text-mainblue font-medium">ไม่</div>}
                  value={`no`}
                  checked={valueChecked === "no" ? true : false}
                />
              </div>
            </div>
          </div>
          {/* <div className=" flex justify-center">
            <MTButton text="ยืนยัน" onClick={onSubmit} />
          </div> */}
        </div>
      )}

      {status === "minus" && (
        <div className=" px-5 pt-5 flex  items-center ">
          <img src="/images/bad_netflow.svg" className=" w-6 h-6" alt="" />
          <div className=" text-mainblue font-medium text-center w-full">
            คุณมีรายจ่ายมากกว่ารายได้
            <div>ทำให้กระแสเงินสดติดลบ</div>
            <div>ตรวจสอบข้อมูลรายรับรายจ่ายของคุณอีกครั้ง</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewNetFlowMain;
